import React from 'react';
import { DateCellWrapperProps } from 'react-big-calendar';
import { Event } from '../SFCCalendar';
import { useNavigate } from 'react-router-dom';
import { dateInTimeZone } from '../../../../utils/dateUtils';

interface CustomDayCellProp {
  date?: Date;
  eventCount: number;
  currentDates?: string[];
}

const CustomDayCell: React.FC<CustomDayCellProp> = ({ date, eventCount, currentDates }) => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: 30 }}>
      {eventCount > 0 && (
        <div
          className="rbc-event bg-primary py-1"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/admin/schedule/classes?starts_on=${date?.toISOString()}&ends_on=${date?.toISOString()}`);
          }}
        >
            {eventCount} Classes
        </div>
      )}
    </div>
  );
};

interface CustomDayCellWrapperProps extends DateCellWrapperProps {
  summarized?: boolean;
  events: Event[];
  currentDates?: string[];
}

export const CustomDayCellWrapper: React.FC<CustomDayCellWrapperProps> = ({ value, children, summarized, events, currentDates }) => {
  return (
    <div className={children.props.className} style={{ zIndex: summarized ? 10 : 1 }}>
      {summarized && (
        <CustomDayCell
          date={value}
          eventCount={events.length}
          currentDates={currentDates}
        />
      )}
      {children}
    </div>
  );
};