import React from 'react';
import { BsFillCheckSquareFill, BsFillXSquareFill } from 'react-icons/bs';

interface StatusIconProps {
  value: boolean;
}

const StatusIcon: React.FC<StatusIconProps> = ({ value }) => {
  return (
    <div>
      {value ? (
        <BsFillCheckSquareFill color="#2B4E64" />
      ) : (
        <BsFillXSquareFill color="#B81F69" />
      )}
    </div>
  );
};

export default StatusIcon;