import React from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { Button } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

const Toolbar: React.FC<ToolbarProps> = ({ label, onNavigate }) => {
  return (
    <div className="rbc-toolbar flex-row p-3 p-lg-0 mb-0 mb-lg-3">
      <Button onClick={() => onNavigate('PREV')}>
        <FaChevronLeft />
      </Button>
      <span className="rbc-toolbar-label fs-4 fw-normal text-uppercase text-info">{label}</span>
      <Button onClick={() => onNavigate('NEXT')}>
        <FaChevronRight />
      </Button>
    </div>
  );
};

export default Toolbar;