import { CreateFormValues } from '../../../features/dash/views/content/FeaturedIngredients/AddEditFeaturedIngredient';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { FeaturedIngredient } from '../../../types/featuredIngredients.type';
import { sfcApi } from '../../api';
export interface FeaturedIngredientCreateUpdateValues {
    id?: number;
    name: string;
    content:string;
    uncovering_instructions: string,
    file: File,
    image_source:string
  }
export const featuredIngredientApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeaturedIngredients: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `featured-ingredients${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getFeaturedIngredient: builder.query<{ featuredIngredient: FeaturedIngredient }, number>({
      query: (id) => `featured-ingredients/${id}`,
    }),


    createFeaturedIngredient: builder.mutation<{ featuredIngredient: FeaturedIngredient }, FeaturedIngredientCreateUpdateValues>({
      query: (data) => {
        const formData = new FormData();
        let payload: any = {
          url: `featured-ingredients/create`,
          method: 'POST',
          body: data,
        };
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
        if (data.file?.name) {
          payload.formData = true;
        }
        if(!data?.file?.name){
            formData.delete('file');
        }
        payload.body = formData;

        return payload;
      },
      invalidatesTags: ['FeaturedIngredients']
    }),

    updateFeaturedIngredient: builder.mutation<{ featuredIngredient: FeaturedIngredient }, CreateFormValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `featured-ingredients/update/${id}`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
    }),

    deleteFeaturedIngredient: builder.mutation<{ featuredIngredient: FeaturedIngredient }, number>({
      query: (id) => ({
        url: `featured-ingredients/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetFeaturedIngredientsQuery,
  useGetFeaturedIngredientQuery,
  useCreateFeaturedIngredientMutation,
  useUpdateFeaturedIngredientMutation,
  useDeleteFeaturedIngredientMutation
} = featuredIngredientApi;