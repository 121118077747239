import { useEffect, useState } from "react";
import { useGetAllOrdersQuery } from "../../services/endpoints/orders/orders";
import { Order } from "../../types/orders.type";
import SessionCard from "./SessionCard";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { DateField } from "../../components/tables/filterForms/DateField";
import { GetQueryParams } from "../../types/api.type";

const calculateDate = (offset: number) => {
  const date = new Date();
  date.setDate(date.getDate() + offset);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const createFilter = (field: string, value: string, operator: string) => {
  return {
    operator,
    field,
    value,
  };
};

const startDate = calculateDate(-30);
const endDate = calculateDate(30);

const defaultDatesFilters = [
  createFilter("session_starts_on", startDate, "greater_equal_than"),
  createFilter("session_ends_on", endDate, "lesser_equal_than"),
];


const Orders: React.FC = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [pageSize, setPageSize] = useState(5);
    const [filters, setFilters] = useState<GetQueryParams>({
        data: {
          entries: pageSize,
          filterBy: defaultDatesFilters,
        },
    });
    const { data, error, isLoading } = useGetAllOrdersQuery(filters);

    useEffect(() => {
        if (data?.results) {
            setOrders(data.results);
        }
    }, [data]);

    useEffect(() => {
      setFilters((prev) => ({
        ...prev,
        data: {
          ...prev!.data,
          entries: pageSize,
        },
      }));
    }, [pageSize]);

    const initialValues = {
      session_starts_on: startDate,
      session_ends_on: endDate,
    };

    const validationSchema = Yup.object({
      session_starts_on: Yup.date()
        .required("Start date is required")
        .typeError("Must be a valid date"),
      session_ends_on: Yup.date()
        .required("End date is required")
        .typeError("Must be a valid date")
        .min(
          Yup.ref("session_starts_on"),
          "End date must be later than start date"
        ),
    });

    const handleRegister = (values: any) => {
      setFilters({
        data: {
          entries: pageSize,
          filterBy: [
            createFilter("session_starts_on", values.session_starts_on, "greater_equal_than"),
            createFilter("session_ends_on", values.session_ends_on, "lesser_equal_than"),
          ],
        },
      });
    };

    let numPages = data?.count !== 0 ? Math.ceil( data?.count! / data?.entries! ) : 1; 
    if (isNaN(numPages)) {
      numPages = 1;
    }

    return (
      <div className="container-fluid">
        <Stack gap={2} direction={"horizontal"}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue, handleReset }) => (
              <FormikForm onSubmit={handleSubmit}>
                <Stack direction={"horizontal"} gap={3}>
                  <DateField
                    name="session_starts_on"
                    label="Start Date"
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                  />
                  <DateField
                    name="session_ends_on"
                    label="End Date"
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                  />
                </Stack>
              </FormikForm>
            )}
          </Formik>
        </Stack>
        {orders.map((order, index) => (
          <SessionCard key={index} order={order} course={order.course} edit={() => {}} />
        ))}
        <hr />
        <Row className="align-items-center p-lg-4 my-3 my-lg-0">
          <Col xs={12}>
            <Stack direction="horizontal" gap={3}>
              <span
                className="fw-normal ms-2 ms-lg-0"
                style={{ fontSize: ".875rem", color: "#344054" }}
              >
                Viewing:
              </span>
              <Form.Select
                id="count"
                value={pageSize}
                onChange={(e) => setPageSize(+e.target.value)}
                style={{
                  width: 39,
                  height: 24,
                  fontSize: ".875rem",
                  color: "#344054",
                }}
                className="px-1 py-0 pagination"
              >
                {[5, 10, 20, 50].map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              <span
                className="d-none d-lg-block"
                style={{ fontSize: ".875rem", color: "#344054" }}
              >
                {`Page ${data?.page ? data.page : 0} of ${numPages}`}
              </span>
            </Stack>
          </Col>
        </Row>
      </div>
    );
};

export default Orders;