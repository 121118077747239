import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { guide } from '../../../types/guides.type';
import { sfcApi } from '../../api';
export interface GuideCreateUpdateValues {
    id?: number;
    name: string;
    description:string;
  }
export const guideApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getGuides: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `guides${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getGuide: builder.query<{ guide: guide }, number>({
      query: (id) => `guides/${id}`,
    }),


    createGuide: builder.mutation<{ guide: guide }, GuideCreateUpdateValues>({
      query: (data) => {

        return {
            url: 'guides/create',
            method: 'POST',
            body: {
              ...data
            },
          }
      },
      invalidatesTags: ['Guides']
    }),

    updateGuide: builder.mutation<{ guide: guide }, GuideCreateUpdateValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `guides/update/${id}`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
      invalidatesTags: ['Guides']

    }),

    deleteGuide: builder.mutation<{ guide: guide }, number>({
      query: (id) => ({
        url: `guides/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetGuidesQuery,
  useGetGuideQuery,
  useCreateGuideMutation,
  useUpdateGuideMutation,
  useDeleteGuideMutation
} = guideApi;