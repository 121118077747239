import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../components/buttons/BackButton';
import Stepper from '../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { getErrorMessage, getObjectDifference } from '../../../../utils/utils';
import { useToast } from '../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EnrollmentForm } from './EnrollmentForm';
import { 
  useCreateEnrollmentMutation,
  useGetEnrollmentQuery,
  useUpdateEnrollmentMutation,
} from '../../../../services/endpoints/schedule/enrollment';
import { Enrollment } from '../../../../types/enrollment.type';

const steps = [
  { name: 'Details', form: EnrollmentForm },
];

export interface FormValues {
  id?: number;
  course_id?: { value: number, label: string };
  quantity: number;
  tuition: number;
  number_of_classes: number;
  scholarship_id: string;
  notes?: string;
  invoice_notes?: string;
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

export type CreateFormValues = Overwrite<FormValues, {
  course_id: number, 
}>

const formatFormValue = (formValue?: FormValues | Enrollment): CreateFormValues => {
  return JSON.parse(JSON.stringify(formValue)) as CreateFormValues;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditInvoiceItemProps {
  editing?: boolean;
}

export const AddEditEnrollment: React.FC<AddEditInvoiceItemProps> = ({ editing }) => {
  const params = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search); // TODO: send course id to pull course info
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createEnrollment] = useCreateEnrollmentMutation();
  const [updateEnrollment] = useUpdateEnrollmentMutation();
  const { data, error, isLoading }  = useGetEnrollmentQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    quantity: 1,
    tuition: 0,
    number_of_classes: 0,
    scholarship_id: '',
  });

  const validationSchemas = [
    Yup.object({
      course_id: Yup.object({value: Yup.number().required('Session selection is required'), label: Yup.string().optional()}).required(),
      quantity: Yup.number().positive().min(1, 'Quantity should be higher than 0').required('Quantity is required'),
      tuition: Yup.number().positive().min(0, 'Tuition should be higher or equal to 0').required('Tuition is required'),
      number_of_classes: Yup.number().positive().min(1, 'Number of classes should be higher than 0').required('Number of classes is required'),
      scholarship_id: Yup.string().required('Scholarship selection is required'),
      notes: Yup.string().optional(),
      invoice_notes: Yup.string().optional(),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading enrollment', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.enrollment) {
      const { enrollment } = data;
      setInitialValues({
        id: enrollment.id,
        course_id: { value: enrollment.course_id, label: enrollment.session_title ?? '' },
        quantity: enrollment.quantity,
        tuition: enrollment.tuition,
        number_of_classes: enrollment.number_of_classes,
        scholarship_id: String(enrollment.scholarship_id) ?? undefined,
        notes: enrollment.notes ?? undefined,
        invoice_notes: enrollment.invoice_notes ?? undefined,
      });
    }
  }, [data]);


  const handleAddInvoice = async (formValue: FormValues) => {
    try {
      const submission = formatFormValue(formValue);
      submission.course_id = formValue.course_id?.value ?? 0;
      if (editing) {
        const formattedEnrollment = formatFormValue(data?.enrollment);
        const update = getObjectDifference(formattedEnrollment, submission);
        if (Object.keys(update).length > 0) {
          submission.id = data?.enrollment.id;
          const res = await updateEnrollment(submission).unwrap();
          if (res && res.enrollment) {
            addToast(`Enrollment updated succesfully`, 'success');
          } else {
            addToast(`No changes to enrollment`, 'info');
          }
          navigate(`/admin/enrollments/${data?.enrollment?.id}`);
        }
      } else {
        const res = await createEnrollment(submission).unwrap();
        if (res && res.enrollment) {
          navigate(`/admin/enrollments/${res.enrollment.id}`);
          addToast('Enrollment created succesfully', 'success');
        } else throw new Error('A problem happened while creating enrollment');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        quantity: true,
        tuition: true,
        number_of_classes: true,
        scholarship_id: true,
        notes: true,
        invoice_notes: true,
      });
    }
  };

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to enrollment${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/enrollments/${data?.enrollment?.id}` : '/admin/enrollments'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Enrollment
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddInvoice}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
