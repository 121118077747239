/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container } from 'react-bootstrap';
import { Course } from '../../../types/course.type';
import './CourseCard.css';
import { CourseCard, course_type_card_style_lookup } from './CourseCard';

interface SelectedCourseProps {
  course?: Course;
  onClose: () => void;
}

const SelectedCourse: React.FC<SelectedCourseProps> = ({course, onClose}) => {

  let color = course ? course_type_card_style_lookup(course?.course_type) : '';

  return course ? (
    <Container>
      <div className={`selected-course-card ${color}`}>
        <CourseCard course={course} selected={true} onClose={onClose} />
      </div>
    </Container>
  ) : <></>;
};

export default SelectedCourse;
