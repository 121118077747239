import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from '../AddChild';
import { allergyOptions, allergySeverityOptions } from '../../../utils/consts';

export const HealthInfoForm = ({ touched, errors, values }: StepProps) => {
  return (
    <>
        <FieldArray 
          name="allergies"
          render={(arrayHelpers) => (
            <Form.Group className="mb-1 mb-lg-3" controlId="allergies">
              <Form.Label className="h4">Allergies & Dietary Restrictions</Form.Label>
              <br />
              <Form.Label>Does your child have any dietary restrictions or allergies?</Form.Label>
              <div className="multi-content-scroll">
                {values.allergies && values.allergies.length > 0 ? (
                  values.allergies.map((a, index) => {
                    let meta = {touched: touched.allergies ? touched.allergies[index] : {}, error: errors.allergies ? (errors.allergies[index] ?? {}) : {}};
                    
                    return (
                    <div key={index}>
                      <Row>
                        <Col>
                          <Row>
                            <Col xs={6} className="pe-1">
                              <Form.Group className="mb-1 mb-lg-3" controlId={`allergies.${index}.allergy`}>
                                <Form.Label>Allergy / Dietary Restriction</Form.Label>
                                <Field
                                  name={`allergies.${index}.allergy`}
                                  as={Form.Select}
                                  options={['2', '3']}
                                  placeholder="Select"
                                  isInvalid={meta.touched?.allergy && !!(typeof meta.error === "string" ? meta.error : meta.error.allergy)}
                                >
                                  <option>Select</option>
                                  {allergyOptions.map((allergy) => (
                                    <option value={allergy} key={allergy}>{allergy}</option>
                                  ))}
                                </Field>
                                <ErrorMessage name={`allergies.${index}.allergy`}>
                                  {(msg) => (
                                    <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                                  )}
                                </ErrorMessage>
                              </Form.Group>
                            </Col>
                            <Col xs={6} className="ps-1">
                              <Form.Group className="mb-1 mb-lg-3" controlId={`allergies.${index}.severity`}>
                                <Form.Label>Severity (Optional)</Form.Label>
                                <Field
                                  name={`allergies.${index}.severity`}
                                  as={Form.Select}
                                  options={['2', '3']}
                                  placeholder="Select"
                                >
                                  <option>Select</option>
                                  {allergySeverityOptions.map((opt) => (
                                    <option value={opt} key={opt}>{opt}</option>
                                  ))}
                                </Field>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-1 mb-lg-3" controlId={`allergies.${index}.details`}>
                                <Form.Label>Details (Optional)</Form.Label>
                                <Field
                                  name={`allergies.${index}.details`}
                                  as={Form.Control}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={1} offset={11} className="pe-1">
                          <Button size="sm" variant="outline-tertiary" onClick={() => arrayHelpers.remove(index)} >x</Button>
                        </Col>
                      </Row>
                      { index < values.allergies.length - 1 ? <hr /> : <></>}
                    </div>
                  )})
                ) : (
                  <></>
                )}
              </div>
              <button
                type='button'
                className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
                onClick={() => arrayHelpers.insert(values.allergies.length, {allergy: "new", severity: "New", details: ""})} // insert an empty string at a position
              >
                + Add Allergy or Dietary Restriction
              </button>
              <ErrorMessage name="address">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>

          )}
        />
        <hr />
        <Form.Group className="mb-1 mb-lg-3" controlId="social_emotional_needs">
          <Form.Label className="h4">Social & Emotional Needs</Form.Label>
          <br />
          <Form.Label>Does your child have any specific social or emotional needs?</Form.Label>
          <Field
            name="social_emotional_needs"
            type="text"
            as={Form.Control}
            isInvalid={touched.social_emotional_needs && !!errors.social_emotional_needs}
          />
          <Form.Text>Please provide any additional details regarding how we can best support your child's social and emotional needs while in our cooking classes.</Form.Text>
          <ErrorMessage name="social_emotional_needs">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
    </>
  )
}
