import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getNestedTouchedTrue } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FeedbackForm } from './FeedbackForm';
import { useCreateFeedbackMutation, useGetFeedbackQuery, useUpdateFeedbackMutation } from '../../../../../services/endpoints/website/feedback';
import { phoneRegExp } from '../../../../../utils/consts';

const steps = [
  { name: 'Details', form: FeedbackForm },
];

export interface FormValues {
  id?: number;
  first_name: string;
  last_name: string;
  location: string;
  email: string;
  phone_number: string;
  message: string;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditFeedbackProps {
  editing?: boolean;
}

export const AddEditFeedback: React.FC<AddEditFeedbackProps> = ({ editing }) => {
  const params = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search); 
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createFeedback] = useCreateFeedbackMutation();
  const [updateFeedback] = useUpdateFeedbackMutation();
  const { data, error, isLoading }  = useGetFeedbackQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    first_name: '',
    last_name: '',
    location: '',
    email: '',
    phone_number: '',
    message: '',
  });

  const validationSchemas = [
    Yup.object({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      location: Yup.string().required('Location is required'),
      email: Yup.string().email().required('Email is required'),
      phone_number: Yup.string()
      .matches(phoneRegExp, 'Phone Number is not valid')
      .min(10, 'Phone Number should have at least 10 digits')
      .required('Phone Number is required'),
      message: Yup.string().required('Message is required'),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading Feedback', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.feedback) {
      const { feedback } = data;
      setInitialValues({
        id: feedback.id,
        first_name: feedback.first_name,
        last_name: feedback.last_name,
        location: feedback.location,
        email: feedback.email,
        phone_number: feedback.phone_number,
        message: feedback.message,
      });
    }
  }, [data]);


  const handleSubmit = async (formValue: FormValues) => {
    try {
      const feedbackData = {
        ...formValue
      };
      if (editing) {
        const res = await updateFeedback(feedbackData).unwrap();
        if (res && res.feedback) {
          addToast(`Feedback updated succesfully`, 'success');
          navigate(`/admin/website/feedbacks/${data?.feedback.id}`);
        }
      } else {
        const res = await createFeedback(feedbackData).unwrap();
        if (res && res.feedback) {
          navigate(`/admin/website/feedbacks/${res.feedback.id}`);
          addToast('Feedback created succesfully', 'success');
        } else throw new Error('A problem happened while creating Feedback');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        first_name: true,
        last_name: true,
        location: true,
        email: true,
        phone_number: true,
        message: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to Feedback${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/website/feedbacks/${data?.feedback.id}` : '/admin/website/feedbacks'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Feedback
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
