/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Form,
  FormControl,
  Row,
  Stack,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import { StepProps } from "./AddEditJokeCategory";

export const JokeCategoryForm = ({
  touched,
  errors,
  readOnly,
  values,
  setFieldValue,
}: StepProps) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <Stack gap={3}>
            <Form.Group className="mb-1 mb-lg-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Field
                name="name"
                type="text"
                as={Form.Control}
                isInvalid={touched.name && !!errors.name}
              />
              <ErrorMessage name="name">
                {(msg) => (
                  <FormControl.Feedback type="invalid">
                    {msg}
                  </FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
          </Stack>
        </Col>
      </Row>
    </>
  );
};
