import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { InstructorCourseRequest } from '../../../../../types/course.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { instructorSessionRequestsColumns } from '../../../../../utils/consts/columnDefs/sessionColumns';
import { useGetAllInstructorCourseRequestsQuery } from '../../../../../services/endpoints/schedule/instructorCourseRequest';

const filterFormFields: FilterField[] = [
  {
    name: 'course_title',
    element: (
      <SearchField name="course_title" label="Session" placeholder="Search session" />
    )
  },
  {
    name: 'instructor_full_name',
    element: (
      <SearchField name="instructor_full_name" label="Instructor" placeholder="Search instructor" />
    )
  },
  {
    name: 'starts_from',
    id: 'created_at',
    operator: 'greater_equal_than',
    element: (
      <DateField name="starts_from" label="Created At (From-To)" />
    )
  },
  {
    name: 'starts_to',
    id: 'created_at',
    operator: 'lesser_equal_than',
    element: (
      <DateField name="starts_to" />
    )
  },
];

export const InstructorSessionRequestView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({});
  const { data, error, isLoading, isFetching } = useGetAllInstructorCourseRequestsQuery(filters);
  const [requests, setRequests] = useState<InstructorCourseRequest[]>([]);

  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading requests', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setRequests(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name="Instructor Session Requests"
        columns={instructorSessionRequestsColumns}
        defaultScope={'instructor_session_requests'}
        data={requests}
        count={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters?.data?.filterBy}
        indexDownloadPath='instructor-course-requests'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
