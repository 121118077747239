import React from 'react';

interface BadgeCellProps {
  active?: boolean;
  activePhrase?: string;
  inactivePhrase?: string;
}

export const BadgeCell: React.FC<BadgeCellProps> = ({
  active,
  activePhrase = 'Active',
  inactivePhrase = 'Inactive',
}) => {
  return (
    <span
      style={{
        background: !active ? '#F2F4F7' : 'rgba(43, 78, 100, .25)',
        color: !active ? '#888888' : '#2B4E64',
        fontSize: '.75rem'
      }}
      className="rounded-4 py-1 px-2 fw-semibold"
    >
      {active ? activePhrase : inactivePhrase}
    </span>
  )
};
