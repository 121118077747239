/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditDocument';

export const DocumentForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const file = event.currentTarget.files?.[0] || null;
    setFieldValue?.('file', file);
  }
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Field
              name="title"
              as={Form.Control}
              isInvalid={touched.title && !!errors.title}
            />
            <ErrorMessage name="title">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="total">
          <Form.Label>Description</Form.Label>
            <Field
              name="description"
              type="textarea"
              as={Form.Control}
              isInvalid={touched.description && !!errors.description}
            />
            <ErrorMessage name="description">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="total">
          <Form.Label>File</Form.Label>
            <Field
              name="file_name"
              type="file"
              as={Form.Control}
              onChange={handleFileChange}
              isInvalid={touched.file && !!errors.file}
            />
            <ErrorMessage name="document">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
