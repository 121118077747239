import { CreateFormValues } from '../../../features/dash/views/places/venues/AddEditVenue';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { VenueResponse, SFCVenue, LedgerResponse, LedgerParams } from '../../../types/place.types';
import { sfcApi } from '../../api';

export const venuesApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getVenue: builder.query<VenueResponse, number>({
      query: (id) => ({
        url: `venues/${id}`,
      }),
      providesTags: ['Venue'],
      transformResponse: (r: VenueResponse) => {
        return {venue: {...r.venue, organization_ids: r.venue.organizations?.map(o => o.id) ?? []}};
      }
    }),
    getVenueLedger: builder.query<LedgerResponse, LedgerParams>({
      query: (params) => ({
        url: `venues/ledger/${params.id}`,
      }),
    }),
    getVenues: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `venues${params?.scope ? '?scope='+params.scope : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getVenuesSimple: builder.query<SFCVenue[], {location_ids: Array<number>}|undefined>({
      query: (data) => ({
        url: `venues/simple`,
        method: 'POST',
        body: data,
      }),
    }),
    createVenue: builder.mutation<VenueResponse, CreateFormValues>({
      query: (params) => ({
        method: 'POST',
        url: `venues/create`,
        body: params
      }),
    }),
    updateVenue: builder.mutation<VenueResponse, CreateFormValues>({
      query: (data) => ({
        url: `venues/update/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Venue'],
    }),
  }),
});

export const {
  useGetVenueQuery,
  useGetVenueLedgerQuery,
  useGetVenuesQuery,
  useGetVenuesSimpleQuery,
  useCreateVenueMutation,
  useUpdateVenueMutation,
} = venuesApi;
