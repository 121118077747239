''
import { Event } from '../SFCCalendar';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { BsFillGeoAltFill, BsCalendar, BsClock } from "react-icons/bs";
import { MdOutlineRestaurant } from "react-icons/md";
import { ReactNode } from 'react';
import { formatDate } from '../../../../utils/dateUtils';
import { Link } from 'react-router-dom';

interface EventWrapperProps {
  event: Event;
  children?: React.ReactNode;
  summarized?: boolean;
}

const PopoverRow = ({ icon, text }: { icon: ReactNode, text: string  }) => (
  <Row className="mb-1 text-secondary mt-1">
    <Col xs={1} style={{ fontSize: 16 }}>
      {icon}
    </Col>
    <Col style={{ fontSize: 18 }}>
      {text}
    </Col>
  </Row>
);

const PopoverBody = ({ event }: { event: Event }) => (
  <Popover.Body>
    <Link
      className="fw-bolder text-info text-uppercase"
      style={{ fontSize: '1.125rem', borderBottom: 0 }}
      to={`/admin/schedule/sessions/${event.lesson?.course_id}`}>
        {event.title}
    </Link>
    <PopoverRow
      icon={<BsFillGeoAltFill />}
      text={
        `${event.lesson?.course.venue.address}
        ${event.lesson?.course.venue.city}, ${event.lesson?.course.venue.state}
        ${event.lesson?.course.venue.zip}`
      }
    />
    <PopoverRow
      icon={<BsCalendar />}
      text={`${formatDate(event.start!, 'MMMM d, yyyy')}`}
    />
    <PopoverRow
      icon={<BsClock />}
      text={`${formatDate(event.start!, 'hh:mm')}-${formatDate(event.end!, 'hh:mm a z')}`}
    />
    <PopoverRow
      icon={<MdOutlineRestaurant />}
      text={event.lesson?.lesson_plans && event.lesson?.lesson_plans.length > 0
        ? event.lesson?.lesson_plans.map((plan) => plan.name).join(' + ')
        : 'No lesson plans'}
    />
  </Popover.Body>
);

export const EventWrapper: React.FC<EventWrapperProps> = ({ event, children, summarized }) => {
  return !summarized ? (
    <OverlayTrigger
      trigger="click"
      placement="auto"
      overlay={(
        <Popover id="event-popover">
          <PopoverBody event={event} />
        </Popover>
      )}
      rootClose
    >
      <div>
        {children}
      </div>
    </OverlayTrigger>
  ) : <></>;
};