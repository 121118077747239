''
import { EventProps } from 'react-big-calendar';
import { formatDate } from '../../../../utils/dateUtils';

export const EventCell = ({ event }: EventProps) => {
  return (
    <div className="d-none d-lg-block custom-event-cell" style={{ fontSize: 12 }}>
      <span className="fw-bold">{event.title}</span>
      <br />
      <span className="">{`${formatDate(event.start!, 'h:mm a')} - ${formatDate(event.end!, 'h:mm')}`}</span>
    </div>
  );
};
