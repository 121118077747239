import "../FranchiseLayout.css";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { FaLaugh } from "react-icons/fa";
import { useToast } from "../../../context";
import { useEffect, useState } from "react";
import { GetQueryParams } from "../../../types/api.type";
import { useGetAllTeamProfilesQuery } from "../../../services/endpoints/website/teamProfile";
import { TeamProfile } from "../../../types/website.types";

const defaultScope = '';

export const OurCrewSubpage = () => {
  const { addToast } = useToast();
  const [filters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading } = useGetAllTeamProfilesQuery(filters);
  const [crew, setCrew] = useState<TeamProfile[]>([]);
  
  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);
  
  useEffect(() => {
    if (data && data.results) {
      setCrew(data.results);
  
    }
  }, [data]);

  return (
    <Container className="pb-5 text-blue">
    <div className="col-12 d-flex flex-nowrap justify-content-sm-center">
      <Button className="text-uppercase px-5 py-3 mb-5 bg-orange mt-3">
        Find Our Classes
        <FaArrowRight className="ms-2" size={22} />
      </Button>
    </div>

    <div className="div-masonry-2-column">
    {crew.map((team_profile, index) => (
        <>
          <div className="div-masonry-content testimonial-card">
            <img src={team_profile.image_file_name} alt="" className="image-bio green" />
            <div className="subheader">{team_profile.title}</div>
            <h3>{team_profile.name}</h3>
            <div className="div-quotation">
              <div className="text-bio" dangerouslySetInnerHTML={{ __html: team_profile.description }}></div>
              {team_profile.quote_type === 'joke' ? (
                <>
                  <div className="d-flex align-items-center">
                    <div className="text-fa pun"><FaLaugh size={16}/></div>
                    <div className="pun"><em>Favorite Food Joke</em></div>
                  </div>
                  <div className="small">{team_profile.quote}</div>
                </>
              ) : (
                <blockquote className="block-quote bio"><em>{team_profile.quote}</em></blockquote>
              )}
            </div>
          </div>
        </>
      ))}
    </div>
  </Container>
  )
}