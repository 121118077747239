import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { ColumnDef } from '@tanstack/react-table';
import { isLocal } from '../../../../../utils/consts';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { useToast } from '../../../../../context/ToastContext';
import { getCurrentUser } from '../../../../../services/helper';
import { GetQueryParams } from '../../../../../types/api.type';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { useGetAllLocationsQuery } from '../../../../../services/endpoints/places/location';
import { SFCLocation } from '../../../../../types/place.types';
import { useNavigate} from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import YesNoCell from '../../../../../components/tables/cellComponents/YesNoCell';

interface DataRow extends SFCLocation {
}

export type HideContextType = { show: boolean, hide: () => void };

export const LocationsView = () => {
  const { addToast } = useToast();
  const currentUser = getCurrentUser();
  const isLocalUser = isLocal(currentUser?.user_type_id!);
  const navigate = useNavigate();

  const [filters, setFilters] = useState<GetQueryParams>({ data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllLocationsQuery(filters, {skip: !isLocalUser});
  
  const filterFormFields: FilterField[] = [
    {
      name: 'title',
      label: 'Title',
    },
  ];

  const columns: ColumnDef<DataRow>[] = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'Title',
      accessorKey: 'title',
    },
    {
      header: 'Business Entity Name',
      accessorKey: 'business_entity_name',
    },
    {
      header: 'Primary City',
      accessorKey: 'primary_city',
    },
    {
      header: 'Area Director',
      accessorKey: 'manager.full_name',
      cell: ({row}) => <LinkCell content={row.original.manager?.full_name ?? ""} url={`/admin/dash-users/admins/${row.original.manager?.id}`} />
    },
    {
      header: 'Invoicing Email',
      accessorKey: 'invoicing_email',
    },
    {
      header: 'Phone Number',
      accessorKey: 'phone',
    },
    {
      header: 'Hotline',
      accessorKey: 'hotline',
    },
    {
      header: 'Scheduling Email',
      accessorKey: 'scheduling_email',
    },
    {
      header: 'Tax ID Number',
      accessorKey: 'tax_id_number',
    },
    {
      header: 'Is Active',
      accessorKey: 'is_active',
      cell: ({ getValue }) => (<YesNoCell value={getValue<boolean>()} />)
    },
    {
      header: '',
      accessorKey: 'blank',
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <LinkCell content='View' url={`/admin/places/locations/${row.original.id}`} />
          &nbsp;
          &nbsp;
          <LinkCell content='Edit' url={`/admin/places/locations/${row.original.id}/edit`} />
        </>
      )
    },
  ];
  
  useEffect(() => {
    if (error) {
      addToast(`Error while loading data: ${JSON.stringify(error)}`, 'error');
    }
  }, [error]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  if (!isLocalUser) {
    return <>Error: Forbidden</>
  }

  if (error || isLoading) {
    return <></>
  }

  return (
    <div className="py-2">
      <SFCTable
        name={'Locations'}
        columns={columns}
        defaultScope={'all'}
        data={data?.results ?? []}
        count={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='locations'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            onClick={() => navigate(`/admin/places/locations/new`)}
          >
            New location
          </SimpleButton>
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  )
}
