import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../../context/ToastContext';
import { Spinner } from 'react-bootstrap';
import { getErrorMessage } from '../../../../../utils/utils';
import { useDeleteRecipeMutation } from '../../../../../services/endpoints/content/recipe';

export const HandleRecipeAction: React.FC = () => {
  const params = useParams();
  const id = +params.id!;
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [deleteRecipe] = useDeleteRecipeMutation();
  const [loading, setLoading] = useState(true);

  const handleDelete = async () => {
    try {
      await deleteRecipe(id).unwrap();
      addToast(`Recipe #${id} deleted correctly`, "success");
    } catch (e) {
      navigate("/admin/content/recipes");
      addToast(getErrorMessage(e), "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "300px" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return <Navigate to={`/admin/content/recipes`} />;
};
