import {
  Col,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import { StepProps } from "./AddEditTrainingVideo";
import { categories } from "../../../../../utils/consts/trainingVideo/categories";
import { titleCase } from "../../../../../utils/stringConversions";

export const TrainingVideosForm = ({
  touched,
  errors,
  values,
  setFieldValue,
}: StepProps) => {
  
  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Name
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Field
            name="name"
            type="text"
            as={Form.Control}
            isInvalid={touched.name && !!errors.name}
          />
          <ErrorMessage name="name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="is_public">
          <Form.Label>Category</Form.Label>
          <Form.Control
            as="select"
            name="category"
            value={values?.category}
            onChange={(e) => setFieldValue!("category", e.target.value)}
            isInvalid={touched.category && !!errors.category}
          >
            <option>Select</option>
            {categories.map(key => (
              <option key={key} value={key}>{titleCase(key)}</option>
            ))}
          </Form.Control>
          <ErrorMessage name="category">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Code</Form.Label>
          <Field
            name="code"
            type="text"
            as={Form.Control}
            isInvalid={touched.code && !!errors.code}
          />
          <ErrorMessage name="code">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      
    </Row>
  );
};
