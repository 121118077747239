import { Image } from 'react-bootstrap';
import image from '../../assets/images/sticky-fingers-cooking-dancing-fruit-1200x733.gif';

export const Error404 = () => {
  return (
    <>
      <div className="align-content-center text-center content min-vh-100" style={{ background: '#FAFAFA' }}>
        <Image src={image} height={"400px"}/>
        <h2>This page is bananas...B-A-N-A-N-A-S</h2>
        <h6>Sorry about that. Go back to <a href="/">Sticky Fingers Cooking.</a></h6>
      </div>
    </>
  )
};
