import React from 'react';
import * as Yup from "yup";
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Formik, Form as FormikForm, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../components/buttons/SimpleButton';
import { useToast } from '../../context';
import { useCreateFeedbackMutation } from '../../services/endpoints/website/feedback';
import { phoneRegExp } from '../../utils/consts';
import { getErrorMessage } from '../../utils/utils';
import { FadeIn } from '../animations/FadeIn';
import { FeedbackForm } from '../dash/views/website/Feedbacks/FeedbackForm';
import { BannerHeader } from './BannerHeader';

export interface FormValues {
  id?: number;
  first_name: string;
  last_name: string;
  location: string;
  email: string;
  phone_number: string;
  message: string;
}

interface ContactFormProps {
}

export const ContactForm: React.FC<ContactFormProps> = ({ }) => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createFeedback] = useCreateFeedbackMutation();

  const initialValues = {
    first_name: '',
    last_name: '',
    location: '',
    email: '',
    phone_number: '',
    message: '',
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    location: Yup.string().required('Location is required'),
    email: Yup.string().email().required('Email is required'),
    phone_number: Yup.string()
    .matches(phoneRegExp, 'Phone Number is not valid')
    .min(10, 'Phone Number should have at least 10 digits')
    .required('Phone Number is required'),
    message: Yup.string().required('Message is required'),
  });

  const handleSubmit = async (formValue: FormValues) => {
    try {
      const feedbackData = {
        ...formValue
      };
      
      const res = await createFeedback(feedbackData).unwrap();
      if (res && res.feedback) {
        addToast('Feedback created succesfully', 'success');
        navigate("/");
      } else throw new Error('A problem happened while creating Feedback');
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  return (
    <>
      <BannerHeader title="Contact Us" />
      <FadeIn className="p-lg-4">
        <Stack gap={3} className="py-2 py-lg-0 px-4">
          <Container>
            <Row>
              <Col sm={7}>
                <h3 className="mt-0 text-weight-700 text-blue">Sticky Fingers strives to provide the best service with every contact!</h3>
                <p>
                  Have questions about our classes or camps? Want to book a private event, 
                  birthday party, or online family cooking class? We're here to help! 
                  <a href="/school-programs/faqs"> Please check out our FAQs</a>, or get in touch today. <br />
                </p>
                <p>If you're a member of the press, <a href="/media-inquiries">please visit our Media Inquiries page</a>.<br /></p>
                <h4 className="text-weight-700 text-blue">Want to bring SFC programming to your community?</h4>
                <p>Let's chat! We are actively growing and looking for new opportunities to expand.</p>
                <h4 className="text-weight-700 text-blue">Would you like to request a scholarship for your child?</h4>
                <p>We're here to help! Get in touch today.</p>
              </Col>
              <Col sm={5}>
                <h5 className="text-uppercase mt-0">sfc headquarters</h5>
                <div>
                  <p>3700 Tennyson St #12492<br />Denver, CO 80212</p>
                </div>
                <h5 className="text-uppercase">direct contact</h5>
                <div className="div-contact-details">
                  <div><a href="tel:3036484078" className="link">303-648-4078</a></div>
                  <div><a href="mailto:hello@stickyfingerscooking.com" className="link">hello@stickyfingerscooking.com</a></div>
                </div>
              </Col>
            </Row>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div >
                    <FeedbackForm errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />
                  </div>
                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={submitForm}
                        className="w-100"
                        variant="primary"
                      >
                        Submit
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
          </Container>
        </Stack>
      </FadeIn>
    </>
  );
};
