import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../../context/ToastContext';
import { Spinner } from 'react-bootstrap';
import { useArchiveExpenseMutation, useDeleteExpenseMutation, useUnarchiveExpenseMutation } from '../../../../../services/endpoints/finance/expense';
import { getErrorMessage } from '../../../../../utils/utils';

interface HandleExpenseActionProps {
  action: 'delete' | 'archive' | 'unarchive';
}

export const HandleExpenseAction: React.FC<HandleExpenseActionProps> = ({ action }) => {
  const params = useParams();
  const id = +params.id!;
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [archiveExpense] = useArchiveExpenseMutation();
  const [unarchiveExpense] = useUnarchiveExpenseMutation();
  const [deleteExpense] = useDeleteExpenseMutation();
  const [loading, setLoading] = useState(true);

  const archiveFn = async () => {
    let fn;
    switch (action) {
      case 'archive':
        fn = archiveExpense;
        break;
      case 'unarchive':
        fn = unarchiveExpense;
        break;
      default:
        fn = deleteExpense;
    }
    try {
      await fn(id).unwrap();
      addToast(`Expense #${id} ${action}d correctly`, 'success');
    } catch (e) {
      navigate('/admin/finance/expenses');
      addToast(getErrorMessage(e), 'error');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    archiveFn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
 
  return (
    <Navigate to={action === 'delete' ? `/admin/finance/expenses` : `/admin/finance/expenses/${id}`} />
  )
}
