import { Badge, Card } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import { Caregiver } from "../../services/endpoints/people/user";
import { PhoneNumber } from "../common/PhoneNumber";
import { formatUserName } from '../../services/helper';

const CaregiverCard = (props: {caregiver: Caregiver }) => {
  const { caregiver } = props;

  return (
    <>
      <Card.Text className="mb-0">
        <b>{formatUserName(caregiver)}</b> ({caregiver.relation})
      </Card.Text>
      <Card.Text className="mb-0">
        {caregiver.email}
        <br />
        <PhoneNumber number={caregiver.phone} />
      </Card.Text>
      <Card.Text className="mb-2 text-muted">
        {caregiver.pickup_allowed ? (<Badge pill className="me-1">Can Pickup <BsCheck /></Badge>) : null}
        {caregiver.is_emergency_contact ? (<Badge pill>Emergency Contact <BsCheck /></Badge>) : null}
      </Card.Text>
    </>
  );
};

export default CaregiverCard;
