import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

interface InputModalProps {
  show: boolean;
  title: string;
  message: string;
  placeholder: string;
  onClose: () => void;
  onSubmit: (inputValue: string) => void;
}

const InputModal: React.FC<InputModalProps> = ({
  show,
  title,
  message,
  placeholder,
  onClose,
  onSubmit,
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(inputValue);
    setInputValue(''); // Clear the input after submission
    onClose(); // Close the modal
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="genericInput">
            <Form.Control
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={placeholder}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InputModal;