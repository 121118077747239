/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditInvoiceClient';

export const InvoiceClientForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Field
              name="name"
              as={Form.Control}
              isInvalid={touched.name && !!errors.name}
            />
            <ErrorMessage name="name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
