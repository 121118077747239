import { Container, Image, Form, Button } from "react-bootstrap";
import "../home/HomeSection.css";
import ThymeToTurnipBeet from "../../assets/images/ThymeToTurnipBeet.png";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

export const FooterSection = () => {
  return (
    
      <footer id="footer" className="footer">
        <Container>
            <div className="d-flex flex-nowrap w-100 justify-content-between">
              <div className="d-flex flex-nowrap w-25 justify-content-between" >
                <a 
                  href="https://www.facebook.com/StickyFingersCooking/"
                  target="_blank"
                  className="social-link" 
                  rel="noreferrer"
                >
                  <FaFacebook size={20}/>
                </a>
                <a
                  href="https://twitter.com/StickyFingersCO"
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                >
                  <FaTwitter size={20}/>
                </a>
                <a
                  href="https://www.instagram.com/stickyfingerscooking/"
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                >
                  <FaInstagram size={20}/>
                </a>
                <a
                  href="https://www.pinterest.com/stickyfingersco/"
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                >
                  <FaPinterestP size={20}/>
                </a>
                <a
                  href="https://www.youtube.com/channel/UC7eHBg7oDKChxsn4DC-69QA/featured"
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                >
                  <FaYoutube size={20}/>
                </a>
                <a
                  href="https://www.tiktok.com/@stickyfingers_cooking"
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                >
                  <FaTiktok size={20}/>
                </a>
              </div>

              <Button className="text-uppercase button-radius button-contact">
                contact us
              </Button>
            </div>

            
            <div className="line-divider-footer second-style"></div>

            <div className="d-flex">
              <div className="footer-flex-child-logo">
                <a
                  href="http://web501.com"
                  aria-current="page"
                  className="footer-logo w-inline-block w--current"
                >
                  <Image
                    className="card-fruit"
                    src={ThymeToTurnipBeet}
                    alt="Denver digital agency web501 logo"
                  />
                </a>
                <p className="paragraph-copyright">© {new Date().getFullYear()} Sticky Fingers Cooking</p>
              </div>
              <div className="footer-flex-child-menu">
                <div>
                  <div className="subheader footer-menu">
                    our programming
                  </div>
                  <a href="/school-programs" className="footer-navigation">
                    School Enrichment Programs
                  </a>
                  <a
                    href="/courses?program_types=online"
                    className="footer-navigation"
                  >
                    Online Cooking Classes
                  </a>
                  <a href="/camps" className="footer-navigation">
                    Cooking Camps
                  </a>
                  <a
                    href="/school-programs/faqs"
                    className="footer-navigation"
                  >
                    FAQs
                  </a>
                  <a href="/private-events" className="footer-navigation">
                    Private Events
                  </a>
                  <a href="/recipes" className="footer-navigation">
                    Recipes
                  </a>
                  <a href="/shop" className="footer-navigation">
                    Shop
                  </a>
                  <a href="/sticky-notes" className="footer-navigation">
                    Sticky Notes
                  </a>
                </div>
              </div>
              <div className="footer-flex-child-menu">
                <div data-ix="fade-up-2">
                  <div className="subheader footer-menu">about us</div>
                  <a href="/about" className="footer-navigation">
                    About Sticky Fingers Cooking
                  </a>
                  <a
                    href="/about/news-events"
                    className="footer-navigation"
                  >
                    Sticky Fingers in the News
                  </a>
                  <a href="/why-we-are-grape" className="footer-navigation">
                    Why We&#x27;re Grape
                  </a>
                  <a href="/testimonials" className="footer-navigation">
                    Testimonials
                  </a>
                  <a href="/about/our-crew" className="footer-navigation">
                    Our Crew
                  </a>
                  <a href="/about/jobs" className="footer-navigation">
                    Available Jobs
                  </a>
                  <a href="/about/locations" className="footer-navigation">
                    Locations
                  </a>
                  <a href="/media-inquiries" className="footer-navigation">
                    Media Inquiries
                  </a>
                  <a
                    href="https://stickyfingerscookingfranchise.com/"
                    target="_blank"
                    className="footer-navigation"
                  >
                    Franchise Opportunities
                  </a>
                  <a
                    href="pages/privacy-policy-for-sticky-fingers-cooking-and-affiliates"
                    className="footer-navigation"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
              <div className="footer-flex-child-contact">
                <div>
                  <div className="subheader footer-menu">contact us</div>
                  <div className="div-footer-contact address">
                    <a
                      href="<%= root_path %>"
                      target="_blank"
                      aria-current="page"
                      className="contact-link w--current"
                    >
                      <FaPaperPlane size={20} className="me-2"/>
                    </a>
                    <div className="text-footer-contact">{"3700 Tennyson St #12492"}<br/>Denver, CO 80212</div>
                  </div>
                  <div className="div-footer-contact">
                    <a href="tel:303-648-4078" className="contact-link">
                      <FaPhoneAlt size={20} className="me-2"/>
                    </a>
                    <div className="text-footer-contact">
                      <a href="tel:303-648-4078" className="link-footer">
                        303-648-4078
                      </a>
                    </div>
                  </div>
                  <div className="div-footer-contact">
                    <a
                      href="mailto:hello@stickyfingerscooking.com"
                      className="contact-link"
                    >
                      <FaEnvelope size={20} className="me-2"/>
                    </a>
                    <div className="text-footer-contact">
                      <a
                        href="mailto:hello@stickyfingerscooking.com"
                        className="link-footer"
                      >
                        hello@stickyfingerscooking.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
        </Container>
      </footer>
    
  );
};
