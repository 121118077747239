import React from 'react';
import { FileSelect } from './FileSelect';

interface ImageSelectProps {
  name: string,
  acceptType?: string,
  label?: string,
  onChange?: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
}

export const ImageSelect: React.FC<ImageSelectProps> = ({ acceptType, ...rest }) => {

  return (
    <FileSelect 
      acceptType={acceptType ?? 'image/*'}
      {...rest}
    />
  )
}
