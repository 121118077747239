import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditKitchenSkill';
import Editor from '../../../../../components/editor/Editor';

export const KitchenSkillForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Name
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Field
            name="name"
            type="text"
            as={Form.Control}
            isInvalid={touched.name && !!errors.name}
          />
          <ErrorMessage name="name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-10 mb-lg-3" controlId="details">
          <Form.Label>Description</Form.Label>
          <Field 
          name="description" 
          component={Editor} 
          className="form-control"
          isInvalid={touched.description && !!errors.description}/>
          <ErrorMessage name="description">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col> 
      
     
    </Row>
  )
}
