import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getObjectDifference } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ScholarshipForm } from './ScholarshipForm';
import {
  useCreateScholarshipMutation,
  useGetScholarshipQuery,
  useUpdateScholarshipMutation,
} from '../../../../../services/endpoints/orders/scholarship';

const steps = [
  { name: 'Details', form: ScholarshipForm },
];

export interface FormValues {
  scholarship_type: string;
  percent_discount: number;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditScholarshipProps {
  editing?: boolean;
}

export const AddEditScholarship: React.FC<AddEditScholarshipProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createScholarship] = useCreateScholarshipMutation();
  const [updateScholarship] = useUpdateScholarshipMutation();
  const { data, error, isLoading }  = useGetScholarshipQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    scholarship_type: '',
    percent_discount: 1,
  });

  const validationSchemas = [
    Yup.object({
      scholarship_type: Yup.string().required('Scholarship type selection is required'),
      percent_discount: Yup.number().positive().min(1, 'Number should be higher than 0').required('Percent discount is required'),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading scholarship', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.scholarship) {
      const { scholarship } = data;
      setInitialValues({
        scholarship_type: scholarship.scholarship_type,
        percent_discount: scholarship.percent_discount
      });
    }
  }, [data]);


  const handleSumbit = async (formValue: FormValues) => {
    try {
      if (editing) {
        const formattedValues = JSON.parse(JSON.stringify(data?.scholarship)) as FormValues;
        const update = getObjectDifference(formattedValues, formValue);
        if (Object.keys(update).length > 0) {
          const res = await updateScholarship({ id: data?.scholarship.id!, data: formValue }).unwrap();
          if (res && res.scholarship) {
            addToast(`Scholarship updated succesfully`, 'success');
          } else {
            addToast(`No changes to enrollment`, 'info');
          }
          navigate(`/admin/orders/scholarships/${data?.scholarship?.id}`);
        }
      } else {
        const res = await createScholarship(formValue).unwrap();
        if (res && res.scholarship) {
          navigate(`/admin/orders/scholarships/${res.scholarship.id}`);
          addToast('Scholarship created succesfully', 'success');
        } else throw new Error('A problem happened while creating scholarship');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        scholarship_type: true,
        percent_discount: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to scholarship${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/orders/scholarships/${data?.scholarship.id}` : '/admin/orders/scholarships'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Scholarship
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSumbit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
