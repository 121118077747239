import React from 'react';
import { Stack } from 'react-bootstrap';

interface InstructorsCellProps {
  instructors: { id: number, name: string, role: string }[];
}

export const InstructorsCell: React.FC<InstructorsCellProps> = ({ instructors }) => {
  return (
    <div>
      {instructors.map((instructor) => (
        <Stack key={instructor.id}>
          <span className="text-info fw-bold">{instructor.name}</span>
          <span className="text-capitalize">{instructor.role}</span>
        </Stack>
      ))}
    </div>
  )
}
