import React from 'react';
import { Button, Card, CloseButton, Stack } from 'react-bootstrap';
import { CourseDateTimes, course_type_style_lookup } from './CourseDates';
import { sessionTypes } from '../../../utils/consts/schedule/sessions';
import { useNavigate } from 'react-router-dom';
import { CourseVenueDistance } from './CourseVenueDistance';
import { APIProvider } from '@vis.gl/react-google-maps';
import { CourseCost } from './CourseCost';
import { Course } from '../../../types/course.type';
import AddFavorite from './details/AddFavorite';

export const course_type_card_style_lookup = (key: string) => {
  switch (key) {
    case 'after_school': return 'pink';
    case 'camp': return 'orange';
    case 'event': return 'green';
    default: return 'primary';
  }
}

export interface CourseCardProps {
  course: Course;
  selected?: boolean;
  onClose?: () => void;
  onClick?: () => void;
}

export const CourseCard: React.FC<CourseCardProps> = ({ course, selected, onClose, onClick }) => {

  const navigate = useNavigate();

  let className = course_type_card_style_lookup(course.course_type);
  let fillStyle = course_type_style_lookup(course.course_type);

  return (
    <Card className={`course-card ${className}`} onClick={() => selected ? undefined : onClick?.()}>
      <Card.Body>
        <Stack direction="horizontal">
          <Stack>
            {selected && <div className={`mb-3 text-uppercase course-event-badge ${className}`}>You Selected</div>}
            <Card.Subtitle className={`course-card-title ${className}`}>{course.title}</Card.Subtitle>
            {selected && (
              <>
                <span className="text-muted">Theme</span>
                <Card.Subtitle ><b>{course.theme.name}</b></Card.Subtitle>
              </>
            )}
            {selected && <b className="text-muted">{course.is_online ? "Online" : "In-Person"}</b>}
            <CourseDateTimes course={course} />
            <APIProvider apiKey={'AIzaSyC3UNDf0rod5gJJ5TUk1z3p1fL0DNBXTg8'}>
              <CourseVenueDistance course={course} color={className} fillStyle={fillStyle} />
            </APIProvider>
          </Stack>
          <Stack className={'align-items-end'} gap={1}>
            {/* <FaRegHeart /> */}
            {selected && <CloseButton onClick={() => onClose?.()} />}
            {selected && <span className="ms-auto"><AddFavorite course_id={course.id} show_label={false} /></span>}
            <CourseCost className={'ms-auto'} course={course} />
          </Stack>
        </Stack>
        <Stack direction='horizontal'>
          <div className={`text-uppercase course-event-badge ${className}`}>{sessionTypes[course.course_type]}</div>
          <Button variant={'primary'} size="sm" className={"ms-auto text-uppercase"} onClick={() => navigate(`/sessions/${course.id}`)}>View Details</Button>
        </Stack>
      </Card.Body>
    </Card>
  );
};
