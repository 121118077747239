import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { dateToUTC } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useGetReceiptQuery } from '../../../../../services/endpoints/finance/receipt';
import { Receipt } from '../../../../../types/finance.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getPartition } from '../../../../../utils/utils';

export const ReceiptDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetReceiptQuery(+params.id!);
  const [ receipt, setReceipt] = useState<Receipt>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setReceipt(data.receipt);
    }
  }, [data]);

  if (isLoading || !receipt) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  let image_url = `${receipt.s3_base_url ?? ''}/${getPartition(receipt.id)}/original/${receipt.image_file_name}`;

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/finance/receipts')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Receipts List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Receipt #${receipt?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/finance/receipts/${receipt?.id}/edit`)}
          >
            Edit receipt
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/finance/receipts/${receipt?.id}/delete`)}
          >
            Delete Receipt
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Instructor:">
              <LinkCell
                content={receipt?.user?.full_name}
                url={`/admin/dash-users/instructors/${receipt?.user?.id}`}
                underline
              />
            </LabelText>
            <LabelText label="Session:">
              <LinkCell
                content={receipt?.course?.title ?? 'No session title'}
                url={`/admin/schedule/sessions/${receipt?.course?.id}`}
                underline
              />
            </LabelText>
            <LabelText label="Store Name:" text={receipt?.store_name} />
            <LabelText label="Date of Purchase:" text={receipt?.purchased_at ? dateToUTC(new Date(receipt?.purchased_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Total:" text={`$${receipt?.total.toFixed(2)}`} />
            {/* TODO: add image icon/thumbnail */}
            <LabelText label="Image:" text={receipt?.image_file_name ?? 'N/A'} /> 
            {/* TODO: add image receipt download file */}
            <LabelText label="Download:">
              <LinkCell
                content={receipt?.image_file_name ?? 'N/A'}
                url={image_url}
                underline
              />
            </LabelText>
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
