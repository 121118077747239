import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { InvoiceForm } from './InvoiceForm';
import { useGetInvoiceQuery, useUpdateInvoiceMutation } from '../../../../../services/endpoints/finance/invoice';
import { dateToUTC, formatDate } from '../../../../../utils/dateUtils';
import { addDays } from 'date-fns';

const steps = [
  { name: 'Details', form: InvoiceForm },
];

export interface FormValues {
  id?: number;
  name: string;
  po_number: string;
  issue_on: string;
  due_on: string;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditInvoiceProps {
  editing?: boolean;
}

export const AddEditInvoice: React.FC<AddEditInvoiceProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [updateInvoice] = useUpdateInvoiceMutation();
  const { data, error, isLoading }  = useGetInvoiceQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    po_number: '',
    issue_on: formatDate(new Date(), 'yyyy-MM-dd'),
    due_on: formatDate(addDays(new Date(), 15), 'yyyy-MM-dd'),
  });

  const validationSchemas = [
    Yup.object({
      name: Yup.string().required('Name is required'),
      po_number: Yup.string().required('PO Number is required'),
      issue_on: Yup.string().required('Issued date is required'),
      due_on: Yup.string().required('Due date is required'),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading invoice', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.invoice) {
      const { invoice } = data;
      setInitialValues({
        id: invoice.id,
        name: invoice.name,
        po_number: invoice.po_number,
        issue_on: invoice.issue_on ?? formatDate(new Date(), 'yyyy-MM-dd'),
        due_on: invoice.due_on ?? formatDate(addDays(new Date(), 15), 'yyyy-MM-dd') ,
      });
    }
  }, [data]);


  const handleAddInvoice = async (formValue: FormValues) => {
    try {
      if (editing) {
        const res = await updateInvoice({
          ...formValue,
          issue_on: dateToUTC(new Date(formValue.issue_on), 'MM/dd/yyyy'),
          due_on: dateToUTC(new Date(formValue.due_on), 'MM/dd/yyyy'),
        }).unwrap();
        if (res && res.invoice) {
          addToast(`Invoice updated succesfully`, 'success');
          navigate(`/admin/finance/invoices/${data?.invoice.id}`);
        }
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
        po_number: true,
        issue_on: true,
        due_on: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to invoice${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/finance/invoices/${data?.invoice.id}` : '/admin/finance/invoices'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Invoice
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddInvoice}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
