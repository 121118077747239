import { FormValues } from '../../../features/dash/views/finance/Invoices/AddEditInvoice';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Invoice } from '../../../types/finance.types';
import { sfcApi } from '../../api';

export const invoiceApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoices: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `invoices${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Invoice'],
    }),
    getInvoice: builder.query<{ invoice: Invoice }, number>({
      query: (id) => `invoices/${id}`,
      providesTags: (result, error, id) => [{ type: 'Invoice', id }]
    }),
    updateInvoice: builder.mutation<{ invoice: Invoice }, FormValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `invoices/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Invoice', id: result?.invoice.id }, 'Invoice']
    }),
    deleteInvoice: builder.mutation<{ invoice: Invoice }, number>({
      query: (id) => ({
        url: `invoices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Invoice', id }, 'Invoice'],
    }),
  }),
});

export const {
  useGetAllInvoicesQuery,
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
} = invoiceApi;