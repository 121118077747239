import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditOrganization';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetVenuesQuery } from '../../../../../services/endpoints/places/venue';
import { getCurrentUser } from '../../../../../services/helper';
import { useDataContext } from '../../../../../context';

const currentUser = getCurrentUser();

export const OrganizationForm = ({ touched, errors, values }: StepProps) => {
  const { locationOptions } = useDataContext();
  const isGlobalUser = (currentUser?.isGlobal);
  const { data: venues, error: venuesError } = useGetVenuesQuery({data: {orderBy: {field: "title", order: "asc"}}});
  
  return (
    <>
      <Row>
        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Field
              name="name"
              type="text"
              as={Form.Control}
              isInvalid={touched.name && !!errors.name}
            />
            <ErrorMessage name="name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          {isGlobalUser ? (
            <Form.Group className="mb-1 mb-lg-3" controlId="location_id">
              <Field 
                as={FormSelect}
                name="location_id"
                label="Location"
                options={locationOptions}
              />
            </Form.Group>
          ) : (<></>)
          }

          <Form.Group className="mb-1 mb-lg-3" controlId="venue_ids">
            <FormSelect
              name="venue_ids"
              label="Venues"
              multiple={true}
              options={venues?.results?.map((venue) => ({ value: venue.id, label: venue.title })) ?? []}
              loadingError={venuesError ? "Error loading venues" : undefined}
            />
            <ErrorMessage name="venue_ids">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
      </Row>

      <Form.Group className="mb-1 mb-lg-3" controlId="notes">
        <Form.Label>Notes</Form.Label>
        <Field
          name="notes"
          as="textarea"
          className="form-control"
        />
        <ErrorMessage name="notes">
          {(msg) => (
            <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <FieldArray 
        name="contacts"
        render={(arrayHelpers) => (
          <Form.Group className="mb-1 mb-lg-3" controlId="contacts">
            <Form.Label className="h4">Organization Contacts</Form.Label>
            <br />
            <div className="multi-content-scroll mt-2">
              <Row>
              {values?.contacts && values?.contacts.length > 0 ? (
                values.contacts.map((a, index) => {
                  let meta = {touched: Array.isArray(touched.contacts) ? touched.contacts[index] : {}, error: Array.isArray(errors.contacts) ? (errors.contacts[index] ?? {}) : {}};
                  return (
                    <Col  key={index} lg={6} className="mb-2">
                    <Card border="primary">
                    {typeof errors.contacts === "string" ? <div>{errors.contacts}</div> : null}
                    <Card.Body>
                      <Col className="ms-1">
                        <Row>
                          <Col xs={6} className="pe-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.first_name`}>
                              <Form.Label>First Name</Form.Label>
                              <Field
                                name={`contacts.${index}.first_name`}
                                as={Form.Control}
                                isInvalid={meta.touched?.first_name && !!(typeof meta.error === "string" ? meta.error : meta.error.first_name)}
                              />
                              <ErrorMessage name={`contacts.${index}.first_name`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.last_name`}>
                              <Form.Label>Last Name</Form.Label>
                              <Field
                                name={`contacts.${index}.last_name`}
                                type="text"
                                as={Form.Control}
                                isInvalid={meta.touched?.last_name && !!(typeof meta.error === "string" ? meta.error : meta.error.last_name)}
                              />
                              <ErrorMessage name={`contacts.${index}.last_name`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.email`}>
                              <Form.Label>Email Address</Form.Label>
                              <Field
                                name={`contacts.${index}.email`}
                                type="email"
                                as={Form.Control}
                                isInvalid={meta.touched?.email && !!(typeof meta.error === "string" ? meta.error : meta.error.email)}
                              />
                              <ErrorMessage name={`contacts.${index}.email`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Button
                              variant="primary"
                              size="sm"
                              className="btn-box-shadow border-0 text-uppercase"
                              style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove  
                            </Button>
                            
                          </Col>
                          <Col xs={6} className="ps-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.title`}>
                              <Form.Label>Title (Optional)</Form.Label>
                              <Field
                                name={`contacts.${index}.title`}
                                as={Form.Control}
                                isInvalid={meta.touched?.title && !!(typeof meta.error === "string" ? meta.error : meta.error.title)}
                              />
                              <ErrorMessage name={`contacts.${index}.title`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>

                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.phone_number`}>
                              <Form.Label>Contact Phone Number</Form.Label>
                              <Field
                                name={`contacts.${index}.phone_number`}
                                type="tel"
                                as={Form.Control}
                                isInvalid={meta.touched?.phone_number && !!(typeof meta.error === "string" ? meta.error : meta.error.phone_number)}
                              />
                              <ErrorMessage name={`contacts.${index}.phone_number`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            
                            <Form.Group controlId={`contacts.${index}.should_be_sent_roster`}>
                              <Form.Label>
                                <Field
                                  name={`contacts.${index}.should_be_sent_roster`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'Should be sent roster'}
                                />
                              </Form.Label>
                            </Form.Group>

                            <Form.Group controlId={`contacts.${index}.should_be_sent_invoices`}>
                              <Form.Label>
                                <Field
                                  name={`contacts.${index}.should_be_sent_invoices`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'Should be sent invoices'}
                                />
                              </Form.Label>
                            </Form.Group>

                            <Form.Group controlId={`contacts.${index}.should_be_sent_bulk_welcomes`}>
                              <Form.Label>
                                <Field
                                  name={`contacts.${index}.should_be_sent_bulk_welcomes`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'Should be sent bulk welcomes'}
                                />
                              </Form.Label>
                            </Form.Group>

                            <Form.Group controlId={`contacts.${index}.should_be_sent_bulk_thanks`}>
                              <Form.Label>
                                <Field
                                  name={`contacts.${index}.should_be_sent_bulk_thanks`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'Should be sent bulk thanks'}
                                />
                              </Form.Label>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Card.Body>
                  </Card>
              </Col>
                )})
              ) : (
                <></>
              )}
              </Row>
            </div>
            <button
              type='button'
              className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
              onClick={() => arrayHelpers.insert(values?.contacts.length ?? 0, {
                first_name: "", 
                last_name: "", 
                email: "", 
                title: "", 
                phone_number: "",
                should_be_sent_roster: false, 
                should_be_sent_invoices: false, 
                should_be_sent_bulk_welcomes: false, 
                should_be_sent_bulk_thanks: false
              })}
            >
              + Add Organization Contact
            </button>
          </Form.Group>
        )}
      />
    </>
  )
}
