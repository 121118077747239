/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditNewsItem';
import { ImageSelect } from '../../../../../components/inputs/ImageSelect';
import ImageWithErrorHandling from '../../../../../components/image/ImageWithErrorHandling';
import { getPartition } from '../../../../../utils/utils';
import Editor from '../../../../../components/editor/Editor';

export const NewsItemForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Field
              name="name"
              as={Form.Control}
              isInvalid={touched.name && !!errors.name}
            />
            <ErrorMessage name="name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group className="mb-1 mb-lg-3" controlId="details">
            <Form.Label>Details</Form.Label>
            <Field
              name="details"
              component={Editor}
              isInvalid={touched.details && !!errors.details}
            />
            <ErrorMessage name="details">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="image">
            <ImageSelect
              label={`Image${values?.image_file_name ? ` (${values?.image_file_name})` : ''}`}
              name="image"
              onChange={setFieldValue}
            />
            <ImageWithErrorHandling
              src={`${values?.s3_base_url}/${getPartition(values?.id)}/thumb/${values?.image_file_name}`} 
              alt="Error loading image"
            />
            <ErrorMessage name="image">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="published_on">
            <Form.Label>Published On</Form.Label>
            <Field
              name="published_on"
              type="date"
              as={Form.Control}
              isInvalid={touched.published_on && !!errors.published_on}
            />
            <ErrorMessage name="published_on">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          </Col>
          <Col xs={12} lg={6} className="pe-1">
          <FieldArray 
            name="news_item_links"
            render={(arrayHelpers) => (
              <Form.Group className="mb-1 mb-lg-3" controlId="news_item_links">
                <Form.Label className="h4">Links</Form.Label>
                <br />
                <div className="multi-content-scroll mt-2">
                  <Row>
                  {values?.news_item_links && values?.news_item_links.length > 0 ? (
                    values.news_item_links.map((link, index) => {
                      let meta = {touched: Array.isArray(touched.news_item_links) ? touched.news_item_links[index] : {}, error: Array.isArray(errors.news_item_links) ? (errors.news_item_links[index] ?? {}) : {}};
                      return (
                        <Col  key={index} lg={6} className="mb-2">
                        <Card border="primary">
                        {typeof errors.news_item_links === "string" ? <div>{errors.news_item_links}</div> : null}

                        <Card.Body>
                          <Col className="ms-1">
                            <Row>
                              <Col xs={6} className="pe-1">
                                <Form.Group className="mb-1 mb-lg-3" controlId={`news_item_links.${index}.text`}>
                                  <Form.Label>Text</Form.Label>
                                  <Field
                                    name={`news_item_links.${index}.text`}
                                    as={Form.Control}
                                    isInvalid={meta.touched?.text && !!(typeof meta.error === "string" ? meta.error : meta.error.text)}
                                  />
                                  <ErrorMessage name={`news_item_links.${index}.text`}>
                                    {(msg) => (
                                      <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group className="mb-1 mb-lg-3" controlId={`news_item_links.${index}.target`}>
                                  <Form.Label>Target</Form.Label>
                                  <Field
                                    name={`news_item_links.${index}.target`}
                                    type="text"
                                    as={Form.Control}
                                    isInvalid={meta.touched?.target && !!(typeof meta.error === "string" ? meta.error : meta.error.target)}
                                  />
                                  <ErrorMessage name={`news_item_links.${index}.target`}>
                                    {(msg) => (
                                      <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Form.Group className="mb-1 mb-lg-3" controlId={`news_item_links.${index}.link_type`}>
                                  <Form.Label>Link Type</Form.Label>
                                  <Field
                                    name={`news_item_links.${index}.link_type`}
                                    type="text"
                                    as={Form.Select}
                                    isInvalid={meta.touched?.link_type && !!(typeof meta.error === "string" ? meta.error : meta.error.link_type)}
                                  >
                                    <option value="">Select</option>
                                    <option value="article" >Article</option>
                                    <option value="video" >Video</option>
                                  </Field>
                                  <ErrorMessage name={`news_item_links.${index}.link_type`}>
                                    {(msg) => (
                                      <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  className="btn-box-shadow border-0 text-uppercase"
                                  style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove  
                                </Button>
                                
                              </Col>
                            </Row>
                          </Col>
                        </Card.Body>
                      </Card>
                  </Col>
                    )})
                  ) : (
                    <></>
                  )}
                  </Row>
                </div>
                <button
                  type='button'
                  className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
                  onClick={() => arrayHelpers.insert(values?.news_item_links.length ?? 0, {
                    text: "", 
                    target: "", 
                    link_type: "", 
                  })}
                >
                  + Add New Link
                </button>
              </Form.Group>
            )}
          />
        </Col>
      </Row>
    </>
  )
}
