import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Accordion, Button, Spinner, Stack } from 'react-bootstrap';
import { SFCAccordion } from '../../../../components/accordion/SFCAccordion';
import { SimpleTable } from '../../../../components/tables/SimpleTable';
import { useToast } from '../../../../context/ToastContext';
import { IconLink } from '../../../../components/iconLink/IconLink';
import { BsCalendar, BsClock, BsEnvelope, BsFiletypePdf, BsFillTelephoneFill, BsGlobe } from 'react-icons/bs';
import { DetailSection, LabelText } from '../../../../components/generic';
import { ColumnDef } from '@tanstack/react-table';
import { Registration } from '../../../../types/registration.type';
import { LinkCell } from '../../../../components/tables/cellComponents/LinkCell';
import CurrencyCell from '../../../../components/tables/cellComponents/CurrencyCell';
import CheckmarkCell from '../../../../components/tables/cellComponents/CheckmarkCell';
import { useGetOrderQuery } from '../../../../services/endpoints/orders/orders';
import { Order } from '../../../../types/orders.type';
import { Badge } from '../../../../components/badge/Badge';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { titleCase } from '../../../../utils/stringConversions';

const LabelTextList = ({ label, list }: { label: string, list: string[] }) => (
  <Stack direction="vertical" gap={1}>
    <span className="fw-semibold" style={{ color: 'rgba(94, 100, 105, .5)' }}>{label}</span>
    {list.map((text, index) => (
      <span key={index} className="text-secondary fw-semibold">{text}</span>
    ))}
  </Stack>
);

const registrationColumns: ColumnDef<Registration>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  // {
  //   header: 'Session',
  //   id: 'session',
  //   cell: ({ row }) => !row.original.course ? '' :
  //     <LinkCell
  //       content={row.original.course.title}
  //       url={`/admin/schedule/sessions/${row.original.course_id}`}
  //     />,
  //   enableSorting: false,
  // },
  {
    header: 'Child',
    id: 'child',
    cell: ({ row }) => !row.original.child ? '' :
      <LinkCell
        content={row.original.child.full_name}
        url={`/admin/sfc-users/children/${row.original.child_id}`}
      />,
    enableSorting: false,
  },
  {
    header: 'Price',
    id: 'price',
    cell: ({ getValue }) => <CurrencyCell value={getValue<number>()} />,
    enableSorting: false,
  },
  {
    header: 'Refunds',
    id: 'refund',
    // TODO: render refunds
    enableSorting: false,
  },
  {
    header: 'Active',
    accessorKey: 'active_status',
    cell: ({ getValue }) => <CheckmarkCell value={getValue<string>() === 'active'} />,
    enableSorting: false,
  },
]

export const OrderDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetOrderQuery(+params.id!);
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading order', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setOrder(data.order);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  const detailsAccordion = (
    <SFCAccordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
          <Accordion.Header>Registrations</Accordion.Header>
          <Accordion.Body>
            <Stack gap={3}>
              <SimpleTable columns={registrationColumns} data={order?.registrations ?? []} />
              <Link to="" className="text-info">Manage/Refund Registrations</Link>
            </Stack>
          </Accordion.Body>
        </Accordion.Item>
    </SFCAccordion>
  );

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/orders/orders')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Orders List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">Order #{order?.id}</h2>
        </Stack>
      </div>
      <div className="d-lg-none">
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LinkCell
              content={`${order?.parent.first_name} ${order?.parent.last_name}`}
              url={`/admin/sfc-users/parents/${order?.parent_id}`}
            />
            <LabelText label="Theme:" text="Theme" />
          </DetailSection>

          {detailsAccordion}

        </Stack>
      </div>
      <div className="d-none d-lg-flex">
        <div className="mt-5 w-100 px-5 py-4" style={{ maxWidth: 'calc(100% - 400px)' }}>
          {detailsAccordion}
        </div>
        <Stack gap={4} className="p-4 vh-100" style={{ width: 400, background: '#F2F2F2' }}>
          <DetailSection title="Details">
            <Stack>
              <LinkCell
                content={order?.parent.full_name}
                url={`/admin/sfc-users/parents/${order?.parent_id}`}
              />
              <span>{`${order?.parent.address}`}</span>
              <span>{`${order?.parent.city}, ${order?.parent.state}, ${order?.parent.zip}`}</span>
            </Stack>
            <LabelText label="Email:" text={order?.parent.email} />

            <LabelText label="Session(s):" />
            <Stack >
              { order?.registrations.map(r => 
                <LinkCell
                  key={r.id}
                  content={r.course?.title}
                  url={`/admin/schedule/sessions/${r.course_id}`}
                />
              )}
            </Stack>

            <LabelText label="Total:">
              <CurrencyCell value={order?.total ?? 0} />
            </LabelText>
              
            {/* TODO: Add debits and fix discount summaries */}
            {/* {order?.debits ? (
              <LabelText label="Credits Used:" >
                <CurrencyCell value={order?.debits?.reduce((acc, d) => (acc + d.amount))} />
              </LabelText>
            ) : (<></>)} */}

            {order?.discount ? (
              <LabelText label="Scholarship:">
                <span className="text-capitalize">
                  {titleCase(order?.discount?.scholarship?.scholarship_type)} ({order?.discount?.scholarship?.percent_discount}%&nbsp;off)
                </span>
              </LabelText>
            ) : (<></>)}

            {/* TODO: add send confirmation feature */}
            {order?.transaction_id ? (
              <Stack>
                <LabelText label="Status:">
                  <span style={{color: 'green'}}>Complete</span>
                </LabelText>
                <Button variant="primary" size="sm">
                  Send Confirmation Email
                </Button>
              </Stack>

            ) : (
              <LabelText label="Status:">
                <span style={{color: 'red'}}>Incomplete</span>
              </LabelText>
            )}
          </DetailSection>

        </Stack>
      </div>
    </>
  );
};
