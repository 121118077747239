import {
  Col,
  Form,
  FormControl,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import { StepProps } from "../AddEditRecipe";
import { useRef, useState } from "react";
import Editor from "../../../../../../components/editor/Editor";
import { useGetFeaturedIngredientsQuery } from "../../../../../../services/endpoints/content/featuredIngredient";
import { CountryFact, FeaturedIngredient, FoodHistory, KitchenSkill } from "../../../../../../types/content.type";
import { useGetCountryFactsQuery } from "../../../../../../services/endpoints/content/countryFacts";
import {
  cookMethods,
  cuisineTypes,
  mealTypes,
  recipeTypes,
  seasonValues,
} from "../../../../../../utils/consts/content/recipes";
import { useGetKitchenSkillsQuery } from "../../../../../../services/endpoints/content/kitchenSkills";
import { useGetFoodHistoriesQuery } from "../../../../../../services/endpoints/content/foodHistories";
import { useToast } from "../../../../../../context/ToastContext";
import { getErrorMessage } from "../../../../../../utils/utils";

const RecipeFileElement = ({
  onChange,
  file,
  label,
  field
}: {
  onChange: any;
  field: string;
  label: string;
  file?: File | null;
}) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    onChange(file);
  };
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <input
        name={field}
        ref={fileRef}
        className="form-control"
        type="file"
        onChange={handleFileChange}
      />
      {/* Display the name of the file if it exists */}
      {file && <div className="mt-2">Selected file: {file.name}</div>}
    </Form.Group>
  );
};

export const RecipeDetailsForm = ({
  touched,
  errors,
  readOnly,
  values,
  setFieldValue,
}: StepProps) => {
  const [fileName, setFileName] = useState<string>('');
  const { data: dataFeaturedIngredients, isLoading: isLoadingFeaturedIngredients, error: errorFeaturedIngredients } = useGetFeaturedIngredientsQuery({ scope: 'all' });
  const { data: dataCountryFacts, isLoading: isLoadingCountryFacts, error: errorCountryFacts } = useGetCountryFactsQuery({ scope: 'all' });
  const { data: dataKitchenSkills, isLoading: isLoadingKitchenSkills, error: errorKitchenSkills } = useGetKitchenSkillsQuery();
  const { data: dataFoodHistories, isLoading: isLoadingFoodHistories,  error: errorFoodHistories } = useGetFoodHistoriesQuery({ scope: 'all' });
  
  const toast = useToast();

  if (
    isLoadingFeaturedIngredients ||
    isLoadingCountryFacts ||
    isLoadingKitchenSkills ||
    isLoadingFoodHistories
  ) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    );
  }

  if (errorFeaturedIngredients || errorCountryFacts || errorKitchenSkills || errorFoodHistories) {
    let errorMessage = '';
    if (errorFeaturedIngredients) {
      errorMessage += getErrorMessage(errorFeaturedIngredients);
    } else if (errorCountryFacts) {
      errorMessage += getErrorMessage(errorCountryFacts);
    } else if (errorKitchenSkills) {
      errorMessage += getErrorMessage(errorKitchenSkills);
    } else if (errorFoodHistories) {
      errorMessage += getErrorMessage(errorFoodHistories);
    }

    toast.addToast(
      getErrorMessage(errorMessage) ?? "Error loading data",
      "error"
    );
  }

  const handleFileChange = (file: File, field: string) => {
    setFieldValue?.(field, file);
  };
  
  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Recipe Details
      </h6>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Field
              name="title"
              type="text"
              as={Form.Control}
              isInvalid={touched.title && !!errors.title}
              disabled={readOnly}
            />
            <ErrorMessage name="title">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="publish_status">
            <Form.Label>Publish Status</Form.Label>
            <Field
              name="publish_status"
              as={Form.Select}
              isInvalid={touched.publish_status && !!errors.publish_status}
              disabled={readOnly}
            >
              <option>Select</option>
              <option value="unpublished">Unpublished</option>
              <option value="published">Published</option>
            </Field>
            <ErrorMessage name="publish_status">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="story">
            <Form.Label>Story</Form.Label>
            <Field
              name="story"
              component={Editor}
              isInvalid={touched.story && !!errors.story}
              disabled={readOnly}
            />
            <ErrorMessage name="story">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <RecipeFileElement
            label="Selected Public/Customer File"
            onChange={(file: File) => handleFileChange(file, "public_file")}
            file={values.public_file}
            field="public_file"
          />
          <Form.Group controlId="featured_ingredient_id">
            <Form.Label>Featured/Surprise Ingredient</Form.Label>
            <Field
              name="featured_ingredient_id"
              as={Form.Select}
              isInvalid={
                touched.featured_ingredient_id &&
                !!errors.featured_ingredient_id
              }
              disabled={readOnly}
            >
              <option>Select</option>
              {dataFeaturedIngredients?.results.map(
                (featuredIngredient: FeaturedIngredient) => (
                  <option
                    key={featuredIngredient.id}
                    value={featuredIngredient.id}
                  >
                    {featuredIngredient.name}
                  </option>
                )
              )}
            </Field>
            <ErrorMessage name="featured_ingredient_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="country_fact_id">
            <Form.Label>Country fact</Form.Label>
            <Field
              name="country_fact_id"
              as={Form.Select}
              isInvalid={touched.country_fact_id && !!errors.country_fact_id}
              disabled={readOnly}
            >
              <option>Select</option>
              {dataCountryFacts?.results.map((countryFact: CountryFact) => (
                <option key={countryFact.id} value={countryFact.id}>
                  {countryFact.name}
                </option>
              ))}
            </Field>
            <ErrorMessage name="country_fact_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="cuisine_type">
            <Form.Label>Geographic Region</Form.Label>
            <Field
              name="cuisine_type"
              as={Form.Select}
              isInvalid={touched.cuisine_type && !!errors.cuisine_type}
              disabled={readOnly}
            >
              <option>Select</option>
              {Object.entries(cuisineTypes).map((p) => (
                <option key={p[0]} value={p[0]}>
                  {p[1]}
                </option>
              ))}
            </Field>
            <ErrorMessage name="cuisine_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="cook_method">
            <Form.Label>Cook Method</Form.Label>
            <Field
              name="cook_method"
              as={Form.Select}
              isInvalid={touched.cook_method && !!errors.cook_method}
              disabled={readOnly}
            >
              <option>Select</option>
              {Object.entries(cookMethods).map((p) => (
                <option key={p[0]} value={p[0]}>
                  {p[1]}
                </option>
              ))}
            </Field>
            <ErrorMessage name="cook_method">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Stack>
      </Col>
      <Col>
        <Stack gap={3}>
          <Form.Group controlId="author">
            <Form.Label>Author</Form.Label>
            <Field
              name="author"
              type="text"
              as={Form.Control}
              isInvalid={touched.author && !!errors.author}
              disabled={readOnly}
            />
            <ErrorMessage name="author">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="recipe_type">
            <Form.Label>Recipe Type</Form.Label>
            <Field
              name="recipe_type"
              as={Form.Select}
              isInvalid={touched.recipe_type && !!errors.recipe_type}
              disabled={readOnly}
            >
              <option>Select</option>
              {Object.entries(recipeTypes).map((p) => (
                <option key={p[0]} value={p[0]}>
                  {p[1]}
                </option>
              ))}
            </Field>
            <ErrorMessage name="recipe_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="kitchen_skill_ids">
            <Form.Label>Kitchen skills</Form.Label>
            <Field
              name="kitchen_skill_ids"
              as={Form.Select}
              isInvalid={
                touched.kitchen_skill_ids && !!errors.kitchen_skill_ids
              }
              multiple
              disabled={readOnly}
            >
              {dataKitchenSkills?.results.map((kitchenSkill: KitchenSkill) => (
                <option key={kitchenSkill.id} value={kitchenSkill.id}>
                  {kitchenSkill.name}
                </option>
              ))}
            </Field>
            <ErrorMessage name="kitchen_skill_ids">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <RecipeFileElement
            label="Selected Private/Instructor File"
            onChange={(file: File) => handleFileChange(file, "private_file")}
            file={values.private_file}
            field="private_file"
          />
          <Form.Group controlId="food_history_id">
            <Form.Label>Food history</Form.Label>
            <Field
              name="food_history_id"
              as={Form.Select}
              isInvalid={touched.food_history_id && !!errors.food_history_id}
              disabled={readOnly}
            >
              <option>Select</option>
              {dataFoodHistories?.results.map((foodHistory: FoodHistory) => (
                <option key={foodHistory.id} value={foodHistory.id}>
                  {foodHistory.name}
                </option>
              ))}
            </Field>
          </Form.Group>
          <Form.Group controlId="season">
            <Form.Label>Season</Form.Label>
            <Field
              name="season"
              as={Form.Select}
              isInvalid={touched.season && !!errors.season}
              disabled={readOnly}
            >
              <option>Select</option>
              {Object.entries(seasonValues).map((p) => (
                <option key={p[0]} value={p[0]}>
                  {p[1]}
                </option>
              ))}
            </Field>
            <ErrorMessage name="season">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="meal_type">
            <Form.Label>Meal type</Form.Label>
            <Field
              name="meal_type"
              as={Form.Select}
              isInvalid={touched.meal_type && !!errors.meal_type}
              disabled={readOnly}
            >
              <option>Select</option>
              {Object.entries(mealTypes).map((p) => (
                <option key={p[0]} value={p[0]}>
                  {p[1]}
                </option>
              ))}
            </Field>
            <ErrorMessage name="meal_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Stack>
      </Col>
    </Row>
  );
};
