import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { Waiver } from '../../../services/endpoints/people/user';
import { Course } from '../../../types/course.type';


export const waiverColumns: ColumnDef<Waiver>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/waivers/${getValue<number>()}`} />
  },
  {
    header: 'Session',
    accessorKey: 'course',
    cell: ({ getValue }) => <LinkCell content={getValue<Course>().title} url={`/admin/schedule/sessions/${getValue<Course>().id}`} />
  },
  {
    header: 'Child First Name',
    accessorKey: 'child_first_name',
  },
  {
    header: 'Child Last Name',
    accessorKey: 'child_last_name',
  },
  {
    header: 'Parent First Name',
    accessorKey: 'parent_first_name',
  },
  {
    header: 'Parent Last Name',
    accessorKey: 'parent_last_name',
  },
  {
    header: 'Parent Email Address',
    accessorKey: 'parent_email_address',
  },
  {
    header: 'Parent Mobile Phone',
    accessorKey: 'parent_mobile_number',
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/waivers/${row.original.id}`} underline />
        <LinkCell content="Delete" url={`/admin/waivers/${row.original.id}/delete`} underline />
      </Stack>
    )
  },
];
