import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../context/ToastContext';
import { LinkCell } from '../../../../components/tables/cellComponents/LinkCell';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { DetailSection, LabelText } from '../../../../components/generic';
import { formatDate } from '../../../../utils/dateUtils';
import CurrencyCell from '../../../../components/tables/cellComponents/CurrencyCell';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import { useDeleteEnrollmentMutation, useGetEnrollmentQuery } from '../../../../services/endpoints/schedule/enrollment';
import { Enrollment } from '../../../../types/enrollment.type';
import { getErrorMessage } from '../../../../utils/utils';

export const EnrollmentDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetEnrollmentQuery(+params.id!);
  const [deleteEnrollment] = useDeleteEnrollmentMutation();
  const [enrollment, setEnrollment] = useState<Enrollment>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading enrollment', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setEnrollment(data.enrollment);
    }
  }, [data]);

  const handleDelete = async () => {
    try {
      await deleteEnrollment(Number(params.id!));
      addToast('Enrollment deleted successfully', 'success');
      navigate(`/admin/enrollments`);
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    } 
  };

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  if (!enrollment) return <></>;

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate(`/admin/enrollments`)}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Enrollments List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Enrollment #${enrollment?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/enrollments/${enrollment?.id}/edit`)}
          >
            Edit enrollment
          </SimpleButton>

          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete enrollment
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection title="Details">
            <LabelText label="ID:" text={enrollment?.id} />
            <LabelText label="Course:">
              <LinkCell
                content={enrollment?.session_title}
                url={`/admin/schedule/enrollments/${enrollment?.course_id}`} // TODO: change enrollment link
              />
            </LabelText>
            <LabelText label="Amount:">
              <CurrencyCell value={enrollment?.tuition} />
            </LabelText>
            <LabelText label="Invoice Notes:">
              {enrollment?.invoice_notes ?? 'N/A'}
            </LabelText>
            <LabelText label="Created At:">
              {formatDate(new Date(enrollment?.created_at), 'MM/dd/yyyy h:mm a zzz')}
            </LabelText>
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete enrollment"
        message="Are you sure you want to remove this enrollment?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => handleDelete()}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
