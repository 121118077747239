import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Card, Modal, Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { WeeklyReportResponse } from '../../../../../types/place.types';
import { DetailSection, LabelTextSpread } from '../../../../../components/generic';
import { SimpleTable } from '../../../../../components/tables/SimpleTable';
import { useGetLocationWeeklyReportQuery } from '../../../../../services/endpoints/places/location';
import { weeklyReportColumns } from '../../../../../utils/consts/columnDefs/ledgerColumns';

export const LocationWeeklyReport: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const resource_id = +params.id!;
  const [title, setTitle] = useState<string>(`Location ${resource_id}`);
  const { data, error, isLoading, isFetching } = useGetLocationWeeklyReportQuery({id: resource_id});

  const [report, setReport] = useState<WeeklyReportResponse>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast(`Error while loading location report`, 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setReport(data);
      setTitle(data.title ?? title);
    }
  }, [data]);

  if (isLoading || !report) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate(`/admin/places/locations/${resource_id}`)}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            {title} Details
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{title} Gross Revenue Report</h2>
        </Stack>
      </div>
      <div>
        <Card body>
          <Stack gap={4}>
            <Card body bg="light" style={{maxWidth: '400px'}}>
              <DetailSection >
                <LabelTextSpread label="Franchise Name" text={report.franchise_name} />
                <LabelTextSpread label="Territory #" text={report.territories.length} />
                <LabelTextSpread label="Royalty" text={`${(report.royalty * 100).toFixed(2)}%`} />
                <LabelTextSpread label="Brand Fee" text={`${(report.brand_fee * 100).toFixed(2)}%`} />
              </DetailSection>
            </Card>
            <SimpleTable
              columns={weeklyReportColumns}
              data={report.data}
              maxHeight={"70vh"}
            />
          </Stack>
        </Card>
        <Modal show={isFetching}>
          <Modal.Body className="text-center">
            <Spinner />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
