import { Badge, Button, Form, Stack } from "react-bootstrap";
import { Child } from "../../../../services/endpoints/people/user";
import { Course } from "../../../../types/course.type";
import "./CourseRegister.css";
import { LuChefHat } from "react-icons/lu";
import { BsFillPersonFill } from "react-icons/bs";
import { SimpleButton } from "../../../../components/buttons/SimpleButton";
import { useState, useEffect } from "react";
import { formatToCurrency, getErrorMessage } from "../../../../utils/utils";
import { Lesson } from "../../../../types/lesson.type";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Order } from "../../../../types/orders.type";
import { useToast } from "../../../../context/ToastContext";
import { useDeleteRegistrationMutation } from "../../../../services/endpoints/content/registrations";
import { useUpsertOrderMutation, useGetAllOrdersQuery } from "../../../../services/endpoints/orders/orders";
import { getCurrentUser } from "../../../../services/helper";
import { GetQueryParamsWithFilterArray } from "../../../../types/api.type";
import { useCartContext } from "../../../../context/CartContext";

interface CourseRegisterProps {
  course: Course;
  userChildren: Child[];
  classesRemaining: Lesson[];
  hideOverlay: () => void;
  addChild: () => void;
}

function getCourseValues(
  course: Course,
  courseChildren: string[],
  classesRemaining: Lesson[]
): any {
  let values = {
    total: 0,
    prorated: 0,
  };

  values.total =
    course.lessons.length * course.price_per_class * courseChildren.length;
  values.prorated = course.can_be_prorated ? (
    classesRemaining.length * course.price_per_class * courseChildren.length
  ) : (values.total);
  return values;
}

const currentUser = getCurrentUser();

const CourseRegister: React.FC<CourseRegisterProps> = ({ course, userChildren, hideOverlay, addChild, classesRemaining }) => {
  const cartContext = useCartContext();
  const params = useParams();
  const [childrenSelected, setChildrenSelected] = useState<string[]>([]);
  const [courseValues, setCourseValues] = useState<{ total: number; prorated: number }>(getCourseValues(course, childrenSelected, classesRemaining));

  const filters: GetQueryParamsWithFilterArray = {data: {...(currentUser ? {filterBy: [{field: 'parent_id', operator: 'equals', value: currentUser?.id}, {field: 'course_id', operator: 'equals', value: +params.id!}]} : {})} };
  const { data: orders, error: ordersError, isLoading: ordersLoading, isFetching, refetch } = useGetAllOrdersQuery(filters, { skip: !currentUser});

  const [ childIds, setChildIds ] = useState<Array<number>>([]);
  const [ removeRegistration ] = useDeleteRegistrationMutation();
  const [ register ] = useUpsertOrderMutation();
  const [ order, setOrder ] = useState<Order>();

  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    setCourseValues(getCourseValues(course, childrenSelected, classesRemaining));
  }, [childrenSelected]);

  useEffect(() => {
    if (!isFetching && orders && orders.results) {
      if (orders.results.length > 0) {
        setOrder(orders.results[0]);
        if (orders.results[0].registrations?.length === 0) {
          cartContext.removeCourses([course.id]);
        }
      }
    } 
  }, [orders, isFetching]);

  const registerChildren = async (childIds: number[]) => {
    try {
      if (course?.id && currentUser?.id && childIds.length > 0) {
        let orderPayload = {
          course_id: course?.id,
          parent_id: currentUser?.id ?? 0,
          child_ids: childIds,
        }
        let result = await register(orderPayload).unwrap();
        let count = result?.order?.registrations?.length ?? 0;
        if (count > 0 && result?.order?.course_id) {
          cartContext.addCourses([result.order.course_id]);
        }
        setOrder(result.order);
      }
    } catch (error) {
      addToast(getErrorMessage(error), 'error');
    } 
  }

  const toggleChildId = (childId?: number) => {
    if (!childId) return;
    setChildIds(old => {
      let copy = old.slice();
      let idx = copy.indexOf(childId);
      if (idx !== -1) {
        copy.splice(idx, 1);
      }
      else {
        copy.push(childId);
      }
      return copy;
    })
  }

  const deleteRegistration = async (id?: number) => {
    if (id === undefined) return;
    try {
      await removeRegistration(id).unwrap();
      refetch();
    } catch (error) {
      addToast(getErrorMessage(error), 'error');
    }
  }
  
  return (
    <>
      {course && (
        <div className="p-2">
          <div className="d-flex align-items-center justify-content-between">
            <span className="fw-bold text-info h2">{course.title}</span>
            <span
              className="fw-bold text-uppercase"
              style={{ color: "#5E646980", cursor: "pointer" }}
              onClick={hideOverlay}
            >
              Cancel
            </span>
          </div>
          <div className="mt-3 d-flex align-items-center">
            <span
              className="text-uppercase pe-1"
              style={{ color: "#5E6469BF" }}
            >
              Enroll Students:
            </span>
            <Badge bg={"light"}>{childrenSelected.length} Selected</Badge>
          </div>
          <Stack gap={1}>
            <div className="children-select">
              {userChildren?.length > 0 &&
                userChildren.map((child, index) => {
                  let registration = order?.registrations?.find(r => r.child_id === child.id);
                  return registration ? 
                    (<Stack key={index} direction="horizontal" gap={1}>
                      <b>{child.full_name}</b>is already registered! 
                      <Link to="#" onClick={() => deleteRegistration(registration?.id)}>Remove</Link> 
                    </Stack>)
                  : 
                  (<Form.Check
                    key={index}
                    type="checkbox"
                    id={`child-${index}`}
                    label={`${child.first_name} ${child.last_name}`}
                    value={child.id}
                    onClick={(e) => {
                      toggleChildId(child.id);
                      const isChecked = (e.target as HTMLInputElement).checked;
                      const value = (e.target as HTMLInputElement).value;
                      if (isChecked) {
                        setChildrenSelected([...childrenSelected, value]);
                      } else {
                        setChildrenSelected(
                          childrenSelected.filter((id) => id !== value)
                        );
                      }
                    }}
                  />)
                })}
            </div>
            <div className="children-select">
              <span className="add-child" onClick={addChild}>
                + Add another child to your account
              </span>
            </div>
            <div className="course-information mt-4">
              <div className="h3">
                <span className="text-info fw-bold">
                  Tuition: {formatToCurrency(courseValues.total)}
                </span>
                {course.can_be_prorated && <>- <span className="prorated">{formatToCurrency(courseValues.prorated)} Prorated</span></>}
              </div>
              <div className="classes-remaining">
                <LuChefHat className="icon" />
                <span>{classesRemaining.length} of {course.lessons.length} Classes remaining</span>
                {course.can_be_prorated && <>: <span className="classes-total">{formatToCurrency(course.price_per_class)} (x{childrenSelected.length})</span></>}
              </div>
              <div className="students-information">
                <BsFillPersonFill className="icon" />
                <span>
                  {childrenSelected.length} student
                  {childrenSelected.length > 1 ? "s" : ""}
                </span>
              </div>
            </div>
          </Stack>
          <Stack gap={2} direction="horizontal" className="mt-3">
            <SimpleButton
              type="button"
              variant="outline-primary"
              width={"100%"}
              onClick={async () => {
                await registerChildren(childIds);
                hideOverlay();
              }
            }
            >
              Keep Shopping
            </SimpleButton>
            <SimpleButton type="button" variant="primary" width={"100%"}
              onClick={async () => {
                await registerChildren(childIds);
                hideOverlay();
                navigate("/cart");
              }}>
              Checkout
            </SimpleButton>
          </Stack>
        </div>
      )}
    </>
  );
};

export default CourseRegister;