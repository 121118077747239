import React, { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { BackButton } from "../../../../../components/buttons/BackButton";
import Stepper from "../../../../../components/stepper/Stepper";
import {
  Formik,
  Form as FormikForm,
  FormikErrors,
  FormikTouched,
  FormikProps,
} from "formik";
import { FadeIn } from "../../../../animations/FadeIn";
import * as Yup from "yup";
import { SimpleButton } from "../../../../../components/buttons/SimpleButton";
import { useToast } from "../../../../../context/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import { JokeForm } from "./JokeForm";
import {
  useCreateJokeMutation,
  useGetJokeQuery,
  useUpdateJokeMutation,
} from "../../../../../services/endpoints/content/joke";
import { getErrorMessage } from "../../../../../utils/utils";
import { addJoke } from "../../../../../services/slicers/jokeSlice";
import { useAppDispatch } from "../../../../../services/hooks";

const steps = [{ name: "Details", form: JokeForm }];

export interface FormValues {
  id?: number;
  name: string;
  joke_category_id: string;
  content: string;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditJokeProps {
  editing?: boolean;
}

export const AddEditJoke: React.FC<AddEditJokeProps> = ({ editing }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [createJoke] = useCreateJokeMutation();
  const [updateJoke] = useUpdateJokeMutation();
  const { data, error, isLoading } = useGetJokeQuery(Number(params.id!), {
    skip: !editing || !params.id,
  });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: "",
    content: "",
    joke_category_id: "",
  });

  const validationSchemas = [
    Yup.object({
      name: Yup.string().required("Name is required"),
      joke_category_id: Yup.string().required("Joke Category is required"),
      content: Yup.string().required("Content is required"),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast("Error while loading joke", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, error, isLoading]);

  useEffect(() => {
    if (data && data.joke) {
      const { joke } = data;
      setInitialValues({
        id: joke.id,
        name: joke.name,
        content: joke.content,
        joke_category_id: joke.joke_category_id.toString(),
      });
    }
  }, [data]);

  const handleSubmit = async (formValue: FormValues) => {
    try {
      const jokeData = {
        ...formValue,
        joke_category_id: Number(formValue.joke_category_id),
      };
      if (editing) {
        const res = await updateJoke(jokeData).unwrap();
        if (res && res.joke) {
          addToast(`Joke updated successfully`, "success");
          navigate(`/admin/content/jokes/${data?.joke.id}`);
        }
      } else {
        const res = await createJoke(jokeData).unwrap();
        if (res && res.joke) {
          dispatch(addJoke(res.joke));
          navigate(`/admin/content/jokes/${res.joke.id}`);
          addToast("Joke created successfully", "success");
        } else throw new Error("A problem happened while creating joke");
      }
    } catch (e) {
      addToast(getErrorMessage(e), "error");
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>,
    setTouched: (
      touched: FormikTouched<FormValues>,
      shouldValidate?: boolean
    ) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
        content: true,
        joke_category_id: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to joke${editing ? " details" : "s"}`}
            color="#2B4E64"
            url={
              editing
                ? `/admin/content/jokes/${data?.joke.id}`
                : "/admin/content/jokes"
            }
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: "2.25rem" }}
          >
            {editing ? "Edit" : "New"} Joke
          </h2>
          {steps.length > 1 && (
            <Stepper
              steps={steps}
              currentStep={currentStep}
              editing={editing === true}
              onStepClick={(step) => setCurrentStep(step)}
            />
          )}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow:
              "0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)",
            border: "1px solid #EBEBEB",
            borderRadius: 10,
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas[currentStep]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              submitForm,
              isSubmitting,
              validateForm,
              touched,
              errors,
              setTouched,
              values,
              setFieldValue,
            }: FormikProps<FormValues>) => (
              <FormikForm className="text-start">
                <div style={{ minHeight: "65vh" }}>
                  {currentStep < steps.length &&
                    React.createElement(steps[currentStep]?.form, {
                      errors,
                      touched,
                      values,
                      setFieldValue,
                    })}
                </div>

                <Row className="justify-content-end mt-3">
                  <Col xs={6} lg={3}>
                    {currentStep !== 0 && (
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={() => setCurrentStep(currentStep - 1)}
                        className="w-100"
                        variant="outline-primary"
                      >
                        Back
                      </SimpleButton>
                    )}
                  </Col>

                  <Col xs={6} lg={3}>
                    <SimpleButton
                      disabled={isSubmitting}
                      type="button"
                      onClick={
                        currentStep === steps.length - 1
                          ? submitForm
                          : () => handleNext(validateForm, setTouched, values)
                      }
                      className="w-100"
                      variant="primary"
                    >
                      {currentStep === steps.length - 1 ? "Finish" : "Continue"}
                    </SimpleButton>
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
