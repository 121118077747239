import { useEffect, useState } from 'react';
import { useToast } from '../../../../context/ToastContext';
import { getCurrentTimesheetColumns } from '../../../../utils/consts/columnDefs/timesheetColumns';
import { useCreateTimesheetOverrideMutation, useGetCurrentEntriesQuery } from '../../../../services/endpoints/finance/timesheets';
import { timesheet_entries } from '../../../../types/finance.types';
import { Form, Spinner, Stack } from 'react-bootstrap';
import { Form as FormikForm, Field, Formik } from 'formik';
import * as Yup from "yup";
import { User, useGetInstructorsQuery } from '../../../../services/endpoints/people/user';
import { formatUserName, getCurrentUser } from '../../../../services/helper';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import CurrencyCell from '../../../../components/tables/cellComponents/CurrencyCell';
import { formatDateString } from '../../../../utils/utils';
import { isLocal } from '../../../../utils/consts';
import { DetailSection, LabelText, TimesheetIntro } from '../../../../components/generic';
import SFCTable from '../../../../components/tables/SFCTable';


export const CurrentTimesheetView = () => {
  const { addToast } = useToast();
  const currentUser = getCurrentUser();
  const isLocalUser = isLocal(currentUser?.user_type_id!);
  const [userId, setUserId] = useState<number>(isLocalUser ? 0 : (currentUser?.id ?? 0));
  const { data, error, isLoading } = useGetCurrentEntriesQuery(userId, {skip: !userId});
  const { data: instructors, error: instructorsError, isLoading: instructorsLoading } = useGetInstructorsQuery({scope: 'active_instructors', data: { orderBy: { field: 'first_name', order: 'asc' } } }, {skip: !isLocalUser});
  const [ createTimesheetOverride ] = useCreateTimesheetOverrideMutation();
  const [timesheetEntries, setTimesheetEntries] = useState<timesheet_entries[]>([]);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.payroll && data.payroll.timesheetEntries) {
      setTimesheetEntries(data.payroll.timesheetEntries);
    }
  }, [data]);

  const validationSchema = Yup.object({
    instructorId: Yup.number(),
  });

  const onSubmit = (arg: any) => {
    setUserId(parseInt(arg.instructor));
  }

  const tableValidationSchema = Yup.object({
    timesheet_override_type: Yup.string(),
    override_request: Yup.string(),
    lesson_id: Yup.number(),
    instructor_id: Yup.number(),
    instructor_current_rate: Yup.number(),
    instructor_current_reimbursement: Yup.number(),
  })

  const onTableFormSubmit = (values: any) => {
    createTimesheetOverride(values).unwrap().then(() => {
      addToast("Successfully created timesheet override", "success");
    }).catch((error: any) => {
      console.log(error);
      addToast(`Failed to create timesheet override: ${JSON.stringify(error)}`, "error");
    });
  }

  const sidebars = [<TimesheetIntro />];

  if (isLocalUser) {
    sidebars.unshift(
      <DetailSection title="Instructors">
        <Formik
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <FormikForm className="text-center">
              <Form.Group controlId="instructor" >
              <Stack direction='horizontal' gap={2}>
                <Field
                  name="instructor"
                  as={Form.Select}
                >
                  <option>Select Instructor</option>
                  {instructors?.results?.map((ins: User) => (
                    <option key={ins.id} value={ins.id}>{formatUserName(ins)}</option>
                  ))}
                </Field>
                <SimpleButton width={100} height={45} type="submit" className="text-center btn-sm" variant="primary">
                  Submit
                </SimpleButton>
              </Stack>
              </Form.Group>
            </FormikForm>
          )}
        </Formik>
      </DetailSection>
    );
  }

  if (data?.payroll) {
    sidebars.push(
      <DetailSection title="Summary">
        <LabelText label="Starts On:" text={formatDateString(String(data?.start))} />
        <LabelText label="Ends On:" text={formatDateString(String(data?.end))} />
        <LabelText label="# of classes:" text={String(data?.payroll.totalLessonCount)} />
        <LabelText label="Total:" >
          <CurrencyCell value={String(data?.payroll.payAmount)} />
        </LabelText>
        <br />
        <LabelText label="YTD Gross Pay:" >
          <CurrencyCell value={String(data?.ytd.payAmount)} />
        </LabelText>
      </DetailSection>
    );
  }

  return (
    <div className="py-2">
      <SFCTable
        name={""}
        columns={getCurrentTimesheetColumns(isLocalUser)}
        data={timesheetEntries}
        count={timesheetEntries?.length}
        defaultScope={'all'}
        isLoading={isLoading}
        sidebars={sidebars}
        indexDownloadPath={currentUser?.isSuper ? `current-entries` : undefined}
        rowFormProps={{
          validationSchema: tableValidationSchema,
          onSubmit: onTableFormSubmit,
          getInitialValues: (row) => ({
            instructor_id: row.original.instructor_id,
            lesson_id: row.original.lesson_id,
            instructor_current_rate: row.original.instructor_session_rate,
            instructor_current_reimbursement: row.original.instructor_session_reimbursement,
          })
        }}
      />
    </div>
  );
}
