import { Card, Col, Modal, Row, Stack } from 'react-bootstrap';
import { OrderCard } from './components/OrderCard';
import { Discount, OrderPricing, LocationOrders, PromotionalCode } from '../../../../types/orders.type';
import { OrderCost } from './components/OrderCost';
import { LabelTextSpread } from '../../../../components/generic';
import CurrencyCell from '../../../../components/tables/cellComponents/CurrencyCell';
import { useStripe } from '@stripe/react-stripe-js';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { useEffect, useState } from 'react';
import PaymentForm from './PaymentForm';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { titleCase } from '../../../../utils/stringConversions';
import { SearchField } from './components/SearchField';
import { useLazySearchPromotionalCodeQuery } from '../../../../services/endpoints/orders/promotionalCode';
import { getErrorMessage } from '../../../../utils/utils';
import { StepProps } from '../OrderCheckout';
import { ReactComponent as SFCLogoLarge } from '../../../../assets/icons/sfc-logo-large.svg';

const clientSecret_qs = new URLSearchParams(window.location.search).get(
  "payment_intent_client_secret"
);

const locationId_qs = new URLSearchParams(window.location.search).get(
  "location_id"
);

export const OrderReview: React.FC<StepProps> = ({ values, next }) => {
  // Promo codes
  const [ searchPromoCode ] = useLazySearchPromotionalCodeQuery();
  const [ promoCodeMessage, setPromoCodeMessage ] = useState<{msg: string, type: 'valid'|'invalid'}>();
  const [ promoCode, setPromoCode ] = useState<PromotionalCode>();

  const [ showModal, setShowModal ] = useState<boolean>(false);
  const [ appliedDiscounts, setAppliedDiscounts ] = useState<Discount[]>([]);
  const [ locationOrders, setLocationOrders ] = useState<LocationOrders>();

  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret_qs) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret_qs).then(({paymentIntent}) => {
      if (!paymentIntent) {
        return;
      };

      if (!locationId_qs) {
        return;
      }

      if (locationId_qs === String(values.location.id)) {
        if (paymentIntent.status === "succeeded") {
          next();
        }
        else {
          console.log("Error: Payment status: ", paymentIntent.status);
        }
      }
    });
  }, [stripe]);

  useEffect(() => {
    let locOrders = values.orders?.reduce((acc, curr) => {
      let location = values.location;
      const childIds = curr.registrations.map(r => r.child_id);
      if ((curr.registrations?.length ?? 0) > 0) {
        const discounts = appliedDiscounts?.filter(d => (
          (d.user_id && d.user_id === curr.parent_id)
          && (d.order_id ? d.order_id === curr.id : true)
          && (d.course_id ? d.course_id === curr.course_id : true)
          && (d.child_id ? childIds.includes(d.child_id) : true)
        ));
        let discount = discounts.reduce((acc, d) => acc + (
          d.child_id ? (childIds.includes(d.child_id) ? ((d.scholarship?.percent_discount / curr.registrations.length)/100) : 0) : d.scholarship.percent_discount/100
        ), 0);
        
        let promoDiscount = (promoCode?.percent_discount ?? 0)/100;

        // TODO: figure out how promos and scholarships are supposed to work together or can a user only use one at a time?
        discount = Math.max(discount, promoDiscount);
        
        const pricing: OrderPricing = {
          fullPrice: curr.registrations.reduce((acc, r) => acc + r.full_price, 0),
          price: curr.registrations.reduce((acc, r) => acc + r.price, 0),
          discount,
        };
        let opc = {...curr, cost: pricing};
        if (acc && acc.orders && acc.orders.length > 0) {
          acc.orders.push(opc);
        }
        else {
          acc = {location, orders: [opc]};
        }
      }
      return acc;
    }, {} as LocationOrders);
    
    setLocationOrders(locOrders);
  }, [values, appliedDiscounts, promoCode]);

  const initiatePayment = async () => {
    setShowModal(true);
  }

  const setSelectedScholarships = (discounts: {label: string, value: number}[]) => {
    const discountIds = discounts.map(d => d.value);
    setAppliedDiscounts(values.discounts.filter(d => discountIds.includes(d.id)));
  };

  const searchForPromo = async (search: string) => {
    if (search) {
      try {
        let result = await searchPromoCode(search).unwrap();
        if (result && result.promotionalCode) {
          setPromoCode(result.promotionalCode);
          setPromoCodeMessage({msg: 'Successfully found promo code', type: 'valid'});
        }
        else {
          setPromoCodeMessage({msg: 'Invalid code', type: 'invalid'});
        }
      } catch (error) {
        setPromoCodeMessage({msg: getErrorMessage(error) || 'Failed to submit code, try again', type: 'invalid'});
      }
    }
  };

  let locId = values.location.id;
  let subtotal = locationOrders?.orders.reduce((acc, o) => acc + o.cost.fullPrice, 0) ?? 0;
  let total = locationOrders?.orders.reduce((acc, o) => acc + (o.cost.price * (1 - (o.cost.discount ?? 0))), 0) ?? 0;
  let discount = total - subtotal;
  let discountLabel = '';
  return <Row key={locId}>
    <h2>Orders - Sticky Fingers Cooking {values.location.title}</h2>
    <Col xs={8} className="pe-1">
      <Stack gap={2}>
        {values.orders?.map((o, index) => 
          <Stack gap={1} key={`${locId}-${o.id}`} >
            <OrderCard key={`order-${locId}-${o.id}`} index={index} order={o} />
          </Stack>
        )}
      </Stack>
    </Col>
    <Col xs={4} className="pe-1">
      <Card>
        <Card.Body>
          <Stack gap={2}>
            <SFCLogoLarge style={{ width: '100%' }} className={'mb-3'} />
            <h6 className="text-uppercase text-info mb-0">Order Summary</h6>
            {locationOrders?.orders?.map((o, index) => {
              return (
                <Stack gap={1} key={index} >
                  <OrderCost key={`ordercost-${locId}-${index}`} order={o} index={index} />
                </Stack>
              );
            }
            )}
            <LabelTextSpread label='Subtotal:'>
              <CurrencyCell value={subtotal} />
            </LabelTextSpread>
            {(discount !== 0) && (
              <LabelTextSpread label='Discounts:' >
                {discountLabel}<CurrencyCell value={discount} />
              </LabelTextSpread>
            )}
            <LabelTextSpread fontSize={'1.25rem'} opacity={1} label='Total Due:'>
              <CurrencyCell value={total} />
            </LabelTextSpread>

            <Stack gap={2} >
              <SearchField 
                name="promoCodeSearch"
                label="Promo Code"
                onClick={() => searchForPromo(values.promoCodeSearch ?? '')}
                clear={() => setPromoCode(undefined)}
                message={promoCodeMessage}
              />
              <FormSelect
                name={"scholarships"}
                label="Scholarships"
                multiple
                options={values.discounts?.map(d => ({label: `${titleCase(d.scholarship?.scholarship_type ?? "")} (${d.scholarship.percent_discount}% off)`, value: d.id})) ?? []}
                onClick={setSelectedScholarships}
              />
              <SimpleButton
                type="button"
                onClick={() => initiatePayment()}
                className="mt-3 float-end"
                variant="primary"
              >Continue</SimpleButton>
            </Stack>
            <Modal show={showModal} onHide={() => {setShowModal(false) }}>
              <Modal.Header closeButton>Payment Details</Modal.Header>
              <Card body>
                <PaymentForm location_id={locId} locationOrders={locationOrders} appliedDiscounts={appliedDiscounts} promoCode={promoCode} />
              </Card>
            </Modal>
          </Stack>
        </Card.Body>
      </Card>
    </Col>
  </Row>
}
