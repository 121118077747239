import React from "react";
import { TiArrowRightThick } from "react-icons/ti";
import { To, useNavigate } from "react-router-dom";

interface GoButtonProps {
  text?: string;
  url?: string;
}

export const GoButton: React.FC<GoButtonProps> = ({ text, url }) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(url ?? (-1 as To))}
      className="btn btn-link p-0 fw-bolder text-uppercase link-sfc-blueberry"
      style={{
        fontSize: ".95rem",
        textDecoration: "none",
      }}
    >
      <span>{text}</span>
      <TiArrowRightThick style={{ marginBottom: 4, marginLeft: 6 }} />
    </button>
  );
};
