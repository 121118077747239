import { Button, Col, Form, FormControl, Row, Spinner } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from '../AddChild';
import { pickupOptions, relationOptions } from '../../../utils/consts';
import { Caregiver, useGetCaregiversQuery } from '../../../services/endpoints/people/user';
import { formatUserName } from '../../../services/helper';

export const ContactInfoForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const {data: caregivers, error, isLoading} = useGetCaregiversQuery();

  const handleExistingCaregiverSelect = (index: number, caregiver: Partial<Caregiver>) => {
    setFieldValue(`caregivers.${index}.first_name`, caregiver.first_name);
    setFieldValue(`caregivers.${index}.last_name`, caregiver.last_name);
    setFieldValue(`caregivers.${index}.phone`, caregiver.phone);
    setFieldValue(`caregivers.${index}.email`, caregiver.email);
    setFieldValue(`caregivers.${index}.id`, caregiver.id);
  }
  
  let caregiverContent = (isLoading && !error ) ? <Spinner></Spinner> : <></>;  
  
  return (
    <>
      <FieldArray 
        name="caregivers"
        render={(arrayHelpers) => (
          <Form.Group className="mb-1 mb-lg-3" controlId="caregivers">
            <Form.Label className="h4">Additional Parents & Caregivers</Form.Label>
            <br />
            <Form.Label>Note: Each Additional Parent/Caregiver added is allowed to pickup this child from classes and will be listed as an Emergency Contact.</Form.Label>
            <br />
            <div className="multi-content-scroll mt-2">
              {typeof errors.caregivers === "string" ? <div className="text-danger">{errors.caregivers}</div> : null}
              {values.caregivers && values.caregivers.length > 0 ? (
                values.caregivers.map((a, index) => {
                  let meta = {touched: touched.caregivers ? touched.caregivers[index] : {}, error: errors.caregivers ? (errors.caregivers[index] ?? {}) : {}};
                  return (
                  <div key={index}>
                    <Row>
                      <Col className="ms-1">
                        {caregiverContent}
                        { !error && !isLoading && caregivers && caregivers.length > 0 && (
                          <Form.Group className="mb-1 mb-lg-3">
                            <Form.Label>Choose from existing</Form.Label>
                            <Form.Select defaultValue={""} onChange={(e) => {handleExistingCaregiverSelect(index, JSON.parse(e.target.value))}}>
                              <option>Select</option>
                              {caregivers.map((opt) => (
                                <option value={JSON.stringify(opt)} key={opt.id}>{formatUserName(opt)}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        )}
                        <Row>
                          <Col xs={6} className="pe-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`caregivers.${index}.first_name`}>
                              <Form.Label>First Name</Form.Label>
                              <Field
                                name={`caregivers.${index}.first_name`}
                                type="text"
                                as={Form.Control}
                                isInvalid={meta.touched?.first_name && !!(typeof meta.error === "string" ? meta.error : meta.error.first_name)}
                              />
                              <ErrorMessage name={`caregivers.${index}.first_name`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col xs={6} className="ps-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`caregivers.${index}.last_name`}>
                              <Form.Label>Last Name</Form.Label>
                              <Field
                                name={`caregivers.${index}.last_name`}
                                type="text"
                                as={Form.Control}
                                isInvalid={meta.touched?.last_name && !!(typeof meta.error === "string" ? meta.error : meta.error.last_name)}
                              />
                              <ErrorMessage name={`caregivers.${index}.last_name`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`caregivers.${index}.relation`}>
                              <Form.Label>Relationship to Child (Optional)</Form.Label>
                              <Field
                                name={`caregivers.${index}.relation`}
                                as={Form.Select}
                                options={['2', '3']}
                                placeholder="Select"
                                isInvalid={meta.touched?.relation && !!(typeof meta.error === "string" ? meta.error : meta.error.relation)}
                              >
                                <option>Select</option>
                                {relationOptions.map((opt) => (
                                  <option value={opt} key={opt}>{opt}</option>
                                ))}
                              </Field>
                              <ErrorMessage name={`caregivers.${index}.relation`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`caregivers.${index}.phone`}>
                              <Form.Label>Phone Number</Form.Label>
                              <Field
                                name={`caregivers.${index}.phone`}
                                type="tel"
                                as={Form.Control}
                                isInvalid={meta.touched?.phone && !!(typeof meta.error === "string" ? meta.error : meta.error.phone)}
                              />
                              <ErrorMessage name={`caregivers.${index}.phone`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>

                            <Form.Group className="mb-1 mb-lg-3" controlId={`caregivers.${index}.email`}>
                              <Form.Label>Email Address</Form.Label>
                              <Field
                                name={`caregivers.${index}.email`}
                                type="email"
                                as={Form.Control}
                                isInvalid={meta.touched?.email && !!(typeof meta.error === "string" ? meta.error : meta.error.email)}
                              />
                              <ErrorMessage name={`caregivers.${index}.email`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            
                            {/* <Form.Group controlId={`caregivers.${index}.pickup_allowed`}>
                              <Form.Label>
                                <Field
                                  name={`caregivers.${index}.pickup_allowed`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'This person is allowed to pickup this child from classes'}
                                />
                              </Form.Label>
                            </Form.Group> */}

                            <Form.Group controlId={`caregivers.${index}.receive_auto_notifications`}>
                              <Form.Label>
                                <Field
                                  name={`caregivers.${index}.receive_auto_notifications`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'This person should receive all auto-notifications and messaging'}
                                />
                              </Form.Label>
                            </Form.Group>

                            {/* <Form.Group controlId={`caregivers.${index}.is_emergency_contact`}>
                              <Form.Label>
                                <Field
                                  name={`caregivers.${index}.is_emergency_contact`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'This person is an emergency contact'}
                                />
                              </Form.Label>
                            </Form.Group> */}

                          </Col>
                        </Row>
                      </Col>
                      <Col hidden={index === 0} xs={1}>
                        <Button size="sm" variant="outline-tertiary" onClick={() => arrayHelpers.remove(index)} >x</Button>
                      </Col>
                    </Row>
                    { index < values.caregivers.length - 1 ? <hr /> : <></>}
                  </div>
                )})
              ) : (
                <></>
              )}
            </div>
            <button
              type='button'
              className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
              onClick={() => arrayHelpers.insert(values.caregivers.length, {pickup_allowed: true, is_emergency_contact: true, receive_auto_notifications: false})} // insert an empty string at a position
            >
              + Add Parent or Caregiver
            </button>
          </Form.Group>
        )}
      />
      <hr />
      <Form.Group className="mb-1 mb-lg-3" controlId="pickup_type">
        <Form.Label className="h4">Pick Up Instructions</Form.Label>
        <br />
        <Form.Label>Pick-up Type</Form.Label>
        <Field
          name="pickup_type"
          as={Form.Select}
          options={['2', '3']}
          placeholder="Select"
          isInvalid={touched.pickup_type && !!errors.pickup_type}
        >
          <option>Select</option>
          {pickupOptions.map((opt) => (
            <option value={opt} key={opt}>{opt}</option>
          ))}
        </Field>
        <ErrorMessage name="pickup_type">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>
      <Form.Group className="mb-1 mb-lg-3" controlId="pickup_other">
        <Form.Label>Additional Instructions (Optional)</Form.Label>
        <Field
          name="pickup_other"
          type="text"
          as={Form.Control}
          isInvalid={touched.pickup_other && !!errors.pickup_other}
        />
        <ErrorMessage name="pickup_other">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>
    </>
  )
}
