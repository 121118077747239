import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';

import { FoodHistoryRecipesSaveForm } from '../../../../../types/foodHistories.type';
import { useGetFoodHistoryQuery,
  useCreateFoodHistoryMutation,
  useUpdateFoodHistoryMutation
   } from '../../../../../services/endpoints/content/foodHistories';
import { FoodHistoriesForm } from './FoodHistoriesForm';

const steps = [
  { name: 'Details', form: FoodHistoriesForm },
];

export interface FormValues {
  id?: number;
  name: string;
  content:string;
  file: File,
  image_source:string
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;
export type CreateFormValues = Overwrite<FormValues, {
  recipes: FoodHistoryRecipesSaveForm[], 
}>

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

const foodHistoryLinksMap = (id?: number | string) => {
    return {
        edit: `/admin/content/food-histories/${id}/edit`,
        detail: `/admin/content/food-histories/${id}`,
        list: `/admin/content/food-histories`,
    }
}

interface AddEditFoodHistoryProps {
  editing?: boolean;
}

export const AddEditFoodHistories: React.FC<AddEditFoodHistoryProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createFoodHistory] = useCreateFoodHistoryMutation();
  const [updateFoodHistory] = useUpdateFoodHistoryMutation();
  
  const { data: foodHistoryData, error: foodHistoryError, isLoading: foodHistoryLoading } = 
  useGetFoodHistoryQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    content:'',
    file:{} as File,
    image_source:''
  });

  const validationSchemas = [
    Yup.object({
      name: Yup.string().required('Name is required'),
      content: Yup.string().required('content is required'),
    }),
  ];

  useEffect(() => {
    if (editing && foodHistoryError && !foodHistoryLoading) {
      addToast('Error while loading admin', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, foodHistoryError, foodHistoryLoading]);

  useEffect(() => {
    let values;
    if (foodHistoryData && foodHistoryData?.foodHistory) {
      values = foodHistoryData.foodHistory;
    }

    if (values?.name)
      setInitialValues({
        id:values.id,
        name: values.name,
        content: values?.content ?? '',
        file: values.file,
        image_source:values.image_source
      });

  }, [foodHistoryData]);

  const formatFormValue = (formValue?: FormValues ): CreateFormValues => {
    return JSON.parse(JSON.stringify(formValue)) as CreateFormValues;
  }
  
  const handleAddFoodHistory = async (formValue: FormValues) => {
    try {
      let submission = formatFormValue(formValue)

      if (editing) {
        const res = await updateFoodHistory({
          ...submission,
          id: Number(params.id)!,
        }).unwrap();
        if (res && res.foodHistory) {
          navigate(foodHistoryLinksMap(res.foodHistory.id!)?.list);
          addToast(`Food History updated succesfully`, 'success');
        }
      } else {
        const res = await createFoodHistory(submission).unwrap();
        if (res && res.foodHistory) {
          navigate(foodHistoryLinksMap(res.foodHistory.id!)?.list);
          addToast('Food History created succesfully', 'success');
        } else throw new Error('A problem happened while creating Food History');
      }
    } catch (e) {
      if(e.data && e.data.error) {
        addToast(e.data.error[0], 'error');
      }
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        id:true,
        name: true,
        content: true,
        
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text="Back"
            color="#2B4E64"
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Food History
          </h2>
          {steps.length > 1 && <Stepper editing steps={steps} currentStep={currentStep} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddFoodHistory}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
