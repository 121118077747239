import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getNestedTouchedTrue } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageForm } from './PageForm';
import { useCreatePageMutation, useGetPageQuery, useUpdatePageMutation } from '../../../../../services/endpoints/website/page';
import { ImageDetails, Page } from '../../../../../types/website.types';
import { dateToUTC } from '../../../../../utils/dateUtils';

const steps = [
  { name: 'Details', form: PageForm },
];

export interface FormValues extends ImageDetails {
  id?: number;
  name: string;
  published_on: string;
  author: string;
  is_featured: boolean;
  content: string;
  button_text: string;
  button_target: string;
  button_opens_in_new_window: boolean;
  gallery_id: {label: string, value: number};
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditPageProps {
  editing?: boolean;
}

export const PageLinkSchema = Yup.object({
  text: Yup.string().optional(),
  target: Yup.string().optional(),
  link_type: Yup.string().optional(),
});

export const AddEditPage: React.FC<AddEditPageProps> = ({ editing }) => {
  const params = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search); 
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createPage] = useCreatePageMutation();
  const [updatePage] = useUpdatePageMutation();
  const { data, error, isLoading }  = useGetPageQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    author: '',
    published_on: '',
    is_featured: false,
    content: '',
    button_text: '',
    button_target: '',
    button_opens_in_new_window: false,
    gallery_id: {label: '', value: 0},
  });

  const validationSchemas = [
    Yup.object({
      name: Yup.string().required('Name is required'),
      author: Yup.string().required('Author is required'),
      image: Yup.string().required('Image is required'),
      published_on: Yup.string().required('Published On is required'),
      page_links: Yup.array().of(PageLinkSchema).optional(),
      content: Yup.string().required('Content is required'),
      button_text: Yup.string().required('Button Text is required'),
      button_target: Yup.string().required('Button Target is required'),
      button_opens_in_new_window: Yup.boolean().required('Button Opens is required').default(false),
      gallery_id: Yup.object({value: Yup.number().min(1, "Gallery is required").required("Gallery is required"), label: Yup.string().optional()}).required('Gallery is required'),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading Page', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.page) {
      const { page } = data;
      setInitialValues({
        id: page.id,
        name: page.name,
        author: page.author,
        published_on: page.published_on,
        image_file_name: page.image_file_name,
        is_featured: page.is_featured,
        content: page.content,
        button_text: page.button_text,
        button_target: page.button_target,
        button_opens_in_new_window: page.button_opens_in_new_window,
        gallery_id: {label: page.gallery?.name ?? '', value: page.gallery_id ?? 0},
      });
    }
  }, [data]);


  const handleSubmit = async (formValue: FormValues) => {
    try {
      const pageData: Page = {
        ...formValue,
        gallery_id: formValue.gallery_id.value,
        published_on: dateToUTC(new Date(formValue.published_on), "MM/dd/yyyy"), 
      };
      delete pageData.image_file_name;
      if (editing) {
        const res = await updatePage(pageData).unwrap();
        if (res && res.page) {
          addToast(`Page updated succesfully`, 'success');
          navigate(`/admin/website/pages/${data?.page.id}`);
        }
      } else {
        const res = await createPage(pageData).unwrap();
        if (res && res.page) {
          navigate(`/admin/website/pages/${res.page.id}`);
          addToast('Page created succesfully', 'success');
        } else throw new Error('A problem happened while creating Page');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
        published_on: true,
        author: true,
        is_featured: true,
        content: true,
        button_text: true,
        button_target: true,
        gallery_id: {label: true, value: true},
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to Page${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/website/pages/${data?.page.id}` : '/admin/website/pages'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Page
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
