import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { sfcApi } from './api';
import jokeReducer from './slicers/jokeSlice';

export const store = configureStore({
  reducer: {
    [sfcApi.reducerPath]: sfcApi.reducer,
    jokes: jokeReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(sfcApi.middleware),
});
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
