import { ColumnDef } from "@tanstack/react-table";
import { LinkCell } from "../../../components/tables/cellComponents/LinkCell";
import { Stack } from "react-bootstrap";
import YesNoCell from "../../../components/tables/cellComponents/YesNoCell";
import { registration } from "../../../types/registrations.type";

export const registrationsColumns: ColumnDef<registration>[] = [
    {
      header: "ID",
      accessorKey: "id",
      cell: ({ getValue }) => (
        <LinkCell
          content={getValue<number>()}
          url={`/registrations/${getValue<number>()}`}
        />
      ),
    },

    {
        header: "Order id",
        accessorKey: "order_id",
        cell: ({ getValue }) => (
            <LinkCell
            content={`Order #${getValue<number>()}`}
            url={`/registrations/${getValue<number>()}`}
            />
        ),
        },

    {
        header: "Parent",
        accessorKey: "parent",
        cell: ({ row }) => <> {row.original.order?.parent?.full_name} </>
    },
    {
      header: "Child",
      accessorKey: "child", 
      cell: ({ row }) => <> {row.original.child?.full_name} </>

    },
    {
        header: "Session",
        accessorKey: "session_title",
    },
    {
        header: "Active Status",
        accessorKey: "active_status",
        cell: ({ row }) => (
         <YesNoCell value={row.original?.active_status}/>
        ),
    },

    {
      header: () => null,
      id: "actions",
      enableSorting: false,
      cell: ({ row }) => (
        <Stack direction="horizontal" gap={2}>
          <LinkCell
            content="View"
            url={`/registrations/${row.original.id}`}
            underline
          />
          <LinkCell
            content="Edit"
            url={`/registrations/${row.original.id}/edit`}
            underline
          />
          <LinkCell
            content="Delete"
            url={`/registrations/${row.original.id}/delete`}
            underline
          />
        </Stack>
      ),
    },
  ];