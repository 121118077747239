import { Row, Col, Accordion, Stack } from "react-bootstrap";
import { Course } from "../../../../types/course.type";
import SafeHTMLDisplay from "../../../common/SafeHTMLDisplay";
import { Lesson } from "../../../../types/lesson.type";
import { Link } from "react-router-dom";
import { PiForkKnifeFill } from "react-icons/pi";

function getLessonDate(lesson: Lesson): string {
  const date = new Date(lesson.date);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
}

interface CourseDetailsAccordionProps {
  course: Course;
}

const CourseDetailsAccordion: React.FC<CourseDetailsAccordionProps> = ({
  course,
}) => {
  return (
    <Row className="mt-4">
      <Col>
        <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen={true}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span className="h4 text-capitalize text-info fw-bold mb-0">
                Important Information you KNEAD to know
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <SafeHTMLDisplay htmlContent={course.description} />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span className="h4 text-capitalize text-info fw-bold mb-0">
                JUICY Theme Details
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <span className="accordion-sub">Theme</span>
                <br />
                <span className="fw-bold">{course.theme.name}</span>
              </p>
              <SafeHTMLDisplay htmlContent={course.theme.details} />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="h4 text-capitalize text-info fw-bold mb-0">
                Recipes
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p className="accordion-sub">Recipes</p>
              {course.lessons.map((lesson, index) => (
                <div key={`recipe-lesson-${index}`}>
                  {lesson.lesson_plans.map((plan, idx) => (
                    <Stack key={idx} gap={1} direction="horizontal">
                      <PiForkKnifeFill style={{color: "#000", fontSize: "18px"}}/>
                      <Link
                        key={`recipe-lesson-plan-${idx}`}
                        to={`/lesson-plans/${plan.slug}`}
                        className="text-capitalize"
                        style={{ textDecoration: "none", color: "#6098B8" }}
                      >
                        {plan.name}
                      </Link>
                    </Stack>
                  ))}
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <span className="h4 text-capitalize text-info fw-bold mb-0">
                Class Schedule
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <p className="accordion-sub">Session Schedule & Recipes</p>
              {course.lessons.map((lesson, index) => (
                <p key={`schedule-lesson-${index}`}>
                  <span className="fw-bold text-uppercase">
                    {getLessonDate(lesson)}{" "}
                  </span>
                  <span>({lesson.formatted_time} MT)</span>
                  <br />
                  {lesson.lesson_plans.map((plan, idx) => (
                    <Link
                      key={`schedule-lesson-plan-${index}-${idx}`}
                      to={`/lesson_plans/${plan.slug}`}
                      className="text-capitalize"
                      style={{ textDecoration: "none", color: "#6098B8" }}
                    >
                      {plan.name}
                    </Link>
                  ))}
                </p>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};
export default CourseDetailsAccordion;
