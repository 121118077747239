/* eslint-disable react-hooks/exhaustive-deps */
import {APIProvider, Map, MapEvent } from '@vis.gl/react-google-maps';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { course_type_marker_lookup } from '../../maps/components';
import { Course } from '../../../types/course.type';
import { MarkerWithIcon } from '../../maps/components/MarkerWithIcon';
import { useMyLocationContext } from '../../../context/LocationContext';
import { LatLng } from '../../../services/helper';

interface CourseMapProps {
  courses: Course[];
  location?: LatLng;
  selectedCourseId?: number;
  onVenueClick: (course: Course) => void;
}

const getCenter = (location?: LatLng, fallback?: LatLng) => {
  return {
    lat: location?.lat ?? fallback?.lat ?? 41.831909,
    lng: location?.lng ?? fallback?.lng ?? -87.698316,
  }
}

const CourseMap: React.FC<CourseMapProps> = ({ courses, location, selectedCourseId, onVenueClick }) => {
  const { myLocation } = useMyLocationContext();
  const [ center, setCenter ] = useState<LatLng>(getCenter(location, myLocation?.location))
  const mapRef = useRef<any>(null);
  
  useEffect(() => {
    const newCenter = getCenter(location, myLocation?.location);
    setCenter(newCenter);
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        if (!bounds.contains(newCenter)) {
          mapRef.current.panTo(newCenter);
          mapRef.current.setZoom(8);
          }
      }
    }
  }, [location, myLocation]);

  // Callback to handle map load
  const handleMapLoad = (event: MapEvent<unknown>) => {
    mapRef.current = event.map; // Store the map instance in the ref
};

  return (
    <div>
      <Container>
        <div style={{minHeight: "500px", position: "relative"}}>
          <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}>
            <APIProvider apiKey={'AIzaSyC3UNDf0rod5gJJ5TUk1z3p1fL0DNBXTg8'}>
              <Map 
                defaultCenter={center} 
                defaultZoom={10} 
                mapId={'784a0a7ed39c3fd8'} 
                disableDefaultUI 
                fullscreenControl={false} 
                zoomControl={false} 
                streetViewControl={false} 
                mapTypeControl={false}
                onTilesLoaded={handleMapLoad}
              >
                {courses.map(c => {
                  if (!(c.venue.latitude && c.venue.longitude)) {
                    return <div key={c.id}></div>;
                  }
                  return <MarkerWithIcon key={c.id} onClick={() => onVenueClick(c)} icon={course_type_marker_lookup(c.course_type, selectedCourseId === c.id)} position={{lat: c.venue.latitude, lng: c.venue.longitude}} />
                })}
              </Map>
            </APIProvider>

          </div>
        </div>
      </Container>
    </div>
  );
};

export default CourseMap;
