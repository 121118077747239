import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditLessonPlan';
import { currentUserHasRole } from '../../../../../services/helper';
import { useEffect, useState } from 'react';
import { useGetRecipesQuery } from '../../../../../services/endpoints/content/recipe';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import Editor from '../../../../../components/editor/Editor';

export const LessonPlanForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const [recipesOptions, setRecipesOptions] = useState<{value: number, label: string}[]>([]);
  const { data: recipes, error: recipesError } = useGetRecipesQuery({data: {orderBy: {field: 'title', order: 'asc'}}});

  useEffect(() => {
    if (recipes && recipes.results) {
      let opts = recipes?.results.map((rec: {id: number, title: string}) => ({ value: rec.id, label: rec.title }));
      setRecipesOptions(opts);
    }
  }, [recipes])

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Details
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Field
            name="name"
            type="text"
            as={Form.Control}
            isInvalid={touched.name && !!errors.name}
          />
          <ErrorMessage name="name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="publish_status">
          <Form.Label>Publish Status</Form.Label>
          <Form.Control
            as="select"
            name="user_type"
            value={values?.publish_status}
            onChange={(e) => setFieldValue!('publish_status', e.target.value)}
            isInvalid={touched.publish_status && !!errors.publish_status}
          >
            <option>Select</option>
            <option value="published">
              Published
            </option>
            <option value="unpublished">
              Unpublished
            </option>
          </Form.Control>
          <ErrorMessage name="publish_status">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <FieldArray 
        name="recipes"
        render={(arrayHelpers) => (
          <Form.Group className="mb-1 mb-lg-3" controlId="recipes">
            <Form.Label className="h4">Recipes</Form.Label>
            <br />
            <div className="multi-content-scroll mt-2">
              <Row>
              {values?.recipes && values?.recipes.length > 0 ? (
                values.recipes.map((recipe, index) => {
                  return (
                    <Col  key={index} lg={3} className="mb-2">
                    <Card border="primary">
                    {typeof errors.recipes === "string" ? <div>{errors.recipes}</div> : null}

                    <Card.Body>
                      <Col className="ms-1">
                        <Row>
                          <Col xs={12} className="pe-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`recipes.${index}.id`}>
                              {recipesOptions.length > 0 ? (<FormSelect
                                name={`recipes.${index}.id`}
                                label="Recipe Name"
                                options={recipesOptions}
                                loadingError={recipesError ? "Error loading recipes" : undefined}
                              />) : (<>{recipe.name}</>)}
                            </Form.Group>

                            <Form.Group controlId={`recipes.${index}.is_primary`}>
                              <Form.Label>
                                <Field
                                  name={`recipes.${index}.is_primary`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'is primary?'}
                                />
                              </Form.Label>
                            </Form.Group>

                            <Button
                              variant="primary"
                              size="sm"
                              className="btn-box-shadow border-0 text-uppercase"
                              style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove  
                            </Button>                        
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col hidden={index === 0} xs={1}>
                        <CloseButton onClick={() => arrayHelpers.remove(index)} />
                      </Col> */}
                    </Card.Body>
                    {/* { index < values.recipes.length - 1 ? <hr /> : <></>} */}
                  </Card>
              </Col>
                )})
              ) : (
                <></>
              )}
              </Row>
            </div>
            <button
              type='button'
              className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
              onClick={() => arrayHelpers.insert(values?.recipes.length ?? 0, {
                id: "", 
              
              })} // insert an empty string at a position
            >
              + Add New Lesson Plan Recipe
            </button>
          </Form.Group>
        )}
      />

      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Family Meal Plan Stats
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="cook_time">
          <Form.Label>Cook Time</Form.Label>
          <Field
            name="cook_time"
            type="text"
            as={Form.Control}
            isInvalid={touched.cook_time && !!errors.cook_time}
          />
          <ErrorMessage name="cook_time">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="prep_time">
          <Form.Label>Prep Time</Form.Label>
          <Field
            name="prep_time"
            type="text"
            as={Form.Control}
            isInvalid={touched.prep_time && !!errors.prep_time}
          />
          <ErrorMessage name="prep_time">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="min_served">
          <Form.Label>Min Served</Form.Label>
          <Field
            name="min_served"
            type="text"
            as={Form.Control}
            isInvalid={touched.min_served && !!errors.min_served}
          />
          <ErrorMessage name="min_served">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="max_served">
          <Form.Label>Max Served</Form.Label>
          <Field
            name="max_served"
            type="text"
            as={Form.Control}
            isInvalid={touched.max_served && !!errors.max_served}
          />
          <ErrorMessage name="max_served">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

      </Col>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Lesson Plan Stats
      </h6>
      <Col xs={12} lg={6} className="pe-1"> 
        <Form.Group className="mb-1 mb-lg-3" controlId="lesson_cook_time">
          <Form.Label>Cook Time</Form.Label>
          <Field
            name="lesson_cook_time"
            type="text"
            as={Form.Control}
            isInvalid={touched.lesson_cook_time && !!errors.lesson_cook_time}
          />
          <ErrorMessage name="lesson_cook_time">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="lesson_prep_time">
          <Form.Label>Prep Time</Form.Label>
          <Field
            name="lesson_prep_time"
            type="text"
            as={Form.Control}
            isInvalid={touched.lesson_prep_time && !!errors.lesson_prep_time}
          />
          <ErrorMessage name="lesson_prep_time">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="lesson_min_served">
          <Form.Label>Min Served</Form.Label>
          <Field
            name="lesson_min_served"
            type="text"
            as={Form.Control}
            isInvalid={touched.lesson_min_served && !!errors.lesson_min_served}
          />
          <ErrorMessage name="lesson_min_served">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="lesson_max_served">
          <Form.Label>Max Served</Form.Label>
          <Field
            name="lesson_max_served"
            type="text"
            as={Form.Control}
            isInvalid={touched.lesson_max_served && !!errors.lesson_max_served}
          />
          <ErrorMessage name="lesson_max_served">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Lists
      </h6>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="shopping_list_items">
          <Form.Label>Shopping list items</Form.Label>
          <Field
            name="shopping_list_items"
            as="textarea"
            className={`form-control ${touched.shopping_list_items && !!errors.shopping_list_items ? 'is-invalid' : ''}`}
          />
          <ErrorMessage name="shopping_list_items">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Instructor Notes
      </h6>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="notes">
          <Form.Label>Lesson Plan Add-ins & Tidbits</Form.Label>

          <Field 
          name="notes" 
          component={Editor} 
          className="form-control"
          isInvalid={touched.notes && !!errors.notes}/>

          <ErrorMessage name="notes">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="before_class">
          <Form.Label>Before class</Form.Label>

          <Field 
          name="before_class" 
          component={Editor} 
          className="form-control"
          isInvalid={touched.before_class && !!errors.before_class}/>

          <ErrorMessage name="before_class">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="at_school">
          <Form.Label>At School</Form.Label>
          <Field 
          name="at_school" 
          component={Editor} 
          className="form-control"
          isInvalid={touched.at_school && !!errors.at_school}/>

          <ErrorMessage name="at_school">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="welcome">
          <Form.Label>Welcome Your Students</Form.Label>
          <Field 
          name="welcome" 
          component={Editor} 
          className="form-control"
          isInvalid={touched.welcome && !!errors.welcome}/>
          <ErrorMessage name="welcome">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Timeline
      </h6>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="timeline_00">
          <Form.Label>0-10 Minutes</Form.Label>
          <Field
          name="timeline_00"
          as="textarea"
          className={`form-control ${touched.timeline_00 && !!errors.timeline_00 ? 'is-invalid' : ''}`}
          />
          <ErrorMessage name="timeline_00">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="timeline_10">
          <Form.Label>10-25 Minutes</Form.Label>
          <Field
          name="timeline_10"
          as="textarea"
          className={`form-control ${touched.timeline_10 && !!errors.timeline_10 ? 'is-invalid' : ''}`}
          />
          <ErrorMessage name="timeline_10">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="timeline_25">
          <Form.Label>25-35 Minutes</Form.Label>
          <Field
          name="timeline_25"
          as="textarea"
          className={`form-control ${touched.timeline_25 && !!errors.timeline_25 ? 'is-invalid' : ''}`}
          />
          <ErrorMessage name="timeline_25">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="timeline_35">
          <Form.Label>35-45 Minutes</Form.Label>
          <Field
          name="timeline_35"
          as="textarea"
          className={`form-control ${touched.timeline_35 && !!errors.timeline_35 ? 'is-invalid' : ''}`}
          />
          <ErrorMessage name="timeline_35">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="timeline_45">
          <Form.Label>45-60 Minutes</Form.Label>
          <Field
          name="timeline_45"
          as="textarea"
          className={`form-control ${touched.timeline_45 && !!errors.timeline_45 ? 'is-invalid' : ''}`}
          />
          <ErrorMessage name="timeline_45">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>      
    </Row>
  )
}
