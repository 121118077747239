import { useEffect, useState } from 'react';
import { useToast } from '../../../../context/ToastContext';
import { getPastTimesheetColumns } from '../../../../utils/consts/columnDefs/timesheetColumns';
import { useGetTimesheetsQuery } from '../../../../services/endpoints/finance/timesheets';
import { timesheets } from '../../../../types/finance.types';
import { Stack } from 'react-bootstrap';
import { getCurrentUser } from '../../../../services/helper';
import { isAdmin } from '../../../../utils/consts';
import SFCTable from '../../../../components/tables/SFCTable';
import { GetQueryParams } from '../../../../types/api.type';
import { DetailSection, TimesheetIntro } from '../../../../components/generic';

export const PastTimesheetsView = () => {
  const { addToast } = useToast();
  const currentUser = getCurrentUser();
  const isAdminUser = isAdmin(currentUser?.user_type_id!);
  const [filters, setFilters] = useState<GetQueryParams>({data: {page: 1, entries: 10}});
  const { data, error, isLoading, isFetching } = useGetTimesheetsQuery(filters, {skip: !filters});
  const [timesheets, setTimesheets] = useState<timesheets[]>([]);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results && data.results) {
      setTimesheets(data.results.map((t: timesheets) => ({...t, count: t.timesheet_entries?.length})));
    }
  }, [data]);

  return (
    <div className="py-2">
      <SFCTable
        name={"Past Timesheets"}
        columns={getPastTimesheetColumns(isAdminUser)}
        data={timesheets}
        count={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        onFiltersChanged={setFilters}
        defaultScope='all'
        sidebars={[<TimesheetIntro />]}
      />
    </div>
  );
}
