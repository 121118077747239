import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { ReactNode, useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { invoicesColumns } from '../../../../../utils/consts/columnDefs/financeColumns';
import { Invoice } from '../../../../../types/finance.types';
import { useGetAllInvoicesQuery } from '../../../../../services/endpoints/finance/invoice';
import { useGetVenuesQuery } from '../../../../../services/endpoints/places/venue';
import { getCurrentUser } from '../../../../../services/helper';
import { DetailSection, InvoiceLegend, LabelText } from '../../../../../components/generic';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { useDataContext } from '../../../../../context';

const defaultScope = 'open';
const currentUser = getCurrentUser();

export const InvoicesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetAllInvoicesQuery(filters);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const isGlobalUser = (currentUser?.isGlobal);
  const isFinanceAdmin = (currentUser?.canSeeFinanceMenu);
  const {data: venues, error: venuesError, isLoading: venuesLoading} = useGetVenuesQuery({data: {orderBy: {field: 'title', order: 'asc'}}});

  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      element: (
        <SearchField name="name" label="Invoice Name" placeholder="Search invoice name" />
      )
    },
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'location_id',
      type: 'number',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'venue_id',
      type: 'number',
      element: (
        <FormSelect
          name="venue_id"
          label="Venue"
          options={venues?.results?.map((venue) => ({ value: venue.id, label: venue.title })) ?? []}
          loadingError={venuesError ? "Error loading venues" : undefined}

        />
      )
    },
    {
      name: 'issue_on_from',
      id: 'issue_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="issue_on_from" label="Issue On" />
      )
    },
    {
      name: 'issue_on_to',
      id: 'issue_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="issue_on_to" />
      )
    },
    {
      name: 'due_on_from',
      id: 'due_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="due_on_from" label="Due Date" />
      )
    },
    {
      name: 'due_on_to',
      id: 'due_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="due_on_to" />
      )
    },
  ];

  if (!isGlobalUser) {
    // remove location filter
    filterFormFields.splice(2,1);
  }

  const sidebars: ReactNode[] = [<InvoiceLegend />];
  if (isFinanceAdmin && ["open", "draft", "sent", "late"].includes(filters.scope ?? "")) {
    sidebars.unshift(
      <DetailSection title="Summary">
        <LabelText label="Total Amount Due:" >
          <CurrencyCell value={String(data?.totals?.amount_due ?? 0)} />
        </LabelText>
      </DetailSection>
    );
  }
  
  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setInvoices(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Invoices`}
        columns={invoicesColumns}
        data={invoices}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath={'invoices'}
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        sidebars={sidebars}
      />
    </div>
  );
}
