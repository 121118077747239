import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { trainingVideo } from '../../../types/trainingVideos.type';
import { sfcApi } from '../../api';
export interface TraningVideoCreateUpdateValues {
    id?: number;
    name: string;
    code:string;
  }
export const trainingVideoApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrainingVideos: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `training-videos${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getTraningVideo: builder.query<{ trainingVideo: trainingVideo }, number>({
      query: (id) => `training-videos/${id}`,
    }),


    createTraningVideo: builder.mutation<{ trainingVideo: trainingVideo }, TraningVideoCreateUpdateValues>({
      query: (data) => {

        return {
            url: 'training-videos/create',
            method: 'POST',
            body: {
              ...data
            },
          }
      },
      invalidatesTags: ['TrainingVideos']
    }),

    updateTraningVideo: builder.mutation<{ trainingVideo: trainingVideo }, TraningVideoCreateUpdateValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `training-videos/update/${id}`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
      invalidatesTags: ['TrainingVideos']

    }),

    deleteTraningVideo: builder.mutation<{ trainingVideo: trainingVideo }, number>({
      query: (id) => ({
        url: `training-videos/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetTrainingVideosQuery,
  useGetTraningVideoQuery,
  useCreateTraningVideoMutation,
  useUpdateTraningVideoMutation,
  useDeleteTraningVideoMutation
} = trainingVideoApi;