import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditPromotionalCode';

export const PromotionalCodeForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Field
              name="name"
              type="text"
              as={Form.Control}
              isInvalid={touched.name && !!errors.name}
            />
            <ErrorMessage name="name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="code">
            <Form.Label>Code</Form.Label>
            <Field
              name="code"
              type="text"
              as={Form.Control}
              isInvalid={touched.code && !!errors.code}
            />
            <ErrorMessage name="code">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="percent_discount">
            <Form.Label>Percent Discount</Form.Label>
            <Field
              name="percent_discount"
              type="number"
              min={1}
              as={Form.Control}
              isInvalid={touched.percent_discount && !!errors.percent_discount}
            />
            <ErrorMessage name="percent_discount">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="is_active">
            <Form.Label>
              <Field
                name="is_active"
                type="checkbox"
                as={Form.Check}
                label="Is active"
              />
            </Form.Label>
          </Form.Group>

        </Col>
      </Row>
    </>
  )
}
