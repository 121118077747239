import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { foodHistoriesColumns } from '../../../../../utils/consts/columnDefs/foodHistories';
import { useGetFoodHistoriesQuery } from '../../../../../services/endpoints/content/foodHistories';
import { foodHistory } from '../../../../../types/foodHistories.type';

const defaultScope = 'all';

export const FoodHistoriesView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetFoodHistoriesQuery(filters);
  const [foodHistory, setFoodHistory] = useState<foodHistory[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();

  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      label: 'Name',
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
       
        setFoodHistory(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Food Histories`}
        columns={foodHistoriesColumns}
        data={foodHistory}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='food-histories'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate('/admin/content/food-histories/new')}
          >
            New Food History
          </SimpleButton>
        )}
      />
    </div>
  );
}
