import { CreateFormValues as EnrollmentValues } from '../../../features/dash/views/enrollment/AddEditEnrollment';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Enrollment } from '../../../types/enrollment.type';
import { sfcApi } from '../../api';

export const enrollmentApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEnrollments: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `enrollments${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Enrollment'],
    }),
    getEnrollment: builder.query<{ enrollment: Enrollment }, number>({
      query: (id) => `enrollments/${id}`,
    }),
    createEnrollment: builder.mutation<{ enrollment: Enrollment }, EnrollmentValues>({
      query: (data) => ({
        url: 'enrollments/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Enrollment']
    }),
    updateEnrollment: builder.mutation<{ enrollment: Enrollment }, EnrollmentValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `enrollments/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error, { id, course_id }) =>
        [{ type: 'Enrollment', id }, 'Enrollment', { type: 'Course', id: course_id }, 'Course']
    }),
    deleteEnrollment: builder.mutation<{ enrollment: Enrollment }, number>({
      query: (id) => ({
        url: `enrollments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Enrollment']
    }),
  }),
});

export const {
  useGetAllEnrollmentsQuery,
  useGetEnrollmentQuery,
  useCreateEnrollmentMutation,
  useUpdateEnrollmentMutation,
  useDeleteEnrollmentMutation,
} = enrollmentApi;
