import Watermelon from '../../../assets/images/watermelon_slice.png';
import Radish from '../../../assets/images/radish.png';
import Lemon from '../../../assets/images/lemon.png';
import PinWisk from '../../../assets/images/pin_wisk.png';

const DEFAULT_SIZE = 30;
const SELECTED_SIZE = 50;

type IconProps = {
  height?: number;
  width?: number;
}

type MapIconProps = {
  src?: string;
  rotate?: number;
  translate?: {x?: number; y?: number}
  height?: number;
  width?: number;
}
const MapIcon: React.FC<MapIconProps> = ({src, rotate, translate, height = DEFAULT_SIZE, width = DEFAULT_SIZE}) => 
  <img 
    height={height} 
    width={width} 
    style={{transform: `rotate(${rotate ? rotate : '0'}deg) ${translate?.y ? `translateY(${translate.y}px)` : ''} ${translate?.x ? `translateX(${translate.x}px)` : ''}`}} 
    src={src}
  />
export const SchoolProgramMarker: React.FC<IconProps> = ({height = DEFAULT_SIZE, width = DEFAULT_SIZE}) => <MapIcon src={Radish} rotate={30} height={height} width={width} />;
export const CookingCampsMarker: React.FC<IconProps> = ({height=DEFAULT_SIZE, width=DEFAULT_SIZE}) => <MapIcon src={Watermelon} rotate={30} translate={{ x: -6}} height={height} width={width} />;
export const EventsMarker: React.FC<IconProps> = ({height=DEFAULT_SIZE, width=DEFAULT_SIZE}) => <MapIcon src={Lemon} rotate={340} height={height} width={width} />;

export const course_type_marker_lookup = (key: string, selected?: boolean) => {
  switch (key) {
    case 'after_school': return <SchoolProgramMarker height={selected ? SELECTED_SIZE : undefined} width={selected ? SELECTED_SIZE : undefined} />;
    case 'camp': return <CookingCampsMarker height={selected ? SELECTED_SIZE : undefined} width={selected ? SELECTED_SIZE : undefined} />;
    case 'event': return <EventsMarker height={selected ? SELECTED_SIZE : undefined} width={selected ? SELECTED_SIZE : undefined} />;
    default: return <MapIcon src={PinWisk} rotate={30} />;
  }
}