import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { foodHistory } from '../../../types/foodHistories.type';
import { sfcApi } from '../../api';
export interface FoodHistoryCreateUpdateValues {
    id?: number;
    name: string;
    content:string;
    file: File,
    image_source:string
  }
export const FoodHistoryApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getFoodHistories: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `food-histories${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getFoodHistory: builder.query<{ foodHistory:foodHistory }, number>({
      query: (id) => `food-histories/${id}`,
    }),


    createFoodHistory: builder.mutation<{ foodHistory:foodHistory}, FoodHistoryCreateUpdateValues>({
      query: (data) => {
        const formData = new FormData();
        let payload: any = {
          url: `food-histories/create`,
          method: 'POST',
          body: data,
        };
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
        if (data.file?.name) {
          payload.formData = true;
        }
        if(!data?.file?.name){
            formData.delete('file');
        }
        payload.body = formData;

        return payload;
      },
      invalidatesTags: ['FoodHistories']
    }),

    updateFoodHistory: builder.mutation<{ foodHistory:foodHistory }, FoodHistoryCreateUpdateValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `food-histories/update/${id}`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
    }),

    deleteFoodHistory: builder.mutation<{ foodHistory:foodHistory }, number>({
      query: (id) => ({
        url: `food-histories/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetFoodHistoriesQuery,
  useGetFoodHistoryQuery,
  useCreateFoodHistoryMutation,
  useUpdateFoodHistoryMutation,
  useDeleteFoodHistoryMutation
} = FoodHistoryApi;