import { FormValues as ChildValues } from '../../../features/dash/views/people/Children/AddEditChild';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { sfcApi } from '../../api';
import { Child } from './user';

export const childApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllChildren: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `children${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Child']
    }),
    getChild: builder.query<{ child: Child }, number>({
      query: (id) => `children/${id}`,
      providesTags: (result, error, id) => [{ type: 'Child', id }]
    }),
    createChild: builder.mutation<{ child: Child }, ChildValues>({
      query: (data) => ({
        url: `children/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Child']
    }),
    updateChild: builder.mutation<{ child: Child }, { id: number, data: Partial<ChildValues> }>({
      query: ({ id, data }) => ({
        url: `children/update/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Child', id }, 'Child']
    }),
    deleteChild: builder.mutation<{ child: Child }, number>({
      query: (id) => ({
        url: `children/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Child', id }, 'Child']
    }),
  }),
});

export const {
  useGetAllChildrenQuery,
  useGetChildQuery,
  useCreateChildMutation,
  useUpdateChildMutation,
  useDeleteChildMutation,
} = childApi;