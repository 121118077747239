import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { dateToUTC } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useGetSnippetQuery } from '../../../../../services/endpoints/website/snippet';
import { Snippet } from '../../../../../types/website.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';

export const SnippetDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetSnippetQuery(+params.id!);
  const [ snippet, setSnippet] = useState<Snippet>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setSnippet(data.snippet);
    }
  }, [data]);

  if (isLoading || !snippet) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/website/snippets')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Snippets List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Snippet #${snippet?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/website/snippets/${snippet?.id}/edit`)}
          >
            Edit Snippet
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/website/snippets/${snippet?.id}/delete`)}
          >
            Delete Snippet
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Name:" text={snippet?.name} />
            <LabelText label="Content:" text={snippet?.content} />
            <LabelText label="Created At:" text={snippet?.created_at ? dateToUTC(new Date(snippet?.created_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Updated At:" text={snippet?.updated_at ? dateToUTC(new Date(snippet?.updated_at), 'MM/dd/yyyy') : 'N/A'} />
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
