import React from 'react';
import CurrencyCell from './CurrencyCell';

interface OverrideCellProps {
  base: number|string;
  override: number|string;
}

const OverrideCell: React.FC<OverrideCellProps> = ({ base, override }) => {
  if (base !== override) {
    return <><s><CurrencyCell value={base} /></s><CurrencyCell value={override} /></>;
  }
  else {
    return <CurrencyCell value={override} />;
  }
};

export default OverrideCell;