import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { FaArrowLeft } from 'react-icons/fa6';
import { Accordion, ListGroup, Spinner, Stack } from 'react-bootstrap';
import { SFCAccordion } from '../../../../../components/accordion/SFCAccordion';
import { SimpleTable } from '../../../../../components/tables/SimpleTable';
import { useGetCourseQuery } from '../../../../../services/endpoints/schedule/course';
import { useToast } from '../../../../../context/ToastContext';
import { IconLink } from '../../../../../components/iconLink/IconLink';
import { BsCalendar, BsClock, BsEnvelope, BsFiletypePdf, BsFillGeoAltFill, BsFillTelephoneFill, BsGlobe } from 'react-icons/bs';
import { ReactComponent as LedgerIcon } from '../../../../../assets/icons/ledger.svg';
import {
  enrollmentColumns,
  revisionsColumns,
  rosterColumns,
  getScheduleColumns,
  sessionExpensesColumns,
  waitlistColumns,
  waiversColumns,
} from '../../../../../utils/consts/columnDefs/sessionColumns';
import StatusIcon from '../../../../../components/status/StatusIcon';
import { Course } from '../../../../../types/course.type';
import { DateCell } from '../../../../../components/tables/cellComponents/DateCell';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useCreateSubRequestMutation, useDeleteSubRequestMutation, useRenegClassMutation } from '../../../../../services/endpoints/schedule/lessonAssignment';
import { useGetThemeQuery } from '../../../../../services/endpoints/content/theme';
import { getCurrentUser } from '../../../../../services/helper';
import SafeHTMLDisplay from '../../../../common/SafeHTMLDisplay';
import { titleCase } from '../../../../../utils/stringConversions';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { LessonPlan } from '../../../../../types/theme.type';
import { AddEditReceipt } from '../../finance/Receipts/AddEditReceipt';
import { useGetAllReceiptsQuery } from '../../../../../services/endpoints/finance/receipt';
import { Receipt } from '../../../../../types/finance.types';

const currentUser = getCurrentUser();

const LabelTextList = ({ label, list }: { label: string, list: string[] }) => (
  <Stack direction="vertical" gap={1}>
    <span className="fw-semibold" style={{ color: 'rgba(94, 100, 105, .5)' }}>{label}</span>
    {list.map((text, index) => (
      <span key={index} className="text-secondary fw-semibold">{text}</span>
    ))}
  </Stack>
);

export type lessonAssignmentActionsData = {
  id?: number,
  lesson_id?: number,
  requesting_instructor_id?: number,
  course_id?: number,
  location_id?: number,
}

export type lessonAssignmentActionsType = 'reneg' | 'create' | 'delete';

export type lessonAssignmentActionFn = (action: lessonAssignmentActionsType, data: lessonAssignmentActionsData) => void;

export const SessionDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetCourseQuery(+params.id!);
  const { data: theme, error: themeError, isLoading: themeLoading } = useGetThemeQuery((data?.course.theme_id ?? 0), {skip: !(data?.course.theme_id)}) 
  const [ renegSubOffer ] = useRenegClassMutation();
  const [ createSubRequest ] = useCreateSubRequestMutation();
  const [ deleteSubRequest ] = useDeleteSubRequestMutation();
  const [course, setCourse] = useState<Course>();
  const [flatRate, setFlatRate] = useState<number>(0);
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const { data: receiptData, error: receiptsError, isLoading: receiptsLoading } = useGetAllReceiptsQuery({data: {filterBy: [{field: 'course_id', operator: 'equals', value: +(params.id ?? 0)}]}}, {skip: !params.id || !course || flatRate === 0});

  const featured_tlps: LessonPlan[] = theme?.theme?.lesson_plans?.filter(lp => lp.theme_lesson_plans && lp.theme_lesson_plans.featured_on !== null) ?? [];

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading session', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setCourse(data.course);
      let fr = Number(data.course.flat_rate);
      setFlatRate(Number.isNaN(fr) ? 0 : fr);
    }
  }, [data]);

  useEffect(() => {
    if (receiptData) {
      setReceipts(receiptData.results);
    }
  }, [receiptData]);

  const actionCallback: lessonAssignmentActionFn = (action, data) => {
    console.log(action, data);
    switch (action) {
      case 'reneg':
        if (data.id){
          renegSubOffer({id: data.id}).unwrap().then(console.log).catch(console.error);
        }
        break;
      case 'create':
          createSubRequest(data).unwrap().then(console.log).catch(console.error);
        break;
      case 'delete':
          deleteSubRequest({id: data.id}).unwrap().then(console.log).catch(console.error);
        break;
      default:
        break;
    }
  }

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  const sessionID = <LabelText label="Session ID:" text={course?.id} />;
  const chefPay = course?.pay_type === 'flat_rate' ? `Flat Rate $${course?.flat_rate}/day` : 'Hourly Rate';

  const prorated = course?.payment_type === 'website' && (
    <LabelText label="PRORATED:">
      <StatusIcon value={course?.can_be_prorated} />
    </LabelText>
  );

  const instructorsList = (
    <LabelText label="Instructors:">
      <Stack>
        {course?.course_assignments.map((ca) => (
          <Link key={ca.id} to={`/admin/dash-users/instructors/${ca.instructor_id}`} className="text-info fw-bold">
            {ca.instructor?.first_name} {ca.instructor?.last_name}
          </Link>
        ))}

      </Stack>
    </LabelText>
  );

  const venueAddress = (
    <div>
      <IconLink icon={<BsFillGeoAltFill size={24} />} text={course?.venue.title} />
      <Stack className="text-info fw-semibold" style={{ marginLeft: 34 }}>
        <span>{course?.venue.address}</span>
        <span>{`${course?.venue.city} ${course?.venue.state}, ${course?.venue.zip}`}</span>
      </Stack>
    </div>
  );

  const tuition = (
    <LabelText
      label="Tuition:"
      direction="vertical"
      text={course?.tuition_summary_string}
    />
  );

  const detailsAccordion = (
    <SFCAccordion defaultActiveKey={["0", "1", "2", "3"]}>
      <Accordion.Item eventKey="0" className="d-lg-none">
        <Accordion.Header>Resources</Accordion.Header>
        <Accordion.Body>
          <DetailSection>
            <IconLink icon={<BsGlobe size={24} />} text="Website" url={`/sessions/${course?.id}`} />
            <IconLink icon={<LedgerIcon />} text="Summary Session Financials" url={`/admin/schedule/sessions/${course?.id}/ledger`} />
            <IconLink icon={<BsFiletypePdf size={24} />} text="Sign-In Sheet" />
            <IconLink icon={<BsFiletypePdf size={24} />} text="Roster" />
            <IconLink icon={<BsEnvelope size={24} />} text="Send Welcome Email" />
            <IconLink icon={<BsEnvelope size={24} />} text="Send Thank You Email" />
            <IconLink icon={<BsEnvelope size={24} />} text="Send Roster/Waiver" />
          </DetailSection>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="99">
        <Accordion.Header>Class Schedule</Accordion.Header>
        <Accordion.Body>
          <SimpleTable
            columns={getScheduleColumns(actionCallback)}
            data={course?.lessons?.map((lesson) => ({ 
              ...lesson, 
              course, 
              featured_tlps,
            })) ?? []}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Instructor Notes</Accordion.Header>
        <Accordion.Body>
          {course?.instructor_notes}
        </Accordion.Body>
      </Accordion.Item>
      {course?.is_online ? (
        <Accordion.Item eventKey="2">
          <Accordion.Header>Roster {`(${course?.enrollment})`}</Accordion.Header>
          <Accordion.Body>
            <Stack gap={3}>
              <Link to="" className="text-info">Manage Registrations</Link>
              <SimpleTable columns={rosterColumns} data={[{ id: 1 }]} />
            </Stack>
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        <Accordion.Item eventKey="2">
          <Accordion.Header>Enrollment Tool</Accordion.Header>
          <Accordion.Body>
            <Stack gap={3}>
              <SimpleTable columns={enrollmentColumns} data={[]} />
            </Stack>
          </Accordion.Body>
        </Accordion.Item>
      )}
      {course?.is_online ? (
        <Accordion.Item eventKey="3">
          <Accordion.Header>Waitlist</Accordion.Header>
          <Accordion.Body>
            <SimpleTable
              columns={waitlistColumns}
              data={[]}
            />
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        <Accordion.Item eventKey="3">
          <Accordion.Header>Waivers</Accordion.Header>
          <Accordion.Body>
            <SimpleTable
              columns={waiversColumns}
              data={[]}
            />
          </Accordion.Body>
        </Accordion.Item>
      )}
      <Accordion.Item eventKey="4">
        <Accordion.Header>Additional Session Related Expenses</Accordion.Header>
        <Accordion.Body>
          <SimpleTable
            columns={sessionExpensesColumns}
            data={[]}
          />
          <SimpleButton variant="primary" height={36} onClick={() => navigate(`/admin/finance/expenses/new?course_id=${course?.id}`)}>
            New Expense
          </SimpleButton>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Revisions</Accordion.Header>
        <Accordion.Body>
          <SimpleTable
            columns={revisionsColumns}
            data={course?.audits ?? []}
          />
        </Accordion.Body>
      </Accordion.Item>
    </SFCAccordion>
  );

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/schedule/sessions')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Sessions List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{course?.title}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/schedule/sessions/${course?.id}/edit`)}
          >
            Edit session
          </SimpleButton>
          <SimpleButton variant="primary" height={36}>Generate invoice</SimpleButton>
        </Stack>
      </div>
      <div className="d-lg-none">
        <Stack gap={4} className="p-4">
          <DetailSection>
            <span className="fs-5 text-info fw-bold">{course?.title}</span>
            {venueAddress}
            <IconLink icon={<BsCalendar size={24} />}>
              <span className="text-secondary fw-semibold">{course?.day_of_week}</span>
            </IconLink>
            <IconLink icon={<BsClock size={24} />}>
              <span className="text-secondary fw-semibold">{course?.starts_at} - {course?.ends_at}</span>
            </IconLink>
            {sessionID}
            <LabelText label="Theme:" text="Theme" />
            <LabelText label="Room:" text={course?.room} />
            <LabelText label="Enrollment:" text={course?.enrollment} />
            <LabelText label="Session Payment type:" text={course?.payment_type === 'onsite' ? 'On-site' : 'Website'} />
            {tuition}
            <LabelText label="Chef Pay:" text={chefPay} />
            {prorated}
            {instructorsList}
          </DetailSection>

          {detailsAccordion}

        </Stack>
      </div>
      <div className="d-none d-lg-flex">
        <div className="mt-5 w-100 px-5 py-4" style={{ maxWidth: 'calc(100% - 400px)' }}>
          {detailsAccordion}
        </div>
        <Stack gap={4} className="p-4" style={{ width: 400, background: '#F2F2F2' }}>
          <DetailSection title="Resources">
            <IconLink icon={<BsGlobe size={24} />} text="Website" url={`/sessions/${course?.id}`} />
            <IconLink icon={<LedgerIcon />} text="Summary Session Financials" url={`/admin/schedule/sessions/${course?.id}/ledger`} />
            <IconLink icon={<BsFiletypePdf size={24} />} text="Sign-In Sheet" />
            <IconLink icon={<BsFiletypePdf size={24} />} text="Roster" />
            <IconLink icon={<BsEnvelope size={24} />} text="Send Welcome Email" />
            <IconLink icon={<BsEnvelope size={24} />} text="Send Thank You Email" />
            <IconLink icon={<BsEnvelope size={24} />} text="Send Roster/Waiver" />
          </DetailSection>

          <DetailSection title="Venue Details">
            {venueAddress}
            <IconLink icon={<BsFillTelephoneFill size={24} />} text={course?.venue.phone} />
          </DetailSection>

          <DetailSection title="Details">
            {sessionID}
            <LabelText label="Theme:" text="Theme" />
            <LabelText label="Room:" text={course?.room} />
            <LabelText label="Time:">
              <DateCell date={course?.lessons?.[0]?.date} time={course?.starts_at} format="h:mm a" />
              {' - '}
              <DateCell date={course?.lessons?.[0]?.date} time={course?.ends_at} format="h:mm a" />
            </LabelText>
            <LabelText label="Enrollment:" text={course?.enrollment} />
            <LabelText label="Session Payment type:" text={course?.payment_type === 'onsite' ? 'On-site' : 'Website'} />
            {tuition}
            <LabelText label="Chef Pay:" text={chefPay} />
            {prorated}
            {instructorsList}
            <LabelText label="Organizations:" direction="vertical">
              {course?.venue?.organizations?.length
                ? (
                  <Stack>
                    {course.venue.organizations.map((org) => (
                      <Link key={org.id} to="" className="text-info fw-bold">
                        {org.name}
                      </Link>
                    ))}
                  </Stack>
                ) : (
                  <span className="fst-italic">No organizations are assigned to this venue</span>
                )}
            </LabelText>
          </DetailSection>
          
          {currentUser?.isAdmin && (            
            <DetailSection title="Notes">
              { (course?.venue && (course?.venue.organizations.length ?? 0) > 0) && (
                course?.venue.organizations.map((org, idx) => (
                  <LabelText key={idx} label={`${ org.name } Notes`} >
                    <SafeHTMLDisplay htmlContent={org.notes || "<em>No notes to show</em>"} />
                  </LabelText>
                ))
                )
              }
              <LabelText label="Venue Notes" >
                { !currentUser.isInstructor ?
                  <SafeHTMLDisplay htmlContent={course?.venue?.notes || "<em>No notes to show</em>"} />
                  :
                  <em>No notes to show.</em>
                }
              </LabelText>
              <LabelText label="Facility Use Fee Payment Details" >
                { !currentUser.isInstructor ?
                  <SafeHTMLDisplay htmlContent={course?.venue?.facility_use_fee_payment_details || "<em>No notes to show</em>"} />
                  :
                  <em>No notes to show.</em>
                }
              </LabelText>
              <LabelText label="Admin Session Notes" >
                <SafeHTMLDisplay htmlContent={course?.admin_notes || "<em>No notes to show</em>"} />
              </LabelText>
            </DetailSection>
          )}

          <DetailSection title="Contacts">
            {course?.venue?.contacts?.length
              ? course.venue.contacts.map((contact, idx) => (
                <LabelTextList key={idx} label={`${contact.full_name}:`} list={[contact.email]} />
              ))
              : (
                <span className="fst-italic">There are no contacts to show.</span>
              )}
          </DetailSection>

          {/* TODO: check invoices field and fill the info here */}
          {currentUser?.isAdmin && (
            <DetailSection title="Invoices">
              <LabelText label="Amount Receivable:">
                <CurrencyCell value={course?.sum_enrollment_values ?? 0} />
              </LabelText>
              <LabelText label="Amount Invoiced:">
                <CurrencyCell value={course?.sum_invoice_item_totals ?? 0} />
              </LabelText>
              <LabelText label="Difference:">
                <CurrencyCell value={(course?.sum_enrollment_values ?? 0) - (course?.sum_invoice_item_totals ?? 0)} />
              </LabelText> 
              <ListGroup>
              {((course?.invoice_statuses?.length ?? 0) > 0) ? (
                course?.invoice_statuses?.map(i => (
                  <Stack key={i.id} direction="horizontal" gap={2}>
                    <IconLink icon={<BsFiletypePdf size={24} />} text={i.name} url={`/admin/finance/invoices/${i.id}`} />
                    <div className={`swatch ${i.status}`}>{titleCase(i.status)}</div>
                  </Stack>
                ))
              ) : (
                <em>There are no invoices to show.</em>
              )}
              </ListGroup>
            </DetailSection>
          )}

          {(flatRate > 0) && (
            <DetailSection title="SFC Corporate Credit Card Receipts">
              <LabelTextList label="Clementine Test:" list={['colorado@email.com']} />
              {((receipts?.length ?? 0) > 0) ? (
                receipts?.map(r => (
                  <Stack key={r.id} direction="horizontal" gap={2}>
                    {r.purchased_at}
                    <IconLink icon={<BsFiletypePdf size={24} />} text={r.store_name} url={`/admin/finance/receipts/${r.id}`} />
                    <CurrencyCell value={r.total} />
                  </Stack>
                ))
              ) : (
                <em>No receipts have been uploaded to this session.</em>
              )}

              <hr />
              {/* TODO: Add form for receipt  - this needs to be updated/adjusted to have all needed fields*/}
              {/* <AddEditReceipt /> */}
              <>Placeholder for Receipt upload form</>
            </DetailSection>
          )}

          <DetailSection title="Parent Emails">
            <LabelTextList label="Clementine Test:" list={['colorado@email.com']} />
          </DetailSection>

        </Stack>
      </div>
    </>
  );
};
