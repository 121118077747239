import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Accordion, Alert, Card, Col, Modal, Row, Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LedgerParams, LedgerData, LedgerResponse } from '../../../../../types/place.types';
import { DetailSection, LabelTextSpread } from '../../../../../components/generic';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { titleCase } from '../../../../../utils/stringConversions';
import { SimpleTable } from '../../../../../components/tables/SimpleTable';
import { ledgerInstructorPaymentColumns } from '../../../../../utils/consts/columnDefs/ledgerColumns';
import { Filters } from './Filters';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, QueryDefinition } from '@reduxjs/toolkit/query';

interface ResourceLedgerProps {
  resource: string;
  resourcePlural: string;
  resourcePath?: string;
  noFilters?: boolean;
  showClaimedStats?: boolean;
  ledgerQuery: UseQuery<QueryDefinition<LedgerParams, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, any, LedgerResponse, "sfcApi">>;
}

interface Stats {show: boolean, claimedRevenue: number, allRevenue: number, percentUnclaimed: number};

export const ResourceLedger: React.FC<ResourceLedgerProps> = ({ resource, resourcePlural, resourcePath, noFilters, showClaimedStats, ledgerQuery }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const resource_id = +params.id!;
  const [title, setTitle] = useState<string>(`${titleCase(resource)} ${resource_id}`);
  const [filters, setFilters] = useState<LedgerParams>({
    id: resource_id,
    ...(noFilters ? {} : {course_statuses: ["open"]}),
  });
  const { data, error, isLoading, isFetching } = ledgerQuery(filters);

  const [ledger, setLedger] = useState<LedgerData>();
  const [expensesSum, setExpensesSum] = useState<number>(0);
  const [revenueSum, setRevenueSum] = useState<number>(0);
  const [claimedStats, setClaimedStats] = useState<Stats>({show: false, claimedRevenue: 0, allRevenue: 0, percentUnclaimed: 0});

  useEffect(() => {
    if (error && !isLoading) {
      addToast(`Error while loading ${resource} ledger`, 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  useEffect(() => {
    if (data) {
      const l = data.ledger as LedgerData;
      const cl = data.claimedLedger as LedgerData;
      setLedger(l);
      setTitle(data.title);

      let allRevenue = Object.values(l?.revenue).reduce((acc, e) => acc + e, 0) ?? 0;
      let claimedRevenue = Object.values(cl?.revenue ?? {}).reduce((acc, e) => acc + e, 0) ?? 0;
      setExpensesSum(Object.values<number>(l?.expenses).reduce((acc, e) => acc + e, 0) ?? 0);
      setRevenueSum(allRevenue);
      let percentUnclaimed = (1 - (claimedRevenue / allRevenue));
      setClaimedStats({show: !!showClaimedStats, claimedRevenue, allRevenue, percentUnclaimed});
    }
  }, [data]);

  if (isLoading || !ledger) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate(`/admin/${resourcePath ? resourcePath : 'places'}/${resourcePlural}/${resource_id}`)}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            {title} Details
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{title} Summary Session Financials</h2>
        </Stack>
      </div>
      <div>
        {noFilters ? (
          <></>
        ) : (
          <Filters id={resource_id} resource={resourcePlural} onApplyFilters={setFilters} disabled={isLoading || isFetching} />
        )}
        <Card body>
          <Row>
            <Col lg={8} md={9}>
              <Stack gap={4}  >
                <Accordion className="overflow-auto" style={{maxHeight: '95vh'}} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Website Payments&nbsp;<small><em>The website payments are listed by child's name (parent's name in parenthesis).</em></small></Accordion.Header>
                    <Accordion.Body className="overflow-auto" style={{maxHeight: '50vh'}}>
                      <DetailSection >
                        {ledger?.registrations?.map(r => (
                          <LabelTextSpread key={r.id} label={`${r.child.full_name} (${r.child?.parent?.full_name})`}>
                            <CurrencyCell value={r.price} />
                          </LabelTextSpread>
                        ))}
                      </DetailSection>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Onsite Enrollments</Accordion.Header>
                    <Accordion.Body className="overflow-auto" style={{maxHeight: '50vh'}}>
                      <DetailSection >
                        {ledger?.enrollments?.map(e => (
                          <LabelTextSpread key={e.id} label={`${e.quantity}`}>
                            <CurrencyCell value={e.value} />
                          </LabelTextSpread>
                        ))}
                      </DetailSection>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Refunds&nbsp;<small><em>The refunds are listed by parent's name.</em></small></Accordion.Header>
                    <Accordion.Body className="overflow-auto" style={{maxHeight: '50vh'}}>
                      <DetailSection >
                        {ledger?.refunds?.map(r => (
                          <LabelTextSpread key={r.id} label={`${r.order?.parent?.full_name}`}>
                            <CurrencyCell value={r.total ?? 0} />
                          </LabelTextSpread>
                        ))}
                      </DetailSection>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Instructor Payments</Accordion.Header>
                    <Accordion.Body className="overflow-auto" style={{maxHeight: '40vh'}}>
                      <DetailSection >
                        <SimpleTable
                          columns={ledgerInstructorPaymentColumns}
                          data={ledger?.timesheet_entries}
                          maxHeight={"85vh"}
                        />
                      </DetailSection>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Receipts</Accordion.Header>
                    <Accordion.Body className="overflow-auto" style={{maxHeight: '50vh'}}>
                      <DetailSection >
                        {ledger?.receipts?.map(r => (
                          <LabelTextSpread key={r.id} label={`${r.user.full_name} (${r.store_name})`}>
                            <CurrencyCell value={r.total ?? 0} />
                          </LabelTextSpread>
                        ))}
                      </DetailSection>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Additional Session-Related Expenses</Accordion.Header>
                    <Accordion.Body className="overflow-auto" style={{maxHeight: '50vh'}}>
                      <DetailSection >
                        {ledger?.per_session_costs?.map(psc => (
                          <LabelTextSpread key={psc.id} label={titleCase(psc.category)}>
                            <CurrencyCell value={psc.amount} />
                          </LabelTextSpread>
                        ))}
                      </DetailSection>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Additional Class-Related Expenses</Accordion.Header>
                    <Accordion.Body className="overflow-auto" style={{maxHeight: '50vh'}}>
                      <DetailSection >
                        {ledger?.per_class_costs?.map(pcc => (
                          <LabelTextSpread key={pcc.id} label={titleCase(pcc.category)}>
                            <CurrencyCell value={pcc.total_for_session ?? 0} />
                          </LabelTextSpread>
                        ))}
                      </DetailSection>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Stack>
            </Col>
            <Col lg={4} md={3}>
              <Card body bg="light">
                <Stack gap={2} >
                  {showClaimedStats && claimedStats.percentUnclaimed > 0.15 && (
                    <Alert variant="warning"><b>Warning</b><br />The Percentage of Net Revenue generated from unclaimed zip codes during the time frame selected above exceeds 15%!</Alert>
                  )}
                  { showClaimedStats && (
                    <DetailSection title="Territories Revenue">
                      <LabelTextSpread label={<b>Net Revenue (Claimed)</b>}>
                        <CurrencyCell value={claimedStats.claimedRevenue} />
                      </LabelTextSpread>
                      <LabelTextSpread label={<b>Net Revenue (Unclaimed)</b>}>
                        <CurrencyCell value={claimedStats.allRevenue - claimedStats.claimedRevenue} />
                      </LabelTextSpread>
                      <LabelTextSpread label={<b>Percent Net Revenue (Unclaimed/Claimed)</b>}>
                        <span className={`${claimedStats.percentUnclaimed > 0.15 ? 'text-warning' : ''}`}>{`${(claimedStats.percentUnclaimed * 100).toFixed(2)}%`}</span>
                      </LabelTextSpread>
                    </DetailSection>
                  )}
                  <DetailSection title="Stats">
                    <LabelTextSpread label="# of Sessions" text={ledger?.sessions?.length} />
                    <LabelTextSpread label="# of Classes" text={ledger?.number_of_classes} />
                    <LabelTextSpread label="# of Children" text={ledger?.number_of_children} />
                    <LabelTextSpread label="Class Average">
                      <CurrencyCell value={ledger?.net_profit/ledger?.number_of_classes} />
                    </LabelTextSpread>
                  </DetailSection>
                  <DetailSection title="Revenue">
                    {Object.entries(ledger?.revenue).map(([k, v]) => (
                      <LabelTextSpread key={k} label={titleCase(k)}>
                        <span className="text-primary">
                          <CurrencyCell value={v ?? 0} />
                        </span>
                      </LabelTextSpread>
                    ))}
                    <LabelTextSpread label={<b>Total Revenue</b>}>
                      <CurrencyCell value={revenueSum} />
                    </LabelTextSpread>
                  </DetailSection>
                  <DetailSection title="Expenses">
                    <LabelTextSpread label="Instructor Payments (Hourly Rate)">
                      <span className="text-danger">
                        <CurrencyCell value={ledger?.rates} />
                      </span>
                    </LabelTextSpread>
                    <LabelTextSpread label="Instructor Payments (Reimbursements)">
                      <span className="text-danger">
                        <CurrencyCell value={ledger?.reimbursements} />
                      </span>
                    </LabelTextSpread>
                    <LabelTextSpread label="Receipts">
                      <span className="text-danger">
                        <CurrencyCell value={ledger?.expenses?.receipts} />
                      </span>
                    </LabelTextSpread>
                    <LabelTextSpread label="Additional Session-Related Expenses">
                      <span className="text-danger">
                        <CurrencyCell value={ledger?.expenses?.per_session_costs} />
                      </span>
                    </LabelTextSpread>
                    <LabelTextSpread label="Additional Class-Related Expenses">
                      <span className="text-danger">
                        <CurrencyCell value={ledger?.expenses?.per_class_costs} />
                      </span>
                    </LabelTextSpread>
                    <LabelTextSpread label={<b>Total Expenses</b>}>
                      <CurrencyCell value={expensesSum} />
                    </LabelTextSpread>
                    <LabelTextSpread label={<b>Margin</b>} text={revenueSum > 0 ? `${((ledger?.net_profit/revenueSum) * 100).toFixed(2)}%` : ''} />
                    <LabelTextSpread label={<b>Total Revenue less Total Expenses</b>}>
                      <CurrencyCell value={ledger?.net_profit} />
                    </LabelTextSpread>
                    <p>
                      * Instructor payments on this export draw from instructor payments that have occurred in a past pay period.
                    </p>
                    <p>
                      ** These values represent the total number of students; including both onsite enrollments and website registrations.
                    </p>
                  </DetailSection>
                </Stack>
              </Card>
            </Col>
          </Row>
        </Card>
        <Modal show={isFetching}>
          <Modal.Body className="text-center">
            <Spinner />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
