/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditFeedback';

export const FeedbackForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="first_name">
            <Form.Label>First Name</Form.Label>
            <Field
              name="first_name"
              as={Form.Control}
              isInvalid={touched.first_name && !!errors.first_name}
            />
            <ErrorMessage name="first_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="last_name">
            <Form.Label>Last Name</Form.Label>
            <Field
              name="last_name"
              as={Form.Control}
              isInvalid={touched.last_name && !!errors.last_name}
            />
            <ErrorMessage name="last_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="location">
            <Form.Label>Location</Form.Label>
            <Field
              name="location"
              as={Form.Control}
              isInvalid={touched.location && !!errors.location}
            />
            <ErrorMessage name="location">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Field
              name="email"
              as={Form.Control}
              isInvalid={touched.email && !!errors.email}
            />
            <ErrorMessage name="email">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="phone_number">
            <Form.Label>Phone Number</Form.Label>
            <Field
              name="phone_number"
              as={Form.Control}
              isInvalid={touched.phone_number && !!errors.phone_number}
            />
            <ErrorMessage name="phone_number">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="message">
            <Form.Label>Message</Form.Label>
            <Field
              name="message"
              as={"textarea"}
              className="form-control"
              isInvalid={touched.message && !!errors.message}
            />
            <ErrorMessage name="message">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
      </Row>
    </>
  )
}
