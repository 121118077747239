import React from 'react';

interface BadgeProps {
  title: string,
  color: string,
}

export const Badge: React.FC<BadgeProps> = ({ title, color }) => {
  return (
    <span
      style={{
        background: color,
        color: '#FFFFFF',
        fontSize: '.75rem'
      }}
      className="rounded-4 py-1 px-2 fw-semibold text-capitalize"
    >
      {title}
    </span>
  )
}
