import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Document } from '../../../types/content.type';
import { sfcApi } from '../../api';

export interface DocumentCreateUpdateValues {
  id?: number;
  title: string;
  description: string;
  file: File;
}

export interface PayDocumentsValues {
  ids: number[];
  check_number?: number;
}

export interface PayDocumentsResponse {
  count: number;
  rows: Document[];
}

export const documentApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDocuments: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `documents${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Documents']
    }),
    getDocument: builder.query<{ document: Document }, number>({
      query: (id) => `documents/${id}`,
      providesTags: (result, error, id) => [{ type: 'Documents', id }]
    }),
    createDocument: builder.mutation<{ document: Document }, DocumentCreateUpdateValues>({
      query: (data) => {
        const formData = new FormData();
        let payload: any = {
          url: `documents/create`,
          method: 'POST',
          body: data,
        };
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
        if (data.file?.name) {
          payload.formData = true;
        }
        if(!data?.file?.name){
            formData.delete('file');
        }
        payload.body = formData;

        return payload;
      },
      invalidatesTags: ['Documents']
    }),
    updateDocument: builder.mutation<{ document: Document }, DocumentCreateUpdateValues>({
      query: (data) => {
        const { id } = data;
        const formData = new FormData();
        let payload: any = {
          url: `documents/update/${id}`,
          method: 'PUT',
          body: data,
        };
        delete data.id;
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
        if (data.file?.name) {
          payload.formData = true;
        }
        if(!data?.file?.name){
            formData.delete('file');
        }
        payload.body = formData;
        return payload;
      },
      invalidatesTags: (result, error) => [{ type: 'Documents', id: result?.document?.id }, 'Documents']
    }),
    approveDocuments: builder.mutation<{ document: PayDocumentsResponse }, {ids: number[]}>({
      query: (params) => ({
        url: `documents/approve`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: () => ['Documents']
    }),
    denyDocuments: builder.mutation<{ document: Document }, {ids: number[], reason_for_denial: string}>({
      query: (params) => ({
        url: `documents/deny`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Documents'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Document', id })), 'Document']
    }),
    deleteDocuments: builder.mutation<{ document: Document }, {id: number}>({
      query: (params) => ({
        url: `documents/${params?.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Documents'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Document', id })), 'Document']
    }),
  }),
});

export const {
  useGetAllDocumentsQuery,
  useGetDocumentQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useApproveDocumentsMutation,
  useDenyDocumentsMutation,
  useDeleteDocumentsMutation,
} = documentApi;