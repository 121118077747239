import { Row } from '@tanstack/react-table';
import { BsCaretDownFill, BsCaretRightFill } from 'react-icons/bs';

export const ExpanderCell = ({ row }: { row: Row<any> }) => {
  return (
    <span
        onClick={() => row.toggleExpanded(!row.getIsExpanded())}
        className="text-info"
        style={{ cursor: 'pointer' }}
      >
      {row.getIsExpanded() ? <BsCaretDownFill /> : <BsCaretRightFill />}
    </span>
  )
};
