import { useEffect, useState } from "react";
import { useGetFavoriteCoursesQuery } from "../../services/endpoints/schedule/course";
import { Course } from "../../types/course.type";
import { GetQueryParams } from "../../types/api.type";
import SessionCard from "./SessionCard";
import { Col, Form, Row, Stack } from "react-bootstrap";

interface FavoritesProps {}

const Favorites: React.FC<FavoritesProps> = ({}) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState<GetQueryParams>({
    data: {
      entries: pageSize,
    },
  });
  const { data, refetch } = useGetFavoriteCoursesQuery(filters);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        entries: pageSize,
      },
    }));
  }, [pageSize]);

  useEffect(() => {
    if (data?.results) {
      setCourses(data.results);
    }
  }, [data]);

  let numPages =
    data?.count !== 0 ? Math.ceil(data?.count! / data?.entries!) : 1;
  if (isNaN(numPages)) {
    numPages = 1;
  }

  return (
    <div className="container-fluid">
      {courses.map((course, index) => (
        <SessionCard
          key={index}
          course={course}
          edit={() => {}}
          updateFavorites={refetch}
          isFavoritePage={true}
        />
      ))}
      <hr />
      <Row className="align-items-center p-lg-4 my-3 my-lg-0">
        <Col xs={12}>
          <Stack direction="horizontal" gap={3}>
            <span
              className="fw-normal ms-2 ms-lg-0"
              style={{ fontSize: ".875rem", color: "#344054" }}
            >
              Viewing:
            </span>
            <Form.Select
              id="count"
              value={pageSize}
              onChange={(e) => setPageSize(+e.target.value)}
              style={{
                width: 39,
                height: 24,
                fontSize: ".875rem",
                color: "#344054",
              }}
              className="px-1 py-0 pagination"
            >
              {[5, 10, 20, 50].map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
            <span
              className="d-none d-lg-block"
              style={{ fontSize: ".875rem", color: "#344054" }}
            >
              {`Page ${data?.page ? data.page : 1} of ${numPages}`}
            </span>
          </Stack>
        </Col>
      </Row>
    </div>
  );
};

export default Favorites;
