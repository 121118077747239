import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../../context/ToastContext";
import { Spinner } from "react-bootstrap";
import { getErrorMessage } from "../../../../../utils/utils";
import { useDeleteJokeMutation } from "../../../../../services/endpoints/content/joke";
import { useAppDispatch } from "../../../../../services/hooks";
import { removeJoke } from "../../../../../services/slicers/jokeSlice";

export const HandleJokeAction: React.FC = () => {
  const params = useParams();
  const id = +params.id!;
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [deleteJoke] = useDeleteJokeMutation();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    try {
      await deleteJoke(id).unwrap();
      dispatch(removeJoke(id));
      addToast(`Joke #${id} deleted correctly`, "success");
    } catch (e) {
      navigate("/admin/content/jokes");
      addToast(getErrorMessage(e), "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "300px" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return <Navigate to={`/admin/content/jokes`} />;
};
