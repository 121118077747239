import { Button, Stack } from "react-bootstrap";
import "./CourseChildConfirmation.css";
import { SimpleButton } from "../../../../components/buttons/SimpleButton";

interface CourseChildConfirmationProps {
    hideOverlay: () => void;
    addChild: () => void;
}

const CourseChildConfirmation: React.FC<CourseChildConfirmationProps> = ({
  hideOverlay,
  addChild,
}) => {
  return (
    <div className="p-2">
      <div className="d-flex align-items-center justify-content-between">
        <span className="fw-bold text-info h2">Success!</span>
        <span
          className="fw-bold text-uppercase"
          style={{ color: "#5E646980", cursor: "pointer" }}
          onClick={hideOverlay}
        >
          Cancel
        </span>
      </div>
      <p className="confirmation-message">
        Your child is added to your account! What would you like to do next?
      </p>
      <Stack gap={2} direction="horizontal">
        <SimpleButton
          type="button"
          variant="outline-primary"
          width={"100%"}
          onClick={addChild}
        >
          Add Another Child
        </SimpleButton>
        <SimpleButton type="button" variant="primary" width={"100%"}>
          Continue Registration
        </SimpleButton>
      </Stack>
    </div>
  );
};

export default CourseChildConfirmation;
