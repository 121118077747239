import { ErrorMessage, Field, FieldProps } from 'formik';
import { FC } from 'react';
import { Form, FormControl } from 'react-bootstrap';

interface FormStaticSelectProps {
  name: string;
  label: string;
  options: { value: string | number | boolean, label: string }[];
  loadingError?: string;
}

export const FormStaticSelect: FC<FormStaticSelectProps> = ({ name, label, options, loadingError }) => {
  
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Field
        name={name}
        >
        {({field, form}: FieldProps) => {
          const { onChange, onBlur, value, ...restField } = field;
          let values = Array.isArray(value) ? value : value ? [value] : [];
          return (
            <>
              <Form.Control
                as="select"
                name="instructor"
                value={values}
                onChange={option => {
                  form.setFieldValue(field.name, option.target.value)
                }}
                style={{ padding: '7px 10px' }}
              >
                <option value="all">Any</option>
                {options.map((opt, idx) => (
                  <option key={idx} value={String(opt.value)}>{opt.label}</option>
                ))}
              </Form.Control>
              <ErrorMessage name={field.name}>
                {(msg: any) => {
                  return (
                    <FormControl.Feedback type="invalid">{`${msg?.value ?? msg}`}</FormControl.Feedback>
                  )
                }}
              </ErrorMessage>
              { loadingError && <Form.Text className="text-danger">{loadingError}</Form.Text> }
            </>
          )
        }}
      </Field>
    </Form.Group>
  )
}