import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { ZipcodeResponse, SFCZipcode, SFCZipcodeSimple } from '../../../types/place.types';
import { sfcApi } from '../../api';

export const zipcodesApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getZipcode: builder.query<ZipcodeResponse, number>({
      query: (id) => ({
        url: `zip-codes/${id}`,
      }),
    }),
    getAllZipcodes: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `zip-codes${params?.scope ? (params?.scope !== 'all' ? `?scope=${params.scope}` : '') : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getAllZipcodesSimple: builder.query<SFCZipcodeSimple[], GetQueryParams | void>({
      query: (params) => ({
        url: `zip-codes/simple${params?.scope ? (params?.scope !== 'all' ? `?scope=${params.scope}` : '') : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    createZipcode: builder.mutation<ZipcodeResponse, Partial<SFCZipcode>>({
      query: (params) => ({
        method: 'POST',
        url: `zip-codes/create`,
        body: params
      }),
    }),
    updateZipcode: builder.mutation<ZipcodeResponse, Partial<SFCZipcode>>({
      query: (data) => ({
        url: `zip-codes/update/${data.id}`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetZipcodeQuery,
  useGetAllZipcodesQuery,
  useLazyGetAllZipcodesQuery,
  useGetAllZipcodesSimpleQuery,
  useCreateZipcodeMutation,
  useUpdateZipcodeMutation,
} = zipcodesApi;
