import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getObjectDifference } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ChildForm } from './ChildForm';
import {
  useCreateChildMutation,
  useGetChildQuery,
  useUpdateChildMutation,
} from '../../../../../services/endpoints/people/child';

const steps = [
  { name: 'Details', form: ChildForm },
];

export interface FormValues {
  first_name: string;
  last_name: string;
  dob?: string;
  grade_level: string;
  gender: string;
  emotional_needs?: string;
  parent_guardian?: string;
  emergency_contacts?: string;
  pickup_type?: string;
  pickup_other?: string;
  origination?: string;
  social_emotional_needs?: string;
  agree_to_terms?: boolean;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditChildProps {
  editing?: boolean;
}

export const AddEditChild: React.FC<AddEditChildProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createChild] = useCreateChildMutation();
  const [updateChild] = useUpdateChildMutation();
  const { data, error, isLoading }  = useGetChildQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    first_name: '',
    last_name: '',
    grade_level: '',
    gender: '',
  });

  const validationSchemas = [
    Yup.object({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      dob: Yup.string().optional(),
      grade_level: Yup.string().required('Grade level is required'),
      gender: Yup.string().required('Gender is required'),
      emotional_needs: Yup.string().optional(),
      parent_guardian: Yup.string().optional(),
      emergency_contacts: Yup.string().optional(),
      pickup_type: Yup.string().optional(),
      pickup_other: Yup.string().optional(),
      origination: Yup.string().optional(),
      social_emotional_needs: Yup.string().optional(),
      agree_to_terms: Yup.boolean().required(),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading promotional code', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.child) {
      const { child } = data;
      setInitialValues({
        first_name: child.first_name,
        last_name: child.last_name,
        dob: child.dob ?? undefined,
        grade_level: child.grade_level,
        gender: child.gender,
        emotional_needs: child.emotional_needs ?? undefined,
        parent_guardian: child.parent_guardian ?? undefined,
        emergency_contacts: child.emergency_contacts ?? undefined,
        pickup_type: child.pickup_type ?? undefined,
        pickup_other: child.pickup_other ?? undefined,
        origination: child.origination ?? undefined,
        social_emotional_needs: child.social_emotional_needs ?? undefined,
        agree_to_terms: child.agree_to_terms,
      });
    }
  }, [data]);


  const handleSumbit = async (formValue: FormValues) => {
    try {      
      if (editing) {
        const formattedDiscount = JSON.parse(JSON.stringify(data?.child)) as FormValues;
        const update = getObjectDifference(formattedDiscount, formValue);
        if (Object.keys(update).length > 0) {
          const res = await updateChild({
            id: data?.child.id!,
            data: formValue
          }).unwrap();
          if (res && res.child) {
            addToast(`Child updated succesfully`, 'success');
          } else {
            addToast(`No changes to child`, 'info');
          }
          navigate(`/admin/sfc-users/children/${data?.child?.id}`);
        }
      } else {
        const res = await createChild(formValue).unwrap();
        if (res && res.child) {
          navigate(`/admin/sfc-users/children/${res.child.id}`);
          addToast('Child created succesfully', 'success');
        } else throw new Error('A problem happened while creating child');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        first_name: true,
        last_name: true,
        dob: true,
        grade_level: true,
        gender: true,
        emotional_needs: true,
        parent_guardian: true,
        emergency_contacts: true,
        pickup_type: true,
        pickup_other: true,
        origination: true,
        social_emotional_needs: true,
        agree_to_terms: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to child${editing ? ' details' : 'ren'}`}
            color="#2B4E64"
            url={editing ? `/admin/sfc-users/children/${data?.child?.id}` : '/admin/sfc-users/children'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Child
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSumbit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
