import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Waitlist } from '../../../types/waitlist.type';
import { sfcApi } from '../../api';

export const waitlistApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllWaitlists: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `waitlists${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getWaitlist: builder.query<{ waitlist: Waitlist }, number>({
      query: (id) => `waitlists/${id}`,
    }),
    archiveWaitlist: builder.mutation<{ waitlist: Partial<Waitlist> }, number>({
      query: (id) => ({
        url: `waitlists/${id}/archive`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetAllWaitlistsQuery,
  useGetWaitlistQuery,
  useArchiveWaitlistMutation,
} = waitlistApi;
