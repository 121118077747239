import React, { ReactNode } from 'react';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface IconLinkProps {
  icon: ReactNode;
  text?: string;
  url?: string;
  children?: ReactNode;
}

export const IconLink: React.FC<IconLinkProps> = ({ icon, text, url, children }) => {
  return (
    <Stack direction="horizontal" gap={2} className="text-info py-1">
      {icon}
      {text && <Link className="text-info fw-semibold" style={{ maxWidth: '80%' }} to={url ?? ''}>{text}</Link>}
      {children}
    </Stack>
  );
};
