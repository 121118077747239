import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { Waitlist } from '../../../../../types/waitlist.type';
import { useGetAllWaitlistsQuery } from '../../../../../services/endpoints/schedule/waitlist';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { waitlistColumns } from '../../../../../utils/consts/columnDefs/waitlistColumns';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { getCurrentUser } from '../../../../../services/helper';
import { WaitlistLegend } from '../../../../../components/generic';
import { useDataContext } from '../../../../../context';

const defaultScope = 'pending';
const currentUser = getCurrentUser();

export const WaitlistView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading, isFetching } = useGetAllWaitlistsQuery(filters, { refetchOnMountOrArgChange: true });
  const [lessons, setLessons] = useState<Waitlist[]>([]);
  const [scopes, setScopes] = useState<Scopes>({});
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const isGlobalUser = (currentUser?.isGlobal);

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'course_title',
      element: (
        <SearchField name="course_title" label="Session" placeholder="Search session" />
      )
    },
    {
      name: 'child_full_name',
      element: (
        <SearchField name="child_full_name" label="Child" placeholder="Child Full Name" />
      )
    },
    {
      name: 'parent_email',
      element: (
        <SearchField name="parent_email" label="Parent Email" placeholder="Parent Email Address" />
      )
    },
    {
      name: 'location_id',
      operator: 'equals',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
  ];


  if (!isGlobalUser) {
    // remove location filter
    filterFormFields.splice(-1,1);
  }

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setLessons(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Waitlists`}
        columns={waitlistColumns}
        data={lessons}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters?.data?.filterBy}
        indexDownloadPath='waitlists'
        sidebars={[<WaitlistLegend />]}
        filterForm={(
          <FilterForm fields={filterFormFields} onApplyFilters={setFilterBy} />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
