import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { getCurrentUser } from '../../../../../services/helper';
import { trainingVideo } from '../../../../../types/trainingVideos.type';
import { useGetTrainingVideosQuery } from '../../../../../services/endpoints/content/trainingVideo';
import { trainingVideosColumns } from '../../../../../utils/consts/columnDefs/trainingVideos';
import { Stack } from 'react-bootstrap';
import { categories } from '../../../../../utils/consts/trainingVideo/categories';
import { titleCase } from '../../../../../utils/stringConversions';
import { DetailSection } from '../../../../../components/generic';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';

const defaultScope = 'active';
const currentUser = getCurrentUser();

export const TrainingVideosView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: { page: 1, entries: 10 }});
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetTrainingVideosQuery(filters);
  const [trainingVideo, setTrainingVideo] = useState<trainingVideo[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();

  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'category',
      type: 'number',
      element: (
        <FormSelect
          name="category"
          label="Category"
          options={categories.map(k => ({label: titleCase(k), value: k}))}
        />
      ),

    }
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setTrainingVideo(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  const trainingVideosPanel = (
    <DetailSection title="Resources">
      { currentUser?.isGlobal && (
        <Stack gap={2}>
          <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTYwrXCL8mRghcwEIfGn81ZhZkLPO6pXIprxys_6SzD9frfZ9EOVoPSreIhImbMOuQ1CzL6GmpxiNTV/embed?start=false&loop=false&delayms=3000" width="200" height="150" allowFullScreen={true}></iframe>
          <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRGgDuB60EI4Qf4cS85rAI1YmLirRBCIr0CG5_yrHPmUX7DV2BDVgX4QiBVG7rArTjXDe9oSCRE9oeN/embed?start=false&loop=false&delayms=3000" width="200" height="150" allowFullScreen={true}></iframe>
          <iframe src="https://docs.google.com/document/d/e/2PACX-1vQcfrnRyS4ox0qre0hjO8k6d5PJY0yktRtHgQQeJSJfXc2dB0ht3kz2d3zyHh2zUXOyKWD6lzK-tMDK/pub?embedded=true" width="200" height="300"></iframe>
        </Stack>
      )}
    </DetailSection>
  );

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Videos`}
        columns={trainingVideosColumns}
        data={trainingVideo}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        sidebars={[trainingVideosPanel]}
        isLoading={isLoading}
        isFetching={isFetching}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate('/admin/training/videos/new')}
          >
            New Video
          </SimpleButton>
        )}
      />
    </div>
  );
}
