import React from 'react';

interface CurrencyCellProps {
  value: number|string;
  label?: string;
}

const CurrencyCell: React.FC<CurrencyCellProps> = ({ value, label }) => {
  let numVal = (value ? Number(value) : Number.NaN) || 0;
  let valueStr = numVal.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <div>${valueStr}{label ? ` ${label}` : ''}</div>
  );
};

export default CurrencyCell;