import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { step } from '../../../types/steps.type';
import { sfcApi } from '../../api';
export interface StepCreateUpdateValues {
    id?: number;
    guide_id: string;
    description:string;
    file: File,

  }
export const stepApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getSteps: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `steps${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getStep: builder.query<{
      description: { step: step; } | undefined; step: step 
}, number>({
      query: (id) => `steps/${id}`,
    }),


    createStep: builder.mutation<{ step: step }, StepCreateUpdateValues>({
      query: (data) => {
        const formData = new FormData();
        let payload: any = {
          url: `steps/create`,
          method: 'POST',
          body: data,
        };
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
        if (data.file?.name) {
          payload.formData = true;
        }
        if(!data?.file?.name){
            formData.delete('file');
        }
        formData.delete('guide');

        payload.body = formData;

        return payload;
      },
      invalidatesTags: ['Steps']
    }),

    updateStep: builder.mutation<{ step: step }, StepCreateUpdateValues>({
      query: (data) => {
        const formData = new FormData();
        let payload: any = {
          url: `steps/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
        delete data.id;
       
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
       
        payload.body = formData;

        return payload;
      },
      invalidatesTags: ['Steps']

    }),

    deleteStep: builder.mutation<{ step: step }, number>({
      query: (id) => ({
        url: `steps/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetStepsQuery,
  useGetStepQuery,
  useCreateStepMutation,
  useUpdateStepMutation,
  useDeleteStepMutation
} = stepApi;