import { Field } from 'formik';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';

interface SearchFieldProps {
  name: string;
  label: string;
  placeholder?: string;
}

export const SearchField : React.FC<SearchFieldProps> = ({ name, label, placeholder }) => {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <InputGroup.Text>
          <BsSearch />
        </InputGroup.Text>
        <Field
          name={name}
          type="text"
          placeholder={placeholder ?? 'Search'}
          as={Form.Control}
        >
        </Field>
      </InputGroup>
    </Form.Group>
  )
}
