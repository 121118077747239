import { ReactComponent as SFCMemberBanner } from '../../assets/icons/sfc-member-banner.svg';

export function SFCMemberRibbon(props: {year: number}) {
  return (
    <div>
      <SFCMemberBanner style={{width: "100%"}} />
      <h6 className="text-white text-uppercase member-banner-text">
        {`Member of the SFC family since ${props.year}`}
      </h6>
    </div>
  )
}