import { parse, format } from 'date-fns';
import { getTimeZone } from '../services/helper';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';

const timeZone = getTimeZone(); // current user's timezone

// given a UTC time, transform it to current time zone with given format
export const formatDate = (date: Date, format: string = 'MM/dd/yyyy') => {
  // Format the zoned time in desired format
  try {
    return formatInTimeZone(date, timeZone, format);
  } catch (e) {
    return 'Invalid Date'
  }
};

export const formatUTCDateOnly = (date: Date, format: string = 'yyyy-MM-dd') => {
  return formatInTimeZone(date, 'UTC', format);
}

export const getCurrentDateOnly = () => {
  return format(new Date(), 'yyyy-MM-dd');
}

export const getDaysOffsetDateOnly = (offsetDays: number) => {
  let d = new Date();
  d.setDate(d.getDate() + offsetDays);
  return format(d, 'yyyy-MM-dd');
}

// given a date in a current time zone, transform it to UTC with given format
export const dateToUTC = (date: Date, format: string = 'yyyy-MM-dd') => {

  // Format the zoned time in desired format
  return formatInTimeZone(date, 'UTC', format);
};

// gives current date using current time zone
export const dateInTimeZone = (date: Date = new Date()) => {
  return fromZonedTime(date, timeZone); // convers time to utc
};

// creates date with current time zone using a string
export const dateFromString = (date: string, format: string = 'yyyy-MM-dd') => {
  // Parse the date string into a Date object
  return fromZonedTime(parse(date, format, new Date()), timeZone);
};

export const UTCToDate = (date: string, format: string = 'yyyy-MM-dd') => {
  const UTCDate = parse(date ?? '', format, new Date());
  const utcDateCorrected = new Date(UTCDate.getTime() - (UTCDate.getTimezoneOffset() * 60000));
  return utcDateCorrected;
}

export const UTCTimeToLocal = (time?: string, format: string = 'h:mma') => {
  const regex = /(\d{1,2}):(\d{2})\s?([ap]+m)/i;
  const match = time?.match(regex);

  if (match) {
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);         // Extract minutes (e.g., 30)
    const amPm = match[3].toLowerCase();            // Extract "am" or "pm"

    // Convert hours to 24-hour format
    let hours24 = hours;
    if (amPm === 'pm' && hours24 < 12) {
      hours24 += 12;
    } else if (amPm === 'am' && hours24 === 12) {
      hours24 = 0;
    }
  
    const now = new Date();
    const dateInUTC = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), hours24, minutes));
    // Convert the time from UTC to the local timezone
    const localTime = new Date(dateInUTC.toLocaleString("en-US", { timeZone: timeZone })); 
    // Format the result back to 'h:mma' format
    const formattedTime = localTime.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true,  }).toLowerCase();
    return formattedTime.replace('am', 'AM').replace('pm', 'PM');
  }
}
