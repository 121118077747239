import { useEffect, useState } from 'react';
import SFCTable from '../../../../components/tables/SFCTable';
import { useToast } from '../../../../context/ToastContext';
import { GetQueryParams } from '../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../components/tables/filterForms/SearchField';
import { ColumnDef } from '@tanstack/react-table';
import { useGetAllEnrollmentsQuery } from '../../../../services/endpoints/schedule/enrollment';
import { Enrollment } from '../../../../types/enrollment.type';
import { LinkCell } from '../../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { useNavigate } from 'react-router-dom';

const defaultScope = 'all';

export const enrollmentColumns: ColumnDef<Enrollment>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Session',
    accessorKey: 'course_id',
    cell: ({ row, getValue }) => (
      <LinkCell content={row.original.session_title} url={`/admin/schedule/sessions/${getValue<number>()}`} />
    ),
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/enrollments/${row.original.id}`} underline />
        <LinkCell content="Edit" url={`/admin/enrollments/${row.original.id}/edit`} underline />
        <LinkCell content="Delete" url={`/admin/enrollments/${row.original.id}/delete`} underline />
      </Stack>
    )
  },
];
// TODO: add status = 'open' for filtering, check if assign instructor column is required

export const EnrollmentsView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading, isFetching } = useGetAllEnrollmentsQuery(filters);
  const [enrollments, setEnrollments] = useState<Enrollment[]>([]);
  const navigate = useNavigate();

  const filterFormFields: FilterField[] = [
    {
      name: 'session_title',
      element: (
        <SearchField name="session_title" label="Session" placeholder="Search session" />
      )
    },
  ];
  
  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading enrollments', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setEnrollments(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Enrollments`}
        columns={enrollmentColumns}
        data={enrollments}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={320}
            onClick={() => navigate('/admin/enrollments/new')}
          >
            New enrollment
          </SimpleButton>
        )}
      />
    </div>
  );
}
