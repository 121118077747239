import { Col, Form, FormControl, InputGroup, Row, Spinner, Stack } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from '../AddEditSession';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { BsCurrencyDollar } from 'react-icons/bs';
import { useGetInstructorsQuery, User } from '../../../../../../services/endpoints/people/user';
import { useToast } from '../../../../../../context/ToastContext';
import { getErrorMessage } from '../../../../../../utils/utils';

export const InstructorDetailsForm = ({ touched, errors, values, setFieldValue, readOnly }: StepProps) => {
  const { data, error, isLoading } = useGetInstructorsQuery();
  const toast = useToast();

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  }

  if (error || !data || !data.results) {
    toast.addToast(getErrorMessage(error) ?? 'Error loading themes', 'error');
  }

  return (
    <Row>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          <h6
            className="fw-bold mb-0"
            style={{ fontSize: '1.125rem', color: '#5E6469' }}
          >
            Instructor Details
          </h6>

          <Form.Group controlId="min_instructors">
            <Form.Label>Main Instructors Required</Form.Label>
            <Field
              name="min_instructors"
              type="number"
              min="1"
              as={Form.Control}
              style={{ width: 66 }}
              isInvalid={touched.min_instructors && !!errors.min_instructors}
              disabled={readOnly}
            />
            <ErrorMessage name="min_instructors">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group controlId="instructor_ids">
            <Form.Label>Main Instructors</Form.Label>
            <Typeahead 
              id="instructor_ids"
              multiple
              disabled={readOnly }
              options={data?.results?.map((instructor: User) => ({ id: instructor.id, label: `${instructor.first_name} ${instructor.last_name}` })) ?? []}
              selected={values?.instructor_ids}
              onChange={(selected) => setFieldValue!('instructor_ids', selected)}
              isInvalid={touched.instructor_ids && !!errors.instructor_ids}
            />
            <ErrorMessage name="instructor_ids">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group controlId="pay_type">
            <Form.Label>Instructor Pay Type</Form.Label>
            <Field name="pay_type">
              {({ field }: { field: any }) => (
                <>
                  <Form.Check
                    type="radio"
                    label="Flat Rate"
                    name="pay_type"
                    id="flat_rate"
                    value="flat_rate"
                    onChange={() => setFieldValue!('pay_type', 'flat_rate')}
                    checked={field.value === 'flat_rate'}
                    disabled={readOnly}
                  />
                  <Form.Check
                    type="radio"
                    label="Per hour (session duration has not yet been set)"
                    name="pay_type"
                    id="per_hour"
                    value="per_hour"
                    onChange={() => {
                      setFieldValue!('pay_type', 'per_hour');
                      setFieldValue!('flat_rate', undefined);
                    }}
                    checked={field.value === "per_hour"}
                    disabled={readOnly}
                  />
                </>
              )}
            </Field>
            <ErrorMessage name="pay_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          {values?.pay_type === 'flat_rate' && (
            <Form.Group controlId="flat_rate">
              <Form.Label>Instructor Flat Daily Rate</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <BsCurrencyDollar color="#5E6469" />
                </InputGroup.Text>
                <Field
                  name="flat_rate"
                  type="number"
                  as={Form.Control}
                  isInvalid={touched.flat_rate && !!errors.flat_rate}
                  disabled={readOnly}
                >
                </Field>
              </InputGroup>
              <ErrorMessage name="flat_rate" component="span" className="text-danger fs-small" />
            </Form.Group>
          )}

          <Form.Group controlId="instructor_notes">
            <Form.Label>Instructor Notes</Form.Label>
            <Field
              name="instructor_notes"
              as="textarea"
              rows={2}
              className="form-control"
              isInvalid={touched.instructor_notes && !!errors.instructor_notes}
              disabled={readOnly}
            />
            <Form.Text>Instructor notes automatically propagate onto the Roster.pdf for Website Payment sessions.</Form.Text>
            <ErrorMessage name="instructor_notes" component="span" className="text-danger fs-small" />
          </Form.Group>
        </Stack>
      </Col>
    </Row>
    
  )
}
