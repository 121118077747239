import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditCountryFact';
import Editor from '../../../../../components/editor/Editor';

export const CountryFactForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const file = event.currentTarget.files?.[0] || null;
    setFieldValue?.('file', file);
  }

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Name
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Field
            name="name"
            type="text"
            as={Form.Control}
            isInvalid={touched.name && !!errors.name}
          />
          <ErrorMessage name="name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-10 mb-lg-3" controlId="details">
          <Form.Label>Content</Form.Label>
          <Field 
          name="content" 
          component={Editor} 
          className="form-control"
          isInvalid={touched.content && !!errors.content}/>
          <ErrorMessage name="content">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col> 
      
      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="total">
          <Form.Label>File</Form.Label>
            <Field
              name="file_name"
              type="file"
              as={Form.Control}
              onChange={handleFileChange}
              isInvalid={touched.file && !!errors.file}
            />
            <ErrorMessage name="document">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
      </Col> 
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Image source</Form.Label>
          <Field
            name="image_source"
            type="text"
            as={Form.Control}
            isInvalid={touched.image_source && !!errors.image_source}
          />
          <ErrorMessage name="image_source">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
    </Row>
  )
}
