import React, { ReactNode } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import './ButtonGroup.css';

export type ButtonData = {
  title: string;
  key?: string;
  count?: number;
  icon?: ReactNode;
};

interface CustomButtonGroupProps {
  group: number;
  buttons: ButtonData[];
  activeIndex: string | null;
  onButtonClick: (group: number, index: number) => void;
}


const CustomButtonGroup: React.FC<CustomButtonGroupProps> = ({ group, buttons, activeIndex, onButtonClick }) => {
  const handleButtonClick = (index: number) => {
    onButtonClick(group, index);
  };

  return (
    <ButtonGroup className="custom-btn-group">
      {buttons?.map((button, index) => (
        <Button
          key={index}
          className={`text-truncate ${activeIndex === `${group}-${index}` ? 'active' : ''} ${buttons.length === 1 ? 'single' : ''}`}
          onClick={() => handleButtonClick(index)}
        >
          {button.icon}
          {button.title}
          {button.count !== undefined && (
            <span>{` (${button.count})`}</span>
          )}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default CustomButtonGroup;