import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { dateToUTC } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useGetFeedbackQuery } from '../../../../../services/endpoints/website/feedback';
import { Feedback } from '../../../../../types/website.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';

export const FeedbackDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetFeedbackQuery(+params.id!);
  const [ feedback, setFeedback] = useState<Feedback>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setFeedback(data.feedback);
    }
  }, [data]);

  if (isLoading || !feedback) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/website/feedbacks')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Feedbacks List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Feedback #${feedback?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/website/feedbacks/${feedback?.id}/edit`)}
          >
            Edit Feedback
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/website/feedbacks/${feedback?.id}/delete`)}
          >
            Delete Feedback
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="First Name:" text={feedback?.first_name} />
            <LabelText label="Last Name:" text={feedback?.last_name} />
            <LabelText label="Location:" text={feedback?.location} />
            <LabelText label="Email:" text={feedback?.email} />
            <LabelText label="Phone Number:" text={feedback?.phone_number} />
            <LabelText label="Message:" text={feedback?.message} />
            <LabelText label="Created At:" text={feedback?.created_at ? dateToUTC(new Date(feedback?.created_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Updated At:" text={feedback?.updated_at ? dateToUTC(new Date(feedback?.updated_at), 'MM/dd/yyyy') : 'N/A'} />
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
