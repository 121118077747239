import { Card, Col, Row } from "react-bootstrap";
import ClassPreview from "./ClassPreview";
import ClassDetailsCalendar from "./ClassDetailsCalendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { Lesson } from "../../../types/lesson.type";
import { useState } from "react";

const EventKeys = () => {
  return (
    <div className="mt-3 p-2 px-lg-3 py-lg-2" style={{ width: "fit-content" }}>
      <span className="fw-semibold" style={{ color: "#5E646980" }}>
        Key
      </span>
      <Row className="align-items-center">
        <Col xs={12} lg="auto" className="d-flex align-items-center me-4">
          <div
            className="rounded-circle me-1"
            style={{ width: 8, height: 8, background: "#B4C634" }}
          ></div>
          <span> Class Scheduled</span>
        </Col>
        <Col xs={12} lg="auto" className="d-flex align-items-center me-4">
          <div
            className="rounded-circle me-1"
            style={{ width: 8, height: 8, background: "#B81F69" }}
          ></div>
          <span> No Class</span>
        </Col>
      </Row>
    </div>
  );
};

interface ClassCalendarCardProps {
  dateChange: ({ start, end }: { start: string; end: string }) => void;
  lessons: Lesson[] | undefined;
}
const ClassCalendarCard: React.FC<ClassCalendarCardProps> = ({
  dateChange,
  lessons,
}) => {
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null);
  const selectedDate = (date: string) => {
    if (lessons && lessons.length > 0) {
      const lesson = lessons.find((l) => l.date === date);
      if (lesson) {
        setSelectedLesson(lesson);
      } else {
        setSelectedLesson(null);
      }
    } else {
      setSelectedLesson(null);
    }
  };
  return (
    <Card className="shadow mt-4 mb-3">
      <Card.Body>
        <Row>
          <Col lg={7} sm={12} className="p-3">
            <ClassDetailsCalendar
              dateChange={dateChange}
              lessons={lessons}
              selectedDate={selectedDate}
              selectedLesson={selectedLesson}
            />
          <EventKeys />
          </Col>
          <Col lg={5} sm={12} className="p-3">
            {!selectedLesson && (
              <Card.Text
                style={{
                  color: "#5E646980",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Select a date to view class details and recipes for that day!
              </Card.Text>
            )}
            <ClassPreview selectedLesson={selectedLesson} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ClassCalendarCard;
