import { lessonAssignmentActionsData } from '../../../features/dash/views/schedule/sessions/SessionDetail';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { LessonAssignment } from '../../../types/lesson.type';
import { sfcApi } from '../../api';

export const lessonAssignmentApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getLessonAssignments: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `lesson-assignments${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['LessonAssignments'],
    }),
    teachClass: builder.mutation<{lessonAssignment: LessonAssignment}, {id: number, instructor_id?: number} | void>({
      query: (params) => ({
        url: `lesson-assignments/teach`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['LessonAssignments'],
    }),
    renegClass: builder.mutation<{lessonAssignment: LessonAssignment}, lessonAssignmentActionsData | void>({
      query: (params) => ({
        url: `lesson-assignments/reneg`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Course'],
    }),
    createSubRequest: builder.mutation<{lessonAssignment: LessonAssignment}, lessonAssignmentActionsData | void>({
      query: (params) => ({
        url: `lesson-assignments`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Course'],
    }),
    deleteSubRequest: builder.mutation<{lessonAssignment: LessonAssignment}, lessonAssignmentActionsData | void>({
      query: (params) => ({
        url: `lesson-assignments`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['Course'],
    }),
  }),
});

export const {
  useGetLessonAssignmentsQuery,
  useTeachClassMutation,
  useRenegClassMutation,
  useCreateSubRequestMutation,
  useDeleteSubRequestMutation
} = lessonAssignmentApi;
