import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditTerritory';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { getCurrentUser } from '../../../../../services/helper';
import { useDataContext } from '../../../../../context';
import { useGetAllZipcodesSimpleQuery } from '../../../../../services/endpoints/places/zipcode';

const currentUser = getCurrentUser();

export const TerritoryForm = ({ touched, errors, values }: StepProps) => {
  const { locationOptions } = useDataContext();
  const isGlobalUser = (currentUser?.isGlobal);
  const { data: zipCodes, error: zipCodesError } = useGetAllZipcodesSimpleQuery({data: {orderBy: {field: "code", order: "asc"}}, scope: 'available'});
  
  return (
    <>
      <Row>
        <Col className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Field
              name="title"
              type="text"
              as={Form.Control}
              isInvalid={touched.title && !!errors.title}
            />
            <ErrorMessage name="title">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          {isGlobalUser ? (
            <Form.Group className="mb-1 mb-lg-3" controlId="location_id">
              <Field 
                as={FormSelect}
                name="location_id"
                label="Location"
                options={locationOptions}
              />
            </Form.Group>
          ) : (<></>)
          }

          { (!values?.id) ? (
            <Form.Group className="mb-1 mb-lg-3" controlId="zip_codes">
              <Form.Label>Zip Code</Form.Label>
              <Field
                name="zip_codes"
                as={'textarea'}
                className={`form-control ${touched.zip_codes && !!errors.zip_codes ? 'is-invalid' : ''}`}
                disabled={!values?.location_id?.value}
              />
              <Form.Text className="text-info">
                {values?.location_id?.value ? 'Enter Zip codes as a space- or comma-separated list' : 'Select a location above before entering zip codes' }
              </Form.Text>
              {errors.zip_codes?.includes("Warning") && (
                <Form.Text className="text-warning">
                  {errors.zip_codes}
                </Form.Text>
              )}
              <ErrorMessage name="zip_codes">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
          ) : (
            <Form.Group className="mb-1 mb-lg-3" controlId="zip_code_ids">
              <FormSelect
                name="zip_code_ids"
                label="Zip Codes"
                multiple={true}
                options={zipCodes?.map((zipCode) => ({ value: zipCode.id, label: String(zipCode.code) })) ?? []}
                loadingError={zipCodesError ? "Error loading Zip Codes" : undefined}
              />
              <ErrorMessage name="zip_code_ids">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
          )}
        </Col>
      </Row>
    </>
  )
}
