import { Col, Form, Row, Stack } from "react-bootstrap";
import { StepProps } from "../AddEditRecipe";
import { Field } from "formik";

export const RecipePreparationForm = ({
  touched,
  errors,
  values,
  setFieldValue,
  readOnly,
}: StepProps) => {
  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Recipe Preparation
      </h6>
      <Col xs={12}>
        <Stack gap={3}>
          <Form.Group controlId="recipe_steps_parent">
            <Form.Label>Family Method</Form.Label>
            <Field
              name="recipe_steps_parent"
              as="textarea"
              className="form-control"
              rows={4}
              isInvalid={
                touched.recipe_steps_parent && !!errors.recipe_steps_parent
              }
            />
            <Form.Text>
              <p>
                This will be broken up based on section headers, encapsulated by
                square brackets. Example:
                <br />
                [mix + match]
                <br />
                Do some stuff.
                <br />
                [bake]
                <br />
                Do some other stuff.
              </p>
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="recipe_steps_instructor">
            <Form.Label>Chef Instructor Method</Form.Label>
            <Field
              name="recipe_steps_instructor"
              as="textarea"
              className="form-control"
              rows={4}
              isInvalid={
                touched.recipe_steps_instructor &&
                !!errors.recipe_steps_instructor
              }
            />
            <Form.Text>
              <p>
                This will be broken up based on section headers, encapsulated by
                square brackets. Example:
                <br />
                [mix + match]
                <br />
                Do some stuff.
                <br />
                [bake]
                <br />
                Do some other stuff.
              </p>
            </Form.Text>
          </Form.Group>
        </Stack>
      </Col>
    </Row>
  );
};
