import React, { useState } from 'react';
import CustomButtonGroup, { ButtonData } from '../buttonGroup/ButtonGroup';
import { Stack } from 'react-bootstrap';

export interface FilterGroupProps {
  filterGroups: Array<ButtonData[]> | undefined;
  onFilterSelect: (filter: string) => void;
}

export const FilterGroup: React.FC<FilterGroupProps> = ({ filterGroups, onFilterSelect }) => {
  const [activeIndex, setActiveIndex] = useState<string | null>(`${0}-${0}`);

  if (!filterGroups) {
    return <></>;
  }

  const handleButtonClick = (group: number, index: number) => {
    setActiveIndex(`${group}-${index}`);
    onFilterSelect(filterGroups[group][index].key ?? '');
  };

  return (
    <Stack direction="horizontal" gap={4} className="mb-3 overflow-auto">
      {filterGroups.map((buttons: ButtonData[], index: number) => (
        <CustomButtonGroup
          key={index}
          group={index}
          buttons={buttons}
          activeIndex={activeIndex}
          onButtonClick={handleButtonClick}
        />
      ))}
    </Stack>
  );
};

