import React from 'react';
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { FaTimes, FaHourglass, FaCheck } from "react-icons/fa";

export type overrideStatusType = "approved" | "pending" | "denied";

interface OverrideStatusCellProps {
  type: overrideStatusType;
  value: string;
  notes?: string;
}

export const OverrideStatusCell: React.FC<OverrideStatusCellProps> = ({ type, value, notes }) => {

  const renderTooltip = (props: any) => (
    <Tooltip id="override-tooltip" {...props}>
      <span style={{ fontSize: '.75rem' }}>
        Override #{value} {type === 'pending' ? 'is' : 'has been'} {type} {notes ? `: ${notes}` : ''}.
      </span>
    </Tooltip>
  );

  return (
    <Stack direction="horizontal" gap={2}>
      <OverlayTrigger overlay={renderTooltip} placement="bottom">
        <span style={{ cursor: 'pointer' }}>
          {type === 'approved'
            ? <FaCheck size={19} />
            : (type === 'denied') 
              ? <FaTimes size={22} /> 
              : <FaHourglass size={22} />
          }
        </span>
        
      </OverlayTrigger>
    </Stack>
  )
};
