import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { formatDate } from '../../../../../utils/dateUtils';
import { useGetInvoiceItemQuery } from '../../../../../services/endpoints/finance/invoiceItem';
import { InvoiceItem } from '../../../../../types/finance.types';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';

export const InvoiceItemDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetInvoiceItemQuery(+params.id!);
  const [invoiceItem, setInvoiceItem] = useState<InvoiceItem>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading invoice item', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setInvoiceItem(data.invoiceItem);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  if (!invoiceItem) return <></>;

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate(`/admin/finance/invoices/${invoiceItem?.invoice_id}`)}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Invoice
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Invoice Item #${invoiceItem?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/finance/invoice-items/${invoiceItem?.id}/edit`)}
          >
            Edit Invoice Item
          </SimpleButton>

          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete Invoice Item
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection title="Details">
            <LabelText label="Invoice:">
              <LinkCell
                content={invoiceItem?.invoice?.name}
                url={`/admin/finance/invoices/${invoiceItem?.invoice?.id}`}
              />
            </LabelText>
            <LabelText label="Quantity:">
              {invoiceItem?.quantity}
            </LabelText>
            <LabelText label="Unit Price:">
              <CurrencyCell value={invoiceItem?.unit_price} />
            </LabelText>
            <LabelText label="Description:">
              {invoiceItem?.description ?? 'N/A'}
            </LabelText>
            <LabelText label="Enrollment:">
              <LinkCell
                content={invoiceItem?.enrollment_id}
                url={`/admin/schedule/enrollments/${invoiceItem?.enrollment_id}`} // TODO: change enrollment link
              />
            </LabelText>
            <LabelText label="Invoice Notes:">
              {invoiceItem?.invoice_notes ?? 'N/A'}
            </LabelText>
            <LabelText label="Created At:">
              {formatDate(new Date(invoiceItem?.created_at), 'MM/dd/yyyy h:mm a zzz')}
            </LabelText>
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Invoice Item"
        message="Are you sure you want to remove this invoice item?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => navigate(`/admin/finance/invoice-items/${invoiceItem?.id}/delete?invoice=${invoiceItem.invoice_id}`)}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
