import React, { ReactNode } from 'react';
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface TooltipLinkCellProps {
  tooltip: string;
  content: ReactNode;
  url: string;
  underline?: boolean;
  onClick?: (props: any) => void;
}

export const TooltipLinkCell: React.FC<TooltipLinkCellProps> = ({ tooltip, content, url, underline, onClick  }) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="enrollment-tooltip" {...props}>
      <span style={{ fontSize: '.75rem' }}>
        {tooltip}
      </span>
    </Tooltip>
  );

  return (
    <Stack direction="horizontal" gap={2}>
      <OverlayTrigger overlay={renderTooltip} placement="bottom">
        <Link
          className="text-info fw-bolder"
          style={{ fontSize: '.875rem', ...(!underline && { textDecoration: 'none' }) }}
          to={url}
          onClick={onClick}
        >
          {content}
        </Link>
      </OverlayTrigger>
    </Stack>
  )
}