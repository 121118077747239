import { useState, useEffect } from 'react';
import "../FranchiseLayout.css";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { GetQueryParams } from '../../../types/api.type';
import { useGetAllNewsItemsQuery } from '../../../services/endpoints/website/newsItem';
import { NewsItem } from '../../../types/website.types';
import { useToast } from '../../../context/ToastContext';
import { FaRegNewspaper } from "react-icons/fa6";

const defaultScope = '';

export const InTheNewsSubpage = () => {
  const { addToast } = useToast();
  const [filters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading } = useGetAllNewsItemsQuery(filters);
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setNewsItems(data.results);

    }
  }, [data]);

  return (
    <Container className="pb-5 text-blue">
    <div className="col-12 d-flex flex-nowrap justify-content-sm-center">
      <Button className="text-uppercase px-5 py-3 mb-5 bg-orange mt-3">
        Find Our Classes
        <FaArrowRight className="ms-2" size={22} />
      </Button>
    </div>
    <div>
      {newsItems.map((news_item, index) => (
        <>
        <div className="div-news-item d-md-block">
          <div className="div-news-details">
            <div className="subheader">{news_item.published_on}</div>
            {(news_item.image_file_name != null) && (
              <img className="image-news float-end" src={`${news_item.s3_base_url}/${news_item.image_file_name}`} />
            )}
            <h2>{news_item.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: news_item.details }} />
            <div>
              {news_item.news_item_links.map((link, link_index) => (
                <div className="text-fa">
                  <a className="link-block-action w-inline-block text-decoration-none" href={link.target} target="_blank">
                    {link.link_type == 'article' && (
                      <FaRegNewspaper className="me-2 mb-1" size={16} />
                    )}

                    {link.link_type == 'link' && (
                      <FaArrowRight className="me-2 mb-1" size={16} />
                    )}
                    
                    {link.text}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="div-break">&nbsp;</div>
        </>
      ))}
    </div>
    
  </Container>
  )
}