import React from 'react';
import { Stack } from 'react-bootstrap';
import { Course } from '../../../types/course.type';
import CurrencyCell from '../../../components/tables/cellComponents/CurrencyCell';
import { UTCTimeToLocal, getCurrentDateOnly } from '../../../utils/dateUtils';


export const CourseCost: React.FC<{ course: Course; className?: string; }> = ({ course, className }) => {
  let timestampNow = (new Date()).getTime();
  const times = course.formatted_time ? course.formatted_time.split(' - ') : [course.starts_at, course.ends_at];
  let start = UTCTimeToLocal(times[0]);
  let upcoming = course.lessons?.filter(l => (new Date(`${l.date} ${start}`).getTime()) > timestampNow) ?? [];
  const prorate = upcoming.length < course.lessons.length;
  let total = (+course.price_per_class * course.lessons.length);
  let newTotal = (+course.price_per_class * upcoming.length);
  let showTotal = (!(getCurrentDateOnly() > course.ends_on) && !(course.payment_type === 'onsite') && course.status != "cancelled");

  let remaining = `(${upcoming.length} of ${course.lessons.length} classes remaining)`;

  let totalContent = <CurrencyCell value={total} label='Total' />;
  if (prorate) {
    totalContent = <s>{totalContent}</s>;
  }
  let content = showTotal ? (
    <Stack gap={1} className={className} style={{alignItems: 'flex-end'}}>
      {totalContent}
      {prorate ? <span className="text-primary"><CurrencyCell value={newTotal} label={`${remaining}`} /></span> : <></>}
    </Stack>
  ) : <></>;
  return content;
};
