import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../../context/ToastContext';
import { Spinner } from 'react-bootstrap';
import { getErrorMessage } from '../../../../../utils/utils';
import { useDeleteInvoiceItemMutation } from '../../../../../services/endpoints/finance/invoiceItem';

export const DeleteInvoiceItem = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const params = useParams();
  const id = +params.id!;
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [deleteInvoiceItem] = useDeleteInvoiceItemMutation();
  const [loading, setLoading] = useState(true);

  const handleDeleteAction = async () => {
    try {
      await deleteInvoiceItem(id).unwrap();
      addToast(`Invoice Item #${id} deleted correctly`, 'success');
      navigate(`/admin/finance/invoices/${urlParams.get('invoice')}`);
    } catch (e) {
      navigate(-1);
      addToast(getErrorMessage(e), 'error');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    handleDeleteAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
 
  return <></>;
}
