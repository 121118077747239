/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditReceipt';

export const ReceiptForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="store_name">
            <Form.Label>Store Name</Form.Label>
            <Field
              name="store_name"
              as={Form.Control}
              isInvalid={touched.store_name && !!errors.store_name}
            />
            <ErrorMessage name="store_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="total">
            <Form.Label>Total</Form.Label>
            <Field
              name="total"
              type="number"
              as={Form.Control}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
