import React from 'react';
import { Stack } from 'react-bootstrap';
import { BsGeoAlt } from 'react-icons/bs';
import { Venue } from '../../../types/venue.type';
import { LinkCell } from './LinkCell';

interface VenueCellProps {
  venue: Venue;
}

export const VenueCell: React.FC<VenueCellProps> = ({ venue }) => {
  return (
    <Stack direction="horizontal" gap={2} className="align-items-start">
      <BsGeoAlt size={16} className="text-info mt-1" />
      <Stack>
        <LinkCell content={venue.title} url={`/admin/places/venues/${venue.id}`} />
        {`${venue.address} ${venue.city} ${venue.state}, ${venue.zip}`}
      </Stack>
    </Stack>
  )
}
