import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { ColumnDef } from '@tanstack/react-table';
import { isGlobal, isLocal } from '../../../../../utils/consts';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useToast } from '../../../../../context/ToastContext';
import { getCurrentUser } from '../../../../../services/helper';
import { GetQueryParams } from '../../../../../types/api.type';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { SFCLocation, SFCTerritory } from '../../../../../types/place.types';
import { useGetAllTerritoriesQuery } from '../../../../../services/endpoints/places/territory';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useNavigate } from 'react-router-dom';
import { useDataContext } from '../../../../../context';
import { Stack } from 'react-bootstrap';

interface DataRow extends SFCTerritory {
}

export const TerritoriesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const isGlobalUser = isGlobal(currentUser?.user_type_id!);
  const isLocalUser = isLocal(currentUser?.user_type_id!);

  const [filters, setFilters] = useState<GetQueryParams>({ data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllTerritoriesQuery(filters, {skip: !isLocalUser});
  
  const filterFormFields: FilterField[] = [
    {
      name: 'title',
      label: 'Title',
    },
    {
      name: 'location_id',
      label: 'Location',
      type: 'number',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
  ];

  if (!isGlobalUser) {
    // remove location filter
    filterFormFields.splice(1,1);
  }

  const columns: ColumnDef<DataRow>[] = [
    {
      header: 'Title',
      accessorKey: 'title',
    },
    {
      header: 'Location',
      accessorKey: 'location',
      enableSorting: false,
      cell: ({ getValue }) => {
        let location = getValue() as SFCLocation;
        return <LinkCell content={location?.title ?? "error"} url={`/admin/places/locations/${location?.id ?? 0}`} />;
      }
    },
    {
      header: '',
      accessorKey: 'blank',
      enableSorting: false,
      cell: ({ row }) => (
        <Stack direction="horizontal" gap={2}>
          <LinkCell content='View' url={`/admin/places/territories/${row.original.id}`} />
          {currentUser?.isSuper && <LinkCell content='Edit' url={`/admin/places/territories/${row.original.id}/edit`} />}
        </Stack>
      )
    },
  ];
  
  useEffect(() => {
    if (error) {
      addToast(`Error while loading data: ${JSON.stringify(error)}`, 'error');
    }
  }, [error]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  if (!isLocalUser) {
    return <>Error: Forbidden</>
  }

  if (error || isLoading) {
    return <></>
  }

  return (
    <div className="py-2">
      <SFCTable
        name={'Territories'}
        defaultScope={'all'}
        columns={columns}
        data={data?.results ?? []}
        count={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='territories'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(currentUser?.isSuper ? (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={400}
            onClick={() => navigate(`/admin/places/territories/new`)}
          >
            New Territory
          </SimpleButton>
          ) : <></>
        )}
      />
    </div>
  )
}

