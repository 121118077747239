import { useEffect, useState } from 'react';
import SFCTable from '../../../../components/tables/SFCTable';
import { ColumnDef } from '@tanstack/react-table';
import { isGlobal, isLocal } from '../../../../utils/consts';
import { FilterField, FilterForm } from '../../../../components/tables/filterForms/FilterForm';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { useToast } from '../../../../context/ToastContext';
import { getCurrentUser } from '../../../../services/helper';
import { GetQueryParams } from '../../../../types/api.type';
import { LinkCell } from '../../../../components/tables/cellComponents/LinkCell';
import { SFCLocation, SFCZipcode } from '../../../../types/place.types';
import { useGetAllZipcodesQuery } from '../../../../services/endpoints/places/zipcode';
import { useDataContext } from '../../../../context';

interface DataRow extends SFCZipcode {
}

export const ZipCodesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const currentUser = getCurrentUser();
  const isGlobalUser = isGlobal(currentUser?.user_type_id!);
  const isLocalUser = isLocal(currentUser?.user_type_id!);

  const [filters, setFilters] = useState<GetQueryParams>({ data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllZipcodesQuery(filters, {skip: !isLocalUser});
  
  const filterFormFields: FilterField[] = [
    {
      name: 'location_id',
      label: 'Location',
      type: 'number',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'code',
      label: 'Code',
    },
    {
      name: 'city',
      label: 'City',
    },
    {
      name: 'state',
      label: 'State',
    },
    {
      name: 'area',
      label: 'Area',
    },
  ];

  if (!isGlobalUser) {
    // remove location filter
    filterFormFields.splice(1,1);
  }

  const columns: ColumnDef<DataRow>[] = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'Location',
      accessorKey: 'territory.location',
      enableSorting: false,
      cell: ({ getValue }) => {
        let location = getValue() as SFCLocation;
        return location ? <LinkCell content={location?.title ?? "error"} url={`admin/places/location/${location?.id ?? 0}`} /> : <>Unassigned</>;
      }
    },
    {
      header: 'Code',
      accessorKey: 'code',
    },
    {
      header: 'City',
      accessorKey: 'city',
    },
    {
      header: 'State',
      accessorKey: 'state',
    },
    {
      header: 'Area',
      accessorKey: 'area',
    },
    {
      header: 'Latitude',
      accessorKey: 'latitude',
    },
    {
      header: 'Longitude',
      accessorKey: 'longitude',
    },
    {
      header: 'Tax Returns Filed',
      accessorKey: 'tax_returns_filed',
    },
    {
      header: 'Estimated Population',
      accessorKey: 'estimated_population',
    },
    {
      header: 'Total Wages',
      accessorKey: 'total_wages',
    },
    // {
    //   header: '',
    //   accessorKey: 'blank',
    //   enableSorting: false,
    //   cell: ({ row }) => (
    //     <>
    //       <LinkCell content='View' url={`/admin/places/zip-codes/${row.original.id}`} />
    //       &nbsp;
    //       &nbsp;
    //       <LinkCell content='Edit' url={`/admin/places/zip-codes/edit/${row.original.id}`} />
    //       &nbsp;
    //       &nbsp;
    //       <LinkCell content='Delete' url={`/admin/places/zip-codes/delete/${row.original.id}`} />
    //     </>
    //   )
    // },
  ];
  
  useEffect(() => {
    if (error) {
      addToast(`Error while loading data: ${JSON.stringify(error)}`, 'error');
    }
  }, [error]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  if (!isLocalUser) {
    return <>Error: Forbidden</>
  }

  if (error || isLoading) {
    return <></>
  }

  return (
    <div className="py-2">
      <SFCTable
        name={'Zip Codes'}
        columns={columns}
        defaultScope={'all'}
        data={data?.results ?? []}
        count={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='zip-codes'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  )
}
