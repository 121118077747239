import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AllergyForm } from './AllergyForm';
import { useCreateAllergyMutation, useGetAllergyQuery, useUpdateAllergyMutation } from '../../../../../services/endpoints/people/allergy';

import { Allergy } from '../../../../../services/endpoints/people/user';

interface FormValue {
  id?: number;
  allergy: string;
  severity: string;
  details: string;
  child_id: {label: string, value: number};
}

const steps = [
  { name: 'Details', form: AllergyForm },
];

export interface StepProps {
  errors: Partial<FormikErrors<FormValue>>;
  touched: Partial<FormikTouched<FormValue>>;
  values?: FormValue;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditAllergyProps {
  editing?: boolean;
}

export const AllergyLinkSchema = Yup.object({
  text: Yup.string().optional(),
  target: Yup.string().optional(),
  link_type: Yup.string().optional(),
});

export const AddEditAllergy: React.FC<AddEditAllergyProps> = ({ editing }) => {
  const params = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search); 
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createAllergy] = useCreateAllergyMutation();
  const [updateAllergy] = useUpdateAllergyMutation();
  const { data, error, isLoading }  = useGetAllergyQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValue>({
    details: '',
    allergy: '',
    severity: '',
    child_id: {label: '', value: 0},
  });

  const validationSchemas = [
    Yup.object({
      details: Yup.string().required('Details is required'),
      allergy: Yup.string().required('Allergy is required'),
      severity: Yup.string().required('Severity is required'),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading Allergy', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.allergy) {
      const { allergy } = data;
      setInitialValues({
        id: allergy.id,
        details: allergy.details,
        allergy: allergy.allergy,
        severity: allergy.severity,
        child_id: {value: allergy.child?.id ?? 0, label: allergy.child?.full_name ?? ''},
      });
    }
  }, [data]);


  const handleSubmit = async (formValue: FormValue) => {
    try {

      const allergy: Allergy = {
        ...formValue,
        child_id: formValue?.child_id?.value,
      }

      delete allergy.child;
      if (editing) {
        const res = await updateAllergy(allergy).unwrap();
        if (res && res.allergy) {
          addToast(`Allergy updated succesfully`, 'success');
          navigate(`/admin/allergies/${data?.allergy.id}`);
        }
      } else {
        const res = await createAllergy(allergy).unwrap();
        if (res && res.allergy) {
          navigate(`/admin/allergies/${res.allergy.id}`);
          addToast('Allergy created succesfully', 'success');
        } else throw new Error('A problem happened while creating Allergy');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValue>>, 
    setTouched: (touched: FormikTouched<FormValue>, shouldValidate?: boolean) => void,
    values: FormValue
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        details: true,
        allergy: true,
        severity: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to Allergy${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/allergies/${data?.allergy.id}` : '/admin/allergies'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Allergy
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValue>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
