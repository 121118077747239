export const userTypes = {
  customer: 0,
  instructor: 1, 
  admin: 2,
  global_admin: 3,
  super_admin: 4,
  supreme_admin: 5,
  web_and_content_editor: 6, 
  location_admin: 7,
};

export const userTypeNames: { [key: string]: string } = {
  customer: 'Customer',
  instructor: 'Instructor', 
  admin: 'Admin',
  global_admin: 'Global Admin',
  super_admin: 'Super Admin',
  supreme_admin: 'Supreme Admin',
  web_and_content_editor: 'Web and Content Editor', 
  location_admin: 'Location Admin',
};

export const isWebContentEditor = (userType: number) => (userTypes.web_and_content_editor === userType);

export const isAdmin = (userType: number) => {
  return [userTypes.admin, userTypes.super_admin, userTypes.supreme_admin, userTypes.location_admin, userTypes.global_admin].includes(userType);
};

export const isSuper = (userType: number) => {
  return [userTypes.super_admin, userTypes.supreme_admin].includes(userType);
};

export const isLocal = (userType: number) => {
  return [userTypes.super_admin, userTypes.supreme_admin, userTypes.admin, userTypes.location_admin].includes(userType);
};

export const isInstructor = (userType: number) => {
  return [userTypes.instructor].includes(userType);
};

export const hasDashAccess = (userType: number) => {
  return userType !== userTypes.customer;
};

export const isGlobal = (userType: number) => {
  return [userTypes.super_admin, userTypes.supreme_admin, userTypes.global_admin].includes(userType);
};

export const canSeeFinanceMenu = (userType: number) => {
  return [userTypes.super_admin, userTypes.supreme_admin, userTypes.global_admin, userTypes.admin, userTypes.location_admin, userTypes.instructor].includes(userType);
};

export const canSeeFinancials = (userType: number) => {
  return [userTypes.super_admin, userTypes.supreme_admin, userTypes.global_admin, userTypes.admin].includes(userType);
};

export const canSeeOrdersMenu = (userType: number) => {
  return [userTypes.super_admin, userTypes.supreme_admin, userTypes.global_admin, userTypes.admin, userTypes.location_admin].includes(userType);
};

export const phoneRegExp = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?[\d\s.-]{7,}$/;

export const usaStates = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
  'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
  'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
  'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

export const usTimezones = [
  'US/Indiana-Starke', 
  'US/Eastern', 
  'US/Pacific', 
  'US/Aleutian', 
  'US/Alaska', 
  'US/Hawaii', 
  'US/Michigan', 
  'US/Arizona',
  'US/East-Indiana', 
  'US/Central', 
  'US/Mountain',
  'US/Samoa',
];

export const timezonesMapping: { [key: string]: string } = {
  'US/Indiana-Starke': "America/Indiana/Knox",        // US/Indiana-Starke
  'US/Eastern': "America/New_York",                   // US/Eastern
  'US/Pacific': "America/Los_Angeles",                // US/Pacific
  'US/Aleutian': "America/Adak",                      // US/Aleutian
  'US/Alaska': "America/Anchorage",                   // US/Alaska
  'US/Hawaii': "Pacific/Honolulu",                    // US/Hawaii
  'US/Michigan': "America/Detroit",                   // US/Michigan
  'US/Arizona': "America/Phoenix",                    // US/Arizona
  'US/East-Indiana': "America/Indiana/Indianapolis",  // US/East-Indiana
  'US/Central': "America/Chicago",                    // US/Central
  'US/Mountain': "America/Denver",                    // US/Mountain
  'US/Samoa': "Pacific/Pago_Pago",                    // US/Samoa
};

export const heardAboutUsOptions = [
  'Google/Search Engine', 
  'School Program', 
  'Recommended by Friend/Familty', 
  'Social Media', 
  'Blog or Publication', 
  'Sticky Fingers Cooking Cookbooks', 
  'Other',
];

export const eventTypes = {
  mainInstructor: 'Main Instructor',
  substitute: 'Substitute',
  addInstructor: 'Additional Instructor',
  trainee: 'Trainee',
  observer: 'Observer',
};

export const eventTypeMapping: { [key: string]: string } = {
  'main_instructor_id': 'mainInstructor',
  'additional_instructor_id': 'addInstructor',
  'substitute_id': 'substitute',
  'observer_id': 'observer',
  'trainee_id': 'trainee',
};

export const eventColors: { [key: string]: string }  = {
  mainInstructor: '#2B4E64',
  substitute: '#F05924',
  addInstructor: '#B81F69',
  trainee: '#6098B8',
  observer: '#F7AB1B',
};

export const waitlistStatusColors: { [key: string]: string; } = {
  archived: '#2B4E64',
  denied: '#B81F69',
  expired: '#F05924',
  enrolled: '#6098B8',
  invited: '#B4C634',
  pending: '#333333',
};

export const gradeLevelOptions = ["Pre-K", "K", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"];

export const genderOptions = [{value: "male", name: "Male"}, {name: "Female", value: "female"}, {name: "Non Binary", value: "non_binary"}];

export const genderLookup = (key: string) => {
  switch (key) {
    case "male":
    case "Male":
    case "M":
    case "m":
      return "Male";
    case "female":
    case "Female":
    case "f":
    case "F":
      return "Female";
    case "non_binary":
      return "Non Binary";
    default:
      return "Unknown";
  }
}

export const allergyOptions = ["Dairy", "Egg", "Wheat/Gluten", "Nuts", "Vegetarian", "Other"];

export const allergySeverityOptions = ["Intolerant", "Moderate", "Very Severe"];

export const relationOptions = ["Parent/Guardian", "Step-parent", "Grandparent", "Family Member", "Caregiver", "Friend"];

export const pickupOptions = ["Parent/Caregiver Pick Up", "In School After Day Care Program", "Child Can Walk Home", "Other"];

export const timesheetOverrideTypes = ["Extra Food", "Food Allergies", "Photo/Quote Award", "Additional Mileage", "Referral Bonus", "Marketing/Flyer Delivery", "Administrative Work", "Other"]

export const instructor_roles = [
  'main_instructor_id',
  'additional_instructor_id',
  'substitute_id',
  'observer_id',
  'trainee_id'
];

export const instructorRolesMapping: { [key: string]: string } = {
  'instructor': 'Additional',
  'observer': 'Observer',
  'trainee': 'Trainee',
  'sub': 'Substitute',
  'substitute': 'Substitute',
};
