import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { dateToUTC, formatDate } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useGetExpenseQuery } from '../../../../../services/endpoints/finance/expense';
import { Expense } from '../../../../../types/finance.types';
import { expensesCategories, paymentMethods, expensesContext } from '../../../../../utils/consts/finance/finance';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';

export const ExpenseDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetExpenseQuery(+params.id!);
  const [ expense, setExpense] = useState<Expense>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setExpense(data.expense);
    }
  }, [data]);

  if (isLoading || !expense) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/finance/expenses')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Expenses List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Expense #${expense?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/finance/expenses/${expense?.id}/edit`)}
          >
            Edit expense
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/finance/expenses/${expense?.id}/archive`)}
          >
            Archive Expense
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/finance/expenses/${expense?.id}/unarchive`)}
          >
            Unarchive Expense
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/finance/expenses/${expense?.id}/delete`)}
          >
            Delete Expense
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Category:" text={expensesCategories[expense?.category]} />
            <LabelText label="Context:" text={expensesContext[expense?.context]} />
            <LabelText label="Admin:">
              <LinkCell
                content={expense?.admin?.full_name}
                url={`/admin/people/admins/${expense?.admin?.id}`}
                underline
              />
            </LabelText>
            <LabelText label="Session:">
              <LinkCell
                content={expense?.course?.title ?? 'No session title'}
                url={`/admin/schedule/sessions/${expense?.course?.id}`}
                underline
              />
            </LabelText>
            <LabelText label="Amount:" text={`$${expense?.amount.toFixed(2)}`} />
            <LabelText label="Check Number:" text={expense?.check_number ?? 'N/A'} />
            <LabelText label="Due On:" text={expense?.due_on ? dateToUTC(new Date(expense?.due_on), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Paid On:" text={expense?.paid_on ? dateToUTC(new Date(expense?.paid_on), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Payment Method:" text={paymentMethods[expense?.payment_method] ?? 'N/A'} />
            <LabelText label="Invoice Number:" text={expense?.invoice_number ?? 'N/A'} />
            <LabelText label="Notes:" text={expense?.notes ?? 'N/A'} />
            <LabelText label="Created At:" text={formatDate(new Date(expense?.created_at), 'MM/dd/yyyy h:mm a zzz')} />
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
