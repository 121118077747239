import {
  Col,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import { StepProps } from "./AddEditSteps";

export const StepsForm = ({
  touched,
  errors,
  values,
  setFieldValue,
}: StepProps) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    setFieldValue?.('file', file);
  }
  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Name
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="guide_id">
          <Form.Label>Guide</Form.Label>
          <Form.Control
            as="select"
            name="guide_id"
            value={values?.guide_id}
            onChange={(e) => setFieldValue!("guide_id", e.target.value)}
            isInvalid={touched.guide_id && !!errors.guide_id}
          >
            <option>Select</option>
            <option value="1">Log in.</option>
            <option value="2">Check your calendar.</option>
            <option value="3">See session details.</option>
            <option value="4">Request to teach.</option>
            <option value="5">Pick up a class.</option>
            <option value="6">Cancel an offer to sub.</option>
            <option value="7">Cancel a request for a sub.</option>
            <option value="8">Check your current timesheet and make note of additional expenses.</option>
            <option value="9">Review your past timesheets.</option>
          </Form.Control>
          <ErrorMessage name="guide_id">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Description</Form.Label>
          <Field
            name="description"
            type="text"
            as={Form.Control}
            isInvalid={touched.description && !!errors.description}
          />
          <ErrorMessage name="description">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      <Form.Group className="mb-1 mb-lg-3" controlId="total">
          <Form.Label>File</Form.Label>
            <Field
              name="file_name"
              type="file"
              as={Form.Control}
              onChange={handleFileChange}
              isInvalid={touched.file && !!errors.file}
            />
            <ErrorMessage name="file">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

    </Row>
  );
};
