import React from 'react';
import { Course } from '../../../types/course.type';
import { Stack } from 'react-bootstrap';
import { FaLocationDot } from 'react-icons/fa6';
import { CourseLink } from './CourseVenueDistance';

interface CourseVenueProps {
  course: Course;
  color: string;
  fillStyle: React.CSSProperties;
}

export const CourseVenue: React.FC<CourseVenueProps> = ({ course, color, fillStyle }) => {
  return (
    <Stack direction='horizontal' gap={1} >
      <FaLocationDot size={16} style={fillStyle} className={`${color}`}/>
      <CourseLink color={color} content={course.venue.title} url={`/admin/places/venues/${course.venue.id}`} /> 
    </Stack>
  )
}
