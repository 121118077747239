import React from 'react';
import { Badge } from 'react-bootstrap';

interface Props {
  value?: boolean;
}

const YesNoCell: React.FC<Props> = ({ value }) => {
  return (
    <Badge bg={value ? 'primary' : 'light'} >{value ? 'Yes' : 'No'}</Badge>
  );
};

export default YesNoCell;