import { Outlet } from 'react-router-dom';
import { DashNavbar } from '../../navbar/DashNavbar/DashNavbar';
import { FilterNavbar } from '../../navbar/FilterNavbar/FilterNavbar';
import { MimicHeader } from '../../navbar/MimicHeader';

export const DashLayout = () => {
  return (
    <>
      <MimicHeader />
      <DashNavbar />
      <FilterNavbar />
      <div className="content min-vh-100">
        <Outlet />
      </div>
    </>
  )
};
