import { useEffect, useState } from 'react';
import { useToast } from '../../../../context/ToastContext';
import { getTimesheetColumns } from '../../../../utils/consts/columnDefs/timesheetColumns';
import { useGetTimesheetEntriesQuery } from '../../../../services/endpoints/finance/timesheets';
import { getCurrentUser } from '../../../../services/helper';
import { isAdmin, isGlobal } from '../../../../utils/consts';
import SFCTable from '../../../../components/tables/SFCTable';
import { GetQueryParams } from '../../../../types/api.type';
import { useParams } from 'react-router-dom';
import { FilterField, FilterForm } from '../../../../components/tables/filterForms/FilterForm';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { useGetInstructorsQuery } from '../../../../services/endpoints/people/user';
import { DateField } from '../../../../components/tables/filterForms/DateField';
import { TimesheetIntro } from '../../../../components/generic';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { downloadIndex } from '../../../../services/api';
import { useDataContext } from '../../../../context';

export const TimesheetView = () => {
  const { timesheetId } = useParams();
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const currentUser = getCurrentUser();
  const isAdminUser = isAdmin(currentUser?.user_type_id!);
  const isGlobalUser = isGlobal(currentUser?.user_type_id!);
  const [filters, setFilters] = useState<GetQueryParams>({});
  const { data, error, isLoading, isFetching } = useGetTimesheetEntriesQuery({timesheetId: +(timesheetId ?? 0), filters}, {skip: !(timesheetId && filters)});
  const {data: instructors, error: instructorsError, isLoading: instructorsLoading} = useGetInstructorsQuery({data: {orderBy: {field: 'first_name', order: 'asc'}}}, {skip: !isAdminUser});

  const getFilterFormFields: () => FilterField[] = () => {
    
      const fields = [
      {
        name: 'id',
        label: 'ID',
        type: 'number',
      },
      {
        name: 'instructor_id',
        label: 'Instructor',
        type: 'number',
        element: (
          <FormSelect
            name="instructor_id"
            label="Instructor"
            options={instructors?.results.map(i => ({value: i.id, label: i.full_name})) ?? []}
            loadingError={instructorsError ? "Error loading instructors" : undefined}
          />
        )
      },
      {
        name: 'location_id',
        label: 'Location',
        type: 'number',
        element: (
          <FormSelect
            name="location_id"
            label="Location"
            options={locationOptions}
          />
        ),
      },
      {
        name: 'class_date',
        id: 'date_from',
        operator: 'greater_equal_than',
        element: (
          <DateField name="date_from" label="Class Date Start" />
        )
      },
      {
        name: 'class_date',
        id: 'date_to',
        operator: 'lesser_equal_than',
        element: (
          <DateField name="date_to" label="Class Date End" />
        )
      },
    ];

    if (!isAdminUser) {
      fields.splice(1, 1);
    }

    if (!isGlobalUser) {
      fields.splice(2 - (!isAdminUser ? 1 : 0), 1);
    }

    return fields;
  }

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Timesheet Entries`}
        columns={getTimesheetColumns(isAdminUser, isGlobalUser)}
        data={data?.results ?? []}
        count={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        onFiltersChanged={setFilters}
        defaultScope='all'
        filters={filters.data?.filterBy}
        indexDownloadPath={`timesheet-entries/${timesheetId}`}
        sidebars={[<TimesheetIntro />]}
        
        newItemEl={( timesheetId !== undefined && isAdminUser) ?
          <SimpleButton 
            onClick={() => downloadIndex({method: "GET", format: "xlsx", path: `timesheets/${timesheetId}`})} 
            height={40} 
            width={150}
            variant="primary">
            Export
          </SimpleButton> 
          : <></>
        }
        filterForm={(
          <FilterForm
            fields={getFilterFormFields()} 
            onApplyFilters={setFilterBy}
          />
        )}
      />
    </div>
  );
}
