import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TeamProfileForm } from './TeamProfileForm';
import { useCreateTeamProfileMutation, useGetTeamProfileQuery, useUpdateTeamProfileMutation } from '../../../../../services/endpoints/website/teamProfile';
import { ImageDetails, TeamProfile } from '../../../../../types/website.types';

const steps = [
  { name: 'Details', form: TeamProfileForm },
];

export interface FormValues extends ImageDetails {
  id?:                      number;
  name:                     string;
  location_id:              {label: string, value: number};
  description:              string;
  quote_type:               string;
  quote:                    string;
  title:                    string;
  is_hq:                    boolean;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditTeamProfileProps {
  editing?: boolean;
}

export const AddEditTeamProfile: React.FC<AddEditTeamProfileProps> = ({ editing }) => {
  const params = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search); 
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createTeamProfile] = useCreateTeamProfileMutation();
  const [updateTeamProfile] = useUpdateTeamProfileMutation();
  const { data, error, isLoading }  = useGetTeamProfileQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    location_id: {label: '', value: -1},
    description: '',
    quote_type: '',
    quote: '',
    title: '',
    is_hq: false,
    image_file_name: '',
  });

  const validationSchemas = [
    Yup.object({
      name: Yup.string().required('Name is required'),
      title: Yup.string().required('Title is required'),
      location_id: Yup.object({value: Yup.number().min(1, "Location is required").required("Location is required"), label: Yup.string().optional()}).required('Location is required'),
      is_hq: Yup.boolean().required().default(false),
      description: Yup.string().required('Description is required'),
      quote_type: Yup.string().required('Quote Type is required'),
      quote: Yup.string().required('Quote is required'),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading Team Profile', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.teamProfile) {
      const { teamProfile } = data;
      setInitialValues({
        id: teamProfile.id,
        name: teamProfile.name,
        title: teamProfile.title,
        location_id: {value: teamProfile.location_id, label: teamProfile.location?.title ?? ''},
        is_hq: teamProfile.is_hq ?? false,
        description: teamProfile.description,
        quote_type: teamProfile.quote_type,
        quote: teamProfile.quote,
        image_file_name: teamProfile.image_file_name,
        s3_base_url: teamProfile.s3_base_url,
      });
    }
  }, [data]);


  const handleSubmit = async (formValues: FormValues) => {
    try {
      const teamProfileData: TeamProfile = {
        ...formValues,
        location_id: formValues.location_id.value,
        image: formValues.image ? formValues.image : undefined,
      };
      delete teamProfileData.image_file_name;
      delete teamProfileData.s3_base_url;
      if (editing) {
        const res = await updateTeamProfile(teamProfileData).unwrap();
        if (res && res.teamProfile) {
          addToast(`Team Profile updated succesfully`, 'success');
          navigate(`/admin/website/team-profiles/${data?.teamProfile.id}`);
        }
      } else {
        const res = await createTeamProfile(teamProfileData).unwrap();
        if (res && res.teamProfile) {
          navigate(`/admin/website/team-profiles/${res.teamProfile.id}`);
          addToast('Team Profile created succesfully', 'success');
        } else throw new Error('A problem happened while creating Team Profile');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
        title: true,
        location_id: {value: true, label: true},
        is_hq: true,
        description: true,
        quote: true,
        quote_type: true,
        image_file_name: true,
        image: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to Team Profile${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/website/team-profiles/${data?.teamProfile.id}` : '/admin/website/team-profiles'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Team Profile
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
