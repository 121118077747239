import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { dateToUTC } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useGetInvoiceClientQuery } from '../../../../../services/endpoints/client/invoiceClient';
import { InvoiceClient } from '../../../../../types/finance.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import YesNoCell from '../../../../../components/tables/cellComponents/YesNoCell';

export const InvoiceClientDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetInvoiceClientQuery(+params.id!);
  const [ invoiceClient, setInvoiceClient] = useState<InvoiceClient>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setInvoiceClient(data.invoiceClient);
    }
  }, [data]);

  if (isLoading || !invoiceClient) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/invoice-clients')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Invoice Clients List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`InvoiceClient #${invoiceClient?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/invoice-clients/${invoiceClient?.id}/edit`)}
          >
            Edit Invoice Client
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/client/invoiceClients/${invoiceClient?.id}/delete`)}
          >
            Delete Invoice Client
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Name:" text={invoiceClient?.name} />
            <LabelText label="Created At:" text={invoiceClient?.created_at ? dateToUTC(new Date(invoiceClient?.created_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Invoiceable Type:" text={invoiceClient?.invoiceable_type} />
            <LabelText label="Invoiceable:">
              <LinkCell content={invoiceClient?.invoiceable_id ?? ''} url={`/admin/schedule/sessions/${invoiceClient?.invoiceable_id}`} /> 
            </LabelText> 
            <LabelText label="Is Personal:" >
              <YesNoCell value={invoiceClient?.is_personal} />  
            </LabelText>
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
