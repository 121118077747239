import React from 'react';
import { Form, Row, Col, Button, FormControl, Stack } from 'react-bootstrap';
import { HiOutlineAdjustmentsHorizontal } from 'react-icons/hi2';
import { endOfMonth, formatDate, startOfMonth } from 'date-fns';
import { dateInTimeZone } from '../../../../../utils/dateUtils';
import { sessionStatus, sessionTypeNumbers } from '../../../../../utils/consts/schedule/sessions';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { ErrorMessage, Field, Formik, Form as FormikForm, FormikProps } from 'formik';
import * as Yup from "yup";
import { LedgerParams } from '../../../../../types/place.types';
import { downloadIndex } from '../../../../../services/api';

interface FiltersProps {
  id: number;
  resource: string;
  onApplyFilters: (filters: LedgerParams) => void;
  disabled?: boolean;

}

const typeOptions = Object.entries(sessionTypeNumbers).map((s) => ({ label: s[1], value: +s[0] }));
const statusOptions = Object.entries(sessionStatus).map((s) => ({ label: s[1], value: s[0] }));

export const Filters: React.FC<FiltersProps> = ({ id, resource, onApplyFilters, disabled }) => {

  const getParams = (formValues: FormValues) => {
    return {
      ...formValues,
      id,
      course_statuses: formValues.course_statuses.map(cs => cs.value),
      course_types: formValues.course_types.map(ct => ct.value),
    }
  }

  const onExport = (values: FormValues) => {
    // TODO export ledger to CSV -- requires backend api
    downloadIndex({
      path: `${resource}/ledger`,
      format: 'csv',
      body: getParams(values),
      method: "POST",
    });
  };

  interface FormValues {
    start: string;
    finish: string;
    course_types: {value: number, label: string}[];
    course_statuses: {value: string, label: string}[],
    by?: 'starts_on'|'ends_on';
  }

  const initialValues: FormValues = {
    start: formatDate(dateInTimeZone(startOfMonth(new Date())),'yyyy-MM-dd'),
    finish: formatDate(dateInTimeZone(endOfMonth(new Date())),'yyyy-MM-dd'),
    course_types: typeOptions,
    course_statuses: statusOptions.filter(s => s.value === "open"),
    by: 'ends_on',
  };

  const validationSchema = Yup.object().shape({
    from: Yup.string().optional(),
    finish: Yup.string().optional(),
    course_types: Yup.array().of(
      Yup.object({
        value: Yup.string().required('Selection is missing value'), 
        label: Yup.string().optional()
      })).min(1, 'At least one type must be selected'),
    course_statuses: Yup.array().of(
      Yup.object({
        value: Yup.string().required('Selection is missing value'), 
        label: Yup.string().optional()
      })).min(1, 'At least one status must be selected'),
      by: Yup.string().oneOf(['starts_on', 'ends_on']).optional(),
  });

  const sendInstructions = (formValue: FormValues) => {
    const params = getParams(formValue);

    onApplyFilters(params);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendInstructions}
      >
        {({ errors, touched, values }: FormikProps<FormValues>) => (
          <FormikForm className="text-start">
            <Row className="ms-3 my-2">
              <Stack gap={2} direction='horizontal'>
                <Col xs={6} lg={2} className="pe-1">
                  <Stack gap={1}>
                    <Form.Group controlId="start">
                      <Form.Label>Start</Form.Label>
                      <Field
                        name="start"
                        type="date"
                        as={Form.Control}
                        isInvalid={touched.start && !!errors.start}
                      />
                      <ErrorMessage name="start">
                        {(msg) => (
                          <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group controlId="finish">
                      <Form.Label>End</Form.Label>
                      <Field
                        name="finish"
                        type="date"
                        as={Form.Control}
                        isInvalid={touched.finish && !!errors.finish}
                      />
                      <ErrorMessage name="finish">
                        {(msg) => (
                          <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                    <Stack gap={2} direction='horizontal'>
                      <Form.Group className="my-2" controlId="by">
                        <Stack direction='horizontal' gap={2} >
                          <Form.Label>By End Date</Form.Label>
                          <Field
                            name="by"
                            type="radio"
                            as={Form.Check}
                            value="ends_on"
                            isInvalid={touched.by && !!errors.by}
                          />
                          <Form.Label>By Start Date</Form.Label>
                          <Field
                            name="by"
                            type="radio"
                            as={Form.Check}
                            value="starts_on"
                            isInvalid={touched.by && !!errors.by}
                          />
                        </Stack>
                        <ErrorMessage name="by">
                          {(msg) => (
                            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Stack>       
                  </Stack>
                </Col>
                <Col xs={6} lg={3} className="pe-1">
                  <Stack gap={2}>
                    <FormSelect 
                      name={'course_types'} 
                      label={'Session Type'}
                      options={typeOptions}
                      multiple={true} 
                    />
                    <FormSelect 
                      name={'course_statuses'} 
                      label={'Session Status'}
                      options={statusOptions}
                      multiple={true} 
                    />
                    <Stack direction='horizontal' gap={2}>
                      <Button
                        type="submit"
                        variant="outline-primary"
                        className={`align-items-center text-uppercase`}
                        disabled={disabled}
                      >
                        <HiOutlineAdjustmentsHorizontal size={20} className="me-2" />
                        Filter
                      </Button>
                      <Button
                        type="button"
                        variant="primary"
                        onClick={() => onExport(values)}
                        className={`align-items-center text-uppercase`}
                      >
                        Export
                      </Button>
                    </Stack>
                  </Stack>
                </Col>
              </Stack>
            </Row>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};
