import { Container, Nav } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';
import './TableLayout.css';
import { NavItem } from '../../navbar/NavBarItems';
import React from 'react';

interface TableLayoutProps {
  title: string;
  tabs: NavItem[];
}

export const TableLayout: React.FC<TableLayoutProps> = ({ title, tabs }) => {
  const location = useLocation();

  return (
    <div className="px-0 px-lg-5">
      <h2
        className="d-block d-lg-none text-secondary text-uppercase px-3 py-2"
        style={{ background: '#EFEFEF', fontSize: '1.5rem', fontWeight: 900 }}
      >
        {title}
      </h2>
      <h2
        className="d-none d-lg-block text-info fw-bold pt-4 ps-2"
        style={{ fontSize: '2.25rem' }}
      >
        {title}
      </h2>

      <Container fluid>
        {tabs.length > 0 && (
          <Nav
            className="table-layout  my-2"
            activeKey={location.pathname}
            variant="underline"
          >
            {tabs.filter(t => !t.hidden).map((tab, idx: number) => (
              <Nav.Item key={idx}>
                <Nav.Link
                  as={Link}
                  className="me-1 p-1"
                  to={tab.url ?? "#"}
                  href={tab.url ?? "#"}
                >
                  {tab.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        )}
        <Outlet />
      </Container>
    </div>
  )
}
