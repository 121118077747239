import { 
  Button,
  CloseButton,
  Navbar,
} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { TbArrowBarLeft } from "react-icons/tb";
import './FilterNavbar.css';
import { useNavbar } from '../../../context/DashNavbarContext';

export const FilterNavbar = () => {
  const { activeNavbar, setActiveNavbar, filterNavContent, filterNavOverlap } = useNavbar();
  const [isOpen, setIsOpen] = useState(activeNavbar === 'filter');

  useEffect(() => {
    setIsOpen(activeNavbar === 'filter');
  }, [activeNavbar]);

  const toggleNavbar = (isOpen: boolean) => {
    if (isOpen) {
      setActiveNavbar('filter');
    } else {
      setActiveNavbar(null);  // Open the current navbar and close the other one if open
    }
  };

  if (!filterNavContent) return <></>;

  return (
    <Navbar expand={false} className={`d-block mb-3 filter-navbar py-0 ${isOpen ? 'open' : ''} ${filterNavOverlap ? 'cover-sidebars' : ''}`}>
      <Button
        variant="primary"
        onClick={() => toggleNavbar(!isOpen)}
        className="d-none d-lg-block btn-box-shadow border-0 fs-5 filter-navbar-toggle px-1 pt-0 pb-1 mt-4"
      >
        <TbArrowBarLeft style={{ transform: `rotate(${!isOpen ? 0 : 180}deg)`, transition: 'all 0.6s' }} />
      </Button>

      <div className="overflow-y-scroll p-3 h-100">
        <div className="d-flex justify-content-between border-bottom pb-1 mb-3">
          <h5 className="text-secondary fw-bold">Filter</h5>
          <CloseButton onClick={() => toggleNavbar(false)} />
        </div>
        {filterNavContent}
      </div>
    </Navbar>
  );
}
