import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { Lesson } from '../../../../../types/lesson.type';
import { useGetLessonsQuery } from '../../../../../services/endpoints/schedule/lesson';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { TimeField } from '../../../../../components/tables/filterForms/TimeField';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { classColumns } from '../../../../../utils/consts/columnDefs/classColumns';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from '../../../../../services/helper';
import { DetailSection, InstructorLegend, LabelText } from '../../../../../components/generic';
import { useDataContext } from '../../../../../context';

const defaultScope = 'upcoming';
const currentUser = getCurrentUser();

export const ClassesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading, isFetching } = useGetLessonsQuery(filters);
  const [scopes, setScopes] = useState<Scopes>({});
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const isGlobalUser = (currentUser?.isGlobal);
  const isAdminUser = (currentUser?.isAdmin);

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'date',
      id: 'starts_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="starts_on" label="Date" />
      )
    },
    {
      name: 'date',
      id: 'ends_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="ends_on" />
      )
    },
    {
      name: 'time',
      element: (
        <TimeField name="time" label="Time" />
      )
    },
    {
      name: 'location_id',
      operator: 'equals',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'course_title',
      element: (
        <SearchField name="course_title" label="Session" placeholder="Search session" />
      )
    },
    {
      name: 'instructor_full_name',
      element: (
        <SearchField name="instructor_full_name" label="Main Instructor" placeholder="Search Main Instructor" />
      )
    },
  ];


  if (!isGlobalUser) {
    // remove location filter
    filterFormFields.splice(-3,1);
  }

  const sidebars = [<InstructorLegend key="legend" />];

  if (isAdminUser) {
    sidebars.unshift((
      <DetailSection key={'details'} title="Summary">
        <LabelText label="# of Classes:" text={String(data?.count ?? 0)} />
        <LabelText label="# of Website Registrations:" text={String(data?.totals?.registrations ?? 0)} />
        <LabelText label="# of Onsite Enrollments:" text={String(data?.totals?.enrollments ?? 0)} />
      </DetailSection>
    ))
  }

  const [filterFields, setFilterFields] = useState<FilterField[]>(filterFormFields);

  useEffect(() => {
    if (params.get('starts_on') && params.get('ends_on')) {
      setFilters((prev) => ({
        ...prev,
        data: {
          ...prev!.data,
          filterBy: [
            {
              operator: 'greater_equal_than',
              field: 'date',
              value: params.get('starts_on')!,
            },
            {
              operator: 'lesser_equal_than',
              field: 'date',
              value: params.get('ends_on')!,
            },
          ],
        }
      }))
      setFilterFields(filterFormFields.map((filter) => {
        if (filter.id === 'starts_on' || filter.id === 'ends_on') {
          return {
            ...filter,
            value: params.get(filter.id)!,
          }
        } return filter;
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setLessons(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Classes`}
        columns={classColumns}
        data={lessons}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        sidebars={sidebars}
        indexDownloadPath='lessons'
        filters={filters.data?.filterBy}
        filterForm={(
          <FilterForm fields={filterFields} onApplyFilters={setFilterBy} />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
