import React, { CSSProperties } from 'react';
import { UTCTimeToLocal, formatDate } from '../../../../../utils/dateUtils';
import { BsClock } from 'react-icons/bs';
import { Course } from '../../../../../types/course.type';
import { ReactComponent as ChefHat } from '../../../../../assets/icons/chef-hat.svg';
import { Order } from '../../../../../types/orders.type';
import { Stack } from 'react-bootstrap';

export const StylePink: React.CSSProperties = {
  fill: `#BA1F69`,
}

export const StyleOrange: React.CSSProperties = {
  fill: `#d36a48`,
}

export const StyleGreen: React.CSSProperties = {
  fill: `#B4C634`,
}

export const course_type_style_lookup = (key: string) => {
  switch (key) {
    case 'after_school': return StylePink;
    case 'camp': return StyleOrange;
    case 'event': return StyleGreen;
    default: return {fill: 'black'};
  }
}

interface CourseDatesProps {
  startDate: Date;
  endDate?: Date;
}

export const CourseDates: React.FC<CourseDatesProps> = ({ startDate, endDate }) => {
  return (
    <span className="text-truncate">{formatDate(startDate)} {endDate ? `- ${formatDate(endDate)}` : ''}</span>
    )
  }

export const CourseCount: React.FC<{count: number, style?: CSSProperties}> = ({count, style}) => {
  return (
    <Stack direction="horizontal" gap={1} className="text-info">
      <ChefHat style={ style ?? {fill: 'currentColor', marginLeft: '2px', marginRight: '1px'}} />
      {count}
      <span>Classes</span>
    </Stack>
  );
}
  
interface CourseTimesProps {
  course: Course;
  dayOfWeek?: string;
}

export const CourseTimes: React.FC<CourseTimesProps> = ({course, dayOfWeek}) => {
  const times = course.formatted_time ? course.formatted_time.split(' - ') : [course.starts_at, course.ends_at];
  let start = UTCTimeToLocal(times[0]);
  let end = UTCTimeToLocal(times[1]);
  
  if ((start?.includes('AM') && end?.includes('AM')) 
    || (start?.includes('PM') && end?.includes('PM'))) {
      start = start.replace(/\sAM|\sPM/, '');
  }
  return (
    <Stack direction='horizontal' gap={1}>
      <BsClock />
      {dayOfWeek && (<span className="fw-light ff-inter">{dayOfWeek}</span>)}
      <span>{start} - {end}</span>
    </Stack>
  )
}

interface CourseDateTimesProps {
  order: Order;
}
export const OrderCourseCount: React.FC<CourseDateTimesProps> = ({order}) => {
  const {course, lesson_count} = order;
  const style = {
    transform: 'translateY(-2px)',
    ...course_type_style_lookup(course.course_type),
  }
  return (
    <CourseCount count={lesson_count ?? course.lessons?.length ?? 0} style={style} />
  )
}

export const OrderCourseDates: React.FC<CourseDateTimesProps> = ({order}) => {
  return (
    <CourseDates startDate={new Date(order.course.starts_on)} endDate={new Date(order.course.ends_on)} />
  )
}

export const OrderCourseTimes: React.FC<CourseDateTimesProps> = ({order}) => {
  return (
    <CourseTimes course={order.course} dayOfWeek={order.course.day_of_week} />
  )
}

export const OrderCourseDatesTimesCount: React.FC<CourseDateTimesProps> = ({order}) => {
  return (
    <Stack direction='horizontal'>
      <OrderCourseDates order={order} />
      <OrderCourseTimes order={order} />
      <OrderCourseCount order={order} />
    </Stack>
  )
}

