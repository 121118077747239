import { CreateFormValues as DiscountValues } from '../../../features/dash/views/orders/Discounts/AddEditDiscount';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Discount } from '../../../types/orders.type';
import { sfcApi } from '../../api';

export const discountApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDiscounts: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `discounts${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Discount']
    }),
    getDiscount: builder.query<{ discount: Discount }, number>({
      query: (id) => `discounts/${id}`,
      providesTags: (result, error, id) => [{ type: 'Discount', id }]
    }),
    createDiscount: builder.mutation<{ discount: Discount }, DiscountValues>({
      query: (data) => ({
        url: `discounts/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Discount']
    }),
    updateDiscounts: builder.mutation<{ discount: Discount }, { id: number, data: Partial<DiscountValues> }>({
      query: ({ id, data }) => ({
        url: `discounts/update/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Discount', id }, 'Discount']
    }),
    deleteDiscounts: builder.mutation<{ discount: Discount }, number>({
      query: (id) => ({
        url: `discounts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Discount', id }, 'Discount']
    }),
  }),
});

export const {
  useGetAllDiscountsQuery,
  useGetDiscountQuery,
  useCreateDiscountMutation,
  useUpdateDiscountsMutation,
  useDeleteDiscountsMutation,
} = discountApi;