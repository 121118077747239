import { ReactElement, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationModal from '../../components/modals/ConfirmationModal';

interface DeleteObjectWrapperProps {
  label: string;
  children: ReactElement;
}

export const DeleteObjectWrapper: React.FC<DeleteObjectWrapperProps> = ({ children, label }) => {
  const params = useParams();
  const loaded = useRef(false);
  const id = +params.id!;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(true);
  const [confirm, setConfirm] = useState<boolean>(false);

  if (showModal) {
    return (
      <ConfirmationModal
        show={showModal}
        title={`Delete ${label}`}
        message={`Are you sure you want to remove ${label} #${id}?`}
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => {
          setConfirm(() => {
            setShowModal(false);
            return true;
          });
        }}
        onCancel={() => setShowModal(false)}
      />
    );
  }
  else {
    if (!confirm) {
      if (!loaded.current) {
        console.log('navigating back');
        navigate(-1);
        loaded.current = true;
      }
      return <div>Error</div>;
    }
    else {
      return children ?? <div>Error</div>;
    }
  }
}
