import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../components/tables/cellComponents/LinkCell';
import { dateToUTC, formatDate } from '../../dateUtils';
import { Expense, Invoice, InvoiceItem, Payment, Receipt } from '../../../types/finance.types';
import { Badge } from '../../../components/badge/Badge';
import { expensesCategories, invoiceStatusColors, paymentMethods, receiptStatuses } from '../finance/finance';
import { Stack } from 'react-bootstrap';
import { Course } from '../../../types/course.type';
import { BsLink } from 'react-icons/bs';
import CurrencyCell from '../../../components/tables/cellComponents/CurrencyCell';
import { DateCell } from '../../../components/tables/cellComponents/DateCell';
import { getPartition } from '../../utils';

export const invoicesColumns: ColumnDef<Invoice>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Location',
    accessorKey: 'location_id',
    cell: ({ row }) => row.original.location?.title ?? 'N/A'
  },
  {
    header: 'Invoice Name',
    accessorKey: 'name',
  },
  {
    header: 'Sessions',
    enableSorting: false,
    cell: ({ row }) => (
      <div>
        {row.original.courses.map((course, idx) => (
          <LinkCell key={idx} content={course.title} url={`/admin/schedule/sessions/${course.id}`} />
        ))}
      </div>
    )
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ row }) => (
      <Badge title={row.original.status} color={invoiceStatusColors[row.original.status]} />
    )
  },
  {
    header: 'Issue On',
    accessorKey: 'issue_on',
    cell: ({ row }) => dateToUTC(new Date(row.original.issue_on), 'MM/dd/yyyy')
  },
  {
    header: 'Due On',
    accessorKey: 'due_on',
    cell: ({ row }) => dateToUTC(new Date(row.original.due_on), 'MM/dd/yyyy')
  },
  {
    header: 'Total',
    accessorKey: 'total',
    cell: ({ row }) => <CurrencyCell value={row.original.total} />,
  },
  {
    header: 'Amount Due',
    accessorKey: 'remaining_balance',
    cell: ({ row }) => <CurrencyCell value={row.original.remaining_balance} />,
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/finance/invoices/${row.original.id}`} underline />
        <LinkCell content="Edit" url={`/admin/finance/invoices/${row.original.id}/edit`} underline />
      </Stack>
    ),
  },
];

export const invoiceSessionsColumns: ColumnDef<Course>[] = [
  {
    header: 'Session ID',
    id: 'session_id',
    accessorKey: 'id',
  },
  {
    header: 'Session Name',
    id: 'session_name',
    accessorKey: 'title',
    cell: ({ row }) => <LinkCell content={row.original.title} url={`/admin/schedule/sessions/${row.original.id}`} />
  },
  {
    header: 'Amount Receivable',
    accessorKey: 'amount_receivable',
    cell: ({ getValue }) => <CurrencyCell value={getValue<number>()} />,
  },
  {
    header: 'Amount Invoiced',
    accessorKey: 'amount_invoiced',
    cell: ({ getValue }) => <CurrencyCell value={getValue<number>()} />,
  },
  {
    header: 'Invoicer',
    id: 'invoicer', // TODO: add invoicer tool link
    cell: ({ row }) => <LinkCell content={<BsLink size={20} />} url={``} />
  },
];

export const invoiceItemsColumns: ColumnDef<InvoiceItem>[] = [
  {
    header: 'ID',
    id: 'invoice_item_id',
    accessorKey: 'id',
  },
  {
    header: 'Description',
    accessorKey: 'description',
  },
  {
    header: 'Enrollment',
    accessorKey: 'enrollment_id',
    // TODO: add enrollment cell link here
  },
  {
    header: 'Unit Price',
    accessorKey: 'unit_price',
    cell: ({ getValue }) => <CurrencyCell value={getValue<number>()} />,
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
  },
  {
    header: 'Total',
    id: 'total',
    cell: ({ row }) => <CurrencyCell value={((row.original.quantity ?? 0) * row.original.unit_price)} />,
  },
  {
    header: () => null,
    id: 'invoice_item_actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/finance/invoice-items/${row.original.id}`} underline />
        <LinkCell content="Edit" url={`/admin/finance/invoice-items/${row.original.id}/edit`} underline />
        {/* <LinkCell content="Delete" url={`/admin/finance/invoice-items/${row.original.id}/delete`} underline /> */}
      </Stack>
    )
  },
];

export const expensesColumns: ColumnDef<Expense>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Invoice Number',
    accessorKey: 'invoice_number',
  },
  {
    header: 'Due On',
    accessorKey: 'due_on',
    cell: ({ getValue }) => dateToUTC(new Date(getValue<string>()), 'MM/dd/yyyy')
  },
  {
    header: `Total Amount Due`,
    accessorKey: 'total',
    cell: ({ getValue }) => <CurrencyCell value={getValue<number>()} />,
  },
  {
    header: 'Payment Method',
    accessorKey: 'payment_method',
    cell: ({ getValue }) => paymentMethods[getValue<string>()]
  },
  {
    header: 'Session',
    accessorKey: 'course_id',
    cell: ({ row }) => (
      <LinkCell content={row.original.course.title} url={`/admin/schedule/sessions/${row.original.course_id}`} />
    )
  },
  {
    header: 'Expense Tool Notes',
    accessorKey: 'notes',
  },
  {
    header: 'Facility Use Fee Payment Mailing Details',
    enableSorting: false,
    cell: ({ row }) => row.original.course.venue.facility_use_fee_payment_details ?? 'N/A'
  },
  {
    header: 'Category',
    accessorKey: 'category',
    cell: ({ getValue }) => expensesCategories[getValue<string>()]
  },
  {
    header: 'Admin',
    enableSorting: false,
    cell: ({ row }) => (
      <LinkCell content={row.original.admin.full_name} url="" /> 
    )
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/finance/expenses/${row.original.id}`} underline />
        <LinkCell content="Edit" url={`/admin/finance/expenses/${row.original.id}/edit`} underline />
        <LinkCell content="Delete" url={`/admin/finance/expenses/${row.original.id}/delete`} underline />
        {/* TODO: add unarchive link */}
      </Stack>
    )
  },
];

export const receiptsColumns: ColumnDef<Receipt>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/finance/receipts/${getValue<number>()}`} />
  },
  {
    header: 'Image',
    accessorKey: 'image_file_name',
    cell: ({ row }) => {
      let image_url = `${row.original.s3_base_url ?? ''}/${getPartition(row.original.id)}/thumb/${row.original.image_file_name}`;
      let content = <img src={image_url} />;
      return <LinkCell content={content} url={`/admin/finance/receipts/${row.original.id}`} />;
    }
  },
  {
    header: 'User',
    accessorKey: 'user.full_name',
    cell: ({ row, getValue }) => <LinkCell content={getValue<string>()} url={`/admin/sfc-users/${row.original.user.id}`} />,
  },
  {
    header: `Session`,
    accessorKey: 'course.title',
    cell: ({ row, getValue }) => <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.course?.id}`} />,
  },
  {
    header: 'Store Name',
    accessorKey: 'store_name',
  },
  {
    header: 'Date of Purchase',
    accessorKey: 'purchased_at',
    cell: ({ getValue }) => (
      <DateCell date={getValue<string>()} format={'MM/dd/yyyy'} />
    )
  },
  {
    header: 'Total',
    accessorKey: 'total',
    cell: ({ getValue }) => (<CurrencyCell value={getValue<number>()} />),
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue }) => {
      let index = getValue<number>();
      let status = index in receiptStatuses ? receiptStatuses[index] : "unknown";
      return status;
    }
  },
];

export const paymentsColumns: ColumnDef<Payment>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/finance/payments/${getValue<number>()}`} />
  },
  {
    header: 'Invoice Name',
    accessorKey: 'invoice.name',
    cell: ({ row }) => <LinkCell content={row.original.invoice?.name} url={`/admin/finance/invoices/${row.original.invoice?.id}`} />
  },
  {
    header: 'Invoice Client',
    accessorKey: 'invoice.invoice_client.name',
    cell: ({ row }) => <LinkCell content={row.original.invoice?.invoice_client.name} url={`/admin/invoice-clients/${row.original.invoice?.invoice_client.id}`} />
  },
  {
    header: 'Location',
    accessorKey: 'invoice.location.name',
    cell: ({ row }) => <LinkCell content={row.original.invoice?.location.title} url={`/admin/places/locations/${row.original.invoice?.location.id}`} />
  },
  {
    header: 'Payment Method',
    accessorKey: 'payment_method',
    cell: ({ getValue }) => (paymentMethods[getValue<string>()]),
  },
  {
    header: 'Check Number',
    accessorKey: 'check_number',
  },
  {
    header: `Total`,
    accessorKey: 'total',
    cell: ({ getValue }) => (<CurrencyCell value={getValue<number>()} />),
  },
  {
    header: 'Paid At',
    accessorKey: 'created_at',
    cell: ({ getValue }) => {
      return formatDate(getValue<Date>(), "MM/dd/yyyy hh:mm a");
    }
  },{
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/finance/payments/${row.original.id}`} underline />
        <LinkCell content="Edit" url={`/admin/finance/payments/${row.original.id}/edit`} underline />
      </Stack>
    )
  },
];