import { FC, ReactNode } from 'react'
import { animated, useSpring } from 'react-spring';

interface FadeInProps extends React.HTMLAttributes<HTMLDivElement> {
  duration?: number;
  delay?: number;
  children: ReactNode; 
}

export const FadeIn: FC<FadeInProps> = ({ duration = 500, delay, children, ...rest }) => {
  const styles = useSpring({
    from: {
      opacity: 0,
      // transform: "translate(0px, 20px)",
    },
    to: {
      opacity: 1,
      transform: "translate(0px, 0px)",
    },
    config: {
      duration,
    }, 
    delay,
  })
  return (
    <animated.div style={styles} {...rest}>
      {children}
    </animated.div>
  )
}
