import {
  Col,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import Editor from "../../../../../components/editor/Editor";
import { StepProps } from "./AddEditQuestion";

export const QuestionsForm = ({
  touched,
  errors,
  values,
  setFieldValue,
}: StepProps) => {

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Name
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Field
            name="name"
            type="text"
            as={Form.Control}
            isInvalid={touched.name && !!errors.name}
          />
          <ErrorMessage name="name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-10 mb-lg-3" controlId="details">
          <Form.Label>Answer</Form.Label>
          <Field
            name="answer"
            component={Editor}
            className="form-control"
            isInvalid={touched.answer && !!errors.answer}
          />
          <ErrorMessage name="answer">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="is_public">
          <Form.Label>Question Type</Form.Label>
          <Form.Control
            as="select"
            name="question_type"
            value={values?.question_type}
            onChange={(e) => setFieldValue!("question_type", e.target.value)}
            isInvalid={touched.question_type && !!errors.question_type}
          >
            <option>Select</option>
            <option value="online">Online</option>
            <option value="in_person">In person</option>
            <option value="private_event">Private event</option>
            <option value="class_theme_descriptions">
              Class theme descriptions
            </option>
          </Form.Control>
          <ErrorMessage name="is_public">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
    </Row>
  );
};
