import { Button } from 'react-bootstrap';
import { FC, ReactNode } from 'react';
import { FaLocationDot } from "react-icons/fa6";

interface LocationButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  height?: number;
  width?: number;
  size?: 'lg' | 'sm' | undefined;
  children: ReactNode,
  href?: string,
}

export const LocationButton: FC<LocationButtonProps> = ({ height, width, size, children, ...rest }) => {
  
  return (
    <Button
      size={size}
      {...rest}
      style={{ height: height ?? 52, width: width ?? 'auto', backgroundColor: '#6098B8', borderColor: '#6098B8', borderRadius: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      className={`${rest.className} text-uppercase text-truncate`}
    >
      <FaLocationDot size={16} color='#fff' className='me-2'/>
      {children}      
    </Button>
  )
}
