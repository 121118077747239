import { FormValues as InvoiceItemValues } from '../../../features/dash/views/finance/InvoiceItems/AddEditInvoiceItem';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { InvoiceItem } from '../../../types/finance.types';
import { sfcApi } from '../../api';

export const invoiceItemApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoiceItems: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `invoice-items${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['InvoiceItem'],
    }),
    getInvoiceItem: builder.query<{ invoiceItem: InvoiceItem }, number>({
      query: (id) => `invoice-items/${id}`,
      providesTags: (result, error, id) => [{ type: 'InvoiceItem', id }]
    }),
    createInvoiceItem: builder.mutation<{ createdInvoiceItem: InvoiceItem }, InvoiceItemValues>({
      query: (data) => ({
        url: 'invoice-items/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: (result, error, { id, invoice_id }) =>
        ['InvoiceItem', { type: 'Invoice', id: invoice_id }, 'Invoice']
    }),
    updateInvoiceItem: builder.mutation<{ invoice: InvoiceItem }, InvoiceItemValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `invoice-items/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error, { id, invoice_id }) =>
        [{ type: 'InvoiceItem', id }, 'InvoiceItem', { type: 'Invoice', id: invoice_id }, 'Invoice']
    }),
    deleteInvoiceItem: builder.mutation<{ invoice: InvoiceItem }, number>({
      query: (id) => ({
        url: `invoice-items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) =>
        [{ type: 'InvoiceItem', id }, 'InvoiceItem', { type: 'Invoice', id: result?.invoice?.invoice_id }]
    }),
  }),
});

export const {
  useGetAllInvoiceItemsQuery,
  useGetInvoiceItemQuery,
  useCreateInvoiceItemMutation,
  useUpdateInvoiceItemMutation,
  useDeleteInvoiceItemMutation,
} = invoiceItemApi;