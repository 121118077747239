import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { kitchenSkill } from '../../../types/kitchenSkills.type';
import { sfcApi } from '../../api';
export interface KitchenSkillCreateUpdateValues {
    id?: number;
    name: string;
    description:string;
  }
export const kitchenSkillApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getKitchenSkills: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `kitchen-skills${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    
    getKitchenSkill: builder.query<{ kitchenSkill: kitchenSkill }, number>({
      query: (id) => `kitchen-skills/${id}`,
    }),

    createKitchenSkill: builder.mutation<{ kitchenSkill: kitchenSkill }, KitchenSkillCreateUpdateValues>({
      query: (data) => {
        return {
          url: 'kitchen-skills/create',
          method: 'POST',
          body: {
            ...data
          },
        }
      },
      invalidatesTags: ['KitchenSkills']
    }),

    updateKitchenSkill: builder.mutation<{ kitchenSkill: kitchenSkill }, KitchenSkillCreateUpdateValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `kitchen-skills/update/${id}`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
      invalidatesTags: ['KitchenSkills']

    }),

    deleteKitchenSkill: builder.mutation<{ kitchenSkill: kitchenSkill }, number>({
      query: (id) => ({
        url: `kitchen-skills/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetKitchenSkillsQuery,
  useGetKitchenSkillQuery,
  useCreateKitchenSkillMutation,
  useUpdateKitchenSkillMutation,
  useDeleteKitchenSkillMutation
} = kitchenSkillApi;