import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { ReactComponent as SFCLogoLarge } from '../../assets/icons/sfc-logo-large.svg';
import WelcomeBanner from '../../assets/images/welcome-banner.png';

export function UserLayout() {
  return (
    <Container fluid className="text-center">
      <Row className="vh-100">
        <Col
          className="d-none d-lg-block vh-100 p-5" 
          lg={4}
          style={{
            background: `linear-gradient(180deg, rgba(96, 152, 184, 0) 10%, rgba(96, 152, 184, 0.5) 60%, rgba(43, 78, 100, 0.5) 100%), url(${WelcomeBanner})`,
            backgroundPositionY: 0,
            backgroundPositionX: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundBlendMode: 'overlay',
          }}
        >
          <SFCLogoLarge style={{ width: '100%' }} />
        </Col>
        <Col className="align-self-center">
          <Outlet />
        </Col>
      </Row>
    </Container>
  )
};
