import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditChild';
import { genderOptions, gradeLevelOptions, pickupOptions } from '../../../../../utils/consts';

export const ChildForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="first_name">
            <Form.Label>First Name</Form.Label>
            <Field
              name="first_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.first_name && !!errors.first_name}
            />
            <ErrorMessage name="first_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="last_name">
            <Form.Label>Last Name</Form.Label>
            <Field
              name="last_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.last_name && !!errors.last_name}
            />
            <ErrorMessage name="last_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="dob">
            <Form.Label>Dob (Optional)</Form.Label>
            <Field
              name="dob"
              type="text"
              as={Form.Control}
              isInvalid={touched.dob && !!errors.dob}
            />
            <ErrorMessage name="dob">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="grade_level">
            <Form.Label>Grade</Form.Label>
            <Field
              name="grade_level"
              as={Form.Select}
              isInvalid={touched.grade_level && !!errors.grade_level}
            >
              <option>Select</option>
              {gradeLevelOptions.map((opt, idx) => (
                <option key={idx} value={opt}>{opt}</option>
              ))}
            </Field>
            <ErrorMessage name="grade_level">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="gender">
            <Form.Label>Gender</Form.Label>
            <Field
              name="gender"
              as={Form.Select}
              isInvalid={touched.gender && !!errors.gender}
            >
              <option>Select</option>
              {genderOptions.map((opt, idx) => (
                <option key={idx} value={opt.value}>{opt.name}</option>
              ))}
            </Field>
            <ErrorMessage name="gender">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="emotional_needs">
            <Form.Label>Emotional Needs (Optional)</Form.Label>
            <Field
              name="emotional_needs"
              as="textarea"
              rows={3}
              className="form-control"
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="parent_guardian">
            <Form.Label>Parent Guardian (Optional)</Form.Label>
            <Field
              name="parent_guardian"
              as="textarea"
              rows={3}
              className="form-control"
            />
          </Form.Group>

        </Col>

        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="emergency_contacts">
            <Form.Label>Emergency Contacts (Optional)</Form.Label>
            <Field
              name="emergency_contacts"
              as="textarea"
              rows={4}
              className="form-control"
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="pickup_type">
            <Form.Label>Pickup Type</Form.Label>
            <Field
              name="pickup_type"
              as={Form.Select}
              isInvalid={touched.pickup_type && !!errors.pickup_type}
            >
              <option>Select</option>
              {pickupOptions.map((opt, idx) => (
                <option key={idx} value={opt}>{opt}</option>
              ))}
            </Field>
            <ErrorMessage name="pickup_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="pickup_other">
            <Form.Label>Pickup Other (Optional)</Form.Label>
            <Field
              name="pickup_other"
              as="textarea"
              rows={3}
              className="form-control"
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="origination">
            <Form.Label>Teacher/Classroom (Optional)</Form.Label>
            <Field
              name="origination"
              as="textarea"
              rows={3}
              className="form-control"
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="social_emotional_needs">
            <Form.Label>Social Emotional Needs (Optional)</Form.Label>
            <Field
              name="social_emotional_needs"
              as="textarea"
              rows={3}
              className="form-control"
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="agree_to_terms">
            <Form.Label>
              <Field
                name="agree_to_terms"
                type="checkbox"
                as={Form.Check}
                label="Agree to terms"
              />
            </Form.Label>
          </Form.Group>

        </Col>
      </Row>
    </>
  )
}
