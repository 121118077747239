import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { paymentsColumns } from '../../../../../utils/consts/columnDefs/financeColumns';
import { useGetAllPaymentsQuery } from '../../../../../services/endpoints/finance/payment';
import { Payment } from '../../../../../types/finance.types';
import { getCurrentUser } from '../../../../../services/helper';
import { paymentMethodNumbers } from '../../../../../utils/consts/finance/finance';
import { DetailSection, LabelText } from '../../../../../components/generic';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';
import { useDataContext } from '../../../../../context';

const defaultScope = 'all';
const currentUser = getCurrentUser();

export const PaymentsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetAllPaymentsQuery(filters);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const isGlobalUser = (currentUser?.isGlobal);
  const userCanSeeFinancials = (currentUser?.canSeeFinancials);
  // TODO: add invoice_clients endpoint to query from
  const { data: invoiceClients, error: invoiceClientsError, isLoading: invoiceClientsLoading } = useGetInvoiceClientsQuery();

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'total',
      label: 'Total',
      type: 'number',
    },
    {
      name: 'location_id',
      type: 'number',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'invoice_client',
      type: 'number',
      element: (
        <FormSelect
          name="invoice_client"
          label="Invoice Client"
          options={invoiceClients?.results?.map((ic: {id: number, title: string}) => ({ value: ic.id, label: ic.title })) ?? []}
          loadingError={invoiceClientsError ? "Error loading Invoice Clients" : undefined}
        />
      ),
    },
    {
      name: 'payment_method',
      type: 'number',
      element: (
        // <FormSelect
        //   name="payment_method"
        //   label="Payment Method"
        //   options={Object.entries(paymentMethodNumbers).map((p) => ({ value: p[0], label: p[1] }))}
        // />
        <Form.Group controlId="payment_method">
          <Form.Label>Payment Method</Form.Label>
          <Field
            as={Form.Select}
            name="payment_method"
          >
            {Object.entries(paymentMethodNumbers).map((op) => (
              <option key={op[0]} value={op[0]} className="text-capitalize">
                {op[1]}
              </option>
            ))}
          </Field>
        </Form.Group>
      ),
    },
    {
      name: 'check_number',
      label: 'Check Number',
      type: 'number',
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At" />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="created_at_to" />
      )
    },
  ];

  if (!isGlobalUser) {
    filterFormFields.splice(4, 1);
  }

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setPayments(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Payments`}
        columns={paymentsColumns}
        data={payments}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='payments'
        sidebars={userCanSeeFinancials ? [
          <DetailSection title="Summary" >
            <LabelText label="Total:">
              <CurrencyCell value={data?.totals?.total ?? 0} />
            </LabelText>
          </DetailSection>] : []}
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}

//TODO: delete this
function useGetInvoiceClientsQuery(): { data: any; error: any; isLoading: any; } {
  return { data: {results: [{id: 1, title: "A client"}]}, error: false, isLoading: false}
}

