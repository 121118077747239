import { FormValues as PromoCodesValues } from '../../../features/dash/views/orders/PromotionalCodes/AddEditPromotionalCode';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { PromotionalCode } from '../../../types/orders.type';
import { sfcApi } from '../../api';

export const promotionalCodeApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPromotionalCodes: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `promotional-codes${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['PromotionalCode']
    }),
    getPromotionalCode: builder.query<{ promotionalCode: PromotionalCode }, number>({
      query: (id) => `promotional-codes/${id}`,
      providesTags: (result, error, id) => [{ type: 'PromotionalCode', id }]
    }),
    searchPromotionalCode: builder.query<{ promotionalCode: PromotionalCode }, string>({
      query: (code) => `promotional-codes/search?code=${code}`,
    }),
    createPromotionalCode: builder.mutation<{ promotionalCode: PromotionalCode }, PromoCodesValues>({
      query: (data) => ({
        url: `promotional-codes/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['PromotionalCode']
    }),
    updatePromotionalCode: builder.mutation<{
      promotionalCode: PromotionalCode },
      { id: number, data: Partial<PromoCodesValues>
    }>({
      query: ({ id, data }) => ({
        url: `promotional-codes/update/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'PromotionalCode', id }, 'PromotionalCode']
    }),
    deletePromotionalCode: builder.mutation<{ promotionalCode: PromotionalCode }, number>({
      query: (id) => ({
        url: `promotional-codes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PromotionalCode', id }, 'PromotionalCode']
    }),
  }),
});

export const {
  useGetAllPromotionalCodesQuery,
  useGetPromotionalCodeQuery,
  useLazySearchPromotionalCodeQuery,
  useCreatePromotionalCodeMutation,
  useUpdatePromotionalCodeMutation,
  useDeletePromotionalCodeMutation,
} = promotionalCodeApi;