import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  useGetThemeQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation,
  } from '../../../../../services/endpoints/content/theme';
import { LessonPlanThemesForm, ThemeLessonPlansSaveForm } from '../../../../../types/theme.type';
import { ThemeForm } from './ThemesForm';

const steps = [
  { name: 'Details', form: ThemeForm },
];

export interface FormValues {
  id?: number;
  name: string;
  is_public?: string;
  details:string;
  lesson_plans: LessonPlanThemesForm[];

}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;
export type CreateFormValues = Overwrite<FormValues, {
  lesson_plans: ThemeLessonPlansSaveForm[], 
}>

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

const themeLinksMap = (id?: number | string) => {
    return {
        edit: `/admin/content/themes/${id}/edit`,
        detail: `/admin/content/themes/${id}`,
        list: `/admin/content/themes`,
    }
}

interface AddEditThemeProps {
  editing?: boolean;
}

export const AddEditTheme: React.FC<AddEditThemeProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createTheme] = useCreateThemeMutation();
  const [updateTheme] = useUpdateThemeMutation();
  
  const { data: themeData, error: themeError, isLoading: themeLoading } = 
  useGetThemeQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    is_public: 'true',
    details:'',
    lesson_plans:[]

   
  });

  const validationSchemas = [
    Yup.object({

      name: Yup.string().required('Name is required'),
      is_public: Yup.string().required('Publish Status is required'),
      details: Yup.string().required('Description is required'),
    }),
  ];

  useEffect(() => {
    if (editing && themeError && !themeLoading) {
      addToast('Error while loading admin', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, themeError, themeLoading]);

  useEffect(() => {
    let values;
    if (themeData && themeData?.theme) {
      values = themeData.theme;
    }

    if (values?.name)
      setInitialValues({
        id: values.id,
        name: values.name,
        is_public: values.is_public ?? 'false',
        details: values.details,
        lesson_plans: values.lesson_plans.map(lp => {
          let ret: LessonPlanThemesForm = {
            id: {value: lp.id, label: lp.name},
            featured_on: lp.theme_lesson_plans.featured_on,
            name: lp.name,
            purpose: lp.theme_lesson_plans.purpose,
          }
          return ret;
        }),
      });

  }, [themeData]);

  const formatFormValue = (formValue?: FormValues ): CreateFormValues => {
    return JSON.parse(JSON.stringify(formValue)) as CreateFormValues;
  }
  
  const handleAddTheme = async (formValue: FormValues) => {
    try {
      let submission = formatFormValue(formValue)
      //TODO: lessonPlan is working, but it's not saving, 
      // check how to fix: {"error":["\"lessonPlans\" is not allowed"]}

      // submission.lesson_plans = formValue.lesson_plans.map(r => {
      //   let ret: LessonPlanThemesForm = {
      //     id: r.id,
      //     name: r.name,
      //     featured_on:r.featured_on
      //   }
      //   return ret;
      // })

      if (editing) {
        const res = await updateTheme({
          ...submission,
          id: Number(params.id)!,
        }).unwrap();
        if (res && res.theme) {
          navigate(themeLinksMap(res.theme.id!)?.list);
          addToast(`Lessons Plan updated succesfully`, 'success');
        }
      } else {
        const res = await createTheme(submission).unwrap();
        if (res && res.theme) {
          navigate(themeLinksMap(res.theme.id!)?.list);
          addToast('Lessons Plan created succesfully', 'success');
        } else throw new Error('A problem happened while creating Lessons Plan');
      }
    } catch (e) {
      if(e.data && e.data.error) {
        addToast(e.data.error[0], 'error');
      }
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
        is_public: true,
        details: true,
        
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text="Back"
            color="#2B4E64"
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Theme
          </h2>
          {steps.length > 1 && <Stepper editing steps={steps} currentStep={currentStep} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddTheme}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
