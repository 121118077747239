import React from 'react';
import { Calendar, CalendarProps, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Event, eventStyleGetter } from '../SFCCalendar/SFCCalendar';
import Toolbar from '../SFCCalendar/CustomComponents/Toolbar';
import './SchedulerCalendar.css';
import { format, getDay, parse, startOfWeek } from 'date-fns';
import { enUS } from 'date-fns/locale/en-US';
import { formatDate, dateInTimeZone } from '../../../utils/dateUtils';

const locales = {
  'en-US': enUS,
};

// Create the localizer
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface SchedulerCalendarProps {
  daysOfWeek: number[];
  startDate?: Date;
  endDate?: Date;
  noClassDates: string[];
}

export const SchedulerCalendar: React.FC<SchedulerCalendarProps> = ({ daysOfWeek, noClassDates, startDate, endDate }) => {

  const dayPropGetter: CalendarProps<Event>['dayPropGetter'] = (date) => {
    const currentDate = dateInTimeZone(date);
    if (noClassDates.includes(formatDate(currentDate, 'yyyy-MM-dd'))) {
      return {
        style: {
          backgroundColor: '#B81F69',
          boxShadow: `-1px -1px 4px 0 rgba(0, 0, 0, .25) inset, 1px 1px 4px 1px rgba(255, 255, 255, .35) inset`,
        },
      };
    }
    if (daysOfWeek.includes(date.getDay())
      && currentDate >= startDate!
      && currentDate <= endDate!) {
      return {
        style: {
          backgroundColor: '#B4C634',
          boxShadow: `-1px -1px 4px 0 rgba(0, 0, 0, .25) inset, 1px 1px 4px 1px rgba(255, 255, 255, .35) inset`,
        },
      };
    }
    return {};
  };

  return (
    <div className="p-4 m-auto" style={{ background: '#FAFAFA', maxWidth: 400 }}>
      <Calendar<Event>
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        className="scheduler-calendar"
        views={['month']} // Only show the month view
        defaultView="month" // Set the default view to month
        style={{ height: 400, background: '#FAFAFA' }}
        components={{
          toolbar: Toolbar,
          month: {
            header: (props) => <>{localizer.format(props.date, 'EE')}</>,
          },
        }}
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};
