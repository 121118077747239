import { FadeIn } from '../animations/FadeIn';
import { Form, FormControl, Image, InputGroup, Stack } from "react-bootstrap";
import { Formik, ErrorMessage, Form as FormikForm, FormikProps, Field } from "formik";
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { SimpleButton } from '../../components/buttons/SimpleButton';
import * as Yup from "yup";
import React, { useState } from "react";
import SFCLogoMD from '../../assets/images/sticky-fingers-logo-stacked-2.png';
import { useResetPasswordMutation } from "../../services/endpoints/people/user";
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { getErrorMessage } from '../../utils/utils';

type Props = {}

interface FormValues {
  password: string,
  confirm_password: string,
}

const ForgotPassword: React.FC<Props> = () => {
  const navigate: NavigateFunction = useNavigate();
  const {token} = useParams();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

  const [resetPassword] = useResetPasswordMutation();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  


  const initialValues: FormValues = {
    password: "",
    confirm_password: ""
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const sendInstructions = (formValue: FormValues) => {
    const { password } = formValue;

    setLoading(true);
    setMessage("");
    if (token) {
      resetPassword({password, token }).unwrap().then((response) => {
        setSuccessMessage(response.message);
      }).catch((error) => {
        setMessage(getErrorMessage(error));
      });
    }
    else {
      setMessage("Invalid or missing token!")
    }
    setLoading(false);
  };

  return (
    <FadeIn className="d-flex flex-column flex-wrap align-content-center">
      <div className="pb-4">
        <Image fluid className="d-block d-sm-none" src={SFCLogoMD} alt="Sticky Fingers Logo" />
        <Image className="d-none d-sm-inline-flex" src={SFCLogoMD} style={{ maxWidth: 343 }} alt="Sticky Fingers Logo" />
      </div>

      <h2 className="text-dark fw-bold mt-4 mt-lg-5 text-start text-md-center" style={{ fontSize: 32 }}>
        Reset password
      </h2>
      <Stack className="w-100 mt-3" style={{ maxWidth: 480 }} gap={1}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={sendInstructions}
        >
          {({ errors, touched }: FormikProps<FormValues>) => (
            <FormikForm className="text-start">

              <Form.Group className="mb-1 mb-lg-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Field
                    name="password"
                    type={passwordVisible ? 'text' : 'password'}
                    as={Form.Control}
                    isInvalid={touched.password && !!errors.password}
                  >
                  </Field>
                  <InputGroup.Text
                    style={{ cursor: 'pointer' }}
                    className="text-primary"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {
                      passwordVisible ? (
                        <IoEyeOffOutline style={{ fontSize: 20 }} />
                      ) : (
                        <IoEyeOutline style={{ fontSize: 20 }} />
                      )
                    }
                  </InputGroup.Text>
                  <ErrorMessage name="password">
                    {(msg) => (
                      <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                    )}
                  </ErrorMessage>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-1 mb-lg-3" controlId="confirm_password">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Field
                    name="confirm_password"
                    type={confirmPasswordVisible ? 'text' : 'password'}
                    as={Form.Control}
                    isInvalid={touched.confirm_password && !!errors.confirm_password}
                  >
                  </Field>
                  <InputGroup.Text
                    style={{ cursor: 'pointer' }}
                    className="text-primary"
                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  >
                    {
                      confirmPasswordVisible ? (
                        <IoEyeOffOutline style={{ fontSize: 20 }} />
                      ) : (
                        <IoEyeOutline style={{ fontSize: 20 }} />
                      )
                    }
                  </InputGroup.Text>
                  <ErrorMessage name="confirm_password">
                    {(msg) => (
                      <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                    )}
                  </ErrorMessage>
                </InputGroup>
              </Form.Group>

              <SimpleButton type="submit" className="mt-3 w-100" variant="primary" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Reset Password
              </SimpleButton>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}

              {successMessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                </div>
              )}
            </FormikForm>
          )}
        </Formik>
        <div hidden={successMessage === ""} >
          <div className="my-3 d-flex justify-content-center align-items-center">
            <span className="ff-inter fs-small text-secondary">Ready to login?</span>
            <button
              onClick={() => navigate('/users/login')}
              className="btn btn-link p-0 ms-2 fs-small fw-bolder text-uppercase link-primary link-underline-opacity-0"
            >
              Sign In
            </button>
          </div>
        </div>
      </Stack>
    </FadeIn>
  );
};

export default ForgotPassword;
