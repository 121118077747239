import { Col, Form, FormControl, Row, Stack } from "react-bootstrap";
import { StepProps } from "../AddEditRecipe";
import { Field } from "formik";

export const RecipeStatsForm = ({
  touched,
  errors,
  values,
  setFieldValue,
  readOnly,
}: StepProps) => {
  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Recipe Stats
      </h6>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          {/* Cook Time  */}
          <Form.Group controlId="cook_time">
            <Form.Label>Cook time</Form.Label>
            <Field
              name="cook_time"
              type="number"
              as={Form.Control}
              isInvalid={touched.cook_time && !!errors.cook_time}
              disabled={readOnly}
            />
          </Form.Group>
          {/* Prep time */}
          <Form.Group controlId="prep_time">
            <Form.Label>Prep time</Form.Label>
            <Field
              name="prep_time"
              type="number"
              as={Form.Control}
              isInvalid={touched.prep_time && !!errors.prep_time}
              disabled={readOnly}
            />
          </Form.Group>
        </Stack>
      </Col>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          {/* Min served */}
          <Form.Group controlId="min_served">
            <Form.Label>Min served</Form.Label>
            <Field
              name="min_served"
              type="number"
              as={Form.Control}
              isInvalid={touched.min_served && !!errors.min_served}
              disabled={readOnly}
            />
          </Form.Group>
          {/* Max served */}
          <Form.Group controlId="max_served">
            <Form.Label>Max served</Form.Label>
            <Field
              name="max_served"
              type="number"
              as={Form.Control}
              isInvalid={touched.max_served && !!errors.max_served}
              disabled={readOnly}
            />
          </Form.Group>
        </Stack>
      </Col>
    </Row>
  );
};
