import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import CheckmarkCell from '../../../../../components/tables/cellComponents/CheckmarkCell';
import { formatDate } from '../../../../../utils/dateUtils';
import { useDeleteChildMutation, useGetChildQuery } from '../../../../../services/endpoints/people/child';
import { Child } from '../../../../../services/endpoints/people/user';

export const ChildDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetChildQuery(+params.id!);
  const [child, setChild] = useState<Child>();
  const [deleteChild] = useDeleteChildMutation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading promotional code', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setChild(data.child);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  if (!child) return <></>;

  const handleDelete = async () => {
    try {
      await deleteChild(Number(params.id!));
      addToast('Child deleted successfully', 'success');
      navigate(`/admin/sfc-users/children`);
    } catch (e) {
      addToast('Error while deleting child', 'error');
    } 
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/sfc-users/children')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Children List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">
            {child?.first_name} {child?.last_name}
          </h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/sfc-users/children/${child.id}/edit`)}
          >
            Edit child
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete child
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="First Name:" text={child?.first_name} />
            <LabelText label="Last Name:" text={child?.last_name} />
            <LabelText label="Dob:" text={child?.dob} />
            <LabelText label="Dob formatted:" text={child?.dob_formatted ?? 'N/A'} />
            <LabelText label="Parent Guardian:" text={child?.parent_guardian ?? 'N/A'} />
            <LabelText label="Emergency Contacts:" text={child?.emergency_contacts ?? 'N/A'} />
            <LabelText label="Pickup Type:" text={child?.pickup_type ?? 'N/A'} />
            <LabelText label="Pickup Other:" text={child?.pickup_other ?? 'N/A'} />
            <LabelText label="Teacher/Classroom:" text={child?.origination ?? 'N/A'} /> 
            <LabelText label="Social Emotional Needs:" text={child?.social_emotional_needs} /> 
            <LabelText label="Allergies:" >
              <Stack>
                {child?.allergies.map(a => (
                  <LabelText label={a.allergy}>
                    <b>{a.severity}</b>&nbsp;
                    <span>{a.details}</span>
                  </LabelText>
                ))}
              </Stack>
            </LabelText>
            <LabelText label="Agree to terms:">
              <CheckmarkCell value={child?.agree_to_terms} />
            </LabelText>
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Discount"
        message="Are you sure you want to remove this discount?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => handleDelete()}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
