import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { dateToUTC } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import { Recipe } from '../../../../../types/lessonPlan.type';
import { useGetRecipeQuery } from '../../../../../services/endpoints/content/recipe';

export const RecipeDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetRecipeQuery(+params.id!);
  const [ recipe, setRecipe] = useState<Recipe>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading recipe', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setRecipe(data.recipe);
    }
  }, [data]);

  if (isLoading || !recipe) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: "#EFEFEF" }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button
            className="btn btn-link p-0 text-info"
            onClick={() => navigate("/admin/content/recipes")}
          >
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: "#888888", fontSize: "1rem" }}
          >
            Recipes List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Recipe #${recipe?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() =>
              navigate(`/admin/content/recipes/${recipe?.id}/edit`)
            }
          >
            Edit Recipe
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete Recipe
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="ID:" text={recipe.id} />
            <LabelText label="Title:" text={recipe.title} />
            <LabelText label="Author:" text={recipe.author} />
            <LabelText
              label="Is Public:"
              text={recipe.is_public ? "Yes" : "No"}
            />
            <LabelText label="Recipe Type:" text={recipe.recipe_type} />
            <LabelText
              label="Created At:"
              text={
                recipe?.created_at
                  ? dateToUTC(new Date(recipe?.created_at), "MM/dd/yyyy")
                  : "N/A"
              }
            />
            <LabelText
              label="Updated At:"
              text={
                recipe?.updated_at
                  ? dateToUTC(new Date(recipe?.updated_at), "MM/dd/yyyy")
                  : "N/A"
              }
            />
            {/* Pending due changes in API
            <LabelText label="Private Url:" text={recipe.recipe_type} />
            <LabelText label="Public Url:" text={recipe.recipe_type} /> */}
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Recipe"
        message="Are you sure you want to remove this recipe?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() =>
          navigate(`/admin/content/recipes/${recipe?.id}/delete`)
        }
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
