import { ColumnDef } from "@tanstack/react-table";
import { LinkCell } from "../../../components/tables/cellComponents/LinkCell";
import { Document, Joke, JokeCategory } from "../../../types/content.type";
import { Stack } from "react-bootstrap";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import { CiShare1 } from "react-icons/ci";
import { FaDownload } from "react-icons/fa6";
import { getPartition } from "../../utils";
import YesNoCell from "../../../components/tables/cellComponents/YesNoCell";

export const recipesColumns: ColumnDef<Recipe>[] = [
  {
    header: "ID",
    accessorKey: "id",
  },
  {
    header: "Image",
    accessorKey: "",
  },
  {
    header: "Recipe Type",
    accessorKey: "recipe_type",
  },
  {
    header: "Title",
    accessorKey: "title",
  },
  {
    header: () => null,
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell
          content="View"
          url={`/admin/content/recipes/${row.original.id}`}
          underline
        />
        <LinkCell
          content="Edit"
          url={`/admin/content/recipes/${row.original.id}/edit`}
          underline
        />
        <LinkCell
          content="Delete"
          url={`/admin/content/recipes/${row.original.id}/delete`}
          underline
        />
      </Stack>
    ),
  },
];

export const documentColumns: ColumnDef<Document>[] = [
  {
    header: "ID",
    accessorKey: "id",
    cell: ({ getValue }) => (
      <LinkCell
        content={getValue<number>()}
        url={`/admin/content/documents/${getValue<number>()}`}
      />
    ),
  },
  {
    header: "Title",
    accessorKey: "title",
  },
  {
    header: "Name",
    accessorKey: "file_file_name",
  },
  {
    header: "Download",
    accessorKey: "file_file_name",
    cell: ({ row }) => (
      <LinkCell
        content={<FaDownload />}
        url={`${row.original.s3_base_url ?? ""}/${getPartition(
          row.original.id
        )}/${row.original.file_file_name}`}
      /> 
    ),
  },
  {
    header: () => null,
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell
          content="View"
          url={`/admin/content/documents/${row.original.id}`}
          underline
        />
        <LinkCell
          content="Edit"
          url={`/admin/content/documents/${row.original.id}/edit`}
          underline
        />
        <LinkCell
          content="Delete"
          url={`/admin/content/documents/${row.original.id}/delete`}
          underline
        />
      </Stack>
    ),
  },
];

export const lessonPlanColumns: ColumnDef<LessonPlan>[] = [
  {
    header: "Id",
    id: "lesson_plan_id",
    accessorKey: "id",
    enableSorting: true,
  },
  {
    header: "Image",
    id: "image",
    enableSorting: false,
    cell: ({ row }) => <img src={`${row.original.id}.jpg`} />,
  },
  {
    header: "Name",
    id: "lesson_plan_name",
    accessorKey: "name",
  },
  {
    header: "Live",
    id: "live",
    enableSorting: false,
    cell: ({ row }) => (
      <a href={`/admin/content/lessonsPlans/${row.original.id}`}>
        <CiShare1 size={20} color="#4A90E2" />
      </a>
    ),
  },
  {
    header: () => "",
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell
          content="View"
          url={`/admin/content/lesson-plans/${row.original.id}`}
          underline
        />
        <LinkCell
          content="Edit"
          url={`/admin/content/lesson-plans/${row.original.id}/edit`}
          underline
        />
        <LinkCell
          content="Delete"
          url={`/admin/content/lesson-plans/${row.original.id}/delete`}
          underline
        />
      </Stack>
    ),
  },
];

export const themesColumns: ColumnDef<Document>[] = [
  {
    header: "ID",
    accessorKey: "id",
    cell: ({ getValue }) => (
      <LinkCell
        content={getValue<number>()}
        url={`/admin/content/themes/${getValue<number>()}`}
      />
    ),
  },
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Is Public",
    accessorKey: "is_public",
    cell: ({ row }) => (
     <YesNoCell value={row.original?.is_public}/>
    ),
  },
  {
    header: () => null,
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell
          content="View"
          url={`/admin/content/themes/${row.original.id}`}
          underline
        />
        <LinkCell
          content="Edit"
          url={`/admin/content/themes/${row.original.id}/edit`}
          underline
        />
        <LinkCell
          content="Delete"
          url={`/admin/content/themes/${row.original.id}/delete`}
          underline
        />
      </Stack>
    ),
  },
];


export const jokesColumns: ColumnDef<Joke>[] = [
  {
    header: "ID",
    accessorKey: "id",
  },
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Joke Category",
    cell: ({ row }) => (
      <LinkCell
        content={row.original.joke_category?.name}
        url={`/admin/content/joke-categories/${row.original.joke_category_id}`}
        underline
      />
    ),
  },
  {
    header: () => null,
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell
          content="View"
          url={`/admin/content/jokes/${row.original.id}`}
          underline
        />
        <LinkCell
          content="Edit"
          url={`/admin/content/jokes/${row.original.id}/edit`}
          underline
        />
        <LinkCell
          content="Delete"
          url={`/admin/content/jokes/${row.original.id}/delete`}
          underline
        />
      </Stack>
    ),
  },
];

export const jokeCategoriesColumns: ColumnDef<JokeCategory>[] = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: () => null,
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell
          content="View"
          url={`/admin/content/joke-categories/${row.original.id}`}
          underline
        />
        <LinkCell
          content="Edit"
          url={`/admin/content/joke-categories/${row.original.id}/edit`}
          underline
        />
        <LinkCell
          content="Delete"
          url={`/admin/content/joke-categories/${row.original.id}/delete`}
          underline
        />
      </Stack>
    ),
  },
];
