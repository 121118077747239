import React, { ReactNode } from 'react';
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';

type Props = {
  position: google.maps.LatLng | google.maps.LatLngLiteral | null | undefined;
  icon: ReactNode;
  onClick: () => void;
}
export const MarkerWithIcon: React.FC<Props> = ({position, icon, onClick}) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => onClick()}
        position={position}
      >
        {icon}
      </AdvancedMarker>
    </>
  );
};