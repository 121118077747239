import React from 'react';
import { Order } from '../../../../../types/orders.type';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { Card, Stack } from 'react-bootstrap';

export interface UpdateChildProps {
  order: Order;
}

export const UpdateChild: React.FC<UpdateChildProps> = ({ order }) => {
  const {registrations} = order;
  return (
    <>
      <h3>Update your child's information!</h3>
      <h5>Don't forget to update your child's information</h5>
      <Stack gap={2}>
        {registrations.map(r => (
          <Card key={r.id} style={{border: '3px solid var(--bs-sfc-pink)'}}>
            <Card.Body>
              <Stack direction='horizontal'>

                <Stack>
                  <span>Child</span>
                  <span>Grade</span>
                  <span>Teacher / Classroom</span>
                </Stack>
                <Stack>
                  <span><LinkCell url='/users/profile' content={r.child.full_name} /></span>
                  <span>{r.child.grade_level}</span>
                  <span>{r.child.origination}</span>
                </Stack>
              </Stack>
            </Card.Body>
          </Card>
        ))}
      </Stack>
    </>
  );
};
