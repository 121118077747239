import { 
  Button,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Row,
} from 'react-bootstrap';
import { formatUserName, getCurrentUser, getMimicUser, logoutUser } from '../../../services/helper';
import { ReactNode, useEffect, useState } from 'react';
import SFCMobileNavLogo from '../../../assets/images/SFC-nav-header-logo.png';
import SFCDashLogo from '../../../assets/images/SFC-dash-logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { HiBars3 } from "react-icons/hi2";
import { TbArrowBarLeft } from "react-icons/tb";
import { GoChevronRight } from "react-icons/go";
import { FaChevronDown } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { NavItem, NavItems } from '../NavBarItems';
import { isAdmin } from '../../../utils/consts';
import './DashNavbar.css';
import { ToggleMetadata } from '@restart/ui/esm/Dropdown';
import { useNavbar } from '../../../context/DashNavbarContext';
import { BackButton } from '../../../components/buttons/BackButton';

const NavPill = ({ title, icon, active }: { title: string, icon?: ReactNode, active?: boolean } ) => (
  <Row className={`fw-${active ? 'bold' : 'semibold'}`}>
    <Col xs={1} className="me-3">{icon}</Col>
    <Col style={{ fontSize: 18 }} className={`fw-${active ? 'bold' : 'semibold'}`}>
      {title}
    </Col>
  </Row>
);

export function DashNavbar() {
  const { activeNavbar, setActiveNavbar } = useNavbar();
  const location = useLocation();
  const currentUser = getCurrentUser();
  const mimic = getMimicUser();
  const navItems = NavItems(isAdmin(currentUser?.user_type_id!));
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(activeNavbar === 'dash');
  const [dropdownsOpen, setDropdownsOpen] = useState<{ [key: string]: boolean }>({});
  
  useEffect(() => {
    setDropdownsOpen(navItems
      .reduce(( obj, navItem) =>
        ({
          ...obj,
          ...(navItem.subItems?.length
            ? { [navItem.title]: navItem.subItems.some((item: NavItem) => location.pathname.startsWith(item.url!) || item.urls?.some((url) => location.pathname.includes(url))) }
            : {}
          )
        }), {}
      )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setIsOpen(activeNavbar === 'dash');
  }, [activeNavbar]);

  const toggleNavbar = (isOpen: boolean) => {
    if (isOpen) {
      setActiveNavbar('dash');
    } else {
      setActiveNavbar(null);  // Open the current navbar and close the other one if open
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleToggle = (next: boolean, meta: ToggleMetadata, key: string) => {
    if (meta.source === 'rootClose') return; // avoid rootClose when clicking outside of sidebar
    setDropdownsOpen((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, k) => {
        acc[k] = k === key ? !prevState[k] : false;
        return acc;
      }, {} as { [key: string]: boolean });
      return { ...newState, [key]: !prevState[key] };
    });
  };

  const closeDropdowns = () => {
    setDropdownsOpen(navItems
      .reduce(( obj, navItem) =>
        ({
          ...obj,
          ...(navItem.subItems?.length
            ? { [navItem.title]: false }
            : {}
          )
        }), {}
      )
    );
  }

  const SFCOffCanvasBody = () => (
    <Offcanvas.Body className="p-0 dash-navbar-body overflow-auto">
      <Image className="w-100 my-3" src={SFCDashLogo} />
      <Nav variant="pills" defaultActiveKey={"/"}>

        {navItems.map((navItem: NavItem) => navItem.subItems?.length 
          ? (
            <NavDropdown
              key={navItem.title}
              onToggle={(next, meta) => handleToggle(next, meta, navItem.title)}
              show={dropdownsOpen[navItem.title]}
              title={
                <div className="d-flex align-items-center justify-content-between px-3">
                  <NavPill
                    title={navItem.title}
                    icon={navItem.icon}
                    active={dropdownsOpen[navItem.title]}
                  />
                  {dropdownsOpen[navItem.title]
                    ? (
                      <FaChevronDown className="me-1" />
                    ) : (
                      <GoChevronRight size={20} />
                    )
                  }
                </div>
              }
              id="nav-dropdown"
            >
              {navItem.subItems.filter(f => !f.hidden).map((subItem: NavItem) => (
                <NavDropdown.Item
                  key={subItem.title}
                  as={NavLink}
                  to={subItem.url!}
                  active={location.pathname.startsWith(subItem.url!) || subItem.urls?.some((url) => location.pathname.includes(url))}
                >
                  <NavPill title={subItem.title} />
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          ) : (
            <Nav.Link
              key={navItem.title}
              as={NavLink}
              to={navItem.url!}
              onClick={closeDropdowns}
              className="mb-1 px-3"
            >
              <div className="d-flex align-items-center justify-content-between">
                <NavPill title={navItem.title} icon={navItem.icon} />
                <GoChevronRight size={20} style={{ visibility: 'hidden' }} />
              </div>
            </Nav.Link>
          ))}
      </Nav>
    </Offcanvas.Body> 
  );

  const SFCLogout = () => (
    <div
      className="border-top p-4 d-flex justify-content-between align-items-center bottom-0 position-relative"
      style={{ background: '#FFFFFF' }}
    >
      <Row className="text-dark">
        <Col xs={12} className="fw-semibold">{formatUserName(currentUser)}</Col>
        <Col>{currentUser?.email}</Col>
      </Row>
      <button className="btn btn-link p-0" onClick={logoutUser}>
        <MdLogout size={26} color="#888888" />
      </button>
    </div>
  )

  return (
    <>
      <Navbar expand={false} className="d-lg-none sticky-top" style={{ background: '#FFFFFF', boxShadow: '0 0 8px 0 rgba(40, 40, 40, .3)' }}>
        <Button variant="outline-info" onClick={handleShow} className="border-0 position-absolute">
          <HiBars3 size={24} />
        </Button>
        <Image src={SFCMobileNavLogo} className="w-100 mx-auto" style={{ maxWidth: 175 }} />
        
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${'sm'}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${'sm'}`}
          placement="start"
          show={show}
          onHide={handleClose}
          className="custom-offcanvas d-lg-none"
        >
          <Offcanvas.Header className="ms-auto mt-2 fs-3" closeButton />
          <SFCOffCanvasBody />
          <SFCLogout />
        </Navbar.Offcanvas>
      </Navbar>


      <Navbar
        expand={false}
        className={`d-none d-lg-block mb-3 sidebar py-0 ${isOpen ? 'open' : ''}`}
        style={{ height: mimic ? 'calc(100% - 36px)' : '100%' }}
      >
        <Button
          variant="primary"
          onClick={() => toggleNavbar(!isOpen)}
          className="btn-box-shadow border-0 fs-5 sidebar-toggle px-1 pt-0 pb-1 mt-4"
        >
          <TbArrowBarLeft style={{ transform: `rotate(${isOpen ? 0 : 180}deg)`, transition: 'all 0.6s' }} />
        </Button>

        <Container className="overflow-auto" style={{ height: 'calc(100% - 97px)' }}>
          <div className="h-100 pt-3">
            <Navbar.Brand>
              <BackButton text="Return to SFC site" url="/" />
            </Navbar.Brand>

            <SFCOffCanvasBody />
          </div>
        </Container>

        <SFCLogout />
      </Navbar>
    </>
  );
}
