import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { SimpleSFCLocation} from '../types/place.types';
import { FormSelectOptions } from '../components/tables/filterForms/FormSelect';
import { useGetAllLocationsSimpleQuery } from '../services/endpoints/places/location';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { Spinner } from 'react-bootstrap';
import { getErrorMessage } from '../utils/utils';

interface DataContextType {
  locations?: SimpleSFCLocation[];
  locationOptions: FormSelectOptions[];
  getLocationTitle: (location_id: number | string) => string;
  getLocationOptions: (location_id: number | string) => FormSelectOptions[];
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};

export const DataProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const {data: locations, error: locationsError, isLoading: locationsLoading, isFetching: locationsFetching} = useGetAllLocationsSimpleQuery();
  const [locationOptions, setLocationOptions] = useState<FormSelectOptions[]>([]);

  useEffect(() => {
    if (locations) {
      setLocationOptions(locations?.map(i => ({value: i.id, label: i.title})) ?? []);
    }
  }, [locations]);

  const getLocationTitle = useCallback((location_id: number|string) => {
    return locations?.find(l => String(l.id) === String(location_id))?.title ?? 'Unknown Location';
  }, [locations]);

  const getLocationOptions = useCallback((location_id: number|string) => {
    return locationOptions?.filter(l => String(l.value) === String(location_id)) ?? [];
  }, [locationOptions]);

  if (locationsLoading || locationsFetching) {
    return <Spinner />
  }

  if (locationsError) {
    return <div>Error: {getErrorMessage(locationsError)}</div>;
  }

  return (
    <DataContext.Provider value={{ locations, locationOptions, getLocationTitle, getLocationOptions }}>
      {children}
    </DataContext.Provider>
  );
};
