import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Gallery } from '../../../types/website.types';
import { sfcApi } from '../../api';

export const galleryApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllGalleries: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `galleries${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Gallery']
    }),
    getGallery: builder.query<{ gallery: Gallery }, number>({
      query: (id) => `galleries/${id}`,
      providesTags: (result, error, id) => [{ type: 'Gallery', id }]
    }),
    createGallery: builder.mutation<{ gallery: Gallery }, Gallery>({
      query: (data) => {
        let payload: any = {
          url: `galleries/create`,
          method: 'POST',
          body: data,
        };
        if (data.pictures && data.pictures.length > 0) {
          payload.formData = true;
          const formData = new FormData();
    
          // Append gallery name if needed
          formData.append('name', data.name);
          
          data.pictures?.forEach((picture) => {
            if (!picture.destroy) {
              // If there is a new file, append it
              if (picture.image) {
                formData.append(`images`, picture.image);
              }
            }
          });
          payload.body = formData;
        }
        return payload;
      },
      invalidatesTags: ['Gallery']
    }),
    updateGallery: builder.mutation<{ gallery: Gallery }, Gallery>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        let payload: any = {
          url: `galleries/update/${id}`,
          method: 'PUT',
          body: data
        };
        if ( (data.pictures && data.pictures.length > 0) ) {
          payload.formData = true;
          const formData = new FormData();
          formData.append('name', data.name);
          let image_index = 0;
          data.pictures?.forEach((picture, index) => {
            let prefix = `images[${index}]`;
            formData.append(`${prefix}[id]`, String(picture.id ?? -1));
            if (picture.destroy) {
              // If the image should be deleted
              formData.append(`${prefix}[destroy]`, 'true');
            } else {
              // If there is a new file, append it
              if (picture.image) {
                formData.append(`${prefix}[image_index]`, String(image_index));
                formData.append(`images`, picture.image);
                image_index++;
              }
            }
          });
          payload.body = formData;
        }
        return payload;
      },
      invalidatesTags: (result, error) => [{ type: 'Gallery', id: result?.gallery?.id }, 'Gallery']
    }),
    deleteGallery: builder.mutation<{ gallery: Gallery }, number>({
      query: (id) => ({
        url: `galleries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Gallery'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Gallery', id })), 'Gallery']
    }),
  }),
});

export const {
  useGetAllGalleriesQuery,
  useGetGalleryQuery,
  useCreateGalleryMutation,
  useUpdateGalleryMutation,
  useDeleteGalleryMutation,
} = galleryApi;