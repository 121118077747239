import { Card, Col, Row } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import { Child } from "../../services/endpoints/people/user";
import CaregiverCard from "./CaregiverCard";
import { genderLookup } from "../../utils/consts";
import ChildProfileIcon from '../../assets/images/child-profile-icon.png';
import { formatUserName } from '../../services/helper';

const ChildProfileCard = (props: {child: Child, edit: () => void }) => {
  const { child, edit } = props;

  return (
    <Card className="text-left shadow">
      <Card.Body>
            <Col sm={6}>
          <Row className={"mb-3"}>
              <Col sm={1}>
                <Card.Img src={ChildProfileIcon} style={{height: "40px", width: "40px"}}/>
              </Col>
              <Col sm={11}>
                <Card.Text className={"h2 text-capitalize text-info"} >{formatUserName(child)}</Card.Text>
              </Col>
          </Row>
            </Col>
        <Card.Text className={"edit-card-btn"} >
          <button onClick={edit} className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"><BsPencil /> Edit Child</button>
        </Card.Text>
        <Card.Subtitle className="text-muted">Date of Birth</Card.Subtitle>
        <Card.Text className="fw-bold">{child.dob}</Card.Text>
        <Card.Subtitle className="text-muted">Grade Level</Card.Subtitle>
        <Card.Text className="fw-bold">{child.grade_level}</Card.Text>
        <Card.Subtitle className="text-muted">Teacher / Classroom</Card.Subtitle>
        <Card.Text className="fw-bold">{child.origination}</Card.Text>
        <Card.Subtitle className="text-muted">Gender</Card.Subtitle>
        <Card.Text className="fw-bold">{genderLookup(child.gender)}</Card.Text>
        <Card.Subtitle className="text-muted">Allergies</Card.Subtitle>
        <Card.Text className="fw-bold">
          {child.allergies.map((a, index) => (
            <span key={index}>
              {`(${a.allergy}) ${a.severity} : ${a.details}`}
              <br />
            </span>
          ))}
        </Card.Text>
        <Card.Subtitle className="text-muted">Social / Emotional Needs</Card.Subtitle>
        <Card.Text className="fw-bold">{child.social_emotional_needs}</Card.Text>
        <Card.Subtitle className="text-muted">Parents / Caregivers</Card.Subtitle>
        <Row className="mb-1">
          {child.caregivers.map((c, index) => (
            <Col key={index}>
              <CaregiverCard caregiver={c} />
            </Col>
          ))}
        </Row>
        <Card.Subtitle className="text-muted">Pick Up Type</Card.Subtitle>
        <Card.Text className="fw-bold">{child.pickup_type}</Card.Text>
        <Card.Subtitle className="text-muted">Pick Up Notes</Card.Subtitle>
        <Card.Text className="fw-bold">{child.pickup_other}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ChildProfileCard;
