import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { StepProps } from "../Register"
import { ErrorMessage, Field } from 'formik';
import { useState } from 'react';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { heardAboutUsOptions } from '../../../utils/consts';

export const FinalizeForm = ({ touched, errors }: StepProps) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

  return (
    <>
      <Form.Group className="mb-1 mb-lg-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <InputGroup>
          <Field
            name="password"
            type={passwordVisible ? 'text' : 'password'}
            as={Form.Control}
            isInvalid={touched.password && !!errors.password}
          >
          </Field>
          <InputGroup.Text
            style={{ cursor: 'pointer' }}
            className="text-primary"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {
              passwordVisible ? (
                <IoEyeOffOutline style={{ fontSize: 20 }} />
              ) : (
                <IoEyeOutline style={{ fontSize: 20 }} />
              )
            }
          </InputGroup.Text>
          <ErrorMessage name="password">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-1 mb-lg-3" controlId="confirm_password">
        <Form.Label>Confirm Password</Form.Label>
        <InputGroup>
          <Field
            name="confirm_password"
            type={confirmPasswordVisible ? 'text' : 'password'}
            as={Form.Control}
            isInvalid={touched.confirm_password && !!errors.confirm_password}
          >
          </Field>
          <InputGroup.Text
            style={{ cursor: 'pointer' }}
            className="text-primary"
            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
          >
            {
              confirmPasswordVisible ? (
                <IoEyeOffOutline style={{ fontSize: 20 }} />
              ) : (
                <IoEyeOutline style={{ fontSize: 20 }} />
              )
            }
          </InputGroup.Text>
          <ErrorMessage name="confirm_password">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-1 mb-lg-3" controlId="heard_about">
        <Form.Label>
          How did you hear about us?
          <span className="fw-light">{` (Optional)`}</span>
        </Form.Label>
        <Field
          name="heard_about"
          as={Form.Select}
          options={['2', '3']}
          placeholder="Select"
          isInvalid={touched.heard_about && !!errors.heard_about}
        >
          <option>Select</option>
          {heardAboutUsOptions.map((opt) => (
            <option value={opt} key={opt}>{opt}</option>
          ))}
        </Field>
      </Form.Group>
    </>
  )
}
