import React from 'react';
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';

interface WarningCellProps {
  text: string;
}

export const WarningCell: React.FC<WarningCellProps> = ({ text }) => {

  const renderTooltip = (props: any) => (
    <Tooltip id="enrollment-tooltip" {...props}>
      <span style={{ fontSize: '.75rem' }}>
        {text}
      </span>
    </Tooltip>
  );

  return (
    <Stack direction="horizontal" gap={2}>
      <OverlayTrigger overlay={renderTooltip} placement="bottom">
        <span style={{ cursor: 'pointer' }}>
          <BsExclamationTriangle size={20} color="#B81F69" />
        </span>
      </OverlayTrigger>
    </Stack>
  )
};
