import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { useGetCoursesQuery } from '../../../../../services/endpoints/schedule/course';
import { Course } from '../../../../../types/course.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { TimeField } from '../../../../../components/tables/filterForms/TimeField';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { sessionColumns, sessionInvoiceColumns, sessionOtherColumns, simpleScheduleColumns } from '../../../../../utils/consts/columnDefs/sessionColumns';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { dateInTimeZone, UTCToDate } from '../../../../../utils/dateUtils';
import { Lesson } from '../../../../../types/lesson.type';
import { getCurrentUser } from '../../../../../services/helper';
import { DetailSection, LabelText } from '../../../../../components/generic';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { useDataContext } from '../../../../../context';

const defaultScope = 'upcoming';
const currentUser = getCurrentUser();

export const SessionsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetCoursesQuery(filters);
  const [courses, setCourses] = useState<Course[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const isGlobalUser = (currentUser?.isGlobal);
  const isFinanceViewerUser = (currentUser?.canSeeFinanceMenu);

  const filterFormFields: FilterField[] = [
    {
      name: 'title',
      element: (
        <SearchField name="title" label="Session" placeholder="Search session" />
      )
    },
    {
      name: 'instructor_full_name',
      label: 'Instructor',
    },
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'day_of_week',
      label: 'Day of Week',
    },
    {
      name: 'time',
      element: (
        <TimeField name="formatted_time" label="Time" />
      )
    },
    {
      name: 'starts_from',
      id: 'starts_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="starts_from" label="Start Date" />
      )
    },
    {
      name: 'starts_to',
      id: 'starts_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="starts_to" />
      )
    },
    {
      name: 'ends_from',
      id: 'ends_on',
      operator: 'greater_equal_than',
      element: <DateField name="ends_from" label="End Date" />
    },
    {
      name: 'ends_to',
      id: 'ends_on',
      operator: 'lesser_equal_than',
      element: <DateField name="ends_to" />
    },
    {
      name: 'location_id',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
  ];

  if (!isGlobalUser) {
    // remove location filter
    filterFormFields.splice(-1,1);
  }

  const sidebars = (filters?.scope === "needs_invoice" && isFinanceViewerUser) ? ([
    <DetailSection title="Summary">
      <LabelText label="Total Amount Receivable:" >
        <CurrencyCell value={String(data?.totals?.total_receivable)} />
      </LabelText>
      <LabelText label="Total Amount Invoiced:" >
        <CurrencyCell value={String(data?.totals?.total_invoiced)} />
      </LabelText>
      <LabelText label="Difference:" >
        <CurrencyCell value={String((data?.totals?.total_receivable ?? 0) - (data?.totals?.total_invoiced ?? 0))} />
      </LabelText>
    </DetailSection>
  ]) : undefined;

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
        setCourses(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Sessions`}
        columns={sessionColumns.concat(['needs_invoice', 'over_invoiced'].includes(filters?.scope ?? '') ? sessionInvoiceColumns : sessionOtherColumns)}
        data={courses}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        sidebars={sidebars}
        indexDownloadPath='courses'
        expandedItem={{
          columns: simpleScheduleColumns,
          rowKey: 'lessons',
          filterFn: ((lesson: Lesson) => UTCToDate(`${lesson.date} ${lesson.starts_at}`, 'yyyy-MM-dd h:mm a') >= dateInTimeZone())
        }}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate('/admin/schedule/sessions/new')}
          >
            New session
          </SimpleButton>
        )}
      />
    </div>
  );
}
