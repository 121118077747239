import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Joke } from '../../types/content.type';
import { RootState } from '../store';
interface JokeState {
    jokes: Joke[];
}
const initialState: JokeState = {
    jokes: [],
};
const jokeSlice = createSlice({
    name: 'jokes',
    initialState,
    reducers: {
        addJoke: (state, action: PayloadAction<Joke>) => {
            state.jokes.push(action.payload);
        },
        removeJoke: (state, action: PayloadAction<number>) => {
            state.jokes = state.jokes.filter(joke => joke.id !== action.payload);
        },
        setJokes: (state, action: PayloadAction<Joke[]>) => {
            state.jokes = action.payload;
        },
    },
});
export const selectJoke = (state: RootState) => state.jokes.jokes;
export const { addJoke, removeJoke, setJokes } = jokeSlice.actions;
export default jokeSlice.reducer;