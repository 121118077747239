import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { dateToUTC } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useGetPageQuery } from '../../../../../services/endpoints/website/page';
import { Page } from '../../../../../types/website.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import YesNoCell from '../../../../../components/tables/cellComponents/YesNoCell';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';

export const PageDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetPageQuery(+params.id!);
  const [ page, setPage] = useState<Page>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setPage(data.page);
    }
  }, [data]);

  if (isLoading || !page) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/website/pages')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Pages List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Page #${page?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/website/pages/${page?.id}/edit`)}
          >
            Edit Page
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/website/pages/${page?.id}/delete`)}
          >
            Delete Page
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Name:" text={page?.name} />
            <LabelText label="Content:" text={page?.content} />
            <LabelText label="Created At:" text={page?.created_at ? dateToUTC(new Date(page?.created_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Updated At:" text={page?.updated_at ? dateToUTC(new Date(page?.updated_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Slug:" text={page?.slug} />
            <LabelText label="Is Featured:" >
              <YesNoCell value={page?.is_featured} />
            </LabelText>
            <LabelText label="Image File Name:" text={page?.image_file_name} />
            <LabelText label="Image Content Type:" text={page?.image_content_type} />
            <LabelText label="Image File Size:" text={page?.image_file_size} />
            <LabelText label="Image Updated At:" text={page?.image_updated_at ? dateToUTC(new Date(page?.image_updated_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Author:" text={page?.author} />
            <LabelText label="Published On:" text={page?.published_on} />
            <LabelText label="Button Text:" text={page?.button_text} />
            <LabelText label="Button Target:" text={page?.button_target} />
            <LabelText label="Button Opens In New Window:">
              <YesNoCell value={page?.button_opens_in_new_window} />
            </LabelText>
            <LabelText label="Gallery:">
              <LinkCell content={page?.gallery?.name} url={`/admin/website/galleries/${page?.gallery_id}`} />
            </LabelText>
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
