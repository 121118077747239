import { Form, Row } from "react-bootstrap";
import { Field } from "formik";
import { useEffect } from "react";
import { useToast } from "../../../../../context/ToastContext";
import { StepProps } from "./AddEditRegistration";
import { useGetCoursesQuery } from "../../../../../services/endpoints/schedule/course";
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';

export const RegistrationsForm = ({
  touched,
  errors,
  values,
  setFieldValue,
}: StepProps) => {
  const { data, error, isLoading } = useGetCoursesQuery({ scope: 'all' });
  const { addToast } = useToast();

  useEffect(() => {
    if (error && !isLoading) {
      addToast("Error while loading courses", "error");
    }
  }, [data, error, isLoading]);

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Registrations:
      </h6>
     
      <Form.Group className="mb-1 mb-lg-3" controlId="course_id">
          <Field 
            as={FormSelect}
            name="course_id"
            label="Upcoming Session (Optional)"
            options={data?.results?.map((course: {id: number, title: string}) => ({ value: course.id, label: course.title })) ?? []}
            loadingError={error ? 'Error loading sessions': undefined}
          />
        </Form.Group>
    </Row>
  );
};
