import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import { Discount } from '../../../../../types/orders.type';
import { scholarshipTypes } from '../../../../../utils/consts/orders/scholarships';
import { useDeleteDiscountsMutation, useGetDiscountQuery } from '../../../../../services/endpoints/orders/discounts';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';

export const DiscountDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetDiscountQuery(+params.id!);
  const [ discount, setDiscount] = useState<Discount>();
  const [deleteDiscount] = useDeleteDiscountsMutation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading discount', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setDiscount(data.discount);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  if (!discount) return <></>;

  const handleDelete = async () => {
    try {
      await deleteDiscount(Number(params.id!));
      addToast('Discount deleted successfully', 'success');
      navigate(`/admin/orders/discounts`);
    } catch (e) {
      addToast('Error while deleting discount', 'error');
    } 
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/orders/discounts')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Discounts List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">
            {discount?.scholarship.percent_discount}%
          </h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/orders/discounts/${discount.id}/edit`)}
          >
            Edit discount
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete discount
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Admin:" text={discount?.admin_id} />
            <LabelText label="User:">
              <LinkCell
                content={`${discount?.user.first_name} ${discount?.user.last_name}`}
                url={`/admin/sfc-users/parents/${discount?.user_id}`} // TODO: change to correct user URL
              />
            </LabelText>
            <LabelText label="Scholarship:">
              <LinkCell
                content={`${scholarshipTypes[discount?.scholarship.scholarship_type]} (${discount?.scholarship.percent_discount}% off)`}
                url={`/admin/orders/scholarships/${discount?.scholarship_id}`}
              />
            </LabelText>
            <LabelText label="Session:">
              {discount?.course ? (
                <LinkCell
                  content={discount?.course?.title ?? 'N/A'}
                  url={`/admin/schedule/sessions/${discount?.course_id}`}
                />
              ) : 'N/A'}
            </LabelText>
            <LabelText label="Child:">
              {discount?.child ? (
                <LinkCell
                  content={`${discount?.child.first_name} ${discount?.child.last_name}`}
                  url={`/admin/sfc-users/children/${discount?.child_id}`} // TODO: change to correct child URL
                />
              ) : 'N/A'}
            </LabelText>
            <LabelText label="Gift Certificate:" text={discount?.gift_certificate_id ?? 'N/A'} />
            <LabelText label="Order:">
              {discount?.order_id ? (
                <LinkCell
                  content={`Order #${discount?.order_id}`}
                  url={`/admin/orders/orders/${discount?.order_id}`}
                />
              ) : 'N/A'}
            </LabelText>
            {/* <LabelText label="Created At:" text={formatDate(new Date(discount?.created_at), 'MM/dd/yyyy h:mm a zzz')} /> */}
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Discount"
        message="Are you sure you want to remove this discount?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => handleDelete()}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
