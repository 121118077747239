import { ErrorMessage, Field } from 'formik';
import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { debounce } from "lodash";

interface DateFieldProps {
  name: string;
  label?: string;
  isInvalid?: boolean;
  disabled?: boolean;
  max?: string;
  min?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  handleSubmit?: () => void;
}

export const DateField : React.FC<DateFieldProps> = ({ name, label, isInvalid, disabled, max, min, handleSubmit, setFieldValue }) => {
  const debouncedSubmit = useCallback(
    debounce(() => {
      if (!isInvalid) {
        handleSubmit!();
      }
    }, 1000),
    [handleSubmit, isInvalid]
  );
  
  return (
    <Form.Group controlId={name} className="w-100">
      {label && <Form.Label>{label}</Form.Label>}
      <Field name={name}>
        {({ field }: { field: any }) => (
          <Form.Control
            type="date"
            max={max}
            min={min}
            {...field}
            disabled={disabled}
            isInvalid={isInvalid}
            onChange={handleSubmit ? (e: any) => {
              const newDate = e.target.value;
              setFieldValue!(name, newDate);
              debouncedSubmit();
            } : undefined}
          />
        )}
      </Field>

      <ErrorMessage
        name={name}
        component="span"
        className="text-danger fs-small"
      />
    </Form.Group>
  );
}
