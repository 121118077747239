/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditEnrollment';
import { useToast } from '../../../../context/ToastContext';
import { useEffect } from 'react';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { useGetCoursesQuery } from '../../../../services/endpoints/schedule/course';
import { useGetAllScholarshipsQuery } from '../../../../services/endpoints/orders/scholarship';
import { Scholarship } from '../../../../types/orders.type';
import { scholarshipTypes } from '../../../../utils/consts/orders/scholarships';

export const EnrollmentForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const { data: scholarships, error: scholarshipsError, isLoading: scholarshipsLoading } = useGetAllScholarshipsQuery();
  const { addToast } = useToast();
  const { data: courses, error: coursesError, isLoading: coursesLoading } = useGetCoursesQuery();

  useEffect(() => {
    if (coursesError && !coursesLoading) {
      addToast('Error while loading courses', 'error');
    } else if (scholarshipsError && !scholarshipsLoading) {
      addToast('Error while loading scholarships', 'error');
    }
  },[coursesError, coursesLoading, scholarshipsError, scholarshipsLoading]);

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="course_id">
            <Field 
              as={FormSelect}
              name="course_id"
              label="Session"
              options={courses?.results?.map((course: {id: number, title: string}) => ({ value: course.id, label: course.title })) ?? []}
              loadingError={coursesError ? "Error loading sessions"
                : (!!errors.course_id ? 'Course selection is required' : undefined)}
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="quantity">
            <Form.Label>Quantity</Form.Label>
            <Field
              name="quantity"
              type="number"
              as={Form.Control}
              isInvalid={touched.quantity && !!errors.quantity}
            />
            <ErrorMessage name="quantity">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="tuition">
            <Form.Label>Tuition</Form.Label>
            <Field
              name="tuition"
              type="number"
              as={Form.Control}
              isInvalid={touched.tuition && !!errors.tuition}
            />
            <ErrorMessage name="tuition">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="number_of_classes">
            <Form.Label>Number of classes</Form.Label>
            <Field
              name="number_of_classes"
              type="number"
              as={Form.Control}
              isInvalid={touched.number_of_classes && !!errors.number_of_classes}
            />
            <ErrorMessage name="number_of_classes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="scholarship_id">
            <Form.Label>Scholarship</Form.Label>
            <Field
              name="scholarship_id"
              as={Form.Select}
              isInvalid={touched.scholarship_id && !!errors.scholarship_id}
            >
              <option>Select</option>
              {scholarships?.results?.map((scholarship: Scholarship, idx: number) => (
                <option key={idx} value={scholarship.id}>
                  {scholarshipTypes[scholarship.scholarship_type]} {`(${scholarship.percent_discount}% off)`}
                </option>
              ))}
            </Field>
            <ErrorMessage name="scholarship_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="notes">
            <Form.Label>Notes (Optional)</Form.Label>
            <Field
              name="notes"
              as="textarea"
              className="form-control"
              rows={4}
            />
            <ErrorMessage name="notes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="invoice_notes">
            <Form.Label>Invoice Notes (Optional)</Form.Label>
            <Field
              name="invoice_notes"
              as="textarea"
              className="form-control"
              rows={4}
            />
            <ErrorMessage name="invoice_notes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
