import React from 'react';
import { sanitize } from 'dompurify';

const SafeHTMLDisplay: React.FC<{htmlContent: string}> = ({ htmlContent }) => {
  const sanitizedHTML = sanitize(htmlContent);
  
  return (
    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
  );
};

export default SafeHTMLDisplay;
