import { ErrorMessage, Field } from 'formik';
import React, { useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { BsClock } from 'react-icons/bs';

interface SearchFieldProps {
  name: string;
  label: string;
  isInvalid?: boolean;
  disabled?: boolean;
}

export const TimeField : React.FC<SearchFieldProps> = ({ name, label, isInvalid, disabled }) => {
  const timeInputRef = useRef<HTMLInputElement>(null);

  return (
    <Form.Group controlId={name} className="w-100">
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        {!disabled && (
          <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => timeInputRef?.current?.showPicker()}>
            <BsClock />
          </InputGroup.Text>
        )}
        <Field name={name}>
          {({ field }: { field: any }) => (
            <Form.Control
              type="time"
              {...field}
              ref={timeInputRef}
              disabled={disabled}
              isInvalid={isInvalid}
            />
          )}
        </Field>
      </InputGroup>

      <ErrorMessage name={name} component="span" className="text-danger fs-small" />
    </Form.Group>
  )
}
