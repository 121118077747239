import { useParams } from "react-router-dom";
import { useGetCoursePublicQuery } from "../../../../services/endpoints/schedule/course";
import { BackButton } from "../../../../components/buttons/BackButton";
import { Course } from "../../../../types/course.type";
import { useEffect, useState } from "react";
import { Col, Modal, Row, Stack } from "react-bootstrap";
import CourseImg from "../../../../assets/images/course_img.png";
import "./CourseDetails.css";
import { formatAddress, formatToCurrency, formatTwoDates, getClassesRemaining } from "../../../../utils/utils";
import { FaMapMarkerAlt, FaTag } from "react-icons/fa";
import { BsClock } from "react-icons/bs";
import { LuChefHat } from "react-icons/lu";
import AddFavorite from "./AddFavorite";
import CourseDetailsAccordion from "./CourseDetailsAccordion";
import CourseRegisterButton from "./CourseRegisterButton";
import AddChild from "../../../user/AddChild";
import CourseChildConfirmation from "./CourseChildConfirmation";
import { useGetUserProfileQuery } from "../../../../services/endpoints/people/user";
import CourseRegister from "./CourseRegister";
import { Lesson } from "../../../../types/lesson.type";

interface CourseDetailsProps {}

const CourseDetails: React.FC<CourseDetailsProps> = ({}) => {
  const params = useParams();
  const [course, setCourse] = useState<Course>();
  const [showAddChild, setShowAddChild] = useState(false);
  const [showChildConfirmation, setShowChildConfirmation] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const { data, isLoading } = useGetCoursePublicQuery(+params.id!);
  const { data: userProfile, refetch } = useGetUserProfileQuery();

  useEffect(() => {
    if (!isLoading && data) {
      setCourse(data.course);
      const actionQS = new URLSearchParams(window.location.search).get("action");
      setShowRegister(actionQS === "edit");
    }
  }, [data]);

  const hideOverlay = (showConfirmation: boolean) => {
    setShowAddChild(false);
    if (showConfirmation) {
      setShowChildConfirmation(true);
    }
  }

  const hideChildConfirmation = () => {
    setShowChildConfirmation(false);
  }
  
  const hideRegister = () => {
    setShowRegister(false);
  }

  const addChild = ({
    closeConfirmation,
    closeRegister,
    getProfile,
  }: {
    closeConfirmation?: boolean;
    closeRegister?: boolean;
    getProfile?: boolean;
  }) => {
    if (closeConfirmation) {
      setShowChildConfirmation(false);
    }
    if (closeRegister) {
      setShowRegister(false);
    }
    if (getProfile) {
      refetch();
    }
    setShowAddChild(true);
  };

  const classesRemaining: Lesson[] = course ? getClassesRemaining(course) : [];

  return (
    <>
      <div className="m-5">
        <BackButton text="Back" url="/sessions" color="#2B4E64" size=".95rem" />
        {course && (
          <>
            <Row className="mt-2">
              <Col xs={12} lg={4}>
                <div
                  className="course-img w-100"
                  style={{ backgroundImage: `url(${CourseImg})` }}
                ></div>
              </Col>
              <Col xs={12} lg={8}>
                <Stack direction="vertical" gap={2}>
                  <span className={"h2 text-capitalize text-info fw-bold mb-0"}>
                    {course?.title}
                  </span>
                  <span
                    className={"h4 text-capitalize text-secondary fw-bold mb-0"}
                  >
                    {formatTwoDates(course?.starts_on, course?.ends_on)}
                  </span>
                  <span className="text-muted">
                    {!course?.is_online ? "In-Person" : "Online"}
                  </span>
                  <div className="course-detail-row text-info">
                    <div className="detail-icon h5">
                      <FaMapMarkerAlt />
                    </div>
                    <div className="detail-text">
                      <span className="h5 text-uppercase fw-bold">
                        {course?.venue.title}
                      </span>
                      <br />
                      <span className="fw-bold">
                        {formatAddress(course?.venue)}
                      </span>
                    </div>
                  </div>
                  <div className="course-detail-row align-items-center fw-bold h5">
                    <div className="detail-icon">
                      <BsClock />
                    </div>
                    <div className="detail-text">
                      <span>
                        {course?.day_of_week} {course?.formatted_time}
                      </span>
                    </div>
                  </div>
                  <div className="course-detail-row align-items-center fw-bold h5">
                    <div className="detail-icon">
                      <LuChefHat />
                    </div>
                    <div className="detail-text">
                      <span>{`${classesRemaining.length} of ${course.lessons.length} classes remaining`}</span>
                    </div>
                  </div>
                  <div className="course-detail-row align-items-center h5">
                    <div className="detail-icon">
                      <FaTag />
                    </div>
                    <div className="detail-text">
                      <span className="fw-bold">Tuition price is </span>
                      <span className="fw-bold">
                        {formatToCurrency(
                          course.lessons.length * course.price_per_class
                        )}{" "}
                      </span>
                      <span style={{ color: "#5E6469BF" }}>
                        ({formatToCurrency(course.price_per_class)}/class)
                      </span>
                    </div>
                  </div>
                  <CourseRegisterButton
                    course={course}
                    addChild={() =>
                      addChild({
                        closeConfirmation: true,
                        getProfile: true,
                      })
                    }
                    registerCourse={() => setShowRegister(true)}
                    userChildren={userProfile?.user?.children ?? []}
                  />
                  <AddFavorite course_id={course.id} />
                </Stack>
              </Col>
            </Row>
            <CourseDetailsAccordion course={course} />
          </>
        )}
      </div>
      <Modal
        size="xl"
        backdrop={"static"}
        keyboard={true}
        show={showAddChild}
        onEscapeKeyDown={() => hideOverlay(false)}
      >
        <Modal.Body>
          <AddChild isModal={true} hideOverlay={() => hideOverlay(true)} />
        </Modal.Body>
      </Modal>
      <Modal
        centered={true}
        backdrop={"static"}
        keyboard={true}
        show={showChildConfirmation}
        onEscapeKeyDown={() => hideChildConfirmation()}
        dialogClassName="confirmation-modal"
      >
        <Modal.Body>
          <CourseChildConfirmation
            hideOverlay={hideChildConfirmation}
            addChild={() =>
              addChild({
                closeConfirmation: true,
                getProfile: true,
              })
            }
          />
        </Modal.Body>
      </Modal>
      <Modal
        centered={true}
        backdrop={"static"}
        keyboard={true}
        show={showRegister}
        onEscapeKeyDown={() => hideRegister()}
        dialogClassName="register-modal"
      >
        <Modal.Body>
          <CourseRegister
            course={course!}
            userChildren={userProfile?.user?.children ?? []}
            hideOverlay={hideRegister}
            classesRemaining={classesRemaining}
            addChild={() =>
              addChild({
                closeRegister: true,
              })
            }
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CourseDetails;
