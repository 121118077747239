import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { TeamProfile } from '../../../types/website.types';
import { sfcApi } from '../../api';
import { objectToFormData } from '../../helper';

export const teamProfileApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTeamProfiles: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `team-profiles${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['TeamProfile']
    }),
    getTeamProfile: builder.query<{ teamProfile: TeamProfile }, number>({
      query: (id) => `team-profiles/${id}`,
      providesTags: (result, error, id) => [{ type: 'TeamProfile', id }]
    }),
    createTeamProfile: builder.mutation<{ teamProfile: TeamProfile }, TeamProfile>({
      query: (data) => {
        let payload: any = {
          url: `team-profiles/create`,
          method: 'POST',
          body: data,
        };
        if (data.image) {
          payload.formData = true;
          payload.body = objectToFormData(data);
        }
        return payload;
      },
      invalidatesTags: ['TeamProfile']
    }),
    updateTeamProfile: builder.mutation<{ teamProfile: TeamProfile }, TeamProfile>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        let payload: any = {
          url: `team-profiles/update/${id}`,
          method: 'PUT',
          body: data
        };
        if (data.image) {
          payload.formData = true;
          payload.body = objectToFormData(data);
        }
        return payload;
      },
      invalidatesTags: (result, error) => [{ type: 'TeamProfile', id: result?.teamProfile?.id }, 'TeamProfile']
    }),
    deleteTeamProfile: builder.mutation<{ teamProfile: TeamProfile }, number>({
      query: (id) => ({
        url: `team-profiles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TeamProfile'], //(result, error, params) => [...params.ids.map(id => ({ type: 'TeamProfile', id })), 'TeamProfile']
    }),
  }),
});

export const {
  useGetAllTeamProfilesQuery,
  useGetTeamProfileQuery,
  useCreateTeamProfileMutation,
  useUpdateTeamProfileMutation,
  useDeleteTeamProfileMutation,
} = teamProfileApi;