import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getNestedTouchedTrue, getObjectDifference } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { SFCTerritory, SFCZipcode } from '../../../../../types/place.types';
import { useCreateTerritoryMutation, useGetTerritoryQuery, useUpdateTerritoryMutation } from '../../../../../services/endpoints/places/territory';
import { TerritoryForm } from './TerritoryForm';
import { useGetAllZipcodesQuery } from '../../../../../services/endpoints/places/zipcode';

const steps = [
  {name: 'Details', form: TerritoryForm},
];

export interface FormValues {
  id?: number;
  title: string;
  location_id: {value: number, label: string};
  zip_code_ids: {value: number, label: string}[];
  zip_codes?: string;
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

export type CreateFormValues = Overwrite<FormValues, {
  zip_code_ids?: {id: number}[],
  location_id: number,
  zip_codes: undefined, 
}>

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

const formatFormValue = (formValue?: FormValues | SFCTerritory): CreateFormValues => {
  return JSON.parse(JSON.stringify(formValue)) as CreateFormValues;
}

interface AddEditTerritoryProps {
  editing?: boolean;
}

export const AddEditTerritory: React.FC<AddEditTerritoryProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createTerritory] = useCreateTerritoryMutation();
  const [updateTerritory] = useUpdateTerritoryMutation();
  const { data, error, isLoading }  = useGetTerritoryQuery(Number(params.id!), { skip: !editing || !params.id });
  
  const [initialValues, setInitialValues] = useState<FormValues>({
    title: "",
    location_id: {value: 0, label: ''},
    zip_code_ids: [],
    zip_codes: "",
  });

  const [claimedZips, setClaimedZips] = useState<SFCZipcode[]>([]);
  const { data: claimedZipCodes, error: claimedZipsError, isLoading: claimedZipsLoading } = useGetAllZipcodesQuery({scope: 'taken'});

  useEffect(() => {
    if (claimedZipCodes && claimedZipCodes.results) {
      setClaimedZips(claimedZipCodes.results);
    }
  }, [claimedZipCodes]);

  const checkZipsAvailable = async (value: any, context: any) => {
    if (typeof value !== "string")
      return false;

    let zips = value.split(/[, \n]+/).filter(zip => ((initialValues?.zip_code_ids?.findIndex(z => (String(z.value) === zip)) ?? -1) !== -1));
    let claimedZipMatches = claimedZips.filter(z => zips.includes(String(z.code))).map(z => z.code);
    let zipString = claimedZipMatches.join(", ");
    let msgPart = (claimedZipMatches.length > 1) ? `codes ${zipString} are` : `code ${zipString} is`;
    if (claimedZipMatches.length > 0) { 
      return context.createError({message: `Zip ${msgPart} already claimed by another Territory/Franchise!`});
    }
    else {
      return true;
    }
  }

  const validationSchemas = [
    Yup.object({
      title: Yup.string().required('Title is required'),
      location_id: Yup.object({value: Yup.number().min(1, "Location is required").required("Location is required"), label: Yup.string().optional()}).required('Location is required'),
      zip_codes: Yup.string().matches(/^\d{5}([, \n]+\d{5})*$/, 'Invalid zip code detected').test('is-available', 'Unknown error occurred', checkZipsAvailable).required('At least one zip code is required'),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading session', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.territory) {
      const { territory } = data;
      let values = {
        ...territory,
        zip_code_ids: territory.zip_codes?.map(o => ({value: o.id, label: String(o.code)})) ?? [], 
        zip_codes: territory.zip_codes?.map(o => (String(o.code))).join(", ") ?? "", 
        ...{location_id: {value: territory?.location?.id ?? 0, label: territory?.location?.title ?? ""}},
      };
      setInitialValues(values);
    }
  }, [data]);


  const handleAddTerritory = async (formValue: FormValues) => {
    try {
      // formatFormValue(formValue);
      let submission = {
        ...formValue,
        zip_code_ids: formValue.zip_code_ids.map(o => ({id: o.value})) ?? undefined,
        zip_codes: formValue.zip_codes?.split(/[, \n]+/) ?? undefined,
        location_id: formValue.location_id.value,
      } as CreateFormValues;

      try {
        if (editing) {
          delete submission.zip_codes;
          let formattedTerritory = formatFormValue(data?.territory);
          formattedTerritory.zip_code_ids = data?.territory.zip_codes.map(o => ({id: o.id})) ?? [];
          let update = getObjectDifference(formattedTerritory, submission);
          let submitUpdate = Object.keys(update).length > 0;
          if (submitUpdate) {
            update.id = data?.territory.id;
            const res = await updateTerritory(update as CreateFormValues).unwrap();
            if (res && res.territory) {
              addToast(`Territory updated succesfully`, 'success');
            }
          }
          else {
            addToast(`No changes to Territory`, 'info');
          }
          navigate(`/admin/places/territories/${data?.territory.id}`);
        } else {
          delete submission.zip_code_ids;
          const res = await createTerritory(submission).unwrap();
          if (res && res.territory) {
            navigate(`/admin/places/territories/${res.territory.id}`);
            addToast('Territory created succesfully', 'success');
          } else throw new Error('A problem happened while creating Territory');
        }
      } catch (e) {
        addToast(getErrorMessage(e), 'error');
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        title: true,
        location_id: {value: true},
        zip_code_ids: Array.isArray(errors.zip_code_ids) ? errors.zip_code_ids?.map(() => getNestedTouchedTrue(Yup.object({value: Yup.number().positive()}))) : [],
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to territory${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/places/territories/${data?.territory.id}` : '/admin/places/territories'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Territory
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddTerritory}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => {
                return (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? async () => {
                          console.log(await validateForm());
                          submitForm();
                        } : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
