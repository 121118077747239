// TODO: types should come from backend
export const scholarshipTypes: { [key: string] : string } = {
  school_teacher: 'Teacher/Staff',
  parent_helper: 'Parent Helper',
  sibling: 'Sibling', 
  sfc_paid_scholarship: 'SFC Paid Scholarship',
  school_paid_scholarship: 'School Paid Scholarship',
  sfc_donation_certificate: 'SFC Donation Certificate',
  advanced_proration: 'Advanced Proration',
}; 
