import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import { useGetQuestionQuery } from '../../../../../services/endpoints/content/questions';
import SafeHTMLDisplay from '../../../../common/SafeHTMLDisplay';
import { question } from '../../../../../types/questions.type';

export const QuestionsDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetQuestionQuery(+params.id!);
  const [ questions, setQuestions] = useState<question>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
   
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setQuestions(data.question);
    }
  }, [data]);

  if (isLoading || !questions) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <div>Loading...</div>
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/content/questions')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Questions List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Questions #${questions?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/content/questions/${questions?.id}/edit`)}
          >
            Edit Questions
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete Questions
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Id:" text={questions?.id} />
            <LabelText label="Label:" text={questions?.name} />
            <LabelText label="Content:" ><SafeHTMLDisplay htmlContent={questions?.answer} /></LabelText>
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Question"
        message="Are you sure you want to remove this questions?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => navigate(`/admin/content/questions/${questions?.id}/delete`)}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
