import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { expensesColumns } from '../../../../../utils/consts/columnDefs/financeColumns';
import { useGetAllExpensesQuery, usePayExpensesMutation } from '../../../../../services/endpoints/finance/expense';
import { Expense } from '../../../../../types/finance.types';
import { expensesCategories, paymentMethods } from '../../../../../utils/consts/finance/finance';
import { getErrorMessage } from '../../../../../utils/utils';
import InputModal from '../../../../../components/modals/InputModal';
import { getCurrentUser } from '../../../../../services/helper';
import { useDataContext } from '../../../../../context';

const defaultScope = 'unpaid';
const currentUser = getCurrentUser();

export const ExpensesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetAllExpensesQuery(filters);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const [payExpenses] = usePayExpensesMutation();
  const [showModal, setShowModal] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState<number[]>([]);
  const isGlobalUser = (currentUser?.isGlobal);
  const isAdminUser = (currentUser?.isAdmin);

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'location_id',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'admin_full_name',
      label: 'Admin'
    },
    {
      name: 'session_title',
      element: (
        <SearchField name="session_title" label="Session" placeholder="Search session" />
      )
    },
    {
      name: 'venue_title',
      element: (
        <SearchField name="venue_title" label="Venue" placeholder="Search venue" />
      )
    },
    {
      name: 'category',
      element: (
        <FormSelect
          name="category"
          label="Category"
          options={Object.entries(expensesCategories).map((p) => ({ value: p[0], label: p[1] }))}
        />
      ),
    },
    {
      name: 'notes',
      label: 'Notes',
    },
    {
      name: 'amount',
      label: 'Amount',
      type: 'number',
    },
    {
      name: 'due_on_from',
      id: 'due_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="due_on_from" label="Due Date" />
      )
    },
    {
      name: 'due_on_to',
      id: 'due_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="due_on_to" />
      )
    },
    {
      name: 'paid_on_from',
      id: 'paid_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="paid_on_from" label="Paid On" />
      )
    },
    {
      name: 'paid_on_to',
      id: 'paid_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="paid_on_to" />
      )
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At" />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="created_at_to" />
      )
    },
    {
      name: 'invoice_number',
      label: 'Invoice Number',
      type: 'number',
    },
    {
      name: 'check_number',
      label: 'Check Number',
      type: 'number',
    },
    {
      name: 'payment_method',
      element: (
        <FormSelect
          name="payment_method"
          label="Payment Method"
          options={Object.entries(paymentMethods).map((p) => ({ value: p[0], label: p[1] }))}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setExpenses(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  const handlePayExpenses = async (checkNumber: string) => {
    try {
      await payExpenses({
        ids: selectedIDs,
        check_number: checkNumber.trim() === '' ? undefined : +checkNumber,
      }).unwrap();
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Expenses`}
        columns={!isAdminUser ? expensesColumns : expensesColumns.slice(1)}
        data={expenses}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='expenses'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate('/admin/finance/expenses/new')}
          >
            New Expense
          </SimpleButton>
        )}
        selectableRows={filters.scope !== 'paid'}
        batchActions={[
          {
            name: 'Pay Selected',
            fn: (selectedRowsIDs: number[]) => {
              setShowModal(true);
              setSelectedIDs(selectedRowsIDs)  
            }
          }
        ]}
      />
      <InputModal
        show={showModal}
        title="Check Number"
        message="Enter check number if paying by check. If paying by credit card, leave blank."
        placeholder="Check number"
        onClose={() => setShowModal(false)}
        onSubmit={(value) => handlePayExpenses(value)}
      />
    </div>
  );
}
