import React from 'react';
import { Stack } from 'react-bootstrap';

export interface AuditedChange {
  name: string,
  new?: string | number | boolean,
  old?: string | number | boolean,
}

export const AuditedChangesCell = ({ changes }: { changes: string|null } ) => {
  let formattedChanges: AuditedChange[];
  try {
    formattedChanges = JSON.parse(changes ?? "[]");
  } catch (error) {
    formattedChanges = [{name: "unknown", new: changes ?? ""}];
  }
  return (
    <Stack>
      {formattedChanges.map((change, index) => (
        <div key={index}>
          <span className="fw-bolder">{`${change.old ? 'Old' : 'New'} ${change.name}: `}</span>
          <span>{change[change.old ? 'old' : 'new']}</span>
        </div>
      ))}
    </Stack>
  );
};
