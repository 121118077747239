import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../../context/ToastContext';
import { Spinner } from 'react-bootstrap';
import { useDeleteReceiptsMutation } from '../../../../../services/endpoints/finance/receipt';
import { getErrorMessage } from '../../../../../utils/utils';

interface HandleReceiptActionProps {
  action: 'delete';
}

export const HandleReceiptAction: React.FC<HandleReceiptActionProps> = ({ action }) => {
  const fetching = useRef(false);
  const params = useParams();
  const id = +params.id!;
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [deleteReceipts, { isUninitialized }] = useDeleteReceiptsMutation();
  const [loading, setLoading] = useState(true);

  const actionFn = async () => {
    try {
      await deleteReceipts({ids: [id]}).unwrap();
      addToast(`Receipt #${id} ${action}d correctly`, 'success');
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    } finally {
      setLoading(false);
      fetching.current = false;
      navigate('/admin/finance/receipts');
    }
  };
  
  useEffect(() => {
    const isFetching = fetching.current;
    if (isUninitialized && !isFetching) {
      fetching.current = true;
      actionFn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
 
  return (
    <Navigate to={`/admin/finance/receipts/${id}`} />
  )
}
