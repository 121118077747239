import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { Spinner, Stack } from "react-bootstrap";
import { useToast } from "../../../../../context/ToastContext";
import { dateToUTC } from "../../../../../utils/dateUtils";
import { DetailSection, LabelText } from "../../../../../components/generic";
import { SimpleButton } from "../../../../../components/buttons/SimpleButton";
import ConfirmationModal from "../../../../../components/modals/ConfirmationModal";
import { JokeCategory } from "../../../../../types/content.type";
import { useGetJokeCategoryQuery } from "../../../../../services/endpoints/content/jokeCategory";

export const JokeCategoryDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetJokeCategoryQuery(+params.id!);
  const [jokeCategory, setJokeCategory] = useState<JokeCategory>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (error && !isLoading) {
      addToast("Error while loading joke category", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setJokeCategory(data.jokeCategory);
    }
  }, [data]);

  if (isLoading || !jokeCategory) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    );
  }

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: "#EFEFEF" }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button
            className="btn btn-link p-0 text-info"
            onClick={() => navigate("/admin/content/joke-categories")}
          >
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: "#888888", fontSize: "1rem" }}
          >
            Joke Categories List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Joke Category #${jokeCategory?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() =>
              navigate(
                `/admin/content/joke-categories/${jokeCategory?.id}/edit`
              )
            }
          >
            Edit Joke Category
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete Joke Category
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Name:" text={jokeCategory.name} />
            <LabelText
              label="Created At:"
              text={
                jokeCategory?.created_at
                  ? dateToUTC(new Date(jokeCategory?.created_at), "MM/dd/yyyy")
                  : "N/A"
              }
            />
            <LabelText
              label="Updated At:"
              text={
                jokeCategory?.updated_at
                  ? dateToUTC(new Date(jokeCategory?.updated_at), "MM/dd/yyyy")
                  : "N/A"
              }
            />
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Joke Category"
        message="Are you sure you want to remove this joke category?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => navigate(`/admin/content/joke-categories/${jokeCategory?.id}/delete`)}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
