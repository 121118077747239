import React, { useState } from 'react';
import PinWiskLogo from '../../assets/images/pin_wisk.png';

interface ImageProps {
  src: string;
  alt?: string;
  fallbackSrc?: string; // Optional fallback image
  className?: string;
}

const ImageWithErrorHandling: React.FC<ImageProps> = ({ src, alt, fallbackSrc = PinWiskLogo, className }) => {
  const [hasError, setHasError] = useState(false);
  
  const handleError = () => {
    setHasError(true);
  };
  
  return (
    <div className={className} >
      {hasError && fallbackSrc ? (
        <img src={fallbackSrc} alt="Error loading image" />
      ) : (
        <img src={src} alt={alt} onError={handleError} />
      )}
    </div>
  );
};
      
export default ImageWithErrorHandling;
      