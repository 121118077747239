import { Button, Col, Form, Row, Spinner, Stack } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from '../AddEditSession';
import { useToast } from '../../../../../../context/ToastContext';
import { getErrorMessage } from '../../../../../../utils/utils';
import { TimeField } from '../../../../../../components/tables/filterForms/TimeField';
import { DateField } from '../../../../../../components/tables/filterForms/DateField';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useGetInstructorsQuery, User } from '../../../../../../services/endpoints/people/user';
import { useEffect } from 'react';
import { dateFromString, dateInTimeZone, formatDate } from '../../../../../../utils/dateUtils';
import { addDays } from 'date-fns';
import { useGetThemeQuery } from '../../../../../../services/endpoints/content/theme';

export const ClassesForm = ({ touched, errors, values, setFieldValue, readOnly }: StepProps) => {
  const { data, error, isLoading } = useGetInstructorsQuery();
  const {data: theme, error: themeError, isLoading: themeLoading} = useGetThemeQuery(+(values?.theme_id ?? 0), {skip: !(values?.id && values?.theme_id)});
  const toast = useToast();

  // Add classes based on schedule values
  useEffect(() => {
    if (values && !values!.classes?.length) {
      const endDate = dateFromString(`${values?.ends_on} ${values?.ends_at}`, 'yyyy-MM-dd HH:mm');
      let date = dateFromString(`${values?.starts_on} ${values?.starts_at}`, 'yyyy-MM-dd HH:mm');
      const classes = [];
      while (date <= endDate) {
        if (values.day_of_week.includes(formatDate(date, 'EEEE').slice(0, 3))
          && !values.no_class?.includes(formatDate(date, 'yyyy-MM-dd')))
          classes.push({
            date: formatDate(date, 'yyyy-MM-dd'),
            starts_at: values?.starts_at,
            ends_at: values?.ends_at,
            destroy: 'false',
          });
        date = addDays(date, 1);
      }
      setFieldValue!('classes', classes);
    }
  }, []);

  if (isLoading || themeLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  }

  if (error || themeError) {
    toast.addToast(getErrorMessage(error) ?? 'Error loading items', 'error');
  }

  const instructorOptions =
    data?.results.map((instructor: User) => ({ id: instructor.id, label: `${instructor.first_name} ${instructor.last_name}`})) ?? [];
  const lessonPlansOptions =
   theme?.theme?.lesson_plans?.filter(lp => lp.theme_lesson_plans && lp.theme_lesson_plans.featured_on === null && lp.theme_lesson_plans.purpose === "any_time").map((lp => ({label: lp.name, id: lp.id}))) ?? [];

  return (
    <Stack gap={3}>
      <h6
        className="fw-bold mb-0"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Classes
      </h6>

      {!readOnly && (
        <Form.Text>*Start and end times, if specified, will override the standard class time for that day.</Form.Text>
      )}

      <FieldArray name="classes">
        {({ remove, push }) => (
          <div>
            <Row>
              {values!.classes.map((value, index) => value.destroy === 'false' ? (
                <Col
                  key={index}
                  xs={12}
                  md={6}
                  lg={4}
                  className="mb-3"
                >
                  <Stack gap={2}>
                    <span className="text-secondary">{`Class #${index + 1} details`}</span>

                    <Form.Group controlId={`classes.${index}.date`}>
                      <DateField
                        name={`classes.${index}.date`}
                        label="Date"
                        disabled={readOnly}
                      />
                    </Form.Group>

                    <Row>
                      <Col xs={12} lg={6}>
                        <Form.Group controlId={`classes.${index}.starts_at`}>
                          <TimeField
                            name={`classes.${index}.starts_at`}
                            label="Start Time"
                            disabled={readOnly}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={6}>
                        <Form.Group controlId={`classes.${index}.ends_at`}>
                          <TimeField
                            name={`classes.${index}.ends_at`}
                            label="End Time"
                            disabled={readOnly}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group controlId={`classes.${index}.notes`}>
                      <Form.Label>Notes</Form.Label>
                      <Field
                        name={`classes.${index}.notes`}
                        type="text"
                        as={Form.Control}
                        disabled={readOnly}
                      />
                    </Form.Group>

                    { values?.id && 
                      <Form.Group controlId={`classes.${index}.lesson_plan_ids`}>
                        <Form.Label>Lesson Plan</Form.Label>
                        <Typeahead 
                          id={`classes.${index}.lesson_plan_ids`}
                          multiple
                          disabled={readOnly}
                          placeholder={readOnly ? '' : 'Type here'}
                          options={lessonPlansOptions}
                          selected={values?.classes[index].lesson_plan_ids}
                          onChange={(selected) => setFieldValue!(`classes.${index}.lesson_plan_ids`, selected)}
                        />
                      </Form.Group>
                    }

                    <Form.Group controlId={`classes.${index}.instructor_ids`}>
                      <Form.Label>Additional</Form.Label>
                      <Typeahead 
                        id={`classes.${index}.instructor_ids`}
                        multiple
                        disabled={readOnly}
                        placeholder={readOnly ? '' : 'Type here'}
                        options={instructorOptions}
                        selected={values?.classes[index].instructor_ids}
                        onChange={(selected) => setFieldValue!(`classes.${index}.instructor_ids`, selected)}
                      />
                    </Form.Group>

                    <Form.Group controlId={`classes.${index}.trainee_ids`}>
                      <Form.Label>Trainees</Form.Label>
                      <Typeahead 
                        id={`classes.${index}.trainee_ids`}
                        multiple
                        disabled={readOnly}
                        placeholder={readOnly ? '' : 'Type here'}
                        options={instructorOptions}
                        selected={values?.classes[index].trainee_ids}
                        onChange={(selected) => setFieldValue!(`classes.${index}.trainee_ids`, selected)}
                      />
                    </Form.Group>

                    <Form.Group controlId={`classes.${index}.observer_ids`}>
                      <Form.Label>Observers</Form.Label>
                      <Typeahead 
                        id={`classes.${index}.observer_ids`}
                        multiple
                        disabled={readOnly}
                        placeholder={readOnly ? '' : 'Type here'}
                        options={instructorOptions}
                        selected={values?.classes[index].observer_ids}
                        onChange={(selected) => setFieldValue!(`classes.${index}.observer_ids`, selected)}
                      />
                    </Form.Group>

                    {!readOnly && <Button
                      variant="primary"
                      size="sm"
                      className="btn-box-shadow border-0 text-uppercase"
                      style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                      onClick={() => value.id
                        ? setFieldValue!(`classes.${index}.destroy`, 'true')
                        : remove(index)
                      }
                    >
                      Remove  
                    </Button>}
                  </Stack>
                </Col>
              ) : <></>)}
            </Row>

            {!readOnly && <Button
              variant="primary"
              size="sm"
              className="btn-box-shadow border-0 text-uppercase"
              style={{ width: 152, fontSize: '.75rem', borderBottomLeftRadius: 8 }}
              onClick={() => push({
                date: formatDate(dateInTimeZone(), 'yyyy-MM-dd'),
                starts_at: values?.starts_at,
                ends_at: values?.ends_at,
                destroy: 'false',
              })}
            >
              + Add New Class 
            </Button>}
          </div>
        )}
      </FieldArray>

      <Form.Group>
        <Field as={Form.Floating} isInvalid={touched.classes && !!errors.classes} />
        <ErrorMessage name="classes" >{(msg) => {
          let errMsg = 'ERROR: ';
          if (Array.isArray(msg)) {
            (msg as unknown as {[key: string]: string}[]).forEach((msg, idx) => {
              if (msg) {
                errMsg += `Class #${idx} (${Object.values(msg).join(", ")}); `;
              }
            });
          }
          else if (typeof msg === 'string') {
            errMsg = msg;
          }
          else {
            errMsg = JSON.stringify(msg);
          }
          return <span className="text-danger fs-small">{errMsg}</span>;
        }}</ErrorMessage>
      </Form.Group>
    </Stack>
  )
}
