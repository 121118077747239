import { FormValues as ScholarshipValues } from '../../../features/dash/views/orders/Scholarships/AddEditScholarship';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Scholarship } from '../../../types/orders.type';
import { sfcApi } from '../../api';

export const scholarshipApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllScholarships: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: 'scholarships',
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Scholarship']
    }),
    getScholarship: builder.query<{ scholarship: Scholarship }, number>({
      query: (id) => `scholarships/${id}`,
      providesTags: (result, error, id) => [{ type: 'Scholarship', id }]
    }),
    createScholarship: builder.mutation<{ scholarship: Scholarship }, ScholarshipValues>({
      query: (data) => ({
        url: `scholarships/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Scholarship']
    }),
    updateScholarship: builder.mutation<{ scholarship: Scholarship }, { id: number, data: Partial<ScholarshipValues> }>({
      query: ({ id, data }) => ({
        url: `scholarships/update/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Scholarship', id }, 'Scholarship']
    }),
    deleteScholarship: builder.mutation<{ scholarship: Scholarship }, number>({
      query: (id) => ({
        url: `scholarships/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Scholarship', id }, 'Scholarship']
    }),
  }),
});

export const {
  useGetAllScholarshipsQuery,
  useGetScholarshipQuery,
  useCreateScholarshipMutation,
  useUpdateScholarshipMutation,
  useDeleteScholarshipMutation,
} = scholarshipApi;