
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Refund } from '../../../types/orders.type';
import { sfcApi } from '../../api';

export const refundApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRefunds: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `refunds${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Refund']
    }),
    getRefund: builder.query<{ refund: Refund }, number>({
      query: (id) => `refunds/${id}`,
      providesTags: (result, error, id) => [{ type: 'Refund', id }]
    }),
    processRefund: builder.mutation<{ refund: Refund }, number>({
      query: (id) => ({
        url: `refunds/run/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Refund']
    }),
    // createRefund: builder.mutation<{ refund: Refund }, RefundValues>({
    //   query: (data) => ({
    //     url: `refunds/create`,
    //     method: 'POST',
    //     body: data
    //   }),
    //   invalidatesTags: ['Refund']
    // }),
    // updateRefunds: builder.mutation<{ refund: Refund }, { id: number, data: Partial<RefundValues> }>({
    //   query: ({ id, data }) => ({
    //     url: `refunds/update/${id}`,
    //     method: 'PUT',
    //     body: data
    //   }),
    //   invalidatesTags: (result, error, { id }) => [{ type: 'Refund', id }, 'Refund']
    // }),
    // deleteRefunds: builder.mutation<{ refund: Refund }, number>({
    //   query: (id) => ({
    //     url: `refunds/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: (result, error, id) => [{ type: 'Refund', id }, 'Refund']
    // }),
  }),
});

export const {
  useGetAllRefundsQuery,
  useGetRefundQuery,
  useProcessRefundMutation,
  // useCreateRefundMutation,
  // useUpdateRefundsMutation,
  // useDeleteRefundsMutation,
} = refundApi;