import { ReactNode, useEffect, useState } from 'react';
import { Button, Container, Form, InputGroup, Stack } from 'react-bootstrap';
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Typeahead } from 'react-bootstrap-typeahead';
import { LocationSelection, SavedLocationSelection } from '../../services/helper';
import { LocationButton } from '../../components/buttons/LocationButton';
import { useMyLocationContext } from '../../context/LocationContext';

export interface LocationSearchProps {
  setLocation: (search: LocationSelection, valid: boolean) => void;
  button: ReactNode;
  current?: boolean
}

export const LocationSearch: React.FC<LocationSearchProps> = ({ setLocation, button, current = false }) => {
  const { myLocation, saveMyLocation } = useMyLocationContext();
  const GeocodingLibrary = useMapsLibrary('geocoding');
  const [geocodingService, setGeocodingService] = useState<google.maps.Geocoder>();
  const [validLocation, setValidLocation] = useState<boolean>(false);
  const [locationResults, setLocationResults] = useState<Array<LocationSelection>>();

  useEffect(() => {
    setValidLocation((locationResults?.length ?? 0) > 0);
  }, [locationResults])

  useEffect(() => {
    if (!GeocodingLibrary) return;
    setGeocodingService(new GeocodingLibrary.Geocoder());
  }, [GeocodingLibrary]);

  const preset = current ? [{label: myLocation?.label ?? '', value: JSON.stringify({label: myLocation?.label, location: myLocation?.location})}] : [];

  const getPlaceOptions = (search: string) => {
    if (!geocodingService) return;
    if (geocodingService) {
      geocodingService.geocode({"address": search}, (results => {
        let data = results?.map(r => {
          let zipIndex = r.address_components.findIndex(ac => ac.types.includes("postal_code"));
          let [zip] = zipIndex >= 0 ? r.address_components.splice(zipIndex, 1) : [undefined];
          let shortLabel = r.formatted_address; //r.address_components.map(ac => ac.short_name).join(", ");
          let label = zip ? String(zip.short_name) : shortLabel;
          return ({
            label: `${zip ? shortLabel : label}`, 
            value: JSON.stringify({location: r.geometry.location.toJSON(), label}),
          });
        }) ?? [];
        
        setLocationResults([...preset, ...data]);
      }))
    }
  }

  return (
    <>
      <Typeahead
        id={"location"}
        placeholder='Set by zip code or city'
        options={locationResults ?? []}
        defaultSelected={current ? preset : undefined}
        onChange={option => {setLocation(option?.[0] as LocationSelection, validLocation)}}
        onInputChange={ getPlaceOptions }
      />
      {button}
    </>
  );
};


interface LocationButtonFormProps {
}

export const LocationButtonForm: React.FC<LocationButtonFormProps> = ({ }) => {
  const { myLocation, saveMyLocation } = useMyLocationContext();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [location, setLocation] = useState<SavedLocationSelection | null>(myLocation);

  const savePreferredLocation = (search: LocationSelection, valid: boolean) => {
    let setLoc = saveMyLocation(search.value, true);
    if (valid) {
      let newLoc = setLoc;
      if (newLoc) {
        setLocation(newLoc);
        setShowForm(false);
      } 
    }
  }

  const button = (
    <Button variant="sfc-blueberry" onClick={() => setShowForm(false)}>X</Button>
  );

  const form = (<LocationSearch setLocation={savePreferredLocation} button={button} />);

  const myLocationButton = (
    <LocationButton 
      size="sm"
      width={200}
      onClick={() => setShowForm(true)}>
        <Stack>
          {`My Location`}
          <b className={'text-truncate'} style={{maxWidth: 150}}>{`${location?.label}`}</b>
        </Stack>
    </LocationButton>
  );

  const setLocationButton = (
    <LocationButton 
      size="sm"
      onClick={() => setShowForm(true)}>
        Set My Location
    </LocationButton>
  );

  return showForm ? form : (location ? myLocationButton : setLocationButton);
}

interface LocationSetterProps {
}

export const LocationSetter: React.FC<LocationSetterProps> = ({ }) => {
  return (
    <Container style={{maxWidth: "250px"}}>
      <APIProvider apiKey={'AIzaSyC3UNDf0rod5gJJ5TUk1z3p1fL0DNBXTg8'}>
        <InputGroup size="sm">
          <LocationButtonForm />
        </InputGroup>
      </APIProvider>
    </Container>
  );
}

export const LocationSetterNoForm: React.FC<LocationSearchProps> = ({ setLocation, button }) => {
  return (
    <>
      <APIProvider apiKey={'AIzaSyC3UNDf0rod5gJJ5TUk1z3p1fL0DNBXTg8'}>
        <InputGroup size="sm">
          <LocationSearch setLocation={setLocation} button={button} current={true} />
        </InputGroup>
      </APIProvider>
    </>
  );
}
