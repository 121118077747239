import ClassCalendarCard from "./ClassCalendarCard";
import recipeArtwork from "../../../assets/images/recipes-artwork.png";
import { Col, Row } from "react-bootstrap";
import { getRangeDate } from "../../../utils/utils";
import { useGetChildrenLessonPlansQuery } from "../../../services/endpoints/schedule/lesson";
import { useState } from "react";
import { Lesson } from "../../../types/lesson.type";
import { Link } from "react-router-dom";

function getLessonDate(lesson: Lesson): string {
  const date = new Date(lesson.date);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
}

function isLessonPast(lesson: Lesson): boolean {
  const date = new Date(lesson.date);
  const today = new Date();
  return date < today;
}

const initialRange = getRangeDate(new Date());

interface ClassDetailsRecipesProps {}

const ClassDetailsRecipes: React.FC<ClassDetailsRecipesProps> = () => {
  const [dateRange, setDateRange] = useState({
    starts_on: initialRange.start,
    ends_on: initialRange.end,
  });
  const { data, isLoading } = useGetChildrenLessonPlansQuery({
    data: dateRange,
  });
  
  const dateChange = ({ start, end }: { start: string; end: string }) => {
    if (start && end) {
      setDateRange({
        starts_on: start,
        ends_on: end,
      });
    }
  };

  return (
    <div className="container-fluid">
      <ClassCalendarCard dateChange={dateChange} lessons={data} />
      <Row>
        <Col style={{ textAlign: "center" }}>
          <img src={recipeArtwork} style={{ width: "382px" }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <span style={{ fontWeight: 600, color: "#5E646980" }}>
            Session Schedule & Recipes
          </span>
          {data &&
            data.map((lesson, index) => (
              <p key={`schedule-lesson-${index}`} className="text-secondary">
                <span
                  className="fw-bold text-uppercase"
                  style={{ color: isLessonPast(lesson) ? "#888888" : "" }}
                >
                  {getLessonDate(lesson)}{" "}
                </span>
                <span style={{ color: isLessonPast(lesson) ? "#888888" : "" }}>
                  - {lesson.course.title}
                </span>
                <span
                  className="text-uppercase"
                  style={{
                    color: isLessonPast(lesson) ? "#888888" : "#5E646980",
                  }}
                >
                  {" "}
                  ({lesson.formatted_time} MT)
                </span>
                <br />
                {lesson.lesson_plans.map((plan, idx) => (
                  <Link
                    key={`schedule-lesson-plan-${idx}`}
                    to={`/lesson-plans/${plan.slug}`}
                    className="text-capitalize"
                    style={{ textDecoration: "none", color: "#6098B8" }}
                  >
                    {plan.name}
                  </Link>
                ))}
              </p>
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default ClassDetailsRecipes;
