/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditAllergy';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetAllChildrenQuery } from '../../../../../services/endpoints/people/child';
import { allergyOptions, allergySeverityOptions } from '../../../../../utils/consts';

export const AllergyForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  const { data: children, error: childrenError, isLoading: childrenLoading }  = useGetAllChildrenQuery({data: {orderBy: {field: 'first_name', order: 'asc'}}});
  
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="child_id">
            <Field 
              as={FormSelect}
              name="child_id"
              label="Child"
              options={children?.results?.map((g) => ({ value: g.id, label: g.full_name })) ?? []}
              loadingError={childrenError ? "Error loading children"
                : (!!errors.child_id ? 'Child selection is required' : undefined)}
            />
            <ErrorMessage name="child_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId={`allergyy`}>
            <Form.Label>Allergy</Form.Label>
            <Field
              name={`allergy`}
              as={Form.Select}
              placeholder="Select"
              isInvalid={touched.allergy && !!errors.allergy}
            >
              <option>Select</option>
              {allergyOptions.map((allergy) => (
                <option value={allergy} key={allergy}>{allergy}</option>
              ))}
            </Field>
            <ErrorMessage name={`allergy`}>
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId={`severity`}>
            <Form.Label>Severity</Form.Label>
            <Field
              name={`severity`}
              as={Form.Select}
              placeholder="Select"
              isInvalid={touched.severity && !!errors.severity}
            >
              <option>Select</option>
              {allergySeverityOptions.map((opt) => (
                <option value={opt} key={opt}>{opt}</option>
              ))}
            </Field>
            <ErrorMessage name="severity">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="details">
            <Form.Label>Details</Form.Label>
            <Field
              name="details"
              as="textarea"
              className={`form-control ${touched.details && !!errors.details ? ' isInvalid' : ''}`}
              rows={4}
            />
            <ErrorMessage name="details">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
      </Row>
    </>
  )
}
