import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useNavigate } from 'react-router-dom';
import { scholarshipTypes } from '../../../../../utils/consts/orders/scholarships';
import { Discount, PromotionalCode, Scholarship } from '../../../../../types/orders.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useGetAllDiscountsQuery } from '../../../../../services/endpoints/orders/discounts';
import CheckmarkCell from '../../../../../components/tables/cellComponents/CheckmarkCell';
import { formatDate } from '../../../../../utils/dateUtils';
import { useGetAllPromotionalCodesQuery } from '../../../../../services/endpoints/orders/promotionalCode';
import { DateField } from '../../../../../components/tables/filterForms/DateField';

export const discountColumns: ColumnDef<PromotionalCode>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Code',
    accessorKey: 'code',
  },
  {
    header: 'Percent Discount',
    accessorKey: 'percent_discount',
  },
  {
    header: 'Is Active',
    accessorKey: 'is_active',
    enableSorting: false,
    cell: ({ getValue }) => <CheckmarkCell value={getValue<boolean>()} />,
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: ({ row }) => formatDate(new Date(row.original.created_at), 'MM/dd/yyyy h:mm zzz')
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: ({ row }) => formatDate(new Date(row.original.created_at), 'MM/dd/yyyy h:mm zzz')
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/orders/promotional-codes/${row.original.id}`} underline />
        <LinkCell content="Edit" url={`/admin/orders/promotional-codes/${row.original.id}/edit`} underline />
      </Stack>
    )
  },
];

const defaultScope = 'active';

export const PromotionalCodesView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: '' });
  const [scopes, setScopes] = useState<Scopes>({});
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const { data, error, isLoading, isFetching } = useGetAllPromotionalCodesQuery(filters);
  const [promoCodes, setPromoCodes] = useState<PromotionalCode[]>([]);;
  const navigate = useNavigate();

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      type: 'number',
      label: 'ID'
    },
    {
      name: 'name',
      label: 'Name'
    },
    {
      name: 'code',
      label: 'Code'
    },
    {
      name: 'is_active',
      type: 'number',
      element: (
        <FormSelect
          name="is_active"
          label="Is Active"
          options={[{ value: 1, label: 'Active' }, { value: 0, label: 'Inactive' }]}
        />
      ),
    },
    {
      name: 'created_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_from" label="Created At (From-To)" />
      )
    },
    {
      name: 'created_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="created_to" />
      )
    },
    {
      name: 'updated_from',
      id: 'updated_at',
      operator: 'greater_equal_than',
      element: <DateField name="updated_from" label="Updated At (From-To)" />
    },
    {
      name: 'updated_to',
      id: 'updated_at',
      operator: 'lesser_equal_than',
      element: <DateField name="updated_to" />
    },
  ];
  
  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading discounts', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setPromoCodes(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Promotional Codes`}
        columns={discountColumns}
        data={promoCodes}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters.data?.filterBy}
        indexDownloadPath='promotional-codes'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={520}
            onClick={() => navigate('/admin/orders/promotional-codes/new')}
          >
            New promotional code
          </SimpleButton>
        )}
      />
    </div>
  );
}
