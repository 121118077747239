import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { newsItemColumns } from '../../../../../utils/consts/columnDefs/websiteColumns';
import { useGetAllNewsItemsQuery } from '../../../../../services/endpoints/website/newsItem';
import { NewsItem } from '../../../../../types/website.types';
import { getCurrentUser } from '../../../../../services/helper';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { imageFilterFields } from '../../../../../utils/consts/filterFields/image';
import { created_updated_at_from_to } from '../../../../../utils/consts/filterFields/dates';

const defaultScope = '';
const currentUser = getCurrentUser();

export const NewsItemsView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllNewsItemsQuery(filters);
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const navigate: NavigateFunction = useNavigate();
  const isGlobalUser = (currentUser?.isGlobal);

  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'details',
      label: 'Details',
    },
    ...imageFilterFields,
    {
      name: 'published_on_from',
      id: 'published_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="published_on_from" label="Published On" />
      )
    },
    {
      name: 'published_on_to',
      id: 'published_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="published_on_to" />
      )
    },
    ...created_updated_at_from_to,
  ];

  if (!isGlobalUser) {
    filterFormFields.splice(4, 1);
  }

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setNewsItems(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`News Items`}
        columns={newsItemColumns}
        data={newsItems}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='news-items'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={270}
            onClick={() => navigate('/admin/website/news-items/new')}
          >
            New News Item
          </SimpleButton>
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
