import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import { CourseDateTimes, course_type_style_lookup } from './CourseDates';
import { useNavigate } from 'react-router-dom';
import { Course } from '../../../types/course.type';
import Sticker from '../../../assets/images/cooking-club-sticker.png';
import { CourseVenue } from './CourseVenue';
import { CourseCostSimple } from './CourseCostSimple';


export const course_type_card_style_lookup = (key: string) => {
  switch (key) {
    case 'after_school': return 'pink';
    case 'camp': return 'orange';
    case 'event': return 'green';
    default: return 'primary';
  }
}

export interface CourseCardSimpleProps {
  course: Course;
  selected?: boolean;
  onClose?: () => void;
  onClick?: () => void;
}

export const CourseCardSimple: React.FC<CourseCardSimpleProps> = ({ course, selected, onClose, onClick }) => {

  const navigate = useNavigate();

  let className = course_type_card_style_lookup(course.course_type);
  let fillStyle = course_type_style_lookup(course.course_type);

  return (
    <Card className={`shadow m-1 course-card-simple ${className}`} onClick={() => selected ? undefined : onClick?.()}>
      <Card.Body>
        <Stack gap={1} >
          <div className={'text-center'}><img src={Sticker} height={130} width={130} /></div>
          <Stack direction="horizontal">
            <Stack gap={1}>
              <Card.Subtitle className={`course-card-title ${className}`}>{course.title}</Card.Subtitle>
              <CourseDateTimes course={course} />
              <b className="text-muted">{course.is_online ? "Online" : "In-Person"}</b>
              <CourseVenue course={course} color={className} fillStyle={fillStyle} />
              <CourseCostSimple course={course} />
            </Stack>
          </Stack>
          <Stack direction='horizontal'>
            <Button variant={'primary'} size="sm" className={"text-uppercase mx-2 my-1 form-control"} onClick={() => navigate(`/sessions/${course.id}`)}>View Details</Button>
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
};
