import React from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { PiCaretDownFill, PiCaretUpDownFill, PiCaretUpFill } from 'react-icons/pi';
import { Accordion } from 'react-bootstrap';
import { getCurrentDateOnly } from '../../utils/dateUtils';

interface SimpleTableProps {
  columns: ColumnDef<any>[];
  data: any;
  maxHeight?: string;
}

// Table used for expanded rows information
export const SimpleTable: React.FC<SimpleTableProps> = ({ columns, data, maxHeight }) => {
  const {
    getHeaderGroups,
    getRowModel,
  } = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Accordion className="overflow-auto" style={{maxHeight: maxHeight ?? '50vh'}}>
      <table className="table sfc-table simple-table">
        <thead>
          {getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  <div
                    {...{
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                    className="text-truncate"
                    style={{ whiteSpace: 'pre' }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && ({
                      asc: <PiCaretUpFill className="ms-2 mb-1" />,
                      desc: <PiCaretDownFill className="ms-2 mb-1" />,
                      false: <PiCaretUpDownFill className="ms-2 mb-1" />,
                    }[header.column.getIsSorted() as string] ?? null)}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {getRowModel().rows.map((row, idx, arr) => { 
            let dateComp = getCurrentDateOnly();
            let colorSet = idx > 0 && (arr[idx-1].original.date >= dateComp);
            let coloredBg = false;
            if (row.original.date >= dateComp && !colorSet) {
              coloredBg = true;
            }
            
            return (
              <tr key={row.id} className={`align-middle ${coloredBg ? 'table-primary' : ''}`}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ) 
          })}
        </tbody>
      </table>
    </Accordion>
    
  );
};