import { Stack } from 'react-bootstrap';
import { StepProps } from '../AddEditSession';
import { DocumentsAndFilesForm } from './DocumentsAndFilesForm';
import { SessionDetailsForm } from './SessionDetailsForm';
import { VenueForm } from './VenueForm';
import { InstructorDetailsForm } from './InstructorDetailsForm';
import { AdditionalInfoForm } from './AdditionalInfoForm';
import { ScheduleForm } from './ScheduleForm';
import { ClassesForm } from './ClassesForm';

export const ReviewStep = ({ touched, errors, values, setFieldValue }: StepProps) => {

  return (
    <Stack gap={2}>
      <SessionDetailsForm errors={errors} touched={touched} values={values} readOnly />
      <hr />
      <VenueForm errors={errors} touched={touched} values={values} readOnly />
      <hr />
      <InstructorDetailsForm errors={errors} touched={touched} values={values} readOnly />
      <hr />
      <AdditionalInfoForm errors={errors} touched={touched} values={values} readOnly />
      <hr />
      <ScheduleForm errors={errors} touched={touched} values={values} readOnly />
      <hr />
      <ClassesForm errors={errors} touched={touched} values={values} readOnly />
      <hr />
      <DocumentsAndFilesForm errors={errors} touched={touched} values={values} readOnly />
    </Stack>
  )
}
