import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Card, Col, Row, Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { useGetVenueLedgerQuery, useGetVenueQuery } from '../../../../../services/endpoints/places/venue';
import { SFCVenue } from '../../../../../types/place.types';
import { getCurrentUser } from '../../../../../services/helper';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import React from 'react';
import CheckmarkCell from '../../../../../components/tables/cellComponents/CheckmarkCell';
import { DetailSection, LabelText } from '../../../../../components/generic';
import YesNoCell from '../../../../../components/tables/cellComponents/YesNoCell';
import { ReactComponent as LedgerIcon } from '../../../../../assets/icons/ledger.svg';
import { IconLink } from '../../../../../components/iconLink/IconLink';

const currentUser = getCurrentUser();

export const VenueDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetVenueQuery(+params.id!);
  const { data: ledger, error: ledgerError, isLoading: isLoadingLedger } = useGetVenueLedgerQuery({id: +params.id!}, {skip: !(currentUser?.isAdmin) || (params.id === undefined)});
  const [venue, setVenue] = useState<SFCVenue>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading venue', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setVenue(data.venue);
    }
  }, [data]);

  if (isLoading || !venue) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/places/venues')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Venues List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Venue #${venue?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/places/venues/${venue?.id}/edit`)}
          >
            Edit venue
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <Row>
            <Col>
              <DetailSection title="Details">
                <LabelText label="Title:">
                  {venue?.title}
                </LabelText>
                <LabelText label="Active:">
                  <YesNoCell value={venue?.is_active} />
                </LabelText>
                <LabelText label="Location:">
                  <LinkCell
                    content={venue?.location?.title ?? 'No location title'}
                    url={`/admin/places/locations/${venue?.location?.id}`}
                  />
                </LabelText>
                <LabelText label="Organizations:">
                  {venue?.organizations?.map(o => (
                    <React.Fragment key={o.id}>
                      <LinkCell
                        content={o.name}
                        url={`/admin/places/organizations/${o.id}`}
                      />
                      ,&nbsp;
                    </React.Fragment>
                  ))}
                </LabelText>
                <LabelText label="Phone:">
                  {venue?.phone!}
                </LabelText>
                <LabelText label="Address:">
                  {venue?.address!}
                </LabelText>
                <LabelText label="Address2:">
                  {venue?.address2!}
                </LabelText>
                <LabelText label="City:">
                  {venue?.city!}
                </LabelText>
                <LabelText label="State:">
                  {venue?.state!}
                </LabelText>
                <LabelText label="Zip:">
                  {venue?.zip!}
                </LabelText>
                <LabelText label="Latitude:">
                  {venue?.latitude!}
                </LabelText>
                <LabelText label="Longitude:">
                  {venue?.longitude!}
                </LabelText>
                <LabelText label="Notes:">
                  {venue?.notes!}
                </LabelText>
                <LabelText label="Facility Use Payment Mailing Details:">
                  {venue?.facility_use_fee_payment_details!}
                </LabelText>
                <LabelText label="Session History:">
                  <LinkCell
                    content={`${venue?.course_count} Session${venue?.course_count !== 1 ? 's' : ''}`}
                    url={`/admin/schedule/sessions?venue_id=${venue?.id}`}
                  />
                </LabelText>
                <LabelText label="Revenue History:">
                  <LinkCell
                    content={<CurrencyCell value={String(Object.values(ledger?.ledger?.revenue ?? {}).reduce((acc, curr) => acc + curr, 0))} />}
                    url={`/admin/places/venues/${venue?.id}/ledger`}
                  />
                </LabelText>
              </DetailSection>
              </Col>
            <Col>
              {(currentUser?.isSuper || (currentUser?.isAdmin && +(currentUser?.location_id ?? 0) === venue.location_id)) ? (
              <DetailSection title="Reports">
                <IconLink icon={<LedgerIcon />} text={'Summary Session Financials'} url={`/admin/places/venues/${venue.id}/ledger`} />
              </DetailSection>
              ) : <></>}
            </Col>
          </Row>
          <DetailSection title="Contacts">
            {venue.contacts.map(c => (
              <Col md={6} lg={5} xl={4} xxl={3} >
                <Card className="text-left shadow">
                  <Card.Body>
                    <Card.Text className={"h2 text-capitalize"} >{c.full_name}</Card.Text>
                    <br />
                    <LabelText label="Email:">
                      {c.email}
                    </LabelText>
                    <LabelText label="Receives Rosters:">
                      <CheckmarkCell value={c.should_be_sent_roster} />
                    </LabelText>
                    <LabelText label="Receives Invoices:">
                      <CheckmarkCell value={c.should_be_sent_invoices} />
                    </LabelText>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
