import { Button } from 'react-bootstrap';
import { FC, ReactNode } from 'react';

interface SimpleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: string;
  height?: number;
  width?: number | string;
  size?: 'lg' | 'sm' | undefined;
  children: ReactNode,
  href?: string,
}

export const SimpleButton: FC<SimpleButtonProps> = ({ variant, height, width, size, children, ...rest }) => {
  
  return (
    <Button
      variant={variant}
      size={size}
      {...rest}
      style={{ height: height ?? 52, width: width ?? 'auto' }}
      className={`${rest.className} ${!variant.includes('outline') ? 'btn-box-shadow border-0 text-uppercase' : ''} text-uppercase text-truncate`}
    >
      {children}      
    </Button>
  )
}
