import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { question } from '../../../types/questions.type';
import { sfcApi } from '../../api';
export interface QuestionCreateUpdateValues {
    id?: number;
    name: string;
    answer:string;
  }
export const questionApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuestions: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `questions${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getQuestion: builder.query<{ question: question }, number>({
      query: (id) => `questions/${id}`,
    }),


    createQuestion: builder.mutation<{ question: question }, QuestionCreateUpdateValues>({
      query: (data) => {

        return {
            url: 'questions/create',
            method: 'POST',
            body: {
              ...data
            },
          }
      },
      invalidatesTags: ['Questions']
    }),

    updateQuestion: builder.mutation<{ question: question }, QuestionCreateUpdateValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `questions/update/${id}`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
      invalidatesTags: ['Questions']

    }),

    deleteQuestion: builder.mutation<{ question: question }, number>({
      query: (id) => ({
        url: `questions/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetQuestionsQuery,
  useGetQuestionQuery,
  useCreateQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation
} = questionApi;