/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditPage';
import ImageWithErrorHandling from '../../../../../components/image/ImageWithErrorHandling';
import { ImageSelect } from '../../../../../components/inputs/ImageSelect';
import { getPartition } from '../../../../../utils/utils';
import { useGetAllGalleriesQuery } from '../../../../../services/endpoints/website/gallery';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import Editor from '../../../../../components/editor/Editor';

export const PageForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  const { data: galleries, error: galleriesError, isLoading: galleriesLoading }  = useGetAllGalleriesQuery();
  
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Field
              name="name"
              as={Form.Control}
              isInvalid={touched.name && !!errors.name}
            />
            <ErrorMessage name="name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group className="mb-1 mb-lg-3" controlId="author">
            <Form.Label>Author</Form.Label>
            <Field
              name="author"
              as={Form.Control}
              isInvalid={touched.author && !!errors.author}
            />
            <ErrorMessage name="author">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="published_on">
            <Form.Label>Published On</Form.Label>
            <Field
              name="published_on"
              type="date"
              as={Form.Control}
              isInvalid={touched.published_on && !!errors.published_on}
            />
            <ErrorMessage name="published_on">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="image_name">
            <ImageSelect
              label={`Image${values?.image_file_name ? ` (${values?.image_file_name})` : ''}`}
              name="image"
              onChange={setFieldValue}
            />
            <ImageWithErrorHandling
              src={`${values?.s3_base_url}/${getPartition(values?.id)}/thumb/${values?.image_file_name}`} 
              alt="Error loading image"
            />
            <ErrorMessage name="image">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(JSON.stringify(msg, null, 2))}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="is_featured">
            <Form.Label>
              <Field
                name="is_featured"
                type="checkbox"
                as={Form.Check}
                label="Is Featured"
              />
            </Form.Label>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="content">
            <Form.Label>Content</Form.Label>
            <Field
              name="content"
              component={Editor}
              customItems={["heading", "bold", "italic", "fontColor", "bulletedList", "imageUpload", "insertTable", "horizontalLine", "link", "undo", "redo"]}
              isInvalid={touched.content && !!errors.content}
            />
            <ErrorMessage name="content">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="button_text">
            <Form.Label>Button Text</Form.Label>
            <Field
              name="button_text"
              as={Form.Control}
              isInvalid={touched.button_text && !!errors.button_text}
            />
            <ErrorMessage name="button_text">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="button_target">
            <Form.Label>Button Target</Form.Label>
            <Field
              name="button_target"
              as={Form.Control}
              isInvalid={touched.button_target && !!errors.button_target}
            />
            <ErrorMessage name="button_target">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="button_opens_in_new_window">
            <Form.Label>
              <Field
                name="button_opens_in_new_window"
                type="checkbox"
                as={Form.Check}
                label="Button Opens In New Window"
              />
            </Form.Label>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="gallery_id">
            <Field 
              as={FormSelect}
              name="gallery_id"
              label="Gallery"
              options={galleries?.results?.map((g) => ({ value: g.id, label: g.name })) ?? []}
              loadingError={galleriesError ? "Error loading galleries"
                : (!!errors.gallery_id ? 'Gallery selection is required' : undefined)}
            />
            <ErrorMessage name="gallery_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
