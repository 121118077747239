import { Form, FormControl } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from '../AddChild';

export const WaiverForm = ({ touched, errors }: StepProps) => {

  return (
    <>
      {/* TODO: Update this with waiver not from old site! */}
      <object data="https://sfc-public.s3.us-west-2.amazonaws.com/StickyFingersCooking%C2%AE.Updated.Waiver.Generic_updated_April_2024.pdf" style={{height:"60vh", width:"100%"}}></object>
      <Form.Group controlId="agree_to_terms">
        <Form.Label>
          <Field
            name="agree_to_terms"
            type="checkbox"
            as={Form.Check}
            label={'Accept Terms & Conditions'}
            isInvalid={touched.agree_to_terms && !!errors.agree_to_terms}
          />
        </Form.Label>
        <ErrorMessage name="agree_to_terms">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>
    </>
  )
}
