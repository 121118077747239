import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface CartContextType {
  addCourses: (ids: number[]) => void;
  removeCourses: (ids: number[]) => void;
  clearCart: () => void;
  courseIds: number[];
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCartContext must be used within a CartProvider');
  }
  return context;
};

const storedCourseIds = JSON.parse(localStorage.getItem("course_ids") ?? '[]');

const storeCourseIds = (cids: number[]) => {
  localStorage.setItem("course_ids", JSON.stringify(cids));
}

export const CartProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [courseIds, setCourseIds] = useState<number[]>(storedCourseIds);

  const addCourses = useCallback((ids: number[]) => {
    if (ids.length === 0) return;
    setCourseIds(old => {
      let cIds = [...(new Set([...old, ...ids]))];
      storeCourseIds(cIds);
      return cIds;
    })
  }, []);

  const removeCourses = useCallback((ids: number[]) => {
    if (ids.length === 0) return;
    setCourseIds(old => {
      let copy = [...old];
      let update = copy.filter(i => !ids.includes(i));
      storeCourseIds(update);
      return update;
    })
  }, []);

  const clearCart = useCallback(() => {
    setCourseIds([]);
    storeCourseIds([]);
  }, []);

  return (
    <CartContext.Provider value={{ courseIds, addCourses, removeCourses, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
