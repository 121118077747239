import React from 'react';
import { formatDate, formatUTCDateOnly, UTCToDate } from '../../../utils/dateUtils';

// props based on values coming from backend
// date - format (yyyy-MM-dd)
// time - hh:mm a
interface DateCellProps {
  date?: string;
  time?: string;
  format?: string;
}

export const DateCell: React.FC<DateCellProps> = ({ date, time, format = 'MM/dd/yyyy' }) => {
  if (!date || date === '') return <>N/A</>;
  let dateStr = `${date}${time ? ` ${time}` : ''}`;
  let formatStr = `yyyy-MM-dd${time ? ' hh:mm a' :''}`;
  return (
    <span className="text-truncate">
      {time ? formatDate(UTCToDate(dateStr, formatStr), format) : formatUTCDateOnly(new Date(dateStr), format)}
    </span>
  )
}
