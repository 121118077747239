import React, { createContext, useContext, useState, ReactNode, ReactElement } from 'react';

interface DashNavbarContextProps {
  activeNavbar: string | null;
  setActiveNavbar: (navbar: string | null) => void;
  filterNavContent: ReactElement | null;
  setFilterNavContent: (content: ReactElement | null) => void;
  filterNavOverlap: boolean;
  setFilterNavOverlap:(overlap: boolean) => void;
}

const DashNavbarContext = createContext<DashNavbarContextProps | undefined>(undefined);

export const DashNavbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeNavbar, setActiveNavbar] = useState<string | null>('dash');
  const [filterNavContent, setFilterNavContent] = useState<ReactElement | null>(null);
  const [filterNavOverlap, setFilterNavOverlap] = useState<boolean>(false);

  return (
    <DashNavbarContext.Provider value={{ activeNavbar, setActiveNavbar, filterNavContent, setFilterNavContent, filterNavOverlap, setFilterNavOverlap }}>
      {children}
    </DashNavbarContext.Provider>
  );
};

export const useNavbar = () => {
  const context = useContext(DashNavbarContext);
  if (context === undefined) {
    throw new Error('useNavbar must be used within a NavbarProvider');
  }
  return context;
};