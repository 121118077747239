/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  getPaginationRowModel,
  SortingState,
  Column,
  Row as RTRow,
  RowSelectionState,
  ColumnSort,
} from '@tanstack/react-table';
import { Accordion, Button, Col, Dropdown, DropdownButton, Form, InputGroup, Row, Spinner, Stack } from 'react-bootstrap';
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight, BsSearch } from 'react-icons/bs';
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { PiCaretUpDownFill, PiCaretDownFill, PiCaretUpFill } from "react-icons/pi";
import { SimpleTable } from './SimpleTable';
import './SFCTable.css';
import { ButtonData } from '../buttonGroup/ButtonGroup';
import { FilterGroup } from '../filterGroup/FilterGroup';
import { Filter, GetQueryParams } from '../../types/api.type';
import { useNavbar } from '../../context/DashNavbarContext';
import { FadeIn } from '../../features/animations/FadeIn';
import { Formik } from 'formik';
import { downloadIndex } from '../../services/api';
import { DashLogo } from '../generic';

export interface RowFormProps {
  validationSchema: any;
  onSubmit: (values: any) => void;
  getInitialValues: (row: RTRow<any>) => any;
}

// Custom checkbox component for selecting rows
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        style={{ margin: 0 }} // Optional styling to remove any margin
      />
    );
  }
);

interface SFCTableProps {
  name?: string;
  columns: ColumnDef<any>[];
  data: any;
  count?: number;
  defaultScope?: string;
  scopes?: ButtonData[][];
  isLoading?: boolean;
  isFetching?: boolean;
  expandedItem?: { columns: ColumnDef<any>[], rowKey: string, filterFn: Function };
  filterForm?: ReactElement;
  onFiltersChanged?: React.Dispatch<React.SetStateAction<GetQueryParams>>;
  newItemEl?: ReactNode;
  rowFormProps?: RowFormProps;
  selectableRows?: boolean;
  batchActions?: { name: string, fn: Function }[];
  indexDownloadPath?: string;
  filters?: Filter[];
  sidebars?: ReactNode[];
  defaultSort?: ColumnSort;
}

const SFCTable: React.FC<SFCTableProps> = ({
  name,
  columns,
  data,
  count,
  defaultScope,
  scopes,
  isLoading,
  isFetching,
  expandedItem,
  filterForm,
  onFiltersChanged,
  newItemEl,
  rowFormProps,
  selectableRows,
  indexDownloadPath,
  filters,
  batchActions,
  sidebars,
  defaultSort,
}) => {
  const { activeNavbar, setActiveNavbar, setFilterNavContent, setFilterNavOverlap } = useNavbar();
  const [globalFilter, setGlobalFilter] = useState('');
  const [scope, setScope] = useState(defaultScope ?? '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>(defaultSort ? [defaultSort] : []);
  const [orderBy, setOrderBy] = useState<{ field: string, order: string } | undefined>();
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});

  useEffect(() => {

    // Set filter nav content to null on unmount
    return () => {
      setFilterNavContent(null);
      setFilterNavOverlap(false);
    };
  }, []); 

  useEffect(() => {
    if (filterForm) {
      setFilterNavContent(filterForm);
      setFilterNavOverlap(!!sidebars?.length);
    }
  }, [filterForm]);

  useEffect(() => {
    // if (scope === '') return;
    onFiltersChanged?.((old) => ({
      ...old,
      scope,
      data: {
        ...old?.data,
        orderBy: orderBy?.field ? orderBy : old.data?.orderBy,
        page: pagination.pageIndex + 1,
        entries: pagination.pageSize,
      }
    }));
  }, [pagination, scope, orderBy]);

  const download = (format: 'json' | 'csv') => {
    if (indexDownloadPath) {
      downloadIndex({
        path: indexDownloadPath,
        scope: scope,
        body: { orderBy, filterBy: filters },
        format,
        method: "POST",
      });
    }
  }

  const {
    getHeaderGroups,
    getRowModel,
    getState,
    getPageCount,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    previousPage,
    nextPage,
    firstPage,
    lastPage,
    setPageIndex,
    setPageSize,
    getSelectedRowModel,
    getIsAllRowsSelected,
    getIsSomeRowsSelected,
    getToggleAllPageRowsSelectedHandler,
  } = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      pagination,
      sorting,
      rowSelection,
    },
    manualPagination: true,
    onPaginationChange: setPagination, //update the pagination state when internal APIs mutate the pagination state
    rowCount: count,
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  });

  const getCanLastPage = () => {
    return getState().pagination.pageIndex !== getPageCount()-1;
  }

  const getCanFirstPage = () => {
    return getState().pagination.pageIndex !== 0;
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageIndex = getState().pagination.pageIndex;

    if (getPageOptions().length <= 3) {
      // If total pages are less than or equal to 3, show all pages
      for (let i = 0; i < getPageOptions().length; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => setPageIndex(i)}
            className={`rounded-1 border-0 mx-1 ${i === pageIndex ? 'active' : ''}`}
            style={{
              background: i === pageIndex ? '#F2F2F2' : 'transparent',
              color: i === pageIndex ? '#282828' : '#888888',
              fontSize: '.75rem',
            }}
          >
            {i + 1}
          </button>
        );
      }
    } else {
      // If more than 3 pages, dynamically adjust the page numbers displayed
      const startPage = pageIndex === 0 ? pageIndex : pageIndex === getPageOptions().length - 1 ? pageIndex - 2 : pageIndex - 1;
      const endPage = pageIndex === 0 ? pageIndex + 2 : pageIndex === getPageOptions().length - 1 ? pageIndex : pageIndex + 1;

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => setPageIndex(i)}
            className={`rounded-1 border-0 mx-1 ${i === pageIndex ? 'active' : ''}`}
            style={{
              background: i === pageIndex ? '#F2F2F2' : 'transparent',
              color: i === pageIndex ? '#282828' : '#888888',
              fontSize: '.75rem',
            }}
          >
            {i + 1}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  const handleSortingChange = (id: string, column: Column<any, unknown>) => {
    const nextSorting = column.getNextSortingOrder();
    if (!nextSorting) setOrderBy(undefined);
    else setOrderBy({ field: id, order: nextSorting });
    column.toggleSorting();
  };

  const selectedRowCount = getSelectedRowModel().rows.length;
  const isAtLeastOneRowSelected = selectedRowCount > 0;

  const selectedRowsIDs = getRowModel().rows.filter((row) =>
    Object.keys(rowSelection).includes(row.id)
  ).map((el) => el.original.id);

  if (isLoading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );

  const tableContent = (
      <React.Fragment>
        <Stack className="d-lg-none mb-3" gap={3}>
          <Stack direction="horizontal" gap={2}>
              <h6 className="fw-bolder text-secondary mb-0 mb-lg-0 ps-0 ps-lg-2" style={{ fontSize: '1.125rem' }}>
                {name || scopes?.[0]?.[0]?.title}
              </h6>
              <span
                className="rounded-4 px-2 py-1 fw-bold"
                style={{ background: '#F2F4F7', color: '#888888', fontSize: '.75rem' }}
              >
                {count} items
              </span>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <InputGroup>
              <InputGroup.Text>
                <BsSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                aria-label="Search"
                name="search"
                style={{ height: 40 }}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
              />
            </InputGroup>
            {filterForm && <Button
              variant="outline-primary"
              onClick={() => setActiveNavbar('filter')}
              className={`d-flex align-items-center text-uppercase ${activeNavbar === 'filter' ? 'active' : ''}`}
            >
              <HiOutlineAdjustmentsHorizontal size={20} className="me-2" />
              Filter
            </Button>}
          </Stack>
          {/* <span className="text-secondary d-lg-none">Click on a item to view details</span> */}
        </Stack>

        <Row className="d-none d-lg-flex p-4 align-items-center justify-content-between">
          <Col xs={12} lg={6} className="p-0">
            <Stack direction="horizontal" gap={2}>
              <h6 className="fw-bolder text-secondary mb-0 mb-lg-0" style={{ fontSize: '1.125rem' }}>
                {name || scopes?.[0]?.[0]?.title}
              </h6>
              <span
                className="rounded-4 px-2 py-1 fw-bold"
                style={{ background: '#F2F4F7', color: '#888888', fontSize: '.75rem' }}
              >
                {count} items
              </span>
            </Stack>
          </Col>

          <Col xs={12} lg={6} className="p-0">
            <Stack direction="horizontal" gap={2}>
                <InputGroup>
                  <InputGroup.Text>
                    <BsSearch />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search (only visible column data)"
                    aria-label="Search"
                    name="search"
                    style={{ height: 40 }}
                    onChange={(e) => {
                      setGlobalFilter(e.target.value);
                    }}
                  />
                </InputGroup>
                {filterForm && <Button
                  variant="outline-primary"
                  onClick={() => setActiveNavbar('filter')}
                  className={`d-flex align-items-center text-uppercase ${activeNavbar === 'filter' ? 'active' : ''}`}
                >
                  <HiOutlineAdjustmentsHorizontal size={20} className="me-2" />
                  Filter
                </Button>}
              {newItemEl}
            </Stack>
          </Col>
        </Row>
        
        { isFetching ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
        ) : (
        <Accordion className="overflow-auto">
          <table className="table mb-0">
            <thead>
              {getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {selectableRows && (
                    <th>
                      <IndeterminateCheckbox
                        {...{
                          checked: getIsAllRowsSelected(),
                          indeterminate: getIsSomeRowsSelected(),
                          onChange: getToggleAllPageRowsSelectedHandler(),
                        }}
                      />
                    </th>
                  )}
                  {headerGroup.headers.map(header => (
                    <th key={header.id} colSpan={header.colSpan} className="text-truncate ff-inter" style={{ whiteSpace: 'pre' }}>
                      <div onClick={() => handleSortingChange(header.id, header.column)}>

                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() && ({
                          asc: <PiCaretUpFill className="ms-2 mb-1" />,
                          desc: <PiCaretDownFill className="ms-2 mb-1" />,
                          false: <PiCaretUpDownFill className="ms-2 mb-1" />,
                        }[header.column.getIsSorted() as string] ?? null)}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {getRowModel().rows.map(row => {

                let rowContent = (
                  <tr key={row.id} className="align-middle">
                    {selectableRows && (
                      <td>
                        <IndeterminateCheckbox
                          {...{
                            checked: row.getIsSelected(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                          }}
                        />
                      </td>
                    )}
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                );
                return (
                  <React.Fragment key={row.id}>
                    {
                      rowFormProps ? (
                        <Formik
                          key={row.id}
                          initialValues={rowFormProps.getInitialValues(row)}
                          validationSchema={rowFormProps.validationSchema}
                          onSubmit={rowFormProps.onSubmit}
                        >
                          {() => (rowContent)}
                        </Formik>
                      ) 
                      : ( rowContent )
                    }
                    {expandedItem && row.getIsExpanded() ? (
                      <tr>
                        <td colSpan={columns.length} className="p-0">
                          <SimpleTable
                            columns={expandedItem.columns}
                            data={expandedItem.filterFn
                              ? row.original[expandedItem.rowKey].filter(expandedItem.filterFn)
                              : row.original[expandedItem.rowKey]
                            }
                          />
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </Accordion>
        ) }
        { data.length > 0 && 
          (
            <Row className="align-items-center p-lg-4 my-3 my-lg-0">
              <Col xs={6}>
                <Stack direction="horizontal" gap={3}>
                  <span
                    className="fw-normal ms-2 ms-lg-0"
                    style={{ fontSize: '.875rem', color: '#344054' }}
                  >
                    Viewing:
                  </span>
                  <Form.Select
                    id="count"
                    value={pagination.pageSize}
                    onChange={(e) => setPageSize(+(e.target.value))}
                    style={{ width: 39, height: 24, fontSize: '.875rem', color: '#344054' }}
                    className="px-1 py-0 pagination"
                  >
                    {[5, 10, 20, 50].map((item) => (
                      <option value={item} key={item}>{item}</option>
                    ))}
                  </Form.Select>
                  <span className="d-none d-lg-block" style={{ fontSize: '.875rem', color: '#344054' }}>
                    {`Page ${getState().pagination.pageIndex + 1} of ${getPageCount()}`}
                  </span>
                  { indexDownloadPath && (
                    <div className="d-none d-lg-block" style={{ fontSize: '.875rem', color: '#344054' }}>
                      <span className={"p-1"}>Download:</span>
                      {/* <a href="#" className={"p-1"} onClick={() => download?.('json')}>JSON</a> */}
                      <a href="#" className={"p-1"} onClick={() => download?.('csv')}>CSV</a>
                    </div>
                  )}
                </Stack>
              </Col>

              <Col xs={6}>
                <Stack direction="horizontal" className="justify-content-center justify-content-lg-end">
                  <button className="btn btn-link link-secondary py-0 px-0" onClick={() => firstPage()} hidden={!getCanFirstPage()}>
                    <BsChevronDoubleLeft />
                  </button>
                  <button className="btn btn-link link-secondary py-0 px-0" onClick={() => previousPage()} hidden={!getCanPreviousPage()}>
                    <BsChevronLeft />
                  </button>
                  <div className="d-flex align-items-center pt-1">
                    {renderPageNumbers()}
                  </div>
                  <button className="btn btn-link link-secondary py-0 px-0" onClick={() => nextPage()} hidden={!getCanNextPage()}>
                    <BsChevronRight />
                  </button>
                  <button className="btn btn-link link-secondary py-0 px-0" onClick={() => lastPage()} hidden={!getCanLastPage()}>
                    <BsChevronDoubleRight />
                  </button>
                </Stack>
              </Col>
            </Row>
          )
        }
    </React.Fragment>
  );

  return (
    <FadeIn>
      <Row>
        {batchActions && (
          <Col xs="auto" lg="auto">
            <DropdownButton id="dropdown-basic-button" title="Batch actions" size="sm" variant="info" disabled={!selectableRows || !isAtLeastOneRowSelected}>
              {batchActions.map((action) => (
                <Dropdown.Item key={action.name} onClick={() => action.fn(selectedRowsIDs)}>{action.name}</Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
        )}

        <Col xs="auto" lg="auto">
          {scopes && scopes.length > 0 && (
            <FilterGroup
              filterGroups={scopes}
              onFilterSelect={(filter: string) => {
                setScope(filter);
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: 0,
                }));
              }}
            />
          )}
        </Col>
      </Row>
      <div className="d-lg-none d-flex">
        <div className={'w-100 sfc-table'}>
          {tableContent}
        </div>
      </div>
      <div className="d-none d-lg-flex">
      <div className={(sidebars?.length ?? 0) ? 'w-100 px-2 py-1 sfc-table' : 'w-100 sfc-table'} style={(sidebars?.length ?? 0) ? { maxWidth: 'calc(100% - 300px)' } : {}}>
        {tableContent}
      </div>
      {(sidebars?.length ?? 0) > 0 && (
        <div className="ps-3 py-0 d-none d-lg-flex">
          <Stack gap={4} className="p-3" style={{ width: 300, background: '#F2F2F2', borderRadius: '10px', boxShadow: "0 0 8px 0 rgba(0, 0, 0, .1), 0 0 8px 0 rgba(0, 0, 0, .1)" }}>
            {sidebars}
            <DashLogo />
          </Stack>
        </div>
      )}
      </div>
    </FadeIn>
  );
};

export default SFCTable;
