import React from 'react';

interface BannerHeaderProps {
  title?: string;
}

export const BannerHeader: React.FC<BannerHeaderProps> = ({ title }) => {
  return (
    <div className="w-100 d-flex justify-content-center section-banner-style-events">
      <h1 className="w-100 text-weight-700 text-blue text-center">{title}</h1>
    </div>
  );
};
