import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { useGetWaitlistQuery } from '../../../../../services/endpoints/schedule/waitlist';
import { Waitlist } from '../../../../../types/waitlist.type';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { formatDate } from '../../../../../utils/dateUtils';
import { waitlistStatusColors } from '../../../../../utils/consts';
import { Badge } from '../../../../../components/badge/Badge';
import { DetailSection, LabelText } from '../../../../../components/generic';

export const WaitlistDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetWaitlistQuery(+params.id!);
  const [waitlist, setWaitlist] = useState<Waitlist>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setWaitlist(data.waitlist);
    }
  }, [data]);

  if (isLoading || !waitlist) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/schedule/waitlists')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Waitlists List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Waitlist #${waitlist?.id}`}</h2>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Session:">
              <LinkCell
                content={waitlist?.course?.title ?? 'No session title'}
                url={`/admin/schedule/sessions/${waitlist?.course?.id}`}
                underline
              />
            </LabelText>
            <LabelText label="Child:">
              <LinkCell
                content={`${waitlist?.child.first_name} ${waitlist?.child.last_name}`}
                url=''
                underline
              />
            </LabelText>
            <LabelText label="Created At:">
              {formatDate(new Date(waitlist?.created_at!), 'MM/dd/yyyy h:mm a zzz')}
            </LabelText>
            <LabelText label="Updated At:">
              {formatDate(new Date(waitlist?.updated_at!), 'MM/dd/yyyy h:mm a zzz')}
            </LabelText>
            <LabelText label="Status:">
              <Badge title={waitlist?.status!} color={waitlistStatusColors[waitlist?.status!]} />
            </LabelText>
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
