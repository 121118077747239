import { useState } from "react";
import { Child, useGetUserProfileQuery } from "../../services/endpoints/people/user";
import { Accordion, Button, Modal, Spinner } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import UserProfileCard from "./UserProfileCard";
import ChildProfileCard from "./ChildProfileCard";
import EditProfile from "./Register";
import { getCurrentUser } from '../../services/helper';

const Profile: React.FC = () => {
  const currentUser = getCurrentUser();
  const {data: userProfile, error, isLoading, refetch} = useGetUserProfileQuery();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const navigate = useNavigate();

  const editUser = () => {
    setShowEditProfile(true);
  }
  
  const hideOverlay = (refresh: boolean) => {
    setShowEditProfile(false);
    if (refresh) {
      refetch();
    }
  }

  const redirectChildPage = (child?: Child) => {
    if (child) {
      return navigate(`/children/${child.id}/edit`);
    }
    return navigate("/children/new");
  };

  if (currentUser === null) {
    return <Navigate to="/users/login" />;
  }

  if (isLoading || error) {
    return (<Spinner></Spinner>)
  }

  return (
    <>
      <h6 className="text-uppercase heading-with-rule">My Profile</h6>
      <br />
      <UserProfileCard
        user={userProfile?.user || currentUser}
        edit={editUser}
      />
      <br />
      <h6 className="text-uppercase heading-with-rule">My Children</h6>
      <br />
      <button
        onClick={() => redirectChildPage()}
        className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
      >
        + Add Child
      </button>
      <div className="multi-content-scroll-short">
        <Accordion
          defaultActiveKey={
            (userProfile?.user || currentUser).children?.map((c, i) =>
              i.toString()
            ) ?? ["0"]
          }
          alwaysOpen
        >
          {(userProfile?.user || currentUser).children?.map((child, index) => (
            <Accordion.Item eventKey={`${index}`} key={index}>
              <Accordion.Header>
                {child.first_name} {child.last_name}
              </Accordion.Header>
              <Accordion.Body>
                <ChildProfileCard
                  child={child}
                  edit={() => redirectChildPage(child)}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <Modal
        size="xl"
        backdrop={"static"}
        keyboard={true}
        show={showEditProfile}
        onEscapeKeyDown={() => hideOverlay(false)}
      >
        <Modal.Body>
          <EditProfile
            hide={hideOverlay}
            user={userProfile?.user || currentUser}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hideOverlay(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Profile;
