import {
  Container,
  Image,
  Button,
  Nav,
  Navbar,
  Carousel,
  Row,
  Col,
} from "react-bootstrap";
import { useState, FC, useEffect } from "react";

import "./whyWeAreGrapeSection.css";
import Step1 from "../../assets/images/step-1.png";
import Step2 from "../../assets/images/step-2.png";
import Step3 from "../../assets/images/step-3.png";
import FamilyImg from "../../assets/images/whyWeAreGrape/family.png";
import Cards from "../../assets/images/whyWeAreGrape/Cards.png";
import OurRecipe from "../../assets/images/whyWeAreGrape/our-recipe-.png";
import StickyFingersCooking from "../../assets/images/whyWeAreGrape/sticky-fingers-cooking-after-school-class-louisville-co-900x-p-800.jpeg";

import OurCapmp1 from "../../assets/images/Sticky-Fingers-Cooking_Basic-Training_400x.jpg";
import OurCapmp2 from "../../assets/images/Sticky-Fingers-Cooking_CooksBooks_400x.jpg";
import OurCapmp3 from "../../assets/images/Sticky-Fingers-Cooking_Global-Taste-Buds_400x.jpg";
import OurCapmp4 from "../../assets/images/Sticky-Fingers-Cooking_Kids-Cooking-Showdown_400x.jpg";
import OurCapmp5 from "../../assets/images/Sticky-Fingers-Cooking_STEAM_400x.jpg";
import OurCapmp6 from "../../assets/images/Sticky-Fingers-Cooking_Farm-to-Table_400x.jpg";
import StickyFingersCooking2 from "../../assets/images/sticky-fingers-cooking-avid-for-adventure-summer-camp-900x.jpg";
import StickyFingersCooking3 from "../../assets/images/sticky-fingers-cooking-cooks-books-library-classes-p-500.jpg";
import StickyFingersCooking4 from "../../assets/images/sticky-fingers-cooking-summer-camp-900x-p-500.jpg";

import Pickle from "../../assets/images/pickle.png";
import Dots from "../../assets/images/dots.png";
import Tomato from "../../assets/images/Tomato.png";
import { FaStar } from "react-icons/fa6";
import { dataCarousel,  FruitItem, fruitsDataGrid, NavItem, navItems, navItemsH } from "../../utils/consts/website/whyWhyAreGrape";
import { useGetAllTestimonialsQuery } from "../../services/endpoints/testimonial/testimonial";
import { Testimonial } from "../../types/testimonial.type";
import ImageWithErrorHandling from "../../components/image/ImageWithErrorHandling";
import { getPartition, splitArray } from "../../utils/utils";
import SafeHTMLDisplay from "../common/SafeHTMLDisplay";
import { BannerHeader } from "../user/BannerHeader";

const StarsImgs: FC<number> = (n) => {
  const starsElements: JSX.Element[] = [];

  for (let index = 0; index < n; index++) {
    starsElements.push(
      <>
      <FaStar/>
      </>
    );
  }

  return (
    <div className="text-stars">
      {starsElements}
    </div>
  );
}

interface TestimonialCardProps {
  item: Testimonial;
  bgColor?: string;
}

export const TestimonialCard: FC<TestimonialCardProps> = ({ item, bgColor}) => {
  return (
    <Col md={4} className="div-flex-slide mx-auto custom-col" style={{ maxWidth: '80%' }}>
      <div className={`div-testimonial-card ${bgColor ? bgColor : ''}`}>
        <div className="div-circle grape">
          <ImageWithErrorHandling className="w-100 h-auto px-4" src={`${item?.s3_base_url}/${getPartition(item?.id)}/thumb/${item?.image_file_name}`} />
        </div>
        <div>
          <div className="text-stars">{StarsImgs(5)}</div>
        </div>
        <div className="text-testimonial-card">{item.content}</div>
        <div className="subheader center testimonial text-center"><strong>{item.name}</strong></div>
        <div className="text-testimonial-venue"><SafeHTMLDisplay htmlContent={item.origin} /></div>
      </div>
    </Col>
  )
}

interface TestimonialCarouselProps {
  data: Testimonial[];
  bgColor?: string;
}

export const TestimonialCarousel: FC<TestimonialCarouselProps> = ({data, bgColor}) => {
  let groupedData = splitArray(data, 3);
  return (
    <Carousel controls interval={null} indicators={false}>
      {groupedData.map((group, index) => (
        <Carousel.Item key={index}>
          <Row className="justify-content-center">
            {group.map((item, idx) => (
              <TestimonialCard key={idx} item={item} bgColor={bgColor} />
            ))}
          </Row>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export const WhyWeAreGrapeSection = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [ schoolData, setSchoolData ] = useState<Testimonial[]>([]);
  const [ campData, setCampData ] = useState<Testimonial[]>([]);
  const [ nonProfitData, setNonProfitData ] = useState<Testimonial[]>([]);
  const [ authorData, setAuthorData ] = useState<Testimonial[]>([]);
  const { data, error, isLoading } = useGetAllTestimonialsQuery({data: {orderBy: {field: 'id', order: 'asc'}} });

  useEffect(() => {
    if (data && data?.results) {
      setSchoolData(data.results.slice(0,6));
      setCampData(data.results.slice(6,12));
      // non profit: {/* 13, 14, 15,  */} 
      setNonProfitData(data.results.slice(12,15));
      let authorIds = [16, 29, 18, 19, 20, 21];
      setAuthorData(authorIds.map(i => data.results.find(r => r.id === i)).filter(i => i));
    }
  }, [data])
  const [hoveredIndexCard, setHoveredIndexCard] = useState<number | null>(null);

  const handleSelect = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <>
      <BannerHeader title="Why We're Grape!" />

      <Container fluid className="bg-white">
        <Container className="d-flex justify-content-center px-5 ">
          <Navbar expand="md" className="justify-content-center">
            <Nav className="d-flex flex-row">
              {navItemsH.map((item: NavItem, index: number) => (
                <Nav.Link
                  key={index}
                  href={`#${item.id}`} // Anchor link to the section TODO fix this to go to the correct page
                  onClick={() => handleSelect(index)}
                  style={{
                    color: "grey",
                    padding: "0", // Remove padding
                    margin: "0 15px", // Horizontal margin between links
                    display: "inline", // Ensure the underline fits exactly under the text
                  }}
                >
                  {item.name}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar>
        </Container>
      </Container>

      <div id="who-we-are-section" style={{position: 'relative', top: '-200px'}}></div>
      <Container fluid className="bg-white" id="who-we-are">
        <Container className="d-flex justify-content-center  ">
          <div className=" d-flex flex-nowrap">
            <div className="col-2  ">
            <Navbar expand="md" className="justify-content-center">
            <Nav className="flex-column" style={{ marginTop: "10px" }}>
                  {navItems.map((item, index) => (
                    <Nav.Link
                      key={`section-${index}`}
                      href={`#${item.id}`} // Anchor link to the section
                      onClick={() => handleSelect(index)}
                      style={{
                        color: activeIndex === index ? "#b4c634" : "grey",
                        borderBottom:activeIndex === index ? "1px solid #b4c634" : "none",
                        marginTop: "5px",
                        padding: "0px",
                        transition: "border-bottom 0.3s ease",
                        display: "inline", // Ensure border fits exactly under the text

                        width: "100%", // Make the link take the full width
                        textAlign: "left", // Align text to the left
                        marginBottom: "2vh",
                      }}
                    >
                      {item.name}
                    </Nav.Link>
                  ))}
                </Nav>
              </Navbar>
            </div>

            <div className="col-9 ml-2" style={{ marginLeft: "4vh" }}>
              <h2 className="my-4  text-weight-700 text-blue">
                Welcome to Sticky Fingers Cooking!
              </h2>
              <p className="text-blue  ">
                Sticky Fingers Cooking is a home-grown Colorado company! Our
                dedicated, talented and creative team of Instructor Chefs have
                been busy Cultivating 'Cool'inary Curiosity in Kids® in online
                classes, schools, camps, and special events since 2011.
                </p>
              <p className="text-blue  ">

                Sticky Fingers Cooking is lead by Food Geek in Chief, Erin Fletter.
                Erin has grown Sticky Fingers Cooking from a small, regional
                children’s cooking school to a nationwide education brand.
                Sticky Fingers Cooking now offers hundreds of cooking classes
                and camps online and throughout Colorado, Illinois, Texas and
                beyond.
              </p>
              <Image className="w-50 h-auto px-12 " src={FamilyImg} />
            </div>
          </div>
        </Container>
      </Container>

      <Container fluid className="justify-content-center bg-white ">
        <Container className="d-flex justify-content-center  ">
            <div className="col-10   ">
                <Image className="w-100 mt-2 h-auto" src={Dots} />
            </div>
        </Container>


        <Container className="d-flex justify-content-center  ">
          <div className="d-flex d-flex justify-content-center ">
            <div className="col-12   ">
              <div className=" text-center">
                <h4 className="text-weight-700 text-blue mt-2">
                  About StickyFingers Cooking
                </h4>
              </div>
              <p className="text-blue">
                Sticky Fingers Cooking® is dedicated to making a deliciously
                meaningful impact in our communities. With hundreds of thousands
                of students taught in thousands of schools and venues across the
                U.S., we’re proud to enrich children’s lives through our mobile
                cooking school model.
              </p>
              <p className="text-blue">
                <b>Community Outreach:</b> We offer our programs to a wide range
                of schools, libraries, camps, community centers, and more,
                spreading the joy of cooking and healthy eating.
              </p>
              <p className="text-blue">
                <b>Giving Back:</b> Giving back to our community is baked into
                our company culture. We proudly reinvest in the communities we
                serve, enriching lives and supporting local initiatives.
              </p>
              <p className="text-blue">
                If you’re interested in bringing Sticky Fingers Cooking® to your
                school, camp, or organizing a fun birthday party, get in touch
                with us. We look forward to creating delightful culinary
                experiences and Cultivating 'COOL'INARY CURIOSITY in Kids® in
                our community. LETTUCE make a difference together!
              </p>
            </div>
          </div>
        </Container>
        <Container className="d-flex justify-content-center  ">
            <div className="col-10   ">
                <Image className="w-100 mt-2 h-auto" src={Dots} />
            </div>
        </Container>
      </Container>

      <Container fluid className="justify-content-center bg-white ">
        <div className="w-100 d-flex justify-content-center py-2 ">
          <h4 className="text-weight-700 text-blue">Our Core Values</h4>
        </div>

        <Container className="d-flex justify-content-center  ">

        <div className="w-100 d-flex justify-content-center py-2 flex-nowrap ">
          <Image className=" h-auto px-4" src={Cards} />
        </div>
        </Container>

        <Container className="d-flex justify-content-center  ">

          <div className="col-12  mx-auto ">
            <p className="text-blue text-center">
              At Sticky Fingers Cooking®, we are mission focused on Cultivating
              ‘Cool’inary Curiosity in Kids®—supporting lifelong skills in
              preparing, eating, and sharing nutritious foods. Because it's
              Thyme to Turnip the Beet on what kids eat®.
            </p>
        </div>
        </Container>
         <Container className="d-flex justify-content-center  ">
            <div className="col-10   ">
                <Image className="w-100 mt-2 h-auto" src={Dots} />
            </div>
        </Container>
      </Container>

      <Container fluid className="justify-content-center bg-white">
      
        <div className="w-100 d-flex justify-content-center ">
          <Image
            className="h-auto px-4"
            src={OurRecipe}
            style={{ width: "30%" }}
          />
        </div>
        <div className="w-100 d-flex justify-content-center ">
          <h4 className="text-weight-700 text-blue">
            Our Recipe for Community Impact
          </h4>
        </div>
        <Container className="d-flex justify-content-center  ">
        <div className="d-flex d-flex justify-content-center ">
          <div className="col-12   ">
            <p className="text-blue">
              Sticky Fingers Cooking® is dedicated to making a deliciously
              meaningful impact in our communities. With hundreds of thousands
              of students taught in thousands of schools and venues across the
              U.S., we’re proud to enrich children’s lives through our mobile
              cooking school model.
            </p>
            <p className="text-blue position-relative pl-bullet">
              <span className="bullet-circle"></span>
              Community Outreach: We offer our programs to a wide range of
              schools, libraries, camps, community centers, and more, spreading
              the joy of cooking and healthy eating.
            </p>
            <p className="text-blue position-relative pl-bullet">
              <span className="bullet-circle"></span>
              Giving Back: Giving back to our community is baked into our
              company culture. We proudly reinvest in the communities we serve,
              enriching lives and supporting local initiatives.
            </p>
            <p className="text-blue">
              If you’re interested in bringing Sticky Fingers Cooking® to your
              school, camp, or organizing a fun birthday party, get in touch
              with us. We look forward to creating delightful culinary
              experiences and Cultivating 'COOL'INARY CURIOSITY in Kids® in our
              community. LETTUCE make a difference together!
            </p>
          </div>
        </div>
        </Container>
        <Container className="d-flex justify-content-center  ">
            <div className="col-10   ">
                <Image className="w-100 mt-2 h-auto" src={Dots} />
            </div>
        </Container>
      </Container>

      {/* Sticky Fingers Cooking® in Schools */}
      <div id="schools-section" style={{position: 'relative', top: '-200px'}}></div>
      <Container fluid className="bg-white">
        <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap mt-5">
          <div className="col-12  mx-auto">


          <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap ">


            <div className="col-8  ">
                <div className="w-100 d-flex ">
                    <h4 className="text-weight-700 text-blue">
                    Sticky Fingers Cooking® in Schools
                    </h4>
                </div>


                <div className="col-12   ">
                  <Image className="w-100  h-auto" src={Dots} />
                </div>

                <div className="w-100 fontSize9rem">
                  <p>
                  Sticky Fingers Cooking® is a unique cooking school for children
                  specializing in before, during, and after-school enrichment classes.
                  <b>Since 2011, Sticky Fingers Cooking® has taught over 125,000 children
                  ages 4-18 in over 1,000 schools from coast to coast! </b>
                  </p>

                  <p>
                  At Sticky Fingers  Cooking, our passion is helping young chefs discover the joys of
                  cooking healthy and delicious meals that nourish bodies and minds.
                  Sticky Fingers Cooking® strives to provide comprehensive education
                  related to the essential role of whole foods. We also demonstrate how
                  cooking and nutrition promote health and wellness. We teach simple,
                  highly adaptable cooking skills and integrated nutrition education,
                  opening the door to a lifetime of health and vibrancy. That's why our
                  motto is:<strong> Cultivating 'Cool'inary Curiosity in Kids®! </strong>
                  </p>

                  
                  <ul role="list" className="w-list-unstyled">
                    <li className="list-item"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></li>
                    <li>We have given over $1.5 million back to schools in scholarships and fundraising. </li>
                    <li>We accommodate any and all food allergies.</li>
                    <li>We model kind and inclusive behavior in our classes.</li>
                    <li>We celebrate what makes our students unique.</li>
                  </ul>
                  
                  <p>
                  
                  Our continued relationships
                  with our schools, students, and families make us an ever-growing,
                  thriving community. We make a difference in the lives of kids. 
                  </p>
                  <p>
                  
                  Are you a school administrator? <a href="/contact">Contact us today to schedule Sticky Fingers
                  Cooking® classes at your school. 
                  </a>
                  </p>
                  
                  
                  
                 

                </div>
            </div>
            <div className="col-4 div-image">
                <Image className="image-events" src={StickyFingersCooking} />

            
            </div>
                </div>
                </Container>
                <div className="div-break"></div>

          </div>
          </div>
      </Container>

      <Container>
       
        <div className="w-100 d-flex justify-content-center ">
          <h4 className=" text-weight-700">Our Schools Say It Best</h4>
        </div>

        <Container>
        <div className="carousel-wrapper">
          <TestimonialCarousel data={schoolData} />
        </div>
      </Container>
      </Container>
      
      </Container>

      <Container fluid className="bg-white ">
        <Container className="pb-5 text-blue ">
          

          <div className="col-12 d-flex flex-nowrap ">
            <div className="col-4  align-content-center">
              <p className="text-uppercase mt-5 text-weight-700 letter-spacing-min ">
                serving grades pre-k-12
              </p>
              <h2 className="my-3  text-weight-700 text-blue">
                Bring the Joy of Cooking to Your School
              </h2>
              <p className="text-blue">
                We have taught over 125,000 students in more than 1,000 school
                across the U.S. No kitchen necessary! Contact us today to bring
                our school enrichment program to your school. Our 6-14 week
                sessions teach kids to cook and eat internationally-inspired
                recipes in a fun, hands-on way. We emphasize cooking skills,
                kitchen safety, nutrition, and the value of fresh ingredients.
                All of our well-trained chef instructors are ServSafe certified
                in proper food handling and FBI fingerprint background checked.
              </p>
              <Button className="text-uppercase button-radius mb-5" href="/contact">
                contact us
              </Button>
            </div>
            <div className="col-8   mt-5 offset-min ">
              <div className="">
                <iframe
                  className="video-container"
                  src="https://player.vimeo.com/video/872690025?h=d1f4529074&app_id=122963"
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </Container>
      </Container>
      
      {/* end sticky fingers cooking in schools */}

      {/* Sticky Fingers Cooking® Camps */}
      <div id="camps-section" style={{position: 'relative', top: '-200px'}}></div>
      <Container fluid className="bg-yellow pb-5">
        <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap mt-5">
          <div className="col-12  mx-auto">


          <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap ">


            <div className="col-8  ">
                <div className="w-100 d-flex ">
                    <h4 className="text-weight-700 text-blue">
                    Sticky Fingers Cooking® Camps
                    </h4>
                </div>


                <div className="col-12   ">
                  <Image className="w-100  h-auto" src={Dots} />
                </div>

                <div className="w-100 fontSize9rem">
                  <p>
                  Over the last decade, <strong>we have taught in thousands of camps</strong> offering an edible education to happy campers. For some camps, we integrate fully as a part of their daily activities, while for others, we provide hands-on, in-house field trips. 
                  We currently work with a host of phenomenal day camp providers:
                  </p>

                  


                  
                  <ul role="list" className="w-list-unstyled">
                    <li className="list-item"></li>
                    <li>Private Schools </li>
                    <li>Public School Districts</li>
                    <li>Private Summer Camps</li>
                    <li>Day Camps</li>
                    <li>Boys and Girls Clubs</li>
                    <li>Jewish Community Centers</li>
                    <li>Parks & Recreation Districts</li>
                    <li>Camps in Collaboration with After School Enrichment Providers</li>
                    <li>YMCAs</li>
                    <li>Libraries</li>
                  </ul>
                  
                  <p>
                  
                  What we bring to all of our camps is something truly unique and impactful. Whether camps are one-hour or three-hour themed classes, children discover the joy and fun of cooking, build self-confidence, and learn about recipes and cultures from around the world. 
                  As an integral part of our local communities, we strive to inspire, and educate our students to become enthusiastic healthy food explorers.
                  </p>

                  <p>
                  Is your organization looking for a specialty camp provider? Let's talk! <a href="/contact">Contact us today to discuss your needs.</a>
                  </p>
                  
                  
                  
                 

                </div>
            </div>
            <div className="col-4 div-image-2">
                <Image className="image-events" src={StickyFingersCooking2} />

            
            </div>
                </div>
                </Container>
                <div className="div-break"></div>
                <h5 className="text-center  text-weight-700 ">
          Our Camp Themes
        </h5>
        
          </div>
          </div>
      </Container>
      <Container className="d-flex justify-content-center  ">

        <div className="w-50 d-flex justify-content-center py-2 flex-nowrap ">
          <div className="col-6  d-flex justify-content-center">
            <Image className="w-50 h-auto px-4" src={OurCapmp1} />
            <Image className="w-50 h-auto px-4" src={OurCapmp2} />
            <Image className="w-50 h-auto px-4" src={OurCapmp3} />
            <Image className="w-50 h-auto px-4" src={OurCapmp4} />
            <Image className="w-50 h-auto px-4" src={OurCapmp5} />
            <Image className="w-50 h-auto px-4" src={OurCapmp6} />
          </div>
        </div>
      </Container>
      <Container>
      <div className="div-break"></div>
       
        <div className="w-100 d-flex justify-content-center mt-5">
          <h4 className=" text-weight-700">Recent Testimonials from Our Camp Partners</h4>
        </div>

        <Container>
        <div className="carousel-wrapper">
          <TestimonialCarousel data={campData} bgColor="white" />
        </div>
      </Container>
      </Container>
      
      </Container>

      {/* end Sticky Fingers Cooking® Camps */}

      <div className="d-flex justify-content-center w-100 position-relative">
        <Image
          className="section-fruit"
          src={Pickle}
          alt="sticky fingers cooking banana graphic"
        />
      </div>

      <div id="online-section" style={{position: 'relative', top: '-200px'}}></div>
      <Container fluid className="bg-white">
        <Container className="pb-5 text-blue pt-5">
          

          <div className="col-12 d-flex flex-nowrap ">
            <div className="col-4  align-content-center">
              <p className="text-uppercase mt-5 text-weight-700 letter-spacing-min ">
                interactive livestream
              </p>
              <h2 className="my-3  text-weight-700 text-blue">
                Learn How To Cook at Home
              </h2>
              <p className="text-blue">
                Discover the joy of healthy cooking right from the comfort of
                your own kitchen! Our interactive online cooking classes are a
                delicious blend of education and fun. Led by our skilled chef
                instructors, these virtual culinary adventures pack in a dash of
                nutritional knowledge, safe cooking skills, hygiene, geography,
                math, plus a side of food history and culture. We're
                allergy-friendly too, with safe substitutions for common
                allergens. Your child's well-being is our top priority! Join us
                for a FREE online class today. All ages welcome!
              </p>
              <Button className="text-uppercase button-radius mb-5" href="/sessions/?view=list&session_type=online">
                Enroll now
              </Button>
            </div>
            <div className="col-5   mt-5 offset-min ">
              <div className="">
                <iframe
                  className="video-container"
                  src="https://player.vimeo.com/video/471136681?h=d1f4529074&app_id=122963"
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </Container>
      </Container>


      {/* Get Kids Cooking in Three Simple Steps */}
      <Container fluid className="bg-white text-blue">
        <Container className="pb-5">
        <div className="col-12 d-flex flex-nowrap ">
            <Image className="w-100 mt-5 mb-2 h-auto" src={Dots} />
          </div>
          <div className="w-100 d-flex justify-content-center py-5 ">
            <h3 className=" text-weight-700">
              Get Kids Cooking in Three Simple Steps
            </h3>
          </div>

          <div className="w-100 d-flex flex-nowrap justify-content-center">
            <div className="step-column text-center">
              <div className="step-image">
                <Image className="w-100 h-auto" src={Step1} />
              </div>
              <div className="w-100 text-uppercase mt-3 letter-spacing-min">
                step 1
              </div>
              <h3 className="text-weight-700">Search Class Sessions</h3>
              <p className="">
                <a href="#school-programs">Select your state here</a> and search
                for your child&#x27;s school in the location search box. If
                Sticky Fingers Cooking classes are offered at your child&#x27;s
                school, upcoming class sessions will populate. No results?{" "}
                <a href="/contact">Contact us.</a>
              </p>
            </div>

            <div className="step-column text-center">
              <div className="step-image">
                <Image className="w-100 h-auto " src={Step2} />
              </div>
              <div className="w-100 text-uppercase mt-3 letter-spacing-min">
                step 2
              </div>
              <h3 className="text-weight-700">Enroll Online or at School</h3>
              <p className="">
                After reviewing the upcoming class sessions at your child&#x27;s
                school, <a href="/users/login">login</a> or <a href="/users/register">create an account</a> to
                enroll your child. Some schools prefer that parents enroll
                students directly at school. The class session page will guide
                you.
              </p>
            </div>

            <div className="step-column text-center">
              <div className="step-image">
                <Image className="w-100 h-auto" src={Step3} />
              </div>
              <div className="w-100 text-uppercase mt-3 letter-spacing-min">
                step 3
              </div>
              <h3 className="text-weight-700">Get Cooking</h3>
              <p className="">
              Mark the class session dates on your calendar and ask your child about 
              the delicious new recipes they are making. Better yet, make them again 
              at home! <a href="/recipes">Recipes are available online after each class.</a> 
              <br />
              <br />
              </p>
            </div>

        
          </div>
          <div className="w-100 d-flex justify-content-center ">
            <Button className="text-uppercase button-radius mb-5" href="#school-programs">
              get started Now
            </Button>
          </div>
        </Container>

       
      </Container>


      {/* cards  */}
      <div id="private-events-section" style={{position: 'relative', top: '-200px'}}></div>
      <Container fluid className="bg-white pt-5">
        <Container className="d-flex flex-wrap px-5 ">
        <div className="card-grids-container dual">
      <a href="/birthday-parties" className="link-block-card w-inline-block">
        <div className="div-event">
          <div className="div-70">
            <div className="subheader">entertain ages 5-14</div>
            <h2 className="h2-events">Kid's Birthday Parties</h2>
            <div className="div-faux-button zero-margin-top green">
              <div className="faux-button">reserve a party</div>
            </div>
          </div>
        </div>
      </a>
      <a href="/girl-scouts-and-boy-scouts" className="link-block-card w-inline-block">
        <div className="div-event scouts">
          <div className="div-70">
            <div className="subheader">earn badges</div>
            <h2 className="h2-events">Girl Scout &amp; Scout Cooking Classes</h2>
            <div className="div-faux-button zero-margin-top green">
              <div className="faux-button">book an adventure</div>
            </div>
          </div>
        </div>
      </a>
      <a href="/family-cooking-classes" className="link-block-card w-inline-block">
        <div className="div-event family">
          <div className="div-70">
            <div className="subheader">make memories</div>
            <h2 className="h2-events">Friends &amp; Family Cooking Classes</h2>
            <div className="div-faux-button zero-margin-top green">
              <div className="faux-button">plan a meal</div>
            </div>
          </div>
        </div>
      </a>
      <a href="/corporate-events" className="link-block-card w-inline-block">
        <div className="div-event corporate">
          <div className="div-70">
            <div className="subheader">treat your staff</div>
            <h2 className="h2-events">Corporate Team Building Events</h2>
            <div className="div-faux-button zero-margin-top green">
              <div className="faux-button">schedule an event</div>
            </div>
          </div>
        </div>
      </a>
    </div>





 <Container fluid className="bg-yellow pb-5" id="who-we-are">
        <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap mt-5">
          <div className="col-12  mx-auto">
      <p>
        <h3>Private Cooking Events</h3> Gather your group of friends, family, or
        co-workers for a Sticky Fingers Cooking private cooking event—online or
        in-person. From birthday parties and family reunions to team building
        and online cooking parties, your group will mix, mingle, and laugh while
        cooking up one of our fun, healthy recipes. Don't see your private
        cooking event concept listed here? Contact us with your idea today!
      </p>

        </div>
        </div>
        </Container>
        </Container>


        <div className="col-12  my-5 ">
                  <Image className="w-100  h-auto" src={Dots} />
                </div>
        </Container>

        
      </Container>

      {/* Sticky Fingers Cooking® at Your Local Library */}

      <div id="library-section" style={{position: 'relative', top: '-200px'}}></div>
      <Container fluid className="bg-yellow pb-5">
        <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap mt-5">
          <div className="col-12  mx-auto">


          <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap ">


            <div className="col-8  ">
                <div className="w-100 d-flex ">
                    <h4 className="text-weight-700 text-blue">
                    Sticky Fingers Cooking® at Your Local Library
                    </h4>
                </div>


                <div className="col-12   ">
                  <Image className="w-100  h-auto" src={Dots} />
                </div>

                <div className="w-100 fontSize9rem">
                  <p>
                  Since 2011 we have taught children the joys of cooking. We can cook in any venue, including libraries! Cue our Nourishing Literacy Cooks + Books Program. We pair books, or sections of books, with a recipe that brings the overarching 
                  theme alive in a tangible way. We have now taught in over 200 libraries spanning 18 states.
                  </p>

                  <p>
                  One of the best aspects of cooking is the creativity it fosters. With Cooks + Books, children get a double dose of inspiration. First, they listen to the story, envisioning the characters, the culture, and the history. Then, they cook our paired recipe, bringing their vision to life. More often than not, 
                  what sprouts from these sessions is a love of reading and a passion for cooking.
                  </p>


                  <p>Some of our most popular pairings include:</p>
                  <ul role="list" className="w-list-unstyled">
                    <li className="list-item"></li>
                    <li> Last Stop on Market Street by Matt de la PeñaRecipe with Nana's Last Stop Potato Leek Soup + Market Street Croutons + CJ's Creamy Dreamy Apple Cider</li>
                    <li>Dragons Love Tacos by Adam Rubin with Dragon Party Street Tacos + Cilantro Lime Rice + Easy NOT SPICY Salsa</li>
                    <li>Watercress by Andrea Wang with Roadside Noodle Stir fry + Mom's Sauteed Watercress + Chinese Green Tea Shakes</li>
                    <li>Harry Potter & The Chamber of Secrets by JK Rowling (Chapter 3- The Burrow, Pages: 30-34) with "Charm Your Own" Cauliflower Chive Mac n Cheese + Mrs Weasley's Apple Sodas</li>
                    <li>The Lightning Thief by Rick Riordan (Chapter 10, pages 157-60) with Percy’s Perfect Personal Pan Pizzas + Green Greek God Salad + Mt. Olympus Honey Lemon Yogurt Smoothies</li>
                   
                  </ul>
                  
                

                  <p>
                  Are you a librarian or author who would like to do a book reading with Sticky Fingers Cooking? <a href="/contact">Contact us today to schedule a Cooks + Books program.</a>
                  </p>
                  
                  
                  
                 

                </div>
            </div>
            <div className="col-4 div-image-2">
                <Image className="image-events" src={StickyFingersCooking3} />

            
            </div>
                </div>
                </Container>
        
          </div>
          </div>
      </Container>

      <Container>
      <div className="div-break"></div>
       
        <div className="w-100 d-flex justify-content-center mt-5">
          <h4 className=" text-weight-700">Our Favorite Quotes from Authors & Librarians</h4>
        </div>

        <Container>
        <div className="carousel-wrapper">
          <TestimonialCarousel data={authorData} bgColor="white"/>
        </div>
      </Container>
      </Container>
      
      </Container>
      {/* Sticky Fingers Cooking® at Your Local Library END */}


      {/* Sticky Fingers Cooking® & Non-Profits */}
      <div id="nonprofit-section" style={{position: 'relative', top: '-200px'}}></div>
      <Container fluid className="bg-white">
        <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap mt-5">
          <div className="col-12  mx-auto">


          <Container className="d-flex  ">
          <div className=" d-flex flex-nowrap ">


            <div className="col-8  ">
                <div className="w-100 d-flex ">
                    <h4 className="text-weight-700 text-blue">
                    Sticky Fingers Cooking® & Non-Profits
                    </h4>
                </div>


                <div className="col-12   ">
                  <Image className="w-100  h-auto" src={Dots} />
                </div>

                <div className="w-100 fontSize9rem">
                  <p>
                  Sticky Fingers Cooking® strongly believes in community and the power that it holds for every single person. That is why we have partnered with over 100 nonprofits in 11 states. Enriching educational opportunities are vital for the long-term success of children and families. By working with organizations like Boys & Girls Clubs, Girls Scouts, Boy Scouts, and other local charities, 
                  we have taught thousands of children to cook and enjoy healthy, whole foods.
                  </p>

                  <p>
                  In addition to offering classes to our non-profit partners, we have also donated thousands of scholarships to homeless children and families living in food deserts. Through our curriculum, vulnerable children are given an opportunity to 
                  learn and nourish their bellies in a way that is fun, interactive, and engaging.
                  </p>
                  <p>
                  Are you a 501(c)(3) organization? <a href="/contact">Contact us today to discuss Sticky Fingers Cooking® classes for your clientele.</a>
                  </p>

                </div>
            </div>
            <div className="col-4 div-image">
                <Image className="image-events" src={StickyFingersCooking4} />

            
            </div>
                </div>
                </Container>
                <div className="div-break"></div>

          </div>
          </div>
      </Container>

      <Container>
       
        <div className="w-100 d-flex justify-content-center ">
          <h4 className=" text-weight-700">Five-Stars from Our Non-Profit Partners</h4>
        </div>

        <Container>
        <div className="carousel-wrapper mb-5">
          <TestimonialCarousel data={nonProfitData} />
        </div>
      </Container>
      </Container>

      
      <div className="d-flex justify-content-center w-100 position-relative pb-3">
          <Image
            className="section-fruit"
            src={Tomato}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
      </Container>

      {/* Sticky Fingers Cooking® & Non-Profits END */}

      <Container fluid className="bg-yellow pb-5">
      <Container className="d-flex  ">
      <Container className="pb-5 mt-5 ">
        <div className="col-12 d-flex flex-nowrap">
          <div className="col-4  align-content-center ">
            <p className="text-uppercase mt-5 text-weight-700 letter-spacing-min text-blue">
              sfc in your community
            </p>
            <h2 className="my-3  text-weight-700 text-blue">
              Spread Good Food and Cheer
            </h2>
            <p className="">
              Give the kids in your community the opportunity to learn how to
              make and enjoy tasty, healthy, fun food! Contact us today to bring
              Sticky Fingers Cooking to your library, camp, church, or troop.
              <span className="d-block mt-4">
                Please provide contact information for your organization’s
                enrichment coordinator. We look forward to cooking with you!{" "}
              </span>
            </p>
            <Button className="text-uppercase button-radius mb-5" href="/contact">
              contact us
            </Button>
          </div>

          <div className="col-5 my-5 d-flex w-75 flex-wrap align-content-center offset-min">


          {fruitsDataGrid.map((item: FruitItem, index: number) => (
            <div className="card-fruit-container flex-nowrap"  
            onMouseEnter={() => setHoveredIndexCard(index)}
            onMouseLeave={() => setHoveredIndexCard(null)}>
              <div className={` fruit-text text-center ${hoveredIndexCard === index ? 'visible' : 'hidden'}`}>
              <h5 className="text-blue">{item.Title}</h5>
                
                {item.description}
              </div>
              <div className={`div-fruit ${hoveredIndexCard === index ? 'hidden' : 'visible'}`}>
                <Image
                  className="card-fruit "
                  src={item.image}
                  alt="sticky fingers cooking banana graphic"
                />
                </div>
            </div>

          )
        )}
          </div>
        </div>
      </Container>
      </Container>
      </Container>
     
    </>
  );
};