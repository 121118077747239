import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Expense } from '../../../types/finance.types';
import { sfcApi } from '../../api';

export interface ExpenseCreateUpdateValues {
  id?: number;
  course_id: number;
  category: string;
  amount: number;
  context: string;
  due_on: string,
  paid_on?: string,
  notes: string;
  invoice_number: string;
  payment_method: string;
  check_number?: number;
}

export interface PayExpensesValues {
  ids: number[];
  check_number?: number;
}

export interface PayExpensesResponse {
  count: number;
  rows: Expense[];
}

export const expenseApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllExpenses: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `expenses${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Expense']
    }),
    getExpense: builder.query<{ expense: Expense }, number>({
      query: (id) => `expenses/${id}`,
      providesTags: (result, error, id) => [{ type: 'Expense', id }]
    }),
    createExpense: builder.mutation<{ expense: Expense }, ExpenseCreateUpdateValues>({
      query: (data) => ({
        url: `expenses/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Expense'] // TODO: invalidate course tag to update session detail view
    }),
    updateExpense: builder.mutation<{ expense: Expense }, ExpenseCreateUpdateValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `expenses/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error) => [{ type: 'Expense', id: result?.expense?.id }, 'Expense']
    }),
    payExpenses: builder.mutation<{ expense: PayExpensesResponse }, PayExpensesValues>({
      query: (data) => ({
        url: `expenses/pay`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: () => ['Expense']
    }),
    archiveExpense: builder.mutation<{ expense: Expense }, number>({
      query: (id) => ({
        url: `expenses/archive/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Expense', id }, 'Expense']
    }),
    unarchiveExpense: builder.mutation<{ expense: Expense }, number>({
      query: (id) => ({
        url: `expenses/unarchive/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Expense', id }, 'Expense']
    }),
    deleteExpense: builder.mutation<{ expense: Expense }, number>({
      query: (id) => ({
        url: `expenses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Expense', id }, 'Expense']
    }),
  }),
});

export const {
  useGetAllExpensesQuery,
  useGetExpenseQuery,
  useCreateExpenseMutation,
  useUpdateExpenseMutation,
  usePayExpensesMutation,
  useArchiveExpenseMutation,
  useUnarchiveExpenseMutation,
  useDeleteExpenseMutation,
} = expenseApi;