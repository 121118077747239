import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { NewsItem } from '../../../types/website.types';
import { sfcApi } from '../../api';
import { objectToFormData } from '../../helper';

export const newsItemApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNewsItems: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `news-items${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['NewsItem']
    }),
    getNewsItem: builder.query<{ newsItem: NewsItem }, number>({
      query: (id) => `news-items/${id}`,
      providesTags: (result, error, id) => [{ type: 'NewsItem', id }]
    }),
    createNewsItem: builder.mutation<{ newsItem: NewsItem }, NewsItem>({
      query: (data) => {
        let payload: any = {
          url: `news-items/create`,
          method: 'POST',
          body: data,
        };
        if (data.image) {
          payload.formData = true;
          payload.body = objectToFormData(data);
        }
        return payload;
      },
      invalidatesTags: ['NewsItem']
    }),
    updateNewsItem: builder.mutation<{ newsItem: NewsItem }, NewsItem>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        let payload: any = {
          url: `news-items/update/${id}`,
          method: 'PUT',
          body: data,
        };
        if (data.image) {
          payload.formData = true;
          payload.body = objectToFormData(data);
        }
        return payload;
      },
      invalidatesTags: (result, error) => [{ type: 'NewsItem', id: result?.newsItem?.id }, 'NewsItem']
    }),
    deleteNewsItem: builder.mutation<{ newsItem: NewsItem }, number>({
      query: (id) => ({
        url: `news-items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NewsItem'], //(result, error, params) => [...params.ids.map(id => ({ type: 'NewsItem', id })), 'NewsItem']
    }),
  }),
});

export const {
  useGetAllNewsItemsQuery,
  useGetNewsItemQuery,
  useCreateNewsItemMutation,
  useUpdateNewsItemMutation,
  useDeleteNewsItemMutation,
} = newsItemApi;