import React, { useState } from 'react';
import { ButtonData } from '../buttonGroup/ButtonGroup';
import { Stack } from 'react-bootstrap';
import MultiSelectButtonGroup from '../buttonGroup/MultiSelectButtonGroup';

export interface MultiFilterGroupProps {
  filterButtons: Array<ButtonData> | undefined;
  onFilterSelect: (filter: string) => void;
}

const sessionTypeQS = new URLSearchParams(window.location.search).get("session_type") ?? '';

export const MultiFilterGroup: React.FC<MultiFilterGroupProps> = ({ filterButtons, onFilterSelect }) => {

  const defaultActiveIndex = filterButtons?.reduce((acc, fb, idx) => {
    if (fb.key === sessionTypeQS) acc.push(String(idx));
    return acc;
  }, [] as Array<string>) ?? [];
  const [activeIndex, setActiveIndex] = useState<string[] | null>(defaultActiveIndex);

  if (!filterButtons) {
    return <></>;
  }

  const handleButtonClick = (index: number) => {
    let indexStr = `${index}`;

    setActiveIndex((old) => {
      let copy = [...(old ?? [])];
      if (copy.includes(indexStr)) {
        copy.splice(copy.indexOf(indexStr), 1);
      }
      else {
        copy.push(indexStr);
      }
      return copy;
    });
    onFilterSelect(filterButtons[index].key ?? '');
  };

  return (
    <Stack direction="horizontal" gap={2} className="mb-3 overflow-auto">
      <MultiSelectButtonGroup
        buttons={filterButtons}
        activeIndex={activeIndex}
        onButtonClick={handleButtonClick} />
    </Stack>
  );
};
