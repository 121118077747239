import {
  Col,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import { StepProps } from "./AddEditGuide";

export const GuidesForm = ({
  touched,
  errors,
  
}: StepProps) => {

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Name
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Field
            name="name"
            type="text"
            as={Form.Control}
            isInvalid={touched.name && !!errors.name}
          />
          <ErrorMessage name="name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Description</Form.Label>
          <Field
            name="description"
            type="text"
            as={Form.Control}
            isInvalid={touched.description && !!errors.description}
          />
          <ErrorMessage name="description">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      
      
    </Row>
  );
};
