import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { OrganizationResponse, SFCOrganization } from '../../../types/place.types';
import { sfcApi } from '../../api';

export const organizationsApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<OrganizationResponse, number>({
      query: (id) => ({
        url: `organizations/${id}`,
      }),
      providesTags: ['Organization'],
    }),
    getAllOrganizations: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `organizations`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    createOrganization: builder.mutation<OrganizationResponse, Partial<SFCOrganization>>({
      query: (params) => ({
        method: 'POST',
        url: `organizations/create`,
        body: params
      }),
      invalidatesTags: ['Organization'],
    }),
    updateOrganization: builder.mutation<OrganizationResponse, Partial<SFCOrganization>>({
      query: (data) => ({
        url: `organizations/update/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Organization'],
    }),
  }),
});

export const {
  useGetOrganizationQuery,
  useGetAllOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} = organizationsApi;
