import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Col, FormControl, Row, Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { useGetTerritoryQuery } from '../../../../../services/endpoints/places/territory';
import { SFCTerritory } from '../../../../../types/place.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { formatDate } from '../../../../../utils/dateUtils';
import { getCurrentUser } from '../../../../../services/helper';

const currentUser = getCurrentUser();

export const TerritoryDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const isSuperUser = (currentUser?.isSuper);
  const isAdminUser = (currentUser?.isAdmin);
  const { data, error, isLoading } = useGetTerritoryQuery(+params.id!);
  const [territory, setTerritory] = useState<SFCTerritory>();
  const [zipsString, setZipsString] = useState<string>('');

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading territory', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setTerritory(data.territory);
      setZipsString(data.territory.zip_codes.map(z => z.code).sort().join(", "));
    }
  }, [data]);

  if (isLoading || !territory) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/places/territories')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Territories List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`${territory?.title}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          {currentUser?.isSuper && <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/places/territories/${territory?.id}/edit`)}
          >
            Edit Territory
          </SimpleButton>}
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <Row>
            <Col>
              <DetailSection title="Details">
                <LabelText label="Title:" text={territory?.title} />
                <LabelText label="Created At:">
                  {formatDate(new Date(territory?.created_at), 'MMM dd, yyyy hh:mm a')}
                </LabelText>
                <LabelText label="Location:">
                  <LinkCell
                    content={territory?.location?.title ?? 'No location title'}
                    url={`/admin/places/locations/${territory?.location?.id}`}
                  />
                </LabelText>
                <LabelText label="Zip Codes:">
                  <FormControl as="textarea" value={zipsString} disabled rows={10} cols={50} />
                </LabelText>
              </DetailSection>
            </Col>
            <Col>
              {(isSuperUser || (isAdminUser && +(currentUser?.location_id ?? 0) === territory.location_id)) ? (
                <DetailSection title="Reports">
                  <LinkCell content={'Summary Session Financials'} url={`/admin/places/territories/${territory.id}/ledger`} />
                  {/* <LinkCell content={'Gross Revenue Report'} url={`/admin/places/territories/${territory.id}/weekly-report`} /> */}
                </DetailSection>
              ) : <></>}
            </Col>
          </Row>
        </Stack>
      </div>
    </>
  );
};
