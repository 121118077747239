import React from 'react';
import { formatDate } from '../../../utils/dateUtils';

interface DatesCellProps {
  startDate: Date;
  endDate?: Date;
  dayOfWeek?: string;
}

export const DatesCell: React.FC<DatesCellProps> = ({ startDate, endDate, dayOfWeek }) => {
  return (
    <>
        <span className="text-truncate">{formatDate(startDate)} {endDate ? `- ${formatDate(endDate)}` : ''}</span>
        {dayOfWeek && (
          <>
            <br />
            <span className="fw-light ff-inter">
              {dayOfWeek}
            </span>
          </>
        )}
      </>
  )
}
