import React, { ReactNode } from 'react';
import { Button, ToggleButtonGroup } from 'react-bootstrap';
import './ButtonGroup.css';

export type ButtonData = {
  title: string;
  key?: string;
  count?: number;
  icon?: ReactNode;
  btnVariant?: string;
};

interface CustomButtonGroupProps {
  buttons: ButtonData[];
  activeIndex: string[] | null;
  onButtonClick: (index: number) => void;
  className?: string;
}

const MultiSelectButtonGroup: React.FC<CustomButtonGroupProps> = ({ buttons, activeIndex, onButtonClick, className }) => {
  const handleButtonClick = (index: number) => {
    onButtonClick(index);
  };

  return (
    <>
      {buttons?.map((button, index) => (
        <ToggleButtonGroup key={index} size="sm" className={`${className} multi-custom-btn-group`} type={'checkbox'}>
          <Button
            variant={button.btnVariant ?? "primary"}
            className={`text-truncate ${activeIndex?.includes(`${index}`) ? 'active' : ''} ${buttons.length === 1 ? 'single' : ''}`}
            onClick={() => handleButtonClick(index)}
          >
            {button.icon}
            {button.title}
            {button.count !== undefined && (
              <span>{` (${button.count})`}</span>
            )}
          </Button>
        </ToggleButtonGroup>
      ))}
    </>
  );
};

export default MultiSelectButtonGroup;