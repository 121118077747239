import { CreateFormValues } from '../../../features/dash/views/content/CountryFacts/AddEditCountryFact';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { CountryFact } from '../../../types/countryFacts.type';
import { sfcApi } from '../../api';
export interface CountryFactCreateUpdateValues {
    id?: number;
    name: string;
    content:string;
    file: File,
    image_source:string
  }
export const countryFactApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountryFacts: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `country-facts${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    
    getCountryFact: builder.query<{ countryFact: CountryFact }, number>({
      query: (id) => `country-facts/${id}`,
    }),

    createCountryFact: builder.mutation<{ countryFact: CountryFact }, CountryFactCreateUpdateValues>({
      query: (data) => {
        const formData = new FormData();
        let payload: any = {
          url: `country-facts/create`,
          method: 'POST',
          body: data,
        };
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
        if (data.file?.name) {
          payload.formData = true;
        }
        if(!data?.file?.name){
            formData.delete('file');
        }
        payload.body = formData;

        return payload;
      },
      invalidatesTags: ['CountryFacts']
    }),

    updateCountryFact: builder.mutation<{ countryFact: CountryFact }, CreateFormValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `country-facts/update/${id}`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
    }),

    deleteCountryFact: builder.mutation<{ countryFact: CountryFact }, number>({
      query: (id) => ({
        url: `country-facts/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetCountryFactsQuery,
  useGetCountryFactQuery,
  useCreateCountryFactMutation,
  useUpdateCountryFactMutation,
  useDeleteCountryFactMutation
} = countryFactApi;