import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { registration } from '../../../types/registrations.type';
import { sfcApi } from '../../api';
export interface RegistrationCreateUpdateValues {
    id?: number;
    course_id?: number, 
  }
export const registrationApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrations: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `registrations${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getRegistration: builder.query<{ registration: registration }, number>({
      query: (id) => `registrations/${id}`,
    }),


    createRegistration: builder.mutation<{ registration: registration }, RegistrationCreateUpdateValues>({
      query: (data) => {

        return {
            url: 'registrations/create',
            method: 'POST',
            body: {
              ...data
            },
          }
      },
      invalidatesTags: ['Registrations']
    }),

    updateRegistration: builder.mutation<{ registration: registration }, RegistrationCreateUpdateValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `registrations/${id}/transfer`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
      invalidatesTags: ['Registrations']

    }),

    deleteRegistration: builder.mutation<{ registration: registration }, number>({
      query: (id) => ({
        url: `registrations/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetRegistrationsQuery,
  useGetRegistrationQuery,
  useCreateRegistrationMutation,
  useUpdateRegistrationMutation,
  useDeleteRegistrationMutation
} = registrationApi;