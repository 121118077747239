import { Container, Image, Form, Button } from "react-bootstrap";
import "./HomeSection.css";
import SchoolProgram from "../../assets/images/school-program.png";
import CookingCamps from "../../assets/images/cooking-camps.png";
import PrivateEvents from "../../assets/images/private-events.png";
import OnlineClasses from "../../assets/images/online-classes.png";
import FruitGroup from "../../assets/images/fruit-group.png";
import Step1 from "../../assets/images/step-1.png";
import Step2 from "../../assets/images/step-2.png";
import Step3 from "../../assets/images/step-3.png";
import Logo1 from "../../assets/images/logo1.png";
import Logo2 from "../../assets/images/logo2.png";
import Logo3 from "../../assets/images/logo3.png";
import Logo4 from "../../assets/images/logo4.png";
import Logo5 from "../../assets/images/logo5.png";
import Logo6 from "../../assets/images/logo6.png";
import Logo7 from "../../assets/images/logo7.png";
import Banana from "../../assets/images/Banana.png";
import Grapes from "../../assets/images/Grapes.png";
import Coconut from "../../assets/images/Coconut.png";
import Orange from "../../assets/images/Orange-Slice.png";
import Onion from "../../assets/images/Yellow-Onion.png";
import BellPepper from "../../assets/images/Bell-Pepper.png";
import Dots from "../../assets/images/dots.png";
import Mushroom from "../../assets/images/Mushroom.png";
import Avocado from "../../assets/images/Avocado-Half.png";
import Radish from "../../assets/images/radish.png";
import Artichoke from "../../assets/images/Artichoke.png";
import Tomato from "../../assets/images/Tomato.png";
import Slider from 'react-slick';
import { IoIosSearch } from "react-icons/io";
import { FaCaretRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LocationSetterNoForm } from "../maps/LocationSetter";
import { LocationSelection, SavedLocationSelection } from "../../services/helper";
import { useState } from "react";
import { useMyLocationContext } from "../../context/LocationContext";
import BrowserLocationComponent from "../maps/BrowserLocationComponent";

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
};

const testimonials = [
  {
    testimonial: `My parents are not going to believe I made this! It's so good! I LOVE COOKING!!`,
    author: 'greyson, 5th grade',
  },
  {
    testimonial: `Successful cooking and eating! Thanks! Our daughter is so proud of herself!`,
    author: 'Liz Lathan, Texas',
  },
  {
    testimonial: `Can I have seconds, wait, I mean thirds? This is AHHHMAZING! Who knew I would like celery so much?`,
    author: 'Lucy, 2nd grade',
  },
  {
    testimonial: `Thanks for making cooking & eating a joy again for our child who has type 1 diabetes.`,
    author: 'erin, colorado',
  },
  {
    testimonial: `The meal last night was delicious and our son cooked it all! Sign us up for more classes!`,
    author: 'kristen, colorado',
  },
  {
    testimonial: `I don't need a fork. I need a shovel to get this in my mouth faster.`,
    author: 'owen, age 3',
  },
  {
    testimonial: `Cooking gluten-free for kids is no easy feat, so doing so in a kids program is impressive.`,
    author: 'Annika Mosier, colorado',
  },
  {
    testimonial: `I literally can't wait for the next class. This is the most fun E.V.E.R.!`,
    author: 'zach, age 8',
  },

];

export const HomeSection = () => {

  const navigate = useNavigate();

  const {myLocation, saveMyLocation} = useMyLocationContext();

  const [location, setLocation] = useState<SavedLocationSelection | null>(myLocation);
  const [search, setSearch] = useState<string>('');

  const savePreferredLocation = (ls: LocationSelection, valid: boolean) => {
    if (valid && ls?.value) {
      try {
        let loc = JSON.parse(ls.value) as SavedLocationSelection;
        saveMyLocation(ls.value, !myLocation);
        setLocation(loc);
      } catch (error) {
        console.log("Error trying to set location");
      }
    }
  }

  const onSearchClick = () => {
    let url = "/sessions";
    let searchParts = [];
    if (search && search !== '') {
      searchParts.push(`search=${search}`);
    }

    if (location && location.location) {
      let loc = `${location?.location.lat}_${location?.location.lng}`
      searchParts.push(`origin=${loc}`);
    }
    navigate(`${url}?${searchParts.join("&")}`);
  }

  return (
    <>
      <Container className="d-flex flex-wrap">
                <div className="header-image ">
                  <div className="header-text">
                    <h5 className="w-100 text-weight-600">
                      Award-Winning Programming
                    </h5>
                    <h1 className="w-100 text-weight-700">We Get Kids Cooking</h1>
                    <h3 className="w-100 text-weight-700">
                      Inspire your kids to love healthy foods & turn your picky eater
                      into a food explorer
                    </h3>
                    <Button className="button-header justify-content-center text-uppercase">
                    Why we’re Grape
                    </Button>
                  </div>
                </div>
                <div className="header-search ">
                  <Image className="w-75" src={FruitGroup} />
                  <h1 className="">
                    Find Sessions <span className="d-block"> Near You! </span>{" "}
                  </h1>
                  <p>
                    Sticky Fingers Cooking inspires kids to love healthy foods and
                    turns the pickiest of eaters into enthusiastic food explorers.
                  </p>
                  <Form>
                    <Form.Group controlId="formBasicText">
                      <Form.Control
                        type="text"
                        placeholder="Search by school or keyword"
                        className=""
                        onChange={(e) => setSearch(e.currentTarget.value)}
                      />
                      <LocationSetterNoForm 
                        setLocation={savePreferredLocation} 
                        button={
                          <Button className="button-input"
                            onClick={() => onSearchClick()}>
                            <IoIosSearch size={18} className="me-2"/>
                            Search
                          </Button>
                        } 
                      />
                      <BrowserLocationComponent />
                    </Form.Group>
                  </Form>
                </div>

                <div className="button-find-more text-weight-700 my-2">
                  <a className="text-uppercase button-find-more link-header text-blue" >
                    FIND US IN MORE LOCATIONS NATIONWIDE
                    <FaCaretRight size={18} className="me-2"/>
                    
                  </a>
                </div>
              </Container>
              <div className="d-flex justify-content-center w-100 position-relative">
                  <Image
                            className="section-fruit"
                            src={Artichoke}
                            alt="sticky fingers cooking banana graphic"
                          />

                </div>

              <Container fluid className="bg-white pt-5">
                <Container className="d-flex flex-wrap px-5 ">
                  <div className="card-option ">


                    <Image className="w-100 card-header" src={SchoolProgram} />
                    <div className="card-background">
                      <div className="card-description card-blue">
                        <p className="text-uppercase">Now enrolling</p>
                        <h3 className="text-weight-700">School Programs</h3>
                        <p>
                          Our high-quality before and after school enrichment programs
                          provide a fun, hands-on-learning experience. Instruction
                          includes nutrition, food history, food culture, geography,
                          and math. The best part—Sticky Fingers Cooking inspires kids
                          to love healthy foods and turns the pickiest of eaters into
                          enthusiastic food explorers
                        </p>
                        <div className="button-card-align">
                          <Button className="button-card w-100 justify-content-center text-uppercase"
                            onClick={() => navigate('/sessions?session_type=after_school')}>
                            JOIN TODAY
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-option">
                    <Image className="w-100 card-header" src={CookingCamps} />
                    <div className="card-background">
                      <div className="card-description card-red">
                        <p className="text-uppercase">Now enrolling</p>
                        <h3 className="text-weight-700">Cooking Camps</h3>
                        <p>
                          Check out our in-person cooking camps, private at-home
                          camps, and online cooking camps for kids! At Sticky Fingers
                          Cooking, our #1 priority is the safety and well-being of
                          your children and our chef instructors. We have a variety of
                          camp options, all of which will engage campers safely and
                          provide the social interactions and FUN that your kiddos are
                          craving.
                        </p>
                        <div className="button-card-align">
                          <Button className="button-card w-100 justify-content-center text-uppercase"
                            onClick={() => navigate('/sessions?session_type=camps')}>
                            SEE WHAT’S COOKING
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-option">
                    <Image className="w-100 card-header" src={PrivateEvents} />
                    <div className="card-background">
                      <div className="card-description card-orange">
                        <p className="text-uppercase">Now enrolling</p>
                        <h3 className="text-weight-700">Private Events</h3>
                        <p>
                          Gather your group of friends, family, or co-workers for a
                          Sticky Fingers Cooking private cooking event—online or
                          in-person. From birthday parties and family reunions to team
                          building and online cooking parties, your group will mix,
                          mingle, and laugh while cooking up one of our fun, healthy
                          recipes.
                        </p>
                        <div className="button-card-align">
                          <Button className="button-card w-100 justify-content-center text-uppercase">
                            JOIN TODAY
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-option">
                    <Image className="w-100 card-header" src={OnlineClasses} />
                    <div className="card-background">
                      <div className="card-description card-green">
                        <p className="text-uppercase">Now enrolling</p>
                        <h3 className="text-weight-700">Online Classes</h3>
                        <p>
                          Looking for guidance for your budding chef at home? Our
                          online classes offer fun and tasty ‘cool’inary experiences,
                          building lifelong skills with new recipes every week.
                          Connect with friends and family globally through the power
                          of cooking!
                        </p>
                        <div className="button-card-align">
                          <Button className="button-card w-100 justify-content-center text-uppercase"
                          onClick={() => navigate('/sessions?view=list&session_type=online')}>
                            SEE WHAT’S COOKING
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </Container>
              <Container fluid className="bg-white text-blue">
                <Container className="pb-5">
                  <div className="w-100 d-flex justify-content-center py-5 ">
                    <h3 className=" text-weight-700">
                      Get Kids Cooking in Three Simple Steps
                    </h3>
                  </div>

                  <div className="w-100 d-flex flex-nowrap justify-content-center">
                    <div className="step-column text-center">
                      <div className="step-image">
                        <Image className="w-100 h-auto" src={Step1} />
                      </div>
                      <div className="w-100 text-uppercase mt-3 letter-spacing-min">
                        step 1
                      </div>
                      <h3 className="text-weight-700">Search Class Sessions</h3>
                      <p className="">
                        <a href="/school-programs">Select your state here</a> and
                        search for your child&#x27;s school in the location search
                        box. If Sticky Fingers Cooking classes are offered at your
                        child&#x27;s school, upcoming class sessions will populate. No
                        results? <a href="/contact" >Contact us.</a>
                      </p>
                    </div>

                    <div className="step-column text-center">
                      <div className="step-image">
                        <Image className="w-100 h-auto " src={Step2} />
                      </div>
                      <div className="w-100 text-uppercase mt-3 letter-spacing-min">
                        step 2
                      </div>
                      <h3 className="text-weight-700">Enroll Online or at School</h3>
                      <p className="">
                        After reviewing the upcoming class sessions at your
                        child&#x27;s school, login or{" "}
                        <a href="<%= new_user_session_path %>">create an account</a>{" "}
                        to enroll your child. Some schools prefer that parents enroll
                        students directly at school. The class session page will guide
                        you.
                      </p>
                    </div>

                    <div className="step-column text-center">
                      <div className="step-image">
                        <Image className="w-100 h-auto" src={Step3} />
                      </div>
                      <div className="w-100 text-uppercase mt-3 letter-spacing-min">
                        step 3
                      </div>
                      <h3 className="text-weight-700">Get Cooking</h3>
                      <p className="">
                        After reviewing the upcoming class sessions at your
                        child&#x27;s school, login or{" "}
                        <a href="<%= new_user_session_path %>">create an account</a>{" "}
                        to enroll your child. Some schools prefer that parents enroll
                        students directly at school. The class session page will guide
                        you.
                      </p>
                    </div>

                    {/* <div class="w-col w-col-4 w-col-medium-4">
                          <div class="div-column-works">
                            <div class="div-image-works"><img src="/images/225809_StickyFingers_AdvancedCookingGraphic.png" loading="lazy" sizes="100vw" srcset="/images/225809_StickyFingers_AdvancedCookingGraphic-p-1080.png 1080w, /images/225809_StickyFingers_AdvancedCookingGraphic-p-1600.png 1600w, /images/225809_StickyFingers_AdvancedCookingGraphic-p-2000.png 2000w, /images/225809_StickyFingers_AdvancedCookingGraphic-p-2600.png 2600w, /images/225809_StickyFingers_AdvancedCookingGraphic-p-3200.png 3200w, /images/225809_StickyFingers_AdvancedCookingGraphic.png 3751w" alt="happy child who has matriculated to sticky fingers cooking junior chef 101 and 201 online cooking classes"></div>
                            <div class="subheader">step 3</div>
                            <h3 class="zero-margin-top">Get Cooking</h3>
                            <p class="center">Mark the class session dates on your calendar and ask your child about the delicious new recipes they are making. Better yet, make them again at home! <a href="/recipes">Recipes are available online after each class.</a>
                            </p>
                          </div>
                        </div> */}
                  </div>
                  <div className="w-100 d-flex justify-content-center ">
                    <Button className="  text-uppercase button-radius mb-5">
                      get started Now
                    </Button>
                  </div>

                  <h5 className="text-center text-uppercase my-5 letter-spacing-max text-weight-700 ">
                    As Seen On
                  </h5>
                  <div className="w-100 d-flex justify-content-center ">
                    <Image className="w-100 h-auto px-4" src={Logo1} />
                    <Image className="w-100 h-auto px-4" src={Logo2} />
                    <Image className="w-100 h-auto px-4" src={Logo3} />
                    <Image className="w-100 h-auto px-4" src={Logo4} />
                    <Image className="w-100 h-auto px-4" src={Logo5} />
                    <Image className="w-100 h-auto px-4" src={Logo6} />
                    <Image className="w-100 h-auto px-4" src={Logo7} />
                  </div>
                </Container>

                <div className="d-flex justify-content-center w-100 position-relative">
                  <Image
                            className="section-fruit"
                            src={Tomato}
                            alt="sticky fingers cooking banana graphic"
                          />

                </div>

              </Container>

              <Container className="pb-5 mt-5">
                <div className="col-12 d-flex flex-nowrap">
                  <div className="col-4  align-content-center ">
                    <p className="text-uppercase mt-5 text-weight-700 letter-spacing-min text-blue">
                      sfc in your community
                    </p>
                    <h2 className="my-3  text-weight-700 text-blue">
                      Spread Good Food and Cheer
                    </h2>
                    <p className="">
                      Give the kids in your community the opportunity to learn how to
                      make and enjoy tasty, healthy, fun food! Contact us today to
                      bring Sticky Fingers Cooking to your library, camp, church, or
                      troop.
                      <span className="d-block mt-4">
                        Please provide contact information for your organization’s
                        enrichment coordinator. We look forward to cooking with you!{" "}
                      </span>
                    </p>
                    <Button className="text-uppercase button-radius mb-5  ">
                      contact us
                    </Button>
                  </div>

                  <div className="col-5 my-5 d-flex w-75 flex-wrap align-content-center offset-min">
                    <div className="card-fruit-container ">
                      <Image
                        className="card-fruit "
                        src={Banana}
                        alt="sticky fingers cooking banana graphic"
                      />
                    </div>
                    <div className="card-fruit-container">
                      <Image
                        className="card-fruit "
                        src={Grapes}
                        alt="sticky fingers cooking banana graphic"
                      />
                    </div>
                    <div className="card-fruit-container">
                      <Image
                        className="card-fruit"
                        src={Coconut}
                        alt="sticky fingers cooking banana graphic"
                      />
                    </div>
                    <div className="card-fruit-container">
                      <Image
                        className="card-fruit"
                        src={BellPepper}
                        alt="sticky fingers cooking banana graphic"
                      />
                    </div>
                    <div className="card-fruit-container">
                      <Image
                        className="card-fruit"
                        src={Avocado}
                        alt="sticky fingers cooking banana graphic"
                      />
                    </div>
                    <div className="card-fruit-container">
                      <Image
                        className="card-fruit"
                        src={Orange}
                        alt="sticky fingers cooking banana graphic"
                      />
                    </div>
                    <div className="card-fruit-container">
                      <Image
                        className="card-fruit"
                        src={Mushroom}
                        alt="sticky fingers cooking banana graphic"
                      />
                    </div>
                    <div className="card-fruit-container">
                      <Image
                        className="card-fruit"
                        src={Onion}
                        alt="sticky fingers cooking banana graphic"
                      />
                    </div>
                  </div>
                </div>
              </Container>

              <section className="section-testimonial-slider">
                <Container className="d-flex justify-content-sm-start">
                  <div className="carousel-container">
                    <Slider {...carouselSettings}>
                      {testimonials.map((item, index) => (
                        <div key={index} className="carousel-item">
                          <div className="text-quote"><em>{item.testimonial}</em></div>
                          <div className="subheader white">{item.author}</div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </Container>
              </section>

              <div className="d-flex justify-content-center w-100 position-relative">
                  <Image
                            className="section-fruit"
                            src={Radish}
                            alt="sticky fingers cooking banana graphic"
                          />

                </div>


              <Container fluid className="bg-white ">
                <Container className="pb-5 text-blue ">
                  <div className="col-12 d-flex flex-nowrap ">
                    <Image className="w-100 mt-5 mb-2 h-auto" src={Dots} />
                  </div>

                  <div className="col-12 d-flex flex-nowrap ">
                    <div className="col-4  align-content-center">
                      <p className="text-uppercase mt-5 text-weight-700 letter-spacing-min ">
                        serving grades pre-k-12
                      </p>
                      <h2 className="my-3  text-weight-700 text-blue">
                        Bring the Joy of Cooking to Your School
                      </h2>
                      <p className="text-blue">
                        We have taught over 125,000 students in more than 1,000 school
                        across the U.S. No kitchen necessary! Contact us today to
                        bring our school enrichment program to your school. Our 6-14
                        week sessions teach kids to cook and eat
                        internationally-inspired recipes in a fun, hands-on way. We
                        emphasize cooking skills, kitchen safety, nutrition, and the
                        value of fresh ingredients. All of our well-trained chef
                        instructors are ServSafe certified in proper food handling and
                        FBI fingerprint background checked.
                      </p>
                      <Button className="text-uppercase button-radius mb-5">
                        contact us
                      </Button>
                    </div>
                    <div className="col-5   mt-5 offset-min ">
                      <div className="">
                        <iframe
                          className="video-container"
                          src="https://player.vimeo.com/video/872690025?h=d1f4529074&app_id=122963"
                          allow="autoplay; fullscreen; picture-in-picture"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </Container>
              </Container>
    </>
  );
};
