import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { StepProps } from "../Register"
import { ErrorMessage, Field } from 'formik';

export const BasicInfoForm = ({ touched, errors }: StepProps) => {
  return (
    <>
      {/* <Form.Group className="mb-1 mb-lg-3 fw-semibold" controlId="child_session">
        <Field
          name="child_session"
          type="checkbox"
          as={Form.Check}
          label="I'm signing up my child for a session"
        />
      </Form.Group> */}

      <Row>
        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="first_name">
            <Form.Label>First Name</Form.Label>
            <Field
              name="first_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.first_name && !!errors.first_name}
            />
            <ErrorMessage name="first_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
        <Col xs={6} className="ps-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="last_name">
            <Form.Label>Last Name</Form.Label>
            <Field
              name="last_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.last_name && !!errors.last_name}
            />
            <ErrorMessage name="last_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-1 mb-lg-3" controlId="phone_mobile">
        <Form.Label>Phone Number</Form.Label>
        <Field
          name="phone_mobile"
          type="tel"
          as={Form.Control}
          isInvalid={touched.phone_mobile && !!errors.phone_mobile}
        />
        <ErrorMessage name="phone_mobile">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <Form.Group className="mb-1 mb-lg-3" controlId="email">
        <Form.Label>Email Address</Form.Label>
        <Field
          name="email"
          type="email"
          as={Form.Control}
          isInvalid={touched.email && !!errors.email}
        />
        <ErrorMessage name="email">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>
    </>
  )
}
