/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditPayment';
import { paymentMethods } from '../../../../../utils/consts/finance/finance';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetAllInvoicesQuery } from '../../../../../services/endpoints/finance/invoice';
import { Invoice } from '../../../../../types/finance.types';

export const PaymentForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  const { data: invoices, error: invoicesError, isLoading: invoicesLoading } = useGetAllInvoicesQuery();

  const remaining_balance = invoices?.results.find(i => i.id === values?.invoice_id?.value)?.remaining_balance ?? 0;

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="invoice_id">
            <Field 
              as={FormSelect}
              name="invoice_id"
              label="Invoice"
              options={invoices?.results?.map((c: Invoice) => ({ id: c.id ?? 1, label: c.name ?? "" })) ?? []}
              loadingError={invoicesError ? "Error loading invoices" : undefined}
            />
          </Form.Group>
          <Form.Group className="mb-1 mb-lg-3" controlId="payment_method">
            <Field
              as={Form.Select}
              name="payment_method"
            >
              {Object.keys(paymentMethods).map((op) => (
                <option key={op} value={op} className="text-capitalize">
                  {paymentMethods[op]}
                </option>
              ))}
            </Field>
          </Form.Group>
          <Form.Group className="mb-1 mb-lg-3" controlId="check_number">
            <Form.Label>Check Number</Form.Label>
            <Field
              name="check_number"
              as={Form.Control}
              isInvalid={touched.check_number && !!errors.check_number}
            />
            <ErrorMessage name="check_number">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group className="mb-1 mb-lg-3" controlId="amount">
            <Form.Label>Amount ($)</Form.Label>
            <Field
              name="amount"
              as={Form.Control}
              isInvalid={touched.amount && !!errors.amount}
              disabled={remaining_balance === 0}
              />
              <Form.Text>The remaining balance on this invoice is ${remaining_balance.toFixed(2)}. {remaining_balance === 0 ? 'No additional payment can be made.' : ''}</Form.Text>
            <ErrorMessage name="amount">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group className="mb-1 mb-lg-3" controlId="notes">
            <Form.Label>Payment Notes</Form.Label>
            <Field
              name="notes"
              as="textarea"
              className="form-control"
              rows={4}
              isInvalid={touched.notes && !!errors.notes}
            />
            <Form.Text>The payment date will be {new Date().toLocaleDateString()}</Form.Text>
            <ErrorMessage name="notes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}