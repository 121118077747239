import React from 'react';
import { createRoot } from 'react-dom/client';
import { store } from './services/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './css/custom.css'; // custom compiled SFC CSS
import './index.css';
import App from './App';
import { DashNavbarProvider, ToastProvider, DataProvider, MyLocationProvider, CartProvider } from './context';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DashNavbarProvider>
        <ToastProvider>
          <DataProvider>
            <MyLocationProvider>
              <CartProvider>
                <App />
              </CartProvider>
            </MyLocationProvider>
          </DataProvider>
        </ToastProvider>
      </DashNavbarProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
