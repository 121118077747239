import { Col, Form, FormControl, InputGroup, Row, Spinner, Stack } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from '../AddEditSession';
import { BsCurrencyDollar } from 'react-icons/bs';
import { useGetThemesSimpleQuery } from '../../../../../../services/endpoints/content/theme';
import { useToast } from '../../../../../../context/ToastContext';
import { getErrorMessage } from '../../../../../../utils/utils';
import { Theme } from '../../../../../../types/theme.type';

export const SessionDetailsForm = ({ touched, errors, readOnly, values, setFieldValue }: StepProps) => {
  const { data, error, isLoading } = useGetThemesSimpleQuery();
  const toast = useToast();

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  }

  if (error || !data) {
    toast.addToast(getErrorMessage(error) ?? 'Error loading themes', 'error');
  }

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Session Details
      </h6>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Field
              name="title"
              type="text"
              as={Form.Control}
              isInvalid={touched.title && !!errors.title}
              disabled={readOnly}
            />
            <ErrorMessage name="title">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group controlId="is_online">
            <Form.Label>Program Type</Form.Label>
            <Field
              name="is_online"
              as={Form.Select}
              isInvalid={touched.is_online && !!errors.is_online}
              disabled={readOnly}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const { value } = e.target;
                setFieldValue!('is_online', value);
                if (value !== 'online') setFieldValue!('video_url', undefined);
              }}
            >
              <option value="in_person">In-person</option>
              <option value="online">Online</option>
            </Field>
            <ErrorMessage name="is_online">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          {values?.is_online === 'online' && (
            <Form.Group controlId="video_url">
              <Form.Label>Online Class Link</Form.Label>
              <Field
                name="video_url"
                type="text"
                as={Form.Control}
                isInvalid={touched.video_url && !!errors.video_url}
                disabled={readOnly}
              />
              <ErrorMessage name="video_url">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
          )}

          <Form.Group controlId="payment_type">
            <Form.Label>Payment Type</Form.Label>
            <Field
              name="payment_type"
              as={Form.Select}
              isInvalid={touched.payment_type && !!errors.payment_type}
              disabled={readOnly}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const { value } = e.target;
                setFieldValue!('payment_type', value);
                if (value !== 'online') {
                  setFieldValue!('video_url', undefined);
                  setFieldValue!('can_be_prorated', undefined);
                  setFieldValue!('invoice_type', 'per_student');
                } else {
                  setFieldValue!('can_be_prorated', false);
                }
              }}
            >
              <option value="website">Website</option>
              <option value="onsite">Onsite</option>
            </Field>
            <ErrorMessage name="payment_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          {values?.payment_type === 'onsite' && (
            <Form.Group controlId="invoice_type">
              <Form.Label>Program Type</Form.Label>
              <Field
                name="invoice_type"
                as={Form.Select}
                disabled={readOnly}
              >
                <option value="per_student">Per student</option>
                <option value="flat_rate">Flat rate</option>
              </Field>
              <ErrorMessage name="invoice_type">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
          )}

          <Form.Group controlId="price_per_class">
            <Form.Label>Tuition Price</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <BsCurrencyDollar color="#5E6469" />
              </InputGroup.Text>
              <Field
                name="price_per_class"
                type="number"
                as={Form.Control}
                isInvalid={touched.price_per_class && !!errors.price_per_class}
                disabled={readOnly}
              >
              </Field>
            </InputGroup>
            <Form.Text>Price per student, per class (EX: {values?.classes?.length ?? 0} scheduled classes x ${(+(values?.price_per_class ?? 0)).toFixed(2)} = ${((values?.classes?.length ?? 0) * (+(values?.price_per_class ?? 0))).toFixed(2)})</Form.Text>
            <ErrorMessage name="price_per_class">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          {values!.payment_type === 'website' && (
            <Form.Group className="fw-semibold" controlId="can_be_prorated">
              <Field
                as={Form.Check}
                label="Prorate tuition"
                name="can_be_prorated"
                checked={values?.can_be_prorated}
                disabled={readOnly}
              />
            </Form.Group>
          )}
        </Stack>
      </Col>

      <Col xs={12} lg={6}>
        <Stack gap={3}>
          <Form.Group controlId="course_type">
            <Form.Label>Session Type</Form.Label>
            <Field
              name="course_type"
              as={Form.Select}
              isInvalid={touched.course_type && !!errors.course_type}
              disabled={readOnly}
            >
              <option value="after_school">After School</option>
              <option value="camp">Camp</option>
              <option value="event">Event</option>
            </Field>
            <ErrorMessage name="course_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group controlId="theme_id">
            <Form.Label>Theme</Form.Label>
            <Field
              name="theme_id"
              as={Form.Select}
              isInvalid={touched.theme_id && !!errors.theme_id}
              disabled={readOnly}
            >
              <option>Select</option>
              {data?.map((theme: Theme) => (
                <option key={theme.id} value={theme.id}>{theme.name}</option>
              ))}
            </Field>
            <ErrorMessage name="theme_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group controlId="status">
            <Form.Label>Status</Form.Label>
            <Field
              name="status"
              as={Form.Select}
              isInvalid={touched.status && !!errors.status}
              disabled={readOnly}
            >
              <option value="open">Open</option>
              <option value="offline">Offline</option>
              <option value="cancelled">Cancelled</option>
            </Field>
            <ErrorMessage name="status">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <div>
            <Form.Group className="fw-semibold" controlId="is_hidden">
              <Field
                as={Form.Check}
                label="Not visible on the front-end of the site"
                name="is_hidden"
                disabled={readOnly}
                checked={values?.is_hidden}
              />
            </Form.Group>

            <Form.Group className="fw-semibold" controlId="opts_out_of_roster_emails">
              <Field
                as={Form.Check}
                label="Opts out of roster emails"
                name="opts_out_of_roster_emails"
                disabled={readOnly}
                checked={values?.opts_out_of_roster_emails}
              />
            </Form.Group>
          </div>
        </Stack>
      </Col>
    </Row>
  )
}
