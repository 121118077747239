import { ButtonData } from "../../../../../components/buttonGroup/ButtonGroup";
import { useEffect, useState } from "react";
import SFCTable from "../../../../../components/tables/SFCTable";
import { useToast } from "../../../../../context/ToastContext";
import { GetQueryParams } from "../../../../../types/api.type";
import {
  FilterField,
  FilterForm,
} from "../../../../../components/tables/filterForms/FilterForm";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { SimpleButton } from "../../../../../components/buttons/SimpleButton";
import { Scopes } from "../../../../../services/endpoints/people/user";
import { jokeCategoriesColumns } from "../../../../../utils/consts/columnDefs/contentColumns";
import { JokeCategory } from "../../../../../types/content.type";
import { useGetJokeCategoriesQuery } from "../../../../../services/endpoints/content/jokeCategory";

const defaultScope = "all";

export const JokeCategoriesView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({
    scope: defaultScope,
    data: {page: 1, entries: 10},
  });
  const [scopes, setScopes] = useState<Scopes>({});
  const [jokeCategories, setJokeCategories] = useState<JokeCategory[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const { data, error, isLoading, isFetching } = useGetJokeCategoriesQuery(filters);
  const navigate: NavigateFunction = useNavigate();

  const filterFormFields: FilterField[] = [
    {
      name: "name",
      label: "Name",
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(
          data.scopes.reduce(
            (acc: Scopes, curr: Scopes) => ({ ...acc, ...curr }),
            {} as Scopes
          )
        );
        setButtons(
          data.scopes.map((sMap) =>
            Object.entries(sMap).map(([scope, title]) => ({
              title: title,
              key: scope,
            }))
          )
        );
      }
    } else if (error) {
      addToast("Error while loading data", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  useEffect(() => {
    if (data?.results) {
      setJokeCategories(data.results);
    }
  }, [data]);

  const setFilterBy = (
    filterBy: { operator: string; field: string; value: string }[]
  ) => {
    console.log("filter", filterBy);
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      },
    }));
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ""} Joke Categories`}
        columns={jokeCategoriesColumns}
        data={jokeCategories}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        // indexDownloadPath="joke-categories"
        filters={filters?.data?.filterBy}
        filterForm={
          <FilterForm fields={filterFormFields} onApplyFilters={setFilterBy} />
        }
        onFiltersChanged={setFilters}
        newItemEl={
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate("/admin/content/joke-categories/new")}
          >
            New Joke Category
          </SimpleButton>
        }
      />
    </div>
  );
};
