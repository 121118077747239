import React, { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

type ToastMessageType = 'success' | 'error' | 'warning' | 'info';

interface ToastMessage {
  id: number;
  type: ToastMessageType;
  message: string;
}

interface ToastContextType {
  addToast: (message: string, type: ToastMessageType) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [toast, setToast] = useState<ToastMessage | null>();
  const toastId = useRef(0);

  const addToast = useCallback((message: string, type: ToastMessageType) => {
    const id = toastId.current++;
    setToast({ id, type, message });
    setTimeout(() => removeToast(id), 3000);
  }, []);

  const removeToast = (id: number) => {
    setToast(null);
  };

  const getBgColor = (type: ToastMessageType) => {
    switch (type) {
      case 'success':
        return 'bg-success';
      case 'error':
        return 'bg-danger';
      case 'warning':
        return 'bg-warning';
      case 'info':
        return 'bg-info';
      default:
        return '';
    }
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      <ToastContainer position="bottom-end" className="p-3">
        {toast && (
          <Toast
            key={toast.id}
            onClose={() => removeToast(toast.id)}
            delay={3000}
            autohide
            className={`text-white ${getBgColor(toast.type)}`}
          >
            <Toast.Body>{toast.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      {children}
    </ToastContext.Provider>
  );
};
