import { Lesson } from "../../../types/lesson.type";
import "./ClassPreview.css";
import { FaRegCalendar } from "react-icons/fa6";
import { formatDate } from "../../../utils/dateUtils";
import { formatAddress } from "../../../utils/utils";
import { FaMapMarkerAlt, FaTag } from "react-icons/fa";
import { BsClock } from "react-icons/bs";
import { PiForkKnifeFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Child } from "../../../services/endpoints/people/user";
import { Form } from "react-bootstrap";

interface ClassPreviewProps {
  selectedLesson: Lesson | null;
}

const ClassPreview: React.FC<ClassPreviewProps> = ({ selectedLesson }) => {
  const [lessonChildren, setLessonChildren] = useState<Child[]>([]);

  useEffect(() => {
    if (selectedLesson?.course.orders?.length) {
      let children: Child[] = [];
      selectedLesson.course.orders.forEach((order) => {
        // children = children.concat(order.children);
        if (order.registrations.length) {
          children.push(order.registrations[0].child);
        }
      });
      if (children.length) {
        setLessonChildren(children);
      }
    }
  }, [selectedLesson]);

  return selectedLesson ? (
    <div className="container-fluid">
      <span style={{ fontWeight: 600, color: "#5E646980" }}>Class Details</span>
      <br />
      <span className={"h5 text-uppercase text-info fw-bolder mb-0"}>
        {selectedLesson.course?.title}
      </span>
      <span
        style={{ fontWeight: 600, fontSize: "18px" }}
        className="lesson-detail text-secondary"
      >
        <div className="detail-icon">
          <FaRegCalendar style={{ color: "#000" }} />
        </div>
        <div className="detail-text">
          <span>
            {formatDate(new Date(selectedLesson.date), "MMMM dd, yyyy")}
          </span>
        </div>
      </span>
      <span
        style={{ fontWeight: 600, fontSize: "18px" }}
        className="lesson-detail text-secondary"
      >
        <div className="detail-icon">
          <FaMapMarkerAlt style={{ color: "#000" }} />
        </div>
        <div className="detail-text">
          <span>{selectedLesson.course.venue.title}</span>
          <br />
          <span>{formatAddress(selectedLesson.course?.venue)}</span>
        </div>
      </span>
      <span
        style={{ fontWeight: 600, fontSize: "18px" }}
        className="lesson-detail text-secondary"
      >
        <div className="detail-icon">
          <BsClock style={{ color: "#000" }} />
        </div>
        <div className="detail-text text-uppercase">
          <span>{selectedLesson.formatted_time} MT</span>
        </div>
      </span>
      <span
        style={{ fontSize: "18px" }}
        className="lesson-detail text-secondary"
      >
        <div className="detail-icon">
          <PiForkKnifeFill style={{ color: "#000" }} />
        </div>
        <div className="detail-text">
          {selectedLesson.lesson_plans.map((plan, idx) => (
            <Link
              key={`schedule-lesson-plan-${idx}`}
              to={`/lesson-plans/${plan.slug}`}
              className="text-capitalize"
              style={{ textDecoration: "none", color: "#6098B8" }}
            >
              {plan.name}
            </Link>
          ))}
        </div>
      </span>
      <br />
      <span style={{ fontWeight: 600, color: "#5E646980" }}>
        Children Enrolled:
      </span>
      <br />
      {lessonChildren.length && (
        <div className="children-enrolled">
          {lessonChildren.map((child, idx) => (
            <Form.Check
              key={idx}
              type="checkbox"
              id={`child-${idx}`}
              label={`${child.first_name} ${child.last_name}`}
              value={child.id}
              readOnly={true}
              checked={true}
            />
          ))}
        </div>
      )}
    </div>
  ) : (
    <div className="no-class-container">
      <span className="no-class-label">No class selected.</span>
    </div>
  );
};

export default ClassPreview;
