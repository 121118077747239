import React, { ReactNode } from 'react';
import { Badge, Card, Stack } from 'react-bootstrap';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { OrderPlusCost } from '../../../../../types/orders.type';
import { LabelTextSpread } from '../../../../../components/generic';
import { BsTagFill } from 'react-icons/bs';

interface OrderCostProps { 
  order: OrderPlusCost;
  className?: string; 
  index?: number;
}

export const OrderCost: React.FC<OrderCostProps> = ({ order, className, index }) => {
  const {price, fullPrice, discount} = order.cost;
  const discountNum = discount ?? 0;
  const prorated = price !== fullPrice;
  let discountPrice = (discountNum > 0) ? Math.round((price*100) * (1-discountNum))/100 : price;

  const discounted = prorated || discountNum > 0;

  let count = order.registrations.length;

  let totalContent = <CurrencyCell value={discountPrice} />;
  let eachPriceFull = fullPrice/count;
  let eachPrice = discountPrice/count;
  let eachContent = <CurrencyCell value={eachPrice} label="each" />;
  let totalLabel = <span>Session {(index ?? 0)+1}</span>;
  if (discounted) {
    totalContent = (
      <Stack direction="horizontal" gap={1}>
        <s style={{color: 'var(--bs-sfc-pink)', fontSize: '.75rem'}}><CurrencyCell value={fullPrice} /></s>
        <CurrencyCell value={discountPrice} />
      </Stack>
    );
    if (discountNum > 0) {
      totalLabel = (
        <Stack direction="horizontal" gap={1}>
          {totalLabel}
          <Badge><BsTagFill /> -{discountNum * 100}%</Badge>
          {prorated && <Badge>Prorated</Badge>}
        </Stack>
      );
    }
    eachContent = (
      <Stack direction="horizontal" gap={1}>
        <s style={{color: 'var(--bs-sfc-pink)', fontSize: '.75rem'}}><CurrencyCell value={eachPriceFull} label="each" /></s>
        <CurrencyCell value={eachPrice} label="each" />
      </Stack>
    )
  }
  let content = (
    <Stack direction="horizontal">
      <Stack className={className}>
        <LabelTextSpread opacity={1} label={totalLabel} >
          {totalContent}
        </LabelTextSpread>
        {count > 0 && (
          <LabelTextSpread fontSize=".75rem" label={`x${count} Students`} >
            {eachContent}
          </LabelTextSpread>
        )}
      </Stack>
    </Stack>
  );
  return (
    <Card>
      <Card.Body className='p-1'>
        {content}
      </Card.Body>
    </Card>
  );
};
