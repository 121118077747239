import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Page } from '../../../types/website.types';
import { sfcApi } from '../../api';
import { objectToFormData } from '../../helper';

export const pageApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPages: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `pages${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Page']
    }),
    getPage: builder.query<{ page: Page }, number>({
      query: (id) => `pages/${id}`,
      providesTags: (result, error, id) => [{ type: 'Page', id }]
    }),
    createPage: builder.mutation<{ page: Page }, Page>({
      query: (data) => {
        let payload: any = {
          url: `pages/create`,
          method: 'POST',
          body: data,
        };
        if (data.image) {
          payload.formData = true;
          payload.body = objectToFormData(data);
        }
        return payload;
      },
      invalidatesTags: ['Page']
    }),
    updatePage: builder.mutation<{ page: Page }, Page>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        let payload: any = {
          url: `pages/update/${id}`,
          method: 'PUT',
          body: data
        };
        if (data.image) {
          payload.formData = true;
          payload.body = objectToFormData(data);
        }
        return payload;
      },
      invalidatesTags: (result, error) => [{ type: 'Page', id: result?.page?.id }, 'Page']
    }),
    deletePage: builder.mutation<{ page: Page }, number>({
      query: (id) => ({
        url: `pages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Page'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Page', id })), 'Page']
    }),
  }),
});

export const {
  useGetAllPagesQuery,
  useGetPageQuery,
  useCreatePageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,
} = pageApi;