import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../components/buttons/BackButton';
import Stepper from '../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { getErrorMessage, getKeyByValue } from '../../../../utils/utils';
import { useToast } from '../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { UserForm } from './UserForm';
import { phoneRegExp, userTypes } from '../../../../utils/consts';
import { useCreateUserMutation, useGetAdminQuery, useGetInstructorQuery, UserType, useUpdateSFCMutation } from '../../../../services/endpoints/people/user';
import { userTypeNames } from '../../../../utils/consts/index';
import { useGetParentQuery } from '../../../../services/endpoints/people/parent';

const steps = [
  { name: 'Details', form: UserForm },
];

export interface FormValues {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password?: string;
  phone_mobile: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  timezone: string;
  location_id: string;
  user_type: string;
  opts_out_of_texts?: boolean;
  instructor_session_rate?: number;
  instructor_session_reimbursement?: number;
  instructor_online_rate?: number;
  instructor_online_reimbursement?: number;
  trainee_rate?: number;
  observer_rate?: number;
  admin_level?: string;
  active: boolean;
  uses_advanced_scheduling?: boolean;
  user_type_id?: number;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

const userLinksMap = (user: UserType, id?: number | string) => {
  if (user === 'customer') {
    // TODO: add customer values
    return {
      edit: `/admin/dash-users/admins/${id}/edit`,
      detail: `/admin/dash-users/admins/${id}`,
    }
  } else if (user === 'instructor') {
    return {
      edit: `/admin/dash-users/instructors/${id}/edit`,
      detail: `/admin/dash-users/instructors/${id}`,
    }
  } else {
    return {
      edit: `/admin/dash-users/admins/${id}/edit`,
      detail: `/admin/dash-users/admins/${id}`,
    }
  }
}

interface AddEditUserProps {
  editing?: boolean;
  userType: UserType;
}

export const AddEditUser: React.FC<AddEditUserProps> = ({ editing, userType }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateSFCMutation();
  const { data: adminData, error: adminError, isLoading: adminLoading } = 
    useGetAdminQuery(Number(params.id!), { skip: !editing || !params.id || userType === 'instructor' || userType === 'customer' });
  const { data: instructorData, error: instructorError, isLoading: instructorLoading } = 
    useGetInstructorQuery(Number(params.id!), { skip: !editing || !params.id || userType !== 'instructor'});
  const { data: parentData, error: parentError, isLoading: parentLoading } = 
    useGetParentQuery(Number(params.id!), { skip: !editing || !params.id || userType !== 'customer'});

  const [initialValues, setInitialValues] = useState<FormValues>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
    phone_mobile: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    timezone: '',
    location_id: '',
    user_type: userType.toString(),
    instructor_session_rate: 0,
    instructor_session_reimbursement: 0,
    instructor_online_rate: 0,
    instructor_online_reimbursement: 0,
    trainee_rate: 0,
    observer_rate: 0,
    active: true,
    admin_level: 'admin',
  });

  const validationSchemas = [
    Yup.object({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      email: Yup.string().required('Email is required'),
      password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
      phone_mobile: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, 'Phone number should have at least 10 digits')
        .required('Phone number is required'),
      address: Yup.string().required('Address is required'),
      address2: Yup.string().optional(),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().required('Zip is required'),
      timezone: Yup.string().required('Time zone is required'),
      location_id: Yup.number().required('Location selection is required'),
      user_type: Yup.string().required('User type is required'),
      instructor_session_rate: Yup.number().positive().min(0),
      instructor_session_reimbursement: Yup.number().positive().min(0),
      instructor_online_rate: Yup.number().positive().min(0),
      instructor_online_reimbursement: Yup.number().positive().min(0),
      trainee_rate: Yup.number().positive().min(0),
      observer_rate: Yup.number().positive().min(0),
      active: Yup.boolean(),
    }),
  ];

  useEffect(() => {
    if (editing && adminError && !adminLoading) {
      addToast('Error while loading admin', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, adminError, adminLoading]);

  useEffect(() => {
    if (editing && instructorError && !instructorLoading) {
      addToast('Error while loading instructor', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, instructorError, instructorLoading]);

  useEffect(() => {
    if (editing && parentError && !parentLoading) {
      addToast('Error while loading parent', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, parentError, parentLoading]);

  useEffect(() => {
    let values;
    if (adminData && adminData?.admin) {
      values = adminData.admin;
    } else if (instructorData && instructorData?.instructor) {
      values = instructorData.instructor;
    }
    // TODO: load customer data
    else {
      values = parentData?.parent;
    }

    if (values?.first_name)
      setInitialValues({
        ...values,
        password: '',
        confirm_password: '',
        address: values.address ?? undefined,
        address2: values.address2 ?? undefined,
        location_id: values.location_id ? String(values.location_id) : '',
        user_type: getKeyByValue(userTypes, values.user_type_id)!,
        admin_level: 'admin',
      });
  }, [adminData, instructorData, parentData]);


  const handleAddUser = async (formValue: FormValues) => {
    try {
      delete formValue.confirm_password;
      if (editing) {
        const res = await updateUser({
          ...formValue,
          id: Number(params.id)!,
        }).unwrap();
        if (res && res.user) {
          addToast(`User updated succesfully`, 'success');
          navigate(userLinksMap(userType, params.id!)?.detail);
        }
      } else {
        const res = await createUser(formValue).unwrap();
        if (res && res.user) {
          navigate(userLinksMap(userType, params.id!)?.detail);
          addToast('User created succesfully', 'success');
        } else throw new Error('A problem happened while creating user');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        first_name: true,
        last_name: true,
        email: true,
        password: true,
        confirm_password: true,
        phone_mobile: true,
        address: true,
        city: true,
        state: true,
        zip: true,
        timezone: true,
        user_type: true,
        instructor_session_rate: true,
        instructor_session_reimbursement: true,
        instructor_online_rate: true,
        instructor_online_reimbursement: true,
        trainee_rate: true,
        observer_rate: true,
        active: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text="Back"
            color="#2B4E64"
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} {userTypeNames[userType]}
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddUser}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
