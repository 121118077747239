import { FadeIn } from '../animations/FadeIn';
import { Image, Stack } from 'react-bootstrap';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../components/buttons/SimpleButton';
import SFCLogoMD from '../../assets/images/sticky-fingers-logo-stacked-2.png';

export const Welcome = () => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <FadeIn duration={600}>
      <div className="pb-4">
        <Image fluid className="d-block d-sm-none" src={SFCLogoMD} alt="Sticky Fingers Logo" />
        <Image className="d-none d-sm-inline-flex" src={SFCLogoMD} style={{ maxWidth: 380 }} alt="Sticky Fingers Logo" />
      </div>

      <h2 className={`d-none d-md-block text-dark fw-bold mt-4 mt-lg-5`} style={{ fontSize: 32 }}>
        Welcome to Sticky Fingers Cooking!
      </h2>
      <h2 className={`d-block d-md-none text-dark fw-bold mt-4 mt-lg-5`} style={{ whiteSpace: 'pre', fontSize: 30 }}>
        {`Welcome to\n Sticky Fingers Cooking!`}
      </h2>

      <div className="d-inline-flex justify-content-center w-100">
        <Stack className="w-100 pt-4" style={{ maxWidth: 480 }} gap={2}>
          <SimpleButton variant="primary" onClick={() => navigate('/users/login')}>
            Log In
          </SimpleButton>
          <Stack direction="horizontal" gap={2} >
            <hr className="w-50" />
            <span className="text-uppercase" style={{ color: 'rgba(94, 100, 105, 0.5)' }}>or</span>
            <hr className="w-50" />
          </Stack>
          <SimpleButton variant="outline-primary" onClick={() => navigate('/users/register')}>
            Sign Up
          </SimpleButton>
        </Stack>
      </div>
    </FadeIn>
  )
}
