import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../../context/ToastContext';
import { Spinner } from 'react-bootstrap';
import { getErrorMessage } from '../../../../../utils/utils';
import { useDeleteKitchenSkillMutation } from '../../../../../services/endpoints/content/kitchenSkills';

interface HandleActionProps {
  action: 'delete';
}

export const HandleKitchenSkillAction: React.FC<HandleActionProps> = ({ action }) => {
  const params = useParams();
  const id = +params.id!;
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [deleteThemes] = useDeleteKitchenSkillMutation();
  const [loading, setLoading] = useState(true);

  const deleteFn = async () => {
    let fn;
    fn = deleteThemes;
    try {
      await fn(id).unwrap();
      addToast(`Kitchen Action #${id} ${action}d correctly`, 'success');
    } catch (e) {
      navigate('/admin/content/kitchen-skills');
      addToast(getErrorMessage(e), 'error');
    } finally {
      setLoading(false);
    }
  }
  
  useEffect(() => {
    deleteFn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
 
  return (
    <Navigate to={action === 'delete' ? `/admin/content/kitchen-skills` : `/admin/content/kitchen-skills/${id}`} />
  )
}
