import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./CourseDetails.css";
import { useGetUserProfileQuery } from "../../../../services/endpoints/people/user";
import { useGetLessonPlanPublicQuery } from "../../../../services/endpoints/content/lessonPlan";
import { LessonPlan } from "../../../../types/lessonPlan.type";

interface LessonPlanPublicProps {}

const LessonPlanPublic: React.FC<LessonPlanPublicProps> = ({}) => {
  const params = useParams();
  const [lessonPlan, setLessonPlan] = useState<LessonPlan>();
  const { data, isLoading } = useGetLessonPlanPublicQuery(params.id!);
  const { data: userProfile, refetch } = useGetUserProfileQuery();

  useEffect(() => {
    if (!isLoading && data) {
      setLessonPlan(data.lessonPlan);
    }
  }, [data]);

  return (
    <>
      <div className="m-5">
        {lessonPlan && (
          //TODO: complete this page
          <pre>{JSON.stringify(lessonPlan, null, 2)}</pre>
        )}
      </div>
    </>
  );
};

export default LessonPlanPublic;
