import React, { ReactNode } from 'react';
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { BsLaptop } from 'react-icons/bs';
import { LiaUniversitySolid } from 'react-icons/lia';

interface CourseTypeCellProps {
  is_online?: boolean;
  value: string | ReactNode;
}

export const CourseTypeCell: React.FC<CourseTypeCellProps> = ({ is_online, value }) => {

  const renderTooltip = (props: any) => (
    <Tooltip id="enrollment-tooltip" {...props}>
      <span style={{ fontSize: '.75rem' }}>
        This session is {is_online ? 'online' : 'in-person'}.
      </span>
    </Tooltip>
  );

  return (
    <Stack direction="horizontal" gap={2}>
      <OverlayTrigger overlay={renderTooltip} placement="bottom">
        <span style={{ cursor: 'pointer' }}>
          {is_online
            ? <BsLaptop size={19} />
            : <LiaUniversitySolid size={22} />
          }
        </span>
        
      </OverlayTrigger>
      <span>
        {value}
      </span>
    </Stack>
  )
};
