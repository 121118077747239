import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { TerritoryResponse, SFCTerritory, LedgerParams, LedgerResponse, LedgerData } from '../../../types/place.types';
import { sfcApi } from '../../api';

export const territoriesApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getTerritory: builder.query<TerritoryResponse, number>({
      query: (id) => ({
        url: `territories/${id}`,
      }),
      providesTags: ['Territory'],
    }),
    getAllTerritories: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `territories${params?.scope ? (params?.scope !== 'all' ? `?scope=${params.scope}` : '') : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    createTerritory: builder.mutation<TerritoryResponse, Partial<SFCTerritory>>({
      query: (params) => ({
        method: 'POST',
        url: `territories/create`,
        body: params
      }),
    }),
    updateTerritory: builder.mutation<TerritoryResponse, Partial<SFCTerritory>>({
      query: (data) => ({
        url: `territories/update/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Territory'],
    }),
    getTerritoryLedger: builder.query<LedgerResponse, LedgerParams>({
      query: (params) => ({
        url: `territories/ledger`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useGetTerritoryQuery,
  useGetAllTerritoriesQuery,
  useCreateTerritoryMutation,
  useUpdateTerritoryMutation,
  useGetTerritoryLedgerQuery,
} = territoriesApi;
