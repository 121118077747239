import { Col, Container, Row } from 'react-bootstrap';
import { Event, SFCCalendar } from '../../../components/calendar/SFCCalendar/SFCCalendar';
import { Filters } from '../../../components/calendar/SFCCalendar/Filters';
import { eventColors, eventTypeMapping, eventTypes, isAdmin } from '../../../utils/consts/index';
import { SFCMobileCalendar } from '../../../components/calendar/SFCCalendar/SFCMobileCalendar';
import { useGetInstructorLessonsQuery, useGetLessonsCountQuery, useGetLessonsQuery } from '../../../services/endpoints/schedule/lesson';
import { Lesson } from '../../../types/lesson.type';
import { useEffect, useState } from 'react';
import { GetQueryParams } from '../../../types/api.type';
import { useToast } from '../../../context/ToastContext';
import { getCurrentUser } from '../../../services/helper';
import { formatDate, dateInTimeZone, UTCToDate } from '../../../utils/dateUtils';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';

const EventKeys = () => (
  <div className="mt-3 border rounded-3 p-2 px-lg-3 py-lg-2" style={{ width: 'fit-content' }}>
    <span className="text-info text-uppercase fw-semibold">Key</span>
    <Row className="align-items-center">
      {Object.keys(eventTypes).map((type: string) => {
        const key = type as keyof typeof eventTypes;
        return (
          <Col key={type}  xs={12} lg="auto" className="d-flex align-items-center me-4">
            <div className="rounded-circle me-1" style={{ width: 8, height: 8, background: eventColors[key]}}></div>
            <span>{eventTypes[key]}</span>
          </Col>
        )
      })}
    </Row>
  </div>
);

const defaultFilters = [
  {
    operator: 'greater_equal_than',
    field: 'date',
    value: formatDate(dateInTimeZone(startOfWeek(startOfMonth(new Date()))), 'yyyy-MM-dd'),
  },
  {
    operator: 'lesser_equal_than',
    field: 'date',
    value: formatDate(dateInTimeZone(endOfWeek(endOfMonth(new Date()))), 'yyyy-MM-dd'),
  }
];

export const CalendarView = () => {
  const currentUser = getCurrentUser();
  const isAdminUser = currentUser?.isAdmin;
  const [filters, setFilters] = useState<GetQueryParams>({
    data: {
      filterBy: [...defaultFilters, ...(!isAdminUser ? [{
        operator: 'equals',
        field: 'additional_instructor_id',
        value: String(currentUser?.id),
      }]: [])]
    }
  });
  
  const [events, setEvents] = useState<Event[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [showFullEvents, setShowFullEvents] = useState<boolean>(!isAdminUser);
  const [fetchEvents, setFetchEvents] = useState<boolean>(!isAdminUser);
  const { data: lessons, error: lessonsError, isLoading: lessonsLoading, isFetching: lessonsFetching } = useGetInstructorLessonsQuery(filters, { skip: !fetchEvents});
  const { data, error, isLoading, isFetching } = useGetLessonsCountQuery({start: dates[0], end: dates[1]}, {skip: fetchEvents || showFullEvents || !isAdminUser || dates[0] === undefined || dates[1] === undefined});
  const { addToast } = useToast();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading events', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (lessons) {
      const lessonsEvents: Event[] = [];
      lessons.forEach((item) => {
        return item.lessons.forEach((lesson) => lessonsEvents.push({
          title: lesson.course.title,
          start: new Date(lesson.date + ':'),//UTCToDate(`${lesson.date} ${lesson.starts_at}`, 'yyyy-MM-dd h:mm a'),
          end: new Date(lesson.date + ':'), //UTCToDate(`${lesson.date} ${lesson.ends_at}`, 'yyyy-MM-dd h:mm a'),
          type: eventTypeMapping[item.label!],
          lesson,
        }))
      });
      setFetchEvents(false);
      setEvents(lessonsEvents);
      setShowFullEvents(true);
    }
  }, [lessons]);

  useEffect(() => {
    if (data && !showFullEvents) {
      const lessons: Event[] = [];
      data.lessonsCount.forEach((item) => {
        let start = new Date(item.lesson_date + ':');
        let end = new Date(item.lesson_date + ':');
        end.setHours(23);

        for (let index = 0; index < item.count_all; index++) {
          lessons.push({
            title: '',
            start,
            end,
            type: eventTypeMapping['main_instructor_id'],
          });
        }
      });
      setEvents(lessons);
    }
  }, [data, showFullEvents]);

  useEffect(() => {
    if (filters && filters.data?.filterBy) {
      let filterArr = filters.data.filterBy;
      const newDates: string[] = filterArr.filter((filter) => filter.field === 'date')
        .map((filter) => filter.value as string);
      setDates(newDates);
      const fetchEventFlag = filterArr?.find(f => f.field === 'additional_instructor_id') !== undefined;
      setFetchEvents(fetchEventFlag);
    }
  }, [filters]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="px-0 px-lg-5">
      <h2
        className="d-block d-lg-none text-secondary text-uppercase px-3 py-2"
        style={{ background: '#EFEFEF', fontSize: '1.5rem', fontWeight: 900 }}
      >
        Calendar
      </h2>
      <h2
        className="d-none d-lg-block text-info fw-bold pt-4 ps-2"
        style={{ fontSize: '2.25rem' }}
      >
        Calendar
      </h2>

      <Container fluid>
        <Filters onApplyFilters={setFilterBy} disabled={isLoading || isFetching} />
        
        {/* Mobile calendar */}
        <div
          className="d-block d-lg-none mb-4"
        >
          <SFCMobileCalendar
            events={events}
            showFullEvents={showFullEvents}
            loading={isLoading || isFetching || lessonsFetching}
            currentDates={dates}
          />
          <EventKeys />
        </div>

        {/* Desktop */}
        <div
          className="d-none d-lg-block p-5 rounded-4 mb-5"
          style={{
            boxShadow: '2px -2px 8px 2px rgba(0, 0, 0, 0.1), -2px 2px 8px 2px rgba(0, 0, 0, 0.1)',
          }}
        >
          <SFCCalendar
            events={events}
            loading={isLoading || isFetching || lessonsFetching}
            showFullEvents={showFullEvents}
            currentDates={dates}
          />
          <EventKeys />
        </div>
      </Container>
    </div>
  )
}
