import { getAcessToken } from './helper';

export function authHeader() {
  const accessToken = getAcessToken();

  if (accessToken) {
    return { 'x-access-token': accessToken };       // for Node.js Express back-end
  } else {
    return { 'x-access-token': null };
  }
}

export function authHeaderToken() {
  return authHeader()["x-access-token"];
}