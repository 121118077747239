import React from 'react';
import { Badge } from 'react-bootstrap';
import { BsCart3 } from 'react-icons/bs';
import './CartIcon.css'; // Custom CSS for positioning the badge

interface CartIconProps {
  itemCount: number;
}

const CartIcon: React.FC<CartIconProps> = ({ itemCount }) => {
  return (
    <div className="cart-icon-container">
      <BsCart3 size={24} />
      {itemCount > 0 && (
        <Badge pill className="cart-icon-badge">
          {itemCount}
        </Badge>
      )}
    </div>
  );
};

export default CartIcon;