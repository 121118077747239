import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { PayrollResponse, TimesheetOverrideResponse, timesheetOverride, timesheetOverrideUpdateFormValues } from '../../../types/finance.types';
import { sfcApi } from '../../api';

export const timesheetsApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentEntries: builder.query<PayrollResponse, number>({
      query: (id) => ({
        url: `current-entries${id ? '/'+id : ''}`,
      }),
      providesTags: ['CurrentEntries']
    }),
    getTimesheetOverrides: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `timesheet-overrides/${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['TimesheetOverrides'],
    }),
    createTimesheetOverride: builder.mutation<TimesheetOverrideResponse, timesheetOverride>({
      query: (params) => ({
        method: 'POST',
        url: `timesheet-overrides/create`,
        body: params
      }),
      invalidatesTags: ['CurrentEntries']
    }),
    updateTimesheetOverride: builder.mutation<TimesheetOverrideResponse, Partial<timesheetOverrideUpdateFormValues>>({
      query: (data) => ({
        url: `timesheet-overrides/update/${data.override_id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['TimesheetOverrides']
    }),
    getTimesheets: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `timesheets/`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getTimesheetEntries: builder.query<GetQueryResponse, {timesheetId: number, filters: GetQueryParams | void}>({
      query: (params) => ({
        url: `timesheet-entries/${params.timesheetId}`,
        method: 'POST',
        body: params?.filters?.data,
      }),
    }),
  }),
});

export const {
  useGetCurrentEntriesQuery,
  useGetTimesheetOverridesQuery,
  useCreateTimesheetOverrideMutation,
  useUpdateTimesheetOverrideMutation,
  useGetTimesheetsQuery,
  useGetTimesheetEntriesQuery
} = timesheetsApi;
