/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row, Spinner } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditExpense';
import { useGetCoursesQuery } from '../../../../../services/endpoints/schedule/course';
import { useEffect, useState } from 'react';
import { useToast } from '../../../../../context/ToastContext';
import { Course } from '../../../../../types/course.type';
import { expensesCategories, expensesContext, paymentMethods } from '../../../../../utils/consts/finance/finance';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';

export const ExpenseForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const { data, error, isLoading } = useGetCoursesQuery({ scope: 'all' });
  const { addToast } = useToast();
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading sessions', 'error');
    }
  },[error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setCourses(data.results);
    }
  },[data]);

  useEffect(() => {
    if (courses.length > 0 && values?.course_id.value !== '') {
      setFieldValue!(
        'course_id', 
        { value: values?.course_id.value, label: courses.find(c => c.id === Number(values?.course_id.value))?.title ?? '' }
      );
    }
  },[courses]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="course_id">
            <Field 
              as={FormSelect}
              name="course_id"
              label="Session"
              options={courses.map((c: Course) => ({ id: c.id, label: c.title })) ?? []}
              loadingError={error ? "Error loading sessions" : undefined}
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="amount">
            <Form.Label>Amount</Form.Label>
            <Field
              name="amount"
              type="number"
              as={Form.Control}
              isInvalid={touched.amount && !!errors.amount}
            />
            <ErrorMessage name="amount">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="due_on">
            <Form.Label>Due On</Form.Label>
            <Field
              name="due_on"
              type="date"
              as={Form.Control}
              isInvalid={touched.due_on && !!errors.due_on}
            />
            <ErrorMessage name="due_on">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="notes">
            <Form.Label>Session-Related Expense Tool Notes</Form.Label>
            <Field
              name="notes"
              type="text"
              as={Form.Control}
              isInvalid={touched.notes && !!errors.notes}
            />
            <ErrorMessage name="notes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="payment_method">
            <Form.Label>Payment Method</Form.Label>
            <Field
              name="payment_method"
              as={Form.Select}
              placeholder="Select"
              isInvalid={touched.payment_method && !!errors.payment_method}
            >
              <option>Select</option>
              {Object.entries(paymentMethods).map((opt) => (
                <option value={opt[0]} key={opt[0]}>{opt[1]}</option>
              ))}
            </Field>
            <ErrorMessage name="payment_method">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>

        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="category">
            <Form.Label>Category</Form.Label>
            <Field
              name="category"
              as={Form.Select}
              placeholder="Select"
              isInvalid={touched.category && !!errors.category}
            >
              <option>Select</option>
              {Object.entries(expensesCategories).map((opt) => (
                <option value={opt[0]} key={opt[0]}>{opt[1]}</option>
              ))}
            </Field>
            <ErrorMessage name="category">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="context">
            <Form.Label>Context</Form.Label>
            <Field
              name="context"
              as={Form.Select}
              placeholder="Select"
              isInvalid={touched.context && !!errors.context}
            >
              <option>Select</option>
              {Object.entries(expensesContext).map((opt) => (
                <option value={opt[0]} key={opt[0]}>{opt[1]}</option>
              ))}
            </Field>
            <ErrorMessage name="context">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="paid_on">
            <Form.Label>Paid On</Form.Label>
            <Field
              name="paid_on"
              type="date"
              as={Form.Control}
              isInvalid={touched.paid_on && !!errors.paid_on}
            />
            <ErrorMessage name="paid_on">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="invoice_number">
            <Form.Label>Invoice Number</Form.Label>
            <Field
              name="invoice_number"
              type="text"
              as={Form.Control}
              isInvalid={touched.invoice_number && !!errors.invoice_number}
            />
            <ErrorMessage name="invoice_number">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="check_number">
            <Form.Label>Check Number</Form.Label>
            <Field
              name="check_number"
              type="number"
              as={Form.Control}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
