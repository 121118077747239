import { ColumnDef } from '@tanstack/react-table';
import CurrencyCell from '../../../components/tables/cellComponents/CurrencyCell';
import { formatDateString } from '../../utils';

export const ledgerInstructorPaymentColumns: ColumnDef<any>[] = [
  {
    header: 'Date',
    accessorKey: 'lesson.date',
    cell: ({ getValue }) => (formatDateString(getValue<string>())),
  },
  {
    header: 'Instructor',
    accessorKey: 'instructor.full_name',
  },
  {
    header: 'Rate',
    accessorKey: 'rate',
    cell: ({ getValue, row }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Reimbursement',
    accessorKey: 'reimbursement',
    cell: ({ getValue, row }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Override',
    accessorKey: 'override.override_request',
    cell: ({ getValue, row }) => (
      row.original.override?.override_approved ? getValue<string>() : ""
    ),
  },
  {
    header: 'Total',
    accessorKey: 'total',
    cell: ({ getValue, row }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
];

export const weeklyReportColumns: ColumnDef<any>[] = [
  {
    header: 'Date Start',
    accessorKey: 'start',
    cell: ({ getValue }) => (formatDateString(getValue<string>())),
  },
  {
    header: 'Date End',
    accessorKey: 'end',
    cell: ({ getValue }) => (formatDateString(getValue<string>())),
  },
  {
    header: 'Website Revenue',
    accessorKey: 'website_revenue',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Invoice Revenue',
    accessorKey: 'invoice_revenue',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Total Revenue',
    accessorKey: 'total_revenue',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Refunds',
    accessorKey: 'refunds',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Net Revenue',
    accessorKey: 'net_revenue',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Royalty Fee',
    accessorKey: 'royalty_fee',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Brand Fee',
    accessorKey: 'brand_fee',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Amount Due (Credit)',
    accessorKey: 'amount_due',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Cumulative Balance',
    accessorKey: 'cumulative_balance',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Total ACH',
    accessorKey: 'total_ach',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
];
