import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Card, Col, Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { useGetOrganizationQuery } from '../../../../../services/endpoints/places/organization';
import { SFCOrganization } from '../../../../../types/place.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import CheckmarkCell from '../../../../../components/tables/cellComponents/CheckmarkCell';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { formatDate } from '../../../../../utils/dateUtils';

export const OrganizationDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetOrganizationQuery(+params.id!);
  const [organization, setOrganization] = useState<SFCOrganization>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading organization', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setOrganization(data.organization);
    }
  }, [data]);

  if (isLoading || !organization) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/places/organizations')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Organizations List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Organization #${organization?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/places/organizations/${organization?.id}/edit`)}
          >
            Edit organization
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection title="Details">
            <LabelText label="Name:">
              {organization?.name}
            </LabelText>
            <LabelText label="Created At:">
              {formatDate(new Date(organization?.created_at), 'MMM dd, yyyy hh:mm a')}
            </LabelText>
            <LabelText label="Location:">
              <LinkCell
                content={organization?.location?.title ?? 'No location title'}
                url={`/admin/places/locations/${organization?.location?.id}`}
              />
            </LabelText>
            <LabelText label="Venues:">
              <LinkCell
                content={`${organization?.venues.length} Venue${organization?.venues.length !== 1 ? 's' : ''}`}
                url={`/admin/places/venues?organization_id=${organization?.id}`}
              />
            </LabelText>
          </DetailSection>

          <DetailSection title="Contacts">
            {organization.contacts.map(c => (
              <Col md={6} lg={5} xl={4} xxl={3} >
                <Card className="text-left shadow">
                  <Card.Body>
                    <Card.Text className={"h2 text-capitalize"} >{c.full_name}</Card.Text>
                    <br />
                    <LabelText label="Email:">
                      {c.email}
                    </LabelText>
                    <LabelText label="Receives Rosters:">
                      <CheckmarkCell value={c.should_be_sent_roster} />
                    </LabelText>
                    <LabelText label="Receives Invoices:">
                      <CheckmarkCell value={c.should_be_sent_invoices} />
                    </LabelText>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
