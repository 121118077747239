import React from 'react';
import { Form, Stack } from 'react-bootstrap';

interface CheckmarkCellProps {
  value: boolean;
}

const CheckmarkCell: React.FC<CheckmarkCellProps> = ({ value }) => {
  return (
    <div>
      {value && (
        <Stack className="text-dark fw-bolder" direction="horizontal" gap={2}>
          <Form.Check
            checked
          />
          Yes
        </Stack>
      )}
    </div>
  );
};

export default CheckmarkCell;