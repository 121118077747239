import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Allergy } from './user';
import { sfcApi } from '../../api';

export const allergyApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAllergies: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `allergies${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Allergy']
    }),
    getAllergy: builder.query<{ allergy: Allergy }, number>({
      query: (id) => `allergies/${id}`,
      providesTags: (result, error, id) => [{ type: 'Allergy', id }]
    }),
    createAllergy: builder.mutation<{ allergy: Allergy }, Allergy>({
      query: (data) => ({
        url: `allergies/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Allergy']
    }),
    updateAllergy: builder.mutation<{ allergy: Allergy }, Allergy>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        let payload: any = {
          url: `allergies/update/${id}`,
          method: 'PUT',
          body: data
        };
        return payload;
      },
      invalidatesTags: (result, error) => [{ type: 'Allergy', id: result?.allergy?.id }, 'Allergy']
    }),
    deleteAllergy: builder.mutation<{ allergy: Allergy }, number>({
      query: (id) => ({
        url: `allergies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Allergy'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Allergy', id })), 'Allergy']
    }),
  }),
});

export const {
  useGetAllAllergiesQuery,
  useGetAllergyQuery,
  useCreateAllergyMutation,
  useUpdateAllergyMutation,
  useDeleteAllergyMutation,
} = allergyApi;