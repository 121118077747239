import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { Refund } from '../../../../../types/orders.type';
import { useGetRefundQuery } from '../../../../../services/endpoints/orders/refunds';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import YesNoCell from '../../../../../components/tables/cellComponents/YesNoCell';
import { DateCell } from '../../../../../components/tables/cellComponents/DateCell';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';

export const RefundDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetRefundQuery(+params.id!);
  const [ refund, setRefund] = useState<Refund>();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading refund', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setRefund(data.refund);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  if (!refund) return <></>;

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/orders/refunds')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Refunds List
          </h2>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Order:">
              <LinkCell
                content={`Order #${refund?.order_id}`}
                url={`/admin/orders/order/${refund?.order_id}`}
              />
            </LabelText>
            <LabelText label="User:">
              <LinkCell
                content={refund?.user.full_name}
                url={`/admin/sfc-users/users/${refund?.user_id}`} // TODO: change to correct user URL
              />
            </LabelText>
            <LabelText label="Total:">
              <CurrencyCell value={refund?.total} />
            </LabelText>
            <LabelText label="Transaction:" text={refund?.transaction_id} />
            <LabelText label="Notes:" text={refund?.notes} />
            <LabelText label="UUID:" text={refund?.uuid} />
            <LabelText label="Refunded At:">
              <DateCell date={refund?.refunded_at} format="MM/dd/yyyy h:mm a" />
            </LabelText>
            <LabelText label="Created At:">
              <DateCell date={refund?.created_at} format="MM/dd/yyyy h:mm a" />
            </LabelText>
            <LabelText label="Updated At:">
              <DateCell date={refund?.updated_at} format="MM/dd/yyyy h:mm a" />
            </LabelText>
            <LabelText label="Is Legacy:">
              <YesNoCell value={refund?.is_legacy} />
            </LabelText>
          </DetailSection>
        </Stack>
      </div>
    </>
  );
};
