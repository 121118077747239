import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { sfcApi } from '../../api';
import { instructor_roles } from '../../../utils/consts';
import { Lesson } from '../../../types/lesson.type';

interface InstructorLessonsResponse {
  label?: string;
  lessons: Lesson[];
}

interface LessonsCountResponse {
  lessonsCount: Array<{
    count_all: number;
    lesson_date: string;
  }>;
}

interface GetLessonCountQuery {
  start?: string;
  end?: string;
  instructor_id?: number;
}

export const lessonApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getLessons: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `lessons${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getInstructorLessons: builder.query<InstructorLessonsResponse[], GetQueryParams | void>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const url = `lessons${arg?.scope ? `?scope=${arg.scope}` : ''}`;
          if (arg?.data?.filterBy && arg?.data?.filterBy.length > 2) {
            const requests = instructor_roles.map((role) => ({
              url,
              method: 'POST',
              body: {
                ...arg?.data,
                filterBy: [
                  ...[arg?.data?.filterBy?.[0], arg?.data?.filterBy?.[1]],
                  {
                    field: role,
                    operator: 'equals',
                    value: arg?.data?.filterBy?.[2]?.value,
                  }
                ]
              },
            }));
            const response = await Promise.all(requests.map((r) => baseQuery(r)));
            const results: InstructorLessonsResponse[] = response.map((res, index) => {
              const data = res.data as GetQueryResponse;
              return {
                label: instructor_roles[index],
                lessons: data.results as Lesson[],
              };
            });
            return { data: results };
          } else {
            const result = await baseQuery({
              url,
              method: 'POST',
              body: arg?.data,
            });
            if (result.data) {
              const data = result.data as GetQueryResponse; 
              return { data: [{ label: instructor_roles[0], lessons: data.results }] }
            }
          }
        } catch (error) {
          return error;
        }
      }
    }),
    getLessonsCount: builder.query<LessonsCountResponse, GetLessonCountQuery>({
      query: (data) => ({
        url: `lessons/lessonsCount`,
        method: 'POST',
        body: data,
      }),
    }),
    getChildrenLessonPlans: builder.query<Lesson[], { data: any } | void>({
      query: (params) => ({
        url: `lessons/children-lessons`,
        method: 'POST',
        body: params?.data,
      }),
      forceRefetch: () => {
        return true;
      },
    }),
  }),
});

export const {
  useGetLessonsQuery,
  useGetInstructorLessonsQuery,
  useGetLessonsCountQuery,
  useGetChildrenLessonPlansQuery
} = lessonApi;
