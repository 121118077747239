import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditLocation';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetAdminsQuery } from '../../../../../services/endpoints/people/user';

export const LocationForm = ({ touched, errors, values, editing }: StepProps) => {

  const { data: admins, error: adminsError } = useGetAdminsQuery({}, {skip: !editing});
  
  return (
    <>
      <Row>
        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Field
              name="title"
              type="text"
              as={Form.Control}
              isInvalid={touched.title && !!errors.title}
            />
            <ErrorMessage name="title">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="business_entity_name">
            <Form.Label>Business Entity Name</Form.Label>
            <Field
              name="business_entity_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.business_entity_name && !!errors.business_entity_name}
            />
            <ErrorMessage name="business_entity_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="phone_number">
            <Form.Label>Business Phone Number</Form.Label>
            <Field
              name="phone_number"
              type="text"
              as={Form.Control}
              isInvalid={touched.phone_number && !!errors.phone_number}
            />
            <ErrorMessage name="phone_number">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="primary_city">
            <Form.Label>Primary City</Form.Label>
            <Field
              name="primary_city"
              type="text"
              as={Form.Control}
              isInvalid={touched.primary_city && !!errors.primary_city}
            />
            <ErrorMessage name="primary_city">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>


          <Form.Group className="mb-1 mb-lg-3" controlId="is_active">
            <Form.Label>Is Active</Form.Label>
            <Field
              name="is_active"
              checked={values?.is_active}
              as={Form.Check}
              isInvalid={touched.is_active && !!errors.is_active}
            />
            <ErrorMessage name="is_active">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
        <Col xs={6} className="ps-1">
          
          {editing && <Form.Group className="mb-1 mb-lg-3" controlId="manager_id">
            <FormSelect
              name="manager_id"
              label="Area Director"
              options={admins?.results?.map((admin) => ({ value: admin.id, label: admin.full_name })) ?? []}
              loadingError={adminsError ? "Error loading admins" : undefined}
            />
            <ErrorMessage name="manager_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>}

          <Form.Group className="mb-1 mb-lg-3" controlId="scheduling_email">
            <Form.Label>Scheduling Email</Form.Label>
            <Field
              name="scheduling_email"
              type="text"
              as={Form.Control}
              isInvalid={touched.scheduling_email && !!errors.scheduling_email}
            />
            <ErrorMessage name="scheduling_email">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="invoicing_email">
            <Form.Label>Invoicing Email</Form.Label>
            <Field
              name="invoicing_email"
              type="text"
              as={Form.Control}
              isInvalid={touched.invoicing_email && !!errors.invoicing_email}
            />
            <ErrorMessage name="invoicing_email">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="hotline">
            <Form.Label>Chef Hotline Number</Form.Label>
            <Field
              name="hotline"
              type="text"
              as={Form.Control}
              isInvalid={touched.hotline && !!errors.hotline}
            />
            <ErrorMessage name="hotline">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="tax_id_number">
            <Form.Label>Tax ID Number</Form.Label>
            <Field
              name="tax_id_number"
              type="text"
              as={Form.Control}
              isInvalid={touched.tax_id_number && !!errors.tax_id_number}
            />
            <ErrorMessage name="tax_id_number">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
      </Row>

      <FieldArray 
        name="addresses"
        render={(arrayHelpers) => (
          <Form.Group className="mb-1 mb-lg-3" controlId="addresses">
            <Form.Label className="h4">Remittance Mailing Addresses</Form.Label>
            <br />
            <div className="multi-content-scroll mt-2">
              <Row>
              {values?.addresses && values?.addresses.length > 0 ? (
                values.addresses.map((a, index) => {
                  let meta = {touched: Array.isArray(touched.addresses) ? touched.addresses[index] : {}, error: Array.isArray(errors.addresses) ? (errors.addresses[index] ?? {}) : {}};
                  return (
                    <Col  key={index} lg={6} className="mb-2">
                    <Card border="primary">
                    {typeof errors.addresses === "string" ? <div>{errors.addresses}</div> : null}

                    <Card.Body>
                      <Col className="ms-1">
                        <Row>
                          <Col xs={6} className="pe-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.street`}>
                              <Form.Label>Street</Form.Label>
                              <Field
                                name={`addresses.${index}.street`}
                                as={Form.Control}
                                isInvalid={meta.touched?.street && !!(typeof meta.error === "string" ? meta.error : meta.error.street)}
                              />
                              <ErrorMessage name={`addresses.${index}.street`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.unit`}>
                              <Form.Label>Unit</Form.Label>
                              <Field
                                name={`addresses.${index}.unit`}
                                type="text"
                                as={Form.Control}
                                isInvalid={meta.touched?.unit && !!(typeof meta.error === "string" ? meta.error : meta.error.unit)}
                              />
                              <ErrorMessage name={`addresses.${index}.unit`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.city`}>
                              <Form.Label>City</Form.Label>
                              <Field
                                name={`addresses.${index}.city`}
                                type="text"
                                as={Form.Control}
                                isInvalid={meta.touched?.city && !!(typeof meta.error === "string" ? meta.error : meta.error.city)}
                              />
                              <ErrorMessage name={`addresses.${index}.city`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Button
                              variant="primary"
                              size="sm"
                              className="btn-box-shadow border-0 text-uppercase"
                              style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove  
                            </Button>
                            
                          </Col>
                          <Col xs={6} className="ps-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.state`}>
                              <Form.Label>State</Form.Label>
                              <Field
                                name={`addresses.${index}.state`}
                                as={Form.Control}
                                isInvalid={meta.touched?.state && !!(typeof meta.error === "string" ? meta.error : meta.error.state)}
                              />
                              <ErrorMessage name={`addresses.${index}.state`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>

                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.zip_code`}>
                              <Form.Label>Zip Code</Form.Label>
                              <Field
                                name={`addresses.${index}.zip_code`}
                                type="tel"
                                as={Form.Control}
                                isInvalid={meta.touched?.zip_code && !!(typeof meta.error === "string" ? meta.error : meta.error.zip_code)}
                              />
                              <ErrorMessage name={`addresses.${index}.zip_code`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            
                          </Col>
                        </Row>
                      </Col>
                    </Card.Body>
                  </Card>
              </Col>
                )})
              ) : (
                <></>
              )}
              </Row>
            </div>
            <button
              type='button'
              className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
              onClick={() => arrayHelpers.insert(values?.addresses.length ?? 0, {
                street: "", 
                unit: "", 
                city: "", 
                state: "", 
                zip: "",
              })}
            >
              + Add New Address
            </button>
          </Form.Group>
        )}
      />

      <Row>
        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="latitude">
            <Form.Label>Latitude</Form.Label>
            <Field
              name="latitude"
              as={Form.Control}
              isInvalid={touched.latitude && !!errors.latitude}
            />
            <ErrorMessage name="latitude">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="longitude">
            <Form.Label>Longitude</Form.Label>
            <Field
              name="longitude"
              as={Form.Control}
              isInvalid={touched.longitude && !!errors.longitude}
            />
            <ErrorMessage name="longitude">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="zoom">
            <Form.Label>Zoom</Form.Label>
            <Field
              name="zoom"
              as={Form.Control}
              isInvalid={touched.zoom && !!errors.zoom}
            />
            <ErrorMessage name="zoom">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
