import { FadeIn } from '../animations/FadeIn';
import { Form, FormControl, Image, InputGroup, Stack } from "react-bootstrap";
import { Formik, ErrorMessage, Form as FormikForm, FormikProps, Field } from "formik";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { SimpleButton } from '../../components/buttons/SimpleButton';
import { useLoginMutation } from "../../services/endpoints/people/user";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import SFCLogoMD from '../../assets/images/sticky-fingers-logo-stacked-2.png';
import { getCurrentUser, isAuthenticated } from '../../services/helper';
import { getErrorMessage } from '../../utils/utils';

type Props = {}
interface FormValues {
  email: string;
  password: string;
}

const Login: React.FC<Props> = () => {
  const navigate: NavigateFunction = useNavigate();

  
  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/');
    }
    else {
      console.log(getCurrentUser());
    }
  }, [])
  
  const [login] = useLoginMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required("This field is required!"),
    password: Yup.string().min(6, 'Password is too short').required("This field is required!"),
  });

  const handleLogin = async (formValue: FormValues) => {
    const { email: username , password } = formValue;
    setLoading(true);

    try {
      await login({ username, password}).unwrap();
      navigate('/');
    } catch (error) {
      setMessage(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FadeIn className="d-flex flex-column flex-wrap align-content-center">
      <div className="pb-4">
        <Image fluid className="d-block d-sm-none" src={SFCLogoMD} alt="Sticky Fingers Logo" />
        <Image className="d-none d-sm-inline-flex" src={SFCLogoMD} style={{ maxWidth: 343 }} alt="Sticky Fingers Logo" />
      </div>

      <h2 className="text-dark fw-bold mt-4 mt-lg-5 text-start text-md-center" style={{ fontSize: 32 }}>
        Welcome Back!
      </h2>
      <Stack className="w-100" style={{ maxWidth: 480 }} gap={1}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ errors, touched }: FormikProps<FormValues>) => (
            <FormikForm className="text-start">
              <div className="mb-5 pb-5">
                <Form.Group className="mb-2" controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Field
                    name="email"
                    type="email"
                    as={Form.Control}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <ErrorMessage name="email">
                    {(msg) => (
                      <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>

                <Form.Group className="mb-2" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Field
                      name="password"
                      type={passwordVisible ? 'text' : 'password'}
                      as={Form.Control}
                      isInvalid={touched.password && !!errors.password}
                    >
                    </Field>
                    <InputGroup.Text
                      style={{ cursor: 'pointer' }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      
                      {
                        passwordVisible ? (
                          <IoEyeOffOutline style={{ fontSize: 20 }} />
                        ) : (
                          <IoEyeOutline style={{ fontSize: 20 }} />
                        )
                      }
                    </InputGroup.Text>
                    <ErrorMessage name="password">
                      {(msg) => (
                        <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                      )}
                    </ErrorMessage>
                  </InputGroup>
                </Form.Group>

                <div className="d-flex justify-content-between align-items-center">
                  <Form.Group className="fw-semibold" controlId="rememberMe">
                    <Form.Check type="checkbox" label="Remember me" />
                  </Form.Group>
                  <button
                    type="button"
                    onClick={() => navigate('/users/forgot-password')}
                    className="btn btn-link p-0 fs-small fw-bolder text-uppercase link-primary link-underline-opacity-0"
                  >
                    Forgot Password?
                  </button>
                </div>
              </div>

              {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              
              <SimpleButton type="submit" className="w-100" variant="primary" disabled={loading}>
                {loading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : 'Log In'}
              </SimpleButton>
            </FormikForm>
          )}
        </Formik>

        <div className="my-3 d-flex justify-content-center align-items-center">
          <span className="ff-inter fs-small text-secondary">Don't have an account?</span>
          <button
            onClick={() => navigate('/users/register')}
            className="btn btn-link p-0 ms-2 fs-small fw-bolder text-uppercase link-primary link-underline-opacity-0"
          >
            Sign Up
          </button>
        </div>
      </Stack>
    </FadeIn>
  );
};

export default Login;
