import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Testimonial } from '../../../types/testimonial.type';
import { sfcApi } from '../../api';

export const testimonialApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTestimonials: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `testimonials${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getTestimonial: builder.query<{ testimonial: Testimonial }, number>({
      query: (id) => `testimonials/${id}`,
    }),
  }),
});

export const {
  useGetAllTestimonialsQuery,
  useGetTestimonialQuery,
} = testimonialApi;
