import { ColumnDef } from "@tanstack/react-table";
import { Child } from "../../../services/endpoints/people/user";
import { LinkCell } from "../../../components/tables/cellComponents/LinkCell";
import { Order } from "../../../types/orders.type";
import { DateCell } from "../../../components/tables/cellComponents/DateCell";
import CurrencyCell from "../../../components/tables/cellComponents/CurrencyCell";

export const childTableColumns: ColumnDef<Child>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'First Name',
    accessorKey: 'first_name',
    cell: ({ row }) => <LinkCell content={row.original.first_name} url={`admin/sfc-users/children/${row.original.id}`} />
  },
  {
    header: 'Last Name',
    accessorKey: 'last_name',
    cell: ({ row }) => <LinkCell content={row.original.last_name} url={`admin/sfc-users/children/${row.original.id}`} />
  }
]

export const ordersTableColumns: ColumnDef<Order>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Date',
    accessorKey: 'date',
    cell: ({ row }) => <DateCell date={row.original.date} time={row.original.starts_at} />
  },
  {
    header: 'Session',
    accessorKey: 'course_id',
    cell: ({ row, getValue }) => (
      <LinkCell content={row.original.course.title} url={`/admin/schedule/sessions/${getValue<number>()}`} />
    ),
  },
  {
    header: 'Total',
    accessorKey: 'total',
    cell: ({ row }) => {
      let course = row.original.course;
      let total = row.original.registrations?.reduce((acc, r) => (acc + r.price), 0) ?? 0;
      return course.payment_type === "website" ? <CurrencyCell value={total} /> : "--";
    }
  }
]