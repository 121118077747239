export const seasonValues: { [key: string]: string } = {
    spring: 'Spring',
    summer: 'Summer',
    winter: 'Winter',
    fall: 'Fall'
}

export const cuisineTypes: { [key: string]: string } = {
    africa: 'africa',
    australia_and_pacific_islands: 'australia_and_pacific_islands',
    east_asia: 'east_asia',
    western_europe: 'western_europe',
    southeast_asia: 'southeast_asia',
    north_america: 'north_america',
    south_america: 'south_america',
    central_asia: 'central_asia',
    central_america: 'central_america',
    caribbean: 'caribbean',
    eastern_europe: 'eastern_europe',
    middle_east_and_north_africa: 'middle_east_and_north_africa',
    south_asia: 'south_asia',
    central_europe: 'central_europe',
    northern_europe: 'northern_europe'
};

export const cookMethods: { [key: string]: string } = {
    full_kitchen: 'full_kitchen',
    microwave: 'microwave',
    no_cook: 'no_cook'
};

export const mealTypes: { [key: string]: string } = {
    snacks: 'snacks',
    breakfast: 'breakfast',
    main: 'main',
    beverage: 'beverage',
    side: 'side',
    sauce: 'sauce',
    dessert: 'dessert'
};

export const recipeTypes: { [key: string]: string } = {
    standard_recipe: 'standard_recipe',
    recipe_of_the_week: 'recipe_of_the_week',
    first_class_recipe: 'first_class_recipe',
    live: 'live'
};