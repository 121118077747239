import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../../context/ToastContext';
import { Spinner } from 'react-bootstrap';
import { useDeleteInvoiceClientsMutation } from '../../../../../services/endpoints/client/invoiceClient';
import { getErrorMessage } from '../../../../../utils/utils';

interface HandleInvoiceClientActionProps {
  action: 'delete';
}

export const HandleInvoiceClientAction: React.FC<HandleInvoiceClientActionProps> = ({ action }) => {
  const fetching = useRef(false);
  const params = useParams();
  const id = +params.id!;
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [deleteInvoiceClients, { isUninitialized }] = useDeleteInvoiceClientsMutation();
  const [loading, setLoading] = useState(true);

  const actionFn = async () => {
    try {
      await deleteInvoiceClients({ids: [id]}).unwrap();
      addToast(`InvoiceClient #${id} ${action}d correctly`, 'success');
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    } finally {
      setLoading(false);
      fetching.current = false;
      navigate('/admin/invoice-clients');
    }
  };
  
  useEffect(() => {
    const isFetching = fetching.current;
    if (isUninitialized && !isFetching) {
      fetching.current = true;
      actionFn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
 
  return (
    <Navigate to={`/admin/invoice-clients/${id}`} />
  )
}
