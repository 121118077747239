/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Course } from '../../../types/course.type';
import './CourseCard.css';
import { CourseCard } from './CourseCard';
import { CourseCardSimple } from './CourseCardSimple';

interface CourseResultsProps {
  courses: Course[];
  listView: boolean;
  onClick: (course: Course) => void;
}

const CourseResults: React.FC<CourseResultsProps> = ({courses, listView=false, onClick}) => {

  let content = listView ? (
    <Row>
      {courses.map(c => (
        <Col lg={3} sm={6} key={c.id}>
          <CourseCardSimple course={c} />
        </Col>
      ))}
    </Row>
  ) : (
    <Stack gap={2} >
      {courses.map(c => (
        <CourseCard key={c.id} course={c} onClick={() => onClick(c)}/>
      ))}
    </Stack>
  );

  return (
  <div>
    <Container className={'multi-content-scroll'}>
      <span><b>{courses.length} Results</b> for filters</span>
      {content}
    </Container>
  </div>);
};

export default CourseResults;
