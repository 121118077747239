import React from 'react';
import { Order } from '../../../../../types/orders.type';
import { Card, Stack } from 'react-bootstrap';
import ChildProfileIcon from '../../../../../assets/images/child-profile-icon.png';
import { LabelText, LabelTextSpread } from '../../../../../components/generic';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { FaArrowRight } from 'react-icons/fa6';
import { CourseLink } from '../../../search/CourseVenueDistance';
import { Course } from '../../../../../types/course.type';
import { Child } from '../../../../../services/endpoints/people/user';

export interface EnrollmentCompleteCardProps {
  child: Child;
  courses: Course[];
}

export const EnrollmentCompleteCard: React.FC<EnrollmentCompleteCardProps> = ({ child, courses }) => {

  return (
    <Card body>
      <Stack direction="horizontal" gap={2}>
        <img src={ChildProfileIcon} height={130} width={130} />
        <Stack className="">
          <LabelTextSpread label='Child' text={child.full_name} />
          <LabelTextSpread label='Grade' text={child.grade_level} />
          <LabelTextSpread label='Teacher/Classroom' text={child.origination} />
          <LabelTextSpread label='Sessions'>
            <Stack>
              {courses.map(course => <CourseLink key={course.id} content={course?.title} url={`/sessions/${course?.id}`} color={''} />)}
            </Stack>
          </LabelTextSpread>
          <LabelText label="If any of your child's information is incorrect, please update their profile">
            <LinkCell url="/user/profile" content={<span>Update Now<FaArrowRight /></span>} />
          </LabelText>
        </Stack>
      </Stack>
    </Card>
  );
};

export interface EnrollmentCompleteProps {
  orders: Order[];
}

export const EnrollmentComplete: React.FC<EnrollmentCompleteProps> = ({ orders }) => {
  const childCourses: {child: Child, courses: Course[]}[] = [];
  orders.forEach((order) => {
    const {course, registrations} = order;
    registrations.forEach(r => {
      let index = childCourses.findIndex(cc => r.child_id === cc.child.id);
      if (index !== -1) {
        childCourses[index].courses.push(course);
      }
      else {
        childCourses.push({child: r.child, courses: [course]})
      }
    });
  }, [] as {child: Child, courses: Course[]}[]);
  return (
    <Stack gap={2}>
      {childCourses.map(cc => <EnrollmentCompleteCard key={cc.child.id} child={cc.child} courses={cc.courses} />)}
    </Stack>
  );
};
