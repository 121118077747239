import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { LessonPlanForm } from './LessonPlanForm';
import { useGetLessonPlanQuery, useCreateLessonPlanMutation, useUpdateLessonPlanMutation } from '../../../../../services/endpoints/content/lessonPlan';
import { LessonPlanRecipesForm, LessonPlanRecipesSaveForm, Recipe } from '../../../../../types/lessonPlan.type';
import { useGetRecipesQuery } from '../../../../../services/endpoints/content/recipe';

const steps = [
  { name: 'Details', form: LessonPlanForm },
];

export interface FormValues {
  id?: number;
  name: string;
  publish_status?: string;

  recipes: LessonPlanRecipesForm[];

  // Family Meal Plan Stats
  cook_time: number;
  prep_time: number;
  min_served: number;
  max_served: number;

  // Lesson Plan Stats
  lesson_cook_time: number;
  lesson_prep_time: number;
  lesson_min_served: number;
  lesson_max_served: number;

  // Lists
  shopping_list_items: string;

  // Instructor Notes
  notes: string;
  before_class: string;
  at_school: string;
  welcome: string;

  // Timeline
  timeline_00: string;
  timeline_10: string;
  timeline_25: string;
  timeline_35: string;
  timeline_45: string;
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;
export type CreateFormValues = Overwrite<FormValues, {
  recipes: LessonPlanRecipesSaveForm[], 
}>

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

const lessonPlanLinksMap = (id?: number | string) => {
    return {
        edit: `/admin/content/lesson-plans/${id}/edit`,
        detail: `/admin/content/lesson-plans/${id}`,
        list: `/admin/content/lesson-plans`,
    }
}

interface AddEditLessonPlanProps {
  editing?: boolean;
}

export const AddEditLessonPlan: React.FC<AddEditLessonPlanProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createLessonPlan] = useCreateLessonPlanMutation();
  const [updateLessonPlan] = useUpdateLessonPlanMutation();
  
  const { data: lessonPlanData, error: lessonPlanError, isLoading: lessonPlanLoading } = 
  useGetLessonPlanQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    publish_status: '',
  
    recipes: [],
  
    cook_time: 0,
    prep_time: 0,
    min_served: 0,
    max_served: 0,
    lesson_cook_time: 0,
    lesson_prep_time: 0,
    lesson_min_served: 0,
    lesson_max_served: 0,
    shopping_list_items: '',
    notes: '',
    before_class: '',
    at_school: '',
    welcome: '',
    timeline_00: '',
    timeline_10: '',
    timeline_25: '',
    timeline_35: '',
    timeline_45: ''
  });

  const validationSchemas = [
    Yup.object({

      name: Yup.string().required('Name is required'),
      publish_status: Yup.string().required('Publish Status is required'),
    
      // TODO: Include Recipes
    
      cook_time: Yup.string().required('Cook time is required'),
      prep_time: Yup.string().required('Prep time is required'),
      min_served: Yup.string().required('Min Served is required'),
      max_served: Yup.string().required('Max Served is required'),
      lesson_cook_time: Yup.string().required('Cook time is required'),
      lesson_prep_time: Yup.string().required('Prep time is required'),
      lesson_min_served: Yup.string().required('Min Served is required'),
      lesson_max_served: Yup.string().required('Max Served is required'),
      shopping_list_items: Yup.string().required('Shopping list items is required')
    }),
  ];

  useEffect(() => {
    if (editing && lessonPlanError && !lessonPlanLoading) {
      addToast('Error while loading admin', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, lessonPlanError, lessonPlanLoading]);

  useEffect(() => {
    let values;
    if (lessonPlanData && lessonPlanData?.lessonPlan) {
      values = lessonPlanData.lessonPlan;
    }

    if (values?.name)
      setInitialValues({
        name: values.name,
        publish_status: values.publish_status ?? '',
      
        recipes: values.recipes.map(r => {
          let ret: LessonPlanRecipesForm = {
            id: {value: r.id, label: r.title},
            is_primary: r.lesson_plan_recipes.is_primary,
            name: r.title,
          }
          return ret;
        }),
      
        cook_time: values.cook_time,
        prep_time: values.prep_time,
        min_served: values.min_served,
        max_served: values.max_served,
        lesson_cook_time: values.lesson_cook_time,
        lesson_prep_time: values.lesson_prep_time,
        lesson_min_served: values.lesson_min_served,
        lesson_max_served: values.lesson_max_served,
        shopping_list_items: values.shopping_list_items_field,
        notes: values.notes,
        before_class: values.before_class,
        at_school: values.at_school,
        welcome: values.welcome,
        timeline_00: values.timeline_00,
        timeline_10: values.timeline_10,
        timeline_25: values.timeline_25,
        timeline_35: values.timeline_35,
        timeline_45: values.timeline_45
      });

  }, [lessonPlanData]);

  const formatFormValue = (formValue?: FormValues ): CreateFormValues => {
    return JSON.parse(JSON.stringify(formValue)) as CreateFormValues;
  }
  
  const handleAddLessonPlan = async (formValue: FormValues) => {
    try {
      let submission = formatFormValue(formValue)
      submission.recipes = formValue.recipes.map(r => {
        let ret: LessonPlanRecipesSaveForm = {
          id: r.id.value,
          is_primary: r.is_primary
        }
        return ret;
      })
      if (editing) {
        const res = await updateLessonPlan({
          ...submission,
          id: Number(params.id)!,
        }).unwrap();
        if (res && res.lessonPlan) {
          navigate(lessonPlanLinksMap(res.lessonPlan.id!)?.list);
          addToast(`Lessons Plan updated succesfully`, 'success');
        }
      } else {
        const res = await createLessonPlan(submission).unwrap();
        if (res && res.lessonPlan) {
          navigate(lessonPlanLinksMap(res.lessonPlan.id!)?.list);
          addToast('Lessons Plan created succesfully', 'success');
        } else throw new Error('A problem happened while creating Lessons Plan');
      }
    } catch (e) {
      if(e.data && e.data.error) {
        addToast(e.data.error[0], 'error');
      }
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
        publish_status: true,
        cook_time: true,
        prep_time: true,
        min_served: true,
        max_served: true,
        lesson_cook_time: true,
        lesson_prep_time: true,
        lesson_min_served: true,
        lesson_max_served: true,
        before_class: true,
        at_school: true,
        welcome: true,
        timeline_00: true,
        timeline_10: true,
        timeline_25: true,
        timeline_35: true,
        timeline_45: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text="Back"
            color="#2B4E64"
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Lessons Plan
          </h2>
          {steps.length > 1 && <Stepper editing steps={steps} currentStep={currentStep} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddLessonPlan}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
