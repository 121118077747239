import { Col, Form, Row } from 'react-bootstrap';
import { Field } from 'formik';
import { StepProps } from './AddEditDiscount';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetCoursesQuery } from '../../../../../services/endpoints/schedule/course';
import { useGetAllParentsQuery } from '../../../../../services/endpoints/people/parent';
import { Child, User } from '../../../../../services/endpoints/people/user';
import { useGetAllScholarshipsQuery } from '../../../../../services/endpoints/orders/scholarship';
import { Scholarship } from '../../../../../types/orders.type';
import { scholarshipTypes } from '../../../../../utils/consts/orders/scholarships';
import { useGetAllChildrenQuery } from '../../../../../services/endpoints/people/child';

export const DiscountForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const { data: parentData, error: parentError } = useGetAllParentsQuery();
  const { data: scholarshipData, error: scholarshipError } = useGetAllScholarshipsQuery();
  const { data: courseData, error: courseError } = useGetCoursesQuery({ scope: 'upcoming' });
  const { data: childrenData, error: childrenError } = useGetAllChildrenQuery();

  return (
    <Row>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="user_id">
          <Field 
            as={FormSelect}
            name="user_id"
            label="User"
            options={parentData?.results?.map((user: User) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name} (${user.email})`,
            })) ?? []}
            loadingError={parentError ? 'Error loading users': undefined}
          />
          <Form.Text>Enter first name, last name, or email address to find the relevant user.</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="scholarship_id">
          <Field 
            as={FormSelect}
            name="scholarship_id"
            label="Scholarship"
            options={scholarshipData?.results?.map((item: Scholarship) => ({
              value: item.id,
              label: `${scholarshipTypes[item.scholarship_type]} (${item.percent_discount}% off)`
            })) ?? []}
            loadingError={scholarshipError ? 'Error loading scholarships' : undefined}
          />
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="course_id">
          <Field 
            as={FormSelect}
            name="course_id"
            label="Upcoming Session (Optional)"
            options={courseData?.results?.map((course: {id: number, title: string}) => ({ value: course.id, label: course.title })) ?? []}
            loadingError={courseError ? 'Error loading sessions': undefined}
          />
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="child_id">
          <Field 
            as={FormSelect}
            name="child_id"
            label="Child (Optional)"
            options={childrenData?.results?.map((child: Child) => ({
              value: child.id,
              label: `${child.first_name} ${child.last_name}`,
            })) ?? []}
            loadingError={childrenError ? 'Error loading children': undefined}
          />
        </Form.Group>
      </Col>
    </Row>
  )
}
