import React from 'react';
import { Badge, Card, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { CourseCount, OrderCourseDates, OrderCourseTimes } from './OrderCourseDates';
import { Order } from '../../../../../types/orders.type';
import { BsPencilFill, BsPersonFill } from 'react-icons/bs';
import { FaCircleMinus } from 'react-icons/fa6';
import { useDeleteOrderMutation } from '../../../../../services/endpoints/orders/orders';
import { useToast } from '../../../../../context/ToastContext';
import { getErrorMessage } from '../../../../../utils/utils';
import Sticker from '../../../../../assets/images/cooking-club-sticker.png';

interface OrderCardProps {
  order: Order;
  index?: number;
}

export const OrderCard: React.FC<OrderCardProps> = ({ order, index }) => {
  const {course, registrations} = order;
  const navigate = useNavigate();
  const [ deleteOrder ] = useDeleteOrderMutation();
  const { addToast } = useToast();

  const children = registrations.map((r, idx) => <span key={idx} className="mt-0 label">{r.child.full_name}</span>);

  const removeCourse = async () => {
    try {
      await deleteOrder(order.id).unwrap();
    } catch (error) {
      addToast(getErrorMessage(error), 'error');
    }
  }

  const editCourse = () => {
    navigate(`/sessions/${course.id}?action=edit`)
  }

  return (
    <Card className={`course-card shadow`}>
      <Card.Body>
        <Stack direction="horizontal">
          <Stack>
            <Stack direction="horizontal" gap={3}>
              <h6 className="text-info text-uppercase">Session {(index ?? 0)+1}</h6>
              <Stack direction="horizontal" className="ms-auto" gap={3} style={{alignContent: 'flex-start', flexWrap: 'wrap'}}>
                <span className="text-uppercase text-primary fw-bolder" onClick={() => editCourse()}><BsPencilFill size={14} className="mb-1" />&nbsp;Edit</span>
                <span className="text-uppercase fw-bolder" style={{color: 'var(--bs-sfc-pink)'}} onClick={() => removeCourse()}><FaCircleMinus size={16} className="mb-1" />&nbsp;Remove</span>
              </Stack>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <div ><img src={Sticker} height={50} width={50} /></div>
              <Stack>
                <h3 className={`my-0`}>
                  <Link 
                    to={`/sessions/${course.id}`} 
                    className="text-info fw-bolder"
                    style={{ textDecoration: 'none' }}>
                      {course.title}
                  </Link>
                </h3>
                <OrderCourseDates order={order} />
                <OrderCourseTimes order={order} />
              </Stack>
            </Stack>

            <Stack>
              <CourseCount count={order.lesson_count ?? course.lessons?.length ?? 0} />
              <Stack direction='horizontal' gap={1} className="text-info">
                <BsPersonFill />
                <span className="text-info">Students</span>
                <Badge bg='light'>x{children.length}</Badge>
              </Stack>
              <Stack style={{marginLeft: '20px'}}>
                {children}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
};
