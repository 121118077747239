import { Field } from 'formik';
import React from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';

interface SearchFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  onClick?: () => void;
  clear?: () => void;
  message?: {msg: string, type: 'valid'|'invalid'};
}

export const SearchField : React.FC<SearchFieldProps> = ({ name, label, placeholder, onClick, clear, message }) => {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <InputGroup hasValidation>
        <Field
          name={name}
          type="text"
          placeholder={placeholder ?? 'Search'}
          as={Form.Control}
          isInvalid={message?.type === 'invalid'}
          isValid={message?.type === 'valid'}
        />
        {clear && <Button variant="secondary" onClick={clear}>X</Button>}
        {onClick && <Button onClick={onClick}>Apply</Button>}
        <FormControl.Feedback type={message?.type} >{message?.msg}</FormControl.Feedback>
      </InputGroup>
    </Form.Group>
  )
}
