export const formatPhoneNumber = (number?: string) => {
  if (!number) {
    return 'N/A';
  }
  let numOnly = number.replace(/[^\d]/g, "");
  return `(${numOnly.slice(0,3)}) ${numOnly.slice(3,6)}-${numOnly.slice(6)}`
}

export const PhoneNumber = (props: {number?: string}) => {
  return <>{formatPhoneNumber(props.number)}</>
}