import React from 'react';
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { BsLaptop } from 'react-icons/bs';
import { LiaUniversitySolid } from 'react-icons/lia';

interface EnrollmentCellProps {
  type?: string;
  value: string;
}

export const EnrollmentCell: React.FC<EnrollmentCellProps> = ({ type, value }) => {

  const renderTooltip = (props: any) => (
    <Tooltip id="enrollment-tooltip" {...props}>
      <span style={{ fontSize: '.75rem' }}>
        This session uses {type === 'website' ? 'SFC-website' : 'onsite'} enrollment.
      </span>
    </Tooltip>
  );

  return (
    <Stack direction="horizontal" gap={2}>
      <OverlayTrigger overlay={renderTooltip} placement="bottom">
        <span style={{ cursor: 'pointer' }}>
          {type === 'website'
            ? <BsLaptop size={19} />
            : <LiaUniversitySolid size={22} />
          }
        </span>
        
      </OverlayTrigger>
      <span>
        {value}
      </span>
    </Stack>
  )
};
