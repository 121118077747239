import React from "react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Link,
  Heading,
  FontColor,
  Table,
  HorizontalLine,
  Image,
  ImageUpload,
} from "ckeditor5";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "ckeditor5/ckeditor5.css";
import { FieldInputProps, FormikProps } from "formik";

interface EditorProps {
  field: FieldInputProps<any>; // Field input from Formik
  form: FormikProps<any>; // Formik form object
  label?: string; // Optional label
  customItems?: string[];
}

const Editor: React.FC<EditorProps> = ({ field, form, label, customItems }) => {
  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    form.setFieldValue(field.name, data); // Update Formik value
  };
  const isInvalid = !!form.errors[field.name] && !!form.touched[field.name];
  return (
    <div className={isInvalid ? 'is-invalid' : ''}>
      {label && <label>{label}</label>}
      <CKEditor
        editor={ClassicEditor}
        data={field.value} // Set value from Formik
        onChange={handleEditorChange} // Update Formik state on change
        config={{
          toolbar: {
            items: customItems ? customItems : ["bulletedList", "bold", "italic", "link", "undo", "redo"],
          },
          plugins: [
            Bold,
            Essentials,
            FontColor,
            Heading,
            HorizontalLine,
            Image,
            ImageUpload,
            Italic,
            Mention,
            Paragraph,
            Table,
            Undo,
            List,
            Link,
          ],
        }}
      />
    </div>
  );
};

export default Editor;
