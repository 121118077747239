import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditVenue';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetAllOrganizationsQuery } from '../../../../../services/endpoints/places/organization';
import { useDataContext } from '../../../../../context';

export const VenueForm = ({ touched, errors, values }: StepProps) => {
  const { locationOptions } = useDataContext();
  const { data: organizations, error: organizationsError } = useGetAllOrganizationsQuery({data: {orderBy: {field: "name", order: "asc"}}});
  
  return (
    <>
      <Row>
        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Field
              name="title"
              type="text"
              as={Form.Control}
              isInvalid={touched.title && !!errors.title}
            />
            <ErrorMessage name="title">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="location_id">
            <Field 
              as={FormSelect}
              name="location_id"
              label="Location"
              options={locationOptions}
            />
            <ErrorMessage name="location_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="organization_ids">
            <FormSelect
              name="organization_ids"
              label="Organizations"
              multiple={true}
              options={organizations?.results?.map((org) => ({ value: org.id, label: org.name })) ?? []}
              loadingError={organizationsError ? "Error loading organizations" : undefined}
            />
            <ErrorMessage name="organization_ids">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="phone">
            <Form.Label>Venue Phone Number</Form.Label>
            <Field
              name="phone"
              as={Form.Control}
              isInvalid={touched.phone && !!errors.phone}
            />
            <ErrorMessage name="phone">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="is_active">
            <Form.Label>Is Active</Form.Label>
            <Field
              name="is_active"
              checked={values?.is_active}
              as={Form.Check}
              isInvalid={touched.is_active && !!errors.is_active}
            />
            <ErrorMessage name="is_active">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
        <Col xs={6} className="ps-1">
          
          <Form.Group className="mb-1 mb-lg-3" controlId="address">
            <Form.Label>Address</Form.Label>
            <Field
              name="address"
              type="text"
              as={Form.Control}
              isInvalid={touched.address && !!errors.address}
            />
            <ErrorMessage name="address">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group className="mb-1 mb-lg-3" controlId="address2">
            <Form.Label>Address2</Form.Label>
            <Field
              name="address2"
              type="text"
              as={Form.Control}
              isInvalid={touched.address2 && !!errors.address2}
            />
            <ErrorMessage name="address2">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="city">
            <Form.Label>City</Form.Label>
            <Field
              name="city"
              as={Form.Control}
              isInvalid={touched.city && !!errors.city}
            />
            <ErrorMessage name="city">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="state">
            <Form.Label>State</Form.Label>
            <Field
              name="state"
              as={Form.Control}
              isInvalid={touched.state && !!errors.state}
            />
            <ErrorMessage name="state">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="zip">
            <Form.Label>Zip Code</Form.Label>
            <Field
              name="zip"
              as={Form.Control}
              isInvalid={touched.zip && !(errors.zip?.includes("Warning") ?? true)}
              disabled={!values?.location_id?.value}
            />
            {errors.zip?.includes("Warning") && (
              <Form.Text className="text-warning">
                {errors.zip}
              </Form.Text>
            )}
            <ErrorMessage name="zip">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-1 mb-lg-3" controlId="notes">
        <Form.Label>Venue Notes</Form.Label>
        <Field
          name="notes"
          as="textarea"
          className="form-control"
        />
        <ErrorMessage name="notes">
          {(msg) => (
            <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <Form.Group className="mb-1 mb-lg-3" controlId="facility_use_fee_payment_details">
        <Form.Label>Facility Use Payment Mailing Details</Form.Label>
        <Field
          name="facility_use_fee_payment_details"
          as="textarea"
          className="form-control"
        />
        <ErrorMessage name="facility_use_fee_payment_details">
          {(msg) => (
            <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <FieldArray 
        name="contacts"
        render={(arrayHelpers) => (
          <Form.Group className="mb-1 mb-lg-3" controlId="contacts">
            <Form.Label className="h4">Venue Contacts</Form.Label>
            <br />
            <div className="multi-content-scroll mt-2">
              <Row>
              {values?.contacts && values?.contacts.length > 0 ? (
                values.contacts.map((a, index) => {
                  let meta = {touched: Array.isArray(touched.contacts) ? touched.contacts[index] : {}, error: Array.isArray(errors.contacts) ? (errors.contacts[index] ?? {}) : {}};
                  return (
                    <Col  key={index} lg={6} className="mb-2">
                    <Card border="primary">
                    {typeof errors.contacts === "string" ? <div>{errors.contacts}</div> : null}

                    <Card.Body>
                      <Col className="ms-1">
                        <Row>
                          <Col xs={6} className="pe-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.first_name`}>
                              <Form.Label>First Name</Form.Label>
                              <Field
                                name={`contacts.${index}.first_name`}
                                as={Form.Control}
                                isInvalid={meta.touched?.first_name && !!(typeof meta.error === "string" ? meta.error : meta.error.first_name)}
                              />
                              <ErrorMessage name={`contacts.${index}.first_name`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.last_name`}>
                              <Form.Label>Last Name</Form.Label>
                              <Field
                                name={`contacts.${index}.last_name`}
                                type="text"
                                as={Form.Control}
                                isInvalid={meta.touched?.last_name && !!(typeof meta.error === "string" ? meta.error : meta.error.last_name)}
                              />
                              <ErrorMessage name={`contacts.${index}.last_name`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.email`}>
                              <Form.Label>Email Address</Form.Label>
                              <Field
                                name={`contacts.${index}.email`}
                                type="email"
                                as={Form.Control}
                                isInvalid={meta.touched?.email && !!(typeof meta.error === "string" ? meta.error : meta.error.email)}
                              />
                              <ErrorMessage name={`contacts.${index}.email`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Button
                              variant="primary"
                              size="sm"
                              className="btn-box-shadow border-0 text-uppercase"
                              style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove  
                            </Button>
                            
                          </Col>
                          <Col xs={6} className="ps-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.title`}>
                              <Form.Label>Title (Optional)</Form.Label>
                              <Field
                                name={`contacts.${index}.title`}
                                as={Form.Control}
                                isInvalid={meta.touched?.title && !!(typeof meta.error === "string" ? meta.error : meta.error.title)}
                              />
                              <ErrorMessage name={`contacts.${index}.title`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>

                            <Form.Group className="mb-1 mb-lg-3" controlId={`contacts.${index}.phone_number`}>
                              <Form.Label>Contact Phone Number</Form.Label>
                              <Field
                                name={`contacts.${index}.phone_number`}
                                type="tel"
                                as={Form.Control}
                                isInvalid={meta.touched?.phone_number && !!(typeof meta.error === "string" ? meta.error : meta.error.phone_number)}
                              />
                              <ErrorMessage name={`contacts.${index}.phone_number`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            
                            <Form.Group controlId={`contacts.${index}.should_be_sent_roster`}>
                              <Form.Label>
                                <Field
                                  name={`contacts.${index}.should_be_sent_roster`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'Should be sent roster'}
                                />
                              </Form.Label>
                            </Form.Group>

                            <Form.Group controlId={`contacts.${index}.should_be_sent_invoices`}>
                              <Form.Label>
                                <Field
                                  name={`contacts.${index}.should_be_sent_invoices`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'Should be sent invoices'}
                                />
                              </Form.Label>
                            </Form.Group>

                            <Form.Group controlId={`contacts.${index}.should_be_sent_bulk_welcomes`}>
                              <Form.Label>
                                <Field
                                  name={`contacts.${index}.should_be_sent_bulk_welcomes`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'Should be sent bulk welcomes'}
                                />
                              </Form.Label>
                            </Form.Group>

                            <Form.Group controlId={`contacts.${index}.should_be_sent_bulk_thanks`}>
                              <Form.Label>
                                <Field
                                  name={`contacts.${index}.should_be_sent_bulk_thanks`}
                                  type="checkbox"
                                  as={Form.Check}
                                  label={'Should be sent bulk thanks'}
                                />
                              </Form.Label>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col hidden={index === 0} xs={1}>
                        <CloseButton onClick={() => arrayHelpers.remove(index)} />
                      </Col> */}
                    </Card.Body>
                    {/* { index < values.contacts.length - 1 ? <hr /> : <></>} */}
                  </Card>
              </Col>
                )})
              ) : (
                <></>
              )}
              </Row>
            </div>
            <button
              type='button'
              className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
              onClick={() => arrayHelpers.insert(values?.contacts.length ?? 0, {
                first_name: "", 
                last_name: "", 
                email: "", 
                title: "", 
                phone_number: "",
                should_be_sent_roster: false, 
                should_be_sent_invoices: false, 
                should_be_sent_bulk_welcomes: false, 
                should_be_sent_bulk_thanks: false
              })} // insert an empty string at a position
            >
              + Add Venue Contact
            </button>
          </Form.Group>
        )}
      />
    </>
  )
}
