/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditGallery';
import { ImageSelect } from '../../../../../components/inputs/ImageSelect';
import ImageWithErrorHandling from '../../../../../components/image/ImageWithErrorHandling';
import { getPartition } from '../../../../../utils/utils';

export const GalleryForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Field
              name="name"
              as={Form.Control}
              isInvalid={touched.name && !!errors.name}
            />
            <ErrorMessage name="name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
        </Row>
        <Row>
        <Col>
          <FieldArray 
            name="pictures"
            render={(arrayHelpers) => (
              <Form.Group className="mb-1 mb-lg-3" controlId="pictures">
                <Form.Label className="h4">Images</Form.Label>
                <br />
                <div className="multi-content-scroll mt-2">
                  <Row>
                  {values?.pictures && values?.pictures.length > 0 ? (
                    values.pictures.map((picture, index) => {
                      if (picture.destroy) {
                        return <div key={index}></div>;
                      }
                      return (
                        <Col  key={index} md={6} lg={4} className="mb-2">
                        <Card border="primary">
                        {typeof errors.pictures === "string" ? <div>{errors.pictures}</div> : null}
                        <Card.Body>
                          <Col className="ms-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`pictures.${index}.image`}>
                              <ImageWithErrorHandling
                                src={`${picture?.s3_base_url}/${getPartition(picture?.id)}/thumb/${picture?.image_file_name}`} 
                                alt="Error loading image"
                              />
                              <ImageSelect
                                label={`Image${picture.image_file_name ? ` (${picture.image_file_name})` : ''}`}
                                name={`pictures.${index}.image`}
                                onChange={setFieldValue}
                              />
                              <ErrorMessage name={`pictures.${index}.image`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Button
                              variant="primary"
                              size="sm"
                              className="btn-box-shadow border-0 text-uppercase"
                              style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                              onClick={() => { arrayHelpers.replace(index, {...picture, destroy: true}); }}
                            >
                              Remove  
                            </Button>
                          </Col>
                        </Card.Body>
                      </Card>
                    </Col>
                    )})
                  ) : (
                    <></>
                  )}
                  </Row>
                </div>
                <button
                  type='button'
                  className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
                  onClick={() => arrayHelpers.insert(values?.pictures?.length ?? 0, {})}
                >
                  + Add New Picture
                </button>
              </Form.Group>
            )}
          />
        </Col>
      </Row>
    </>
  )
}
