import { Button, Col, Modal, Stack } from 'react-bootstrap';
import { StepProps } from '../OrderCheckout';
import { EnrollmentComplete } from './components/EnrollmentComplete';
import { useGetLocationQuery } from '../../../../services/endpoints/places/location';
import { UpdateChild } from './components/UpdateChild';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCartContext } from '../../../../context/CartContext';

export const OrderComplete = ({ values }: StepProps) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const courseIds_qs = searchParams.get('course_ids');
  const complete_qs = searchParams.get('complete');
  
  const navigate = useNavigate();
  const { data } = useGetLocationQuery(values.location.id);
  const [ showModal, setShowModal ] = useState(false);
  const { removeCourses } = useCartContext();
  const renderAfterCalled = useRef(false);

  const updateCart = async () => {
    if (!renderAfterCalled.current) {
      renderAfterCalled.current = true;
      if (courseIds_qs) {
        let courseIds = courseIds_qs?.split(',').map(id => parseInt(id)) ?? [];
        removeCourses(courseIds);
        if (complete_qs === String(values.location.id)) {
          setShowModal(true);
        }
      }
    }
  }

  useEffect(() => {
    updateCart();
  }, []);
 
  return (
    <>
      <Stack className="text-info">
        <h2>Thank you. <em>You're Awesome Sauce!</em></h2>
        <span>For any questions, please contact us at {data?.location?.manager?.email ?? 'N/A'}</span>
        <span>Please review your enrollment details below and LETTUCE get cooking!</span>
        <hr />
        <div className="label">Enrollment Details</div>
        <Col sm={8}>
          <EnrollmentComplete orders={values.orders} />
        </Col>
      </Stack>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          {values.orders.map(o => (
            <UpdateChild order={o} key={o.id}  />
          ))}
          <Stack direction='horizontal' gap={2} className="mt-2">
            <Button onClick={() => navigate('/user/profile')} className='form-control text-uppercase' variant='outline-primary'>Update Now</Button>
            <Button onClick={() => setShowModal(false)} className='form-control text-uppercase' variant='primary'>This Looks Good!</Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  )
}
