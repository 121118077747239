import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Waiver } from './user';
import { sfcApi } from '../../api';

export const waiverApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllWaivers: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `waivers${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Waiver']
    }),
    getWaiver: builder.query<{ waiver: Waiver }, number>({
      query: (id) => `waivers/${id}`,
      providesTags: (result, error, id) => [{ type: 'Waiver', id }]
    }),
    createWaiver: builder.mutation<{ waiver: Waiver }, Waiver>({
      query: (data) => ({
        url: `waivers/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Waiver']
    }),
    updateWaiver: builder.mutation<{ waiver: Waiver }, Waiver>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        let payload: any = {
          url: `waivers/update/${id}`,
          method: 'PUT',
          body: data
        };
        return payload;
      },
      invalidatesTags: (result, error) => [{ type: 'Waiver', id: result?.waiver?.id }, 'Waiver']
    }),
    deleteWaiver: builder.mutation<{ waiver: Waiver }, number>({
      query: (id) => ({
        url: `waivers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Waiver'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Waiver', id })), 'Waiver']
    }),
  }),
});

export const {
  useGetAllWaiversQuery,
  useGetWaiverQuery,
  useCreateWaiverMutation,
  useUpdateWaiverMutation,
  useDeleteWaiverMutation,
} = waiverApi;