''
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { HiOutlineAdjustmentsHorizontal } from 'react-icons/hi2';
import { useGetInstructorsQuery, User } from '../../../services/endpoints/people/user';
import { useToast } from '../../../context/ToastContext';
import { getCurrentUser } from '../../../services/helper';
import { isAdmin } from '../../../utils/consts';
import { dateFromString, dateToUTC, formatDate, dateInTimeZone } from '../../../utils/dateUtils';
import { endOfMonth, startOfMonth } from 'date-fns';

interface FiltersProps {
  onApplyFilters: (filters: { operator: string, field: string, value: string }[]) => void;
  disabled?: boolean;
}

export const Filters: React.FC<FiltersProps> = ({ onApplyFilters, disabled }) => {
  const currentUser = getCurrentUser();
  const isAdminUser = isAdmin(currentUser?.user_type_id!);
  const { data, error, isLoading } = useGetInstructorsQuery({data: {orderBy: {field: "first_name", order: "asc"}}}, { skip: !isAdminUser });
  const [fromDate, setFromDate] = useState<string>(formatDate(dateInTimeZone(startOfMonth(new Date())),'yyyy-MM-dd'));
  const [toDate, setToDate] = useState<string>(formatDate(dateInTimeZone(endOfMonth(new Date())), 'yyyy-MM-dd'));
  const [instructor, setInstructor] = useState(isAdminUser ? 'all' : String(currentUser?.id));
  const [instructors, setInstructors] = useState<User[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading events', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setInstructors(data.results);
    }
  }, [data]);

  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(event.target.value);
  };

  const handleInstructorChange = (event: React.ChangeEvent<any>) => {
    setInstructor(event.target.value);
  };

  const onFilter = () => {
    if (toDate < fromDate) {
      addToast('End date must be after the start date', 'error');
      return;
    }
    const filters = [
      {
        operator: 'greater_equal_than',
        field: 'date',
        value: dateToUTC(dateFromString(fromDate, 'yyyy-MM-dd')),
      },
      {
        operator: 'lesser_equal_than',
        field: 'date',
        value: dateToUTC(dateFromString(toDate, 'yyyy-MM-dd')),
      },
      ...(instructor !== 'all' ? [{
        operator: 'equals',
        field: 'additional_instructor_id',
        value: instructor,
      }]: [])
    ];

    onApplyFilters(filters);
  };

  return (
    <div>
      <Row className="mb-3 align-items-end">
        <Col xs={6} md={4} lg={3} xl={2} className="pe-1 mb-1">
          <Form.Group controlId="formDateFrom">
            <Form.Label>From</Form.Label>
            <Form.Control
              type="date"
              name="from"
              value={fromDate}
              onChange={handleFromDateChange}
              style={{ padding: '7px 10px' }}
              disabled={disabled}
            />
          </Form.Group>
        </Col>

        <Col xs={6} md={4} lg={3} xl={2} className="px-2 px-lg-1 mb-1">
          <Form.Group controlId="formDateTo">
            <Form.Label>To</Form.Label>
            <Form.Control
              type="date"
              name="to"
              value={toDate}
              onChange={handleToDateChange}
              style={{ padding: '7px 10px' }}
              disabled={disabled}
            />
          </Form.Group>
        </Col>

        {isAdminUser && (
          <Col xs={8} lg={3} xl={2} className="pe-2 px-lg-1 mb-1">
            <Form.Group controlId="instructor">
              <Form.Label>Instructor</Form.Label>
              <Form.Control
                as="select"
                name="instructor"
                value={instructor}
                onChange={handleInstructorChange}
                style={{ padding: '7px 10px' }}
                disabled={disabled}
              >
                <option value="all">All</option>
                {instructors.map((instructor) => (
                  <option key={instructor.id} value={instructor.id}>
                    {`${instructor.first_name} ${instructor.last_name}`}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        )}
        
        <Col xs={4} md="auto" className="ps-2 ps-md-1 mt-3 mb-1">
          <Button
            type="button"
            variant="primary"
            onClick={() => onFilter()}
            className={`d-flex align-items-center text-uppercase`}
            disabled={disabled}
          >
            {/* <HiOutlineAdjustmentsHorizontal size={20} className="me-2" /> */}
            Search
          </Button>
        </Col>
      </Row>
    </div>
  );
};
