import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface LinkCellProps {
  content: ReactNode;
  url: string;
  underline?: boolean; 
}

export const LinkCell: React.FC<LinkCellProps> = ({ content, url, underline }) => {
  return (
    <Link
      className="text-info fw-bolder"
      style={{ fontSize: '.875rem', ...(!underline && { textDecoration: 'none' }) }}
      to={url}
    >
      {content}
    </Link>
  );
};
