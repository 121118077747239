/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Form,
  FormControl,
  FormSelect,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";
import { useToast } from "../../../../../context/ToastContext";
import { JokeCategory } from "../../../../../types/content.type";
import { useGetJokeCategoriesQuery } from "../../../../../services/endpoints/content/jokeCategory";
import { StepProps } from "./AddEditJoke";
import Editor from "../../../../../components/editor/Editor";

export const JokeForm = ({
  touched,
  errors,
  readOnly,
  values,
  setFieldValue,
}: StepProps) => {
  const { data, error, isLoading } = useGetJokeCategoriesQuery({
    scope: "all",
  });
  const { addToast } = useToast();
  const [jokeCategories, setJokeCategories] = useState<JokeCategory[]>([]);

  useEffect(() => {
    if (error && !isLoading) {
      addToast("Error while loading joke categories", "error");
    }
  }, [error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setJokeCategories(data.results);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <Stack gap={3}>
            <Form.Group className="mb-1 mb-lg-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Field
                name="name"
                type="text"
                as={Form.Control}
                isInvalid={touched.name && !!errors.name}
              />
              <ErrorMessage name="name">
                {(msg) => (
                  <FormControl.Feedback type="invalid">
                    {msg}
                  </FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
            <Form.Group controlId="joke_category_id">
              <Form.Label>Joke Category</Form.Label>
              <Field
                name="joke_category_id"
                as={Form.Select}
                isInvalid={
                  touched.joke_category_id && !!errors.joke_category_id
                }
                disabled={readOnly}
              >
                <option>Select</option>
                {jokeCategories.map((jokeCategory: JokeCategory) => (
                  <option key={jokeCategory.id} value={jokeCategory.id}>{jokeCategory.name}</option>
                )) ?? []}
              </Field>
              <ErrorMessage name="joke_category_id">
                {(msg) => (
                  <FormControl.Feedback type="invalid">
                    {msg}
                  </FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
            <Form.Group controlId="content">
              <Form.Label>Content</Form.Label>
              <Field
                name="content"
                component={Editor}
                isInvalid={touched.content && !!errors.content}
                disabled={readOnly}
              />
              <ErrorMessage name="content">
                {(msg) => (
                  <FormControl.Feedback type="invalid">
                    {msg}
                  </FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
          </Stack>
        </Col>
      </Row>
    </>
  );
};
