import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ExpenseForm } from './ExpenseForm';
import { useCreateExpenseMutation, useGetExpenseQuery, useUpdateExpenseMutation } from '../../../../../services/endpoints/finance/expense';

const steps = [
  { name: 'Details', form: ExpenseForm },
];

export interface FormValues {
  id?: number;
  course_id: { value: number | string, label: string };
  category: string;
  amount: number;
  context: string;
  due_on: string,
  paid_on?: string | null,
  notes: string;
  invoice_number: string;
  payment_method: string;
  check_number?: number | null;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditExpenseProps {
  editing?: boolean;
}

export const AddEditExpense: React.FC<AddEditExpenseProps> = ({ editing }) => {
  const params = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search); 
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createExpense] = useCreateExpenseMutation();
  const [updateExpense] = useUpdateExpenseMutation();
  const { data, error, isLoading }  = useGetExpenseQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    course_id: { value: urlParams.get('course_id') ?? '', label: '' },
    category: '',
    amount: 0,
    context: '',
    due_on: '',
    notes: '',
    invoice_number: '',
    payment_method: '',
  });

  const validationSchemas = [
    Yup.object({
      course_id: Yup.object({value: Yup.number().required('Session selection is required'), label: Yup.string().optional()}).required(),
      category: Yup.string().required('Category is required'),
      amount: Yup.number().required('Amount is required'),
      context: Yup.string().required('Context is required'),
      due_on: Yup.string().required('Due date is required'),
      paid_on: Yup.string().nullable().optional(),
      notes: Yup.string().required('Notes are required'),
      invoice_number: Yup.string().required('Invoice number is required'),
      payment_method: Yup.string().required('Payment method is required'),
      check_number: Yup.number().nullable().optional(),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading expense', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.expense) {
      const { expense } = data;
      setInitialValues({
        id: expense.id,
        course_id: { value: expense.course.id, label: expense.course.title },
        category: expense.category,
        amount: expense.amount,
        context: expense.context,
        due_on: expense.due_on,
        paid_on: expense.paid_on,
        notes: expense.notes,
        invoice_number: expense.invoice_number,
        payment_method: expense.payment_method,
        check_number: expense.check_number,
      });
    }
  }, [data]);


  const handleSubmit = async (formValue: FormValues) => {
    try {
      const expenseData = {
        ...formValue,
        course_id: Number(formValue.course_id.value),
        paid_on: formValue.paid_on ?? undefined,
        check_number: formValue.check_number ?? undefined,
      };
      if (editing) {
        const res = await updateExpense(expenseData).unwrap();
        if (res && res.expense) {
          addToast(`Expense updated succesfully`, 'success');
          navigate(`/admin/finance/expenses/${data?.expense.id}`);
        }
      } else {
        const res = await createExpense(expenseData).unwrap();
        if (res && res.expense) {
          navigate(`/admin/finance/expenses/${res.expense.id}`);
          addToast('Expense created succesfully', 'success');
        } else throw new Error('A problem happened while creating expense');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        course_id: { value: true },
        category: true,
        amount: true,
        context: true,
        due_on: true,
        paid_on: true,
        notes: true,
        invoice_number: true,
        payment_method: true,
        check_number: true
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to expense${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/finance/expenses/${data?.expense.id}` : '/admin/finance/expenses'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Expense
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
