/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { FaArrowLeft } from 'react-icons/fa6';
import { Accordion, ListGroup, ListGroupItem, Spinner, Stack } from 'react-bootstrap';
import { SFCAccordion } from '../../../../components/accordion/SFCAccordion';
import { useToast } from '../../../../context/ToastContext';
import { DetailSection, LabelText } from '../../../../components/generic';
import { useGetAdminQuery, useGetInstructorQuery, useMimicMutation, User, UserType } from '../../../../services/endpoints/people/user';
import { useGetParentQuery } from '../../../../services/endpoints/people/parent';
import { SimpleTable } from '../../../../components/tables/SimpleTable';
import { childTableColumns, ordersTableColumns } from '../../../../utils/consts/columnDefs/parentColumns';
import CurrencyCell from '../../../../components/tables/cellComponents/CurrencyCell';
import { useGetAllDiscountsQuery } from '../../../../services/endpoints/orders/discounts';
import { getCurrentUser } from '../../../../services/helper';

const currentUser = getCurrentUser();

const LabelTextList = ({ label, list }: { label: string, list: string[] }) => (
  <Stack direction="vertical" gap={1}>
    <span className="fw-semibold" style={{ color: 'rgba(94, 100, 105, .5)' }}>{label}</span>
    {list.map((text, index) => (
      <span key={index} className="text-secondary fw-semibold">{text}</span>
    ))}
  </Stack>
);

const userLinksMap = (user: UserType, id?: number | string) => {
  if (user === 'customer') {
    return {
      new: `/admin/sfc-users/parents/new`,
      edit: `/admin/sfc-users/parents/${id}/edit`,
      back: `/admin/sfc-users/parents`,
    }
  }
  if (user === 'instructor') {
    return {
      new: `/admin/dash-users/instructors/new`,
      edit: `/admin/dash-users/instructors/${id}/edit`,
      back: `/admin/dash-users/instructors`,
    }
  } else {
    return {
      new: `/admin/dash-users/admins/new`,
      edit: `/admin/dash-users/admins/${id}/edit`,
      back: `/admin/dash-users/admins`,
    }
  }
}

interface UserDetailsViewProps {
  userType: UserType;
}

export const UserDetailsView: React.FC<UserDetailsViewProps> = ({ userType }) => {
  const params = useParams();
  const navigate = useNavigate();
  const userID = +params.id!;
  const { addToast } = useToast();
  const [ mimic ] = useMimicMutation();
  const { data: discounts, error: discountError, isLoading: discountsLoading } = useGetAllDiscountsQuery({data: {filterBy: [{field: 'user_id', operator: 'equals', value: userID}]}});

  const { data: adminData, error: adminError, isLoading: loadingAdmin } = useGetAdminQuery(userID,{ 
    skip: userType === 'instructor' || userType === 'customer',
  });
  const { data: instructorData, error: instructorError, isLoading: loadingInstructor } = useGetInstructorQuery(userID,{ 
    skip: userType !== 'instructor',
  });
  const { data: parentData, error: parentError, isLoading: loadingParent } = useGetParentQuery(userID,{ 
    skip: userType !== 'customer',
  });
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (adminError && !loadingAdmin) {
      addToast('Error while loading admin', 'error');
    }
  },[adminData, adminError, loadingAdmin]);

  useEffect(() => {
    if (instructorError && !loadingInstructor) {
      addToast('Error while loading instructor', 'error');
    }
  },[instructorData, instructorError, loadingInstructor]);

  useEffect(() => {
    if (parentError && !loadingParent) {
      addToast('Error while loading parent', 'error');
    }
  },[parentData, parentError, loadingParent]);

  useEffect(() => {
    if (adminData) {
      setUser({
        ...adminData.admin,
        id: userID,
      });
    }

    if (instructorData) {
      setUser({
        ...instructorData.instructor,
        id: userID,
      });
    }

    if (parentData) {
      setUser({
        ...parentData.parent,
        id: userID,
      });
    }
  }, [adminData, instructorData, parentData]);

  if (loadingAdmin || loadingInstructor || loadingParent) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  const accordion = (
    <SFCAccordion defaultActiveKey={['0', '1', '2']}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Children</Accordion.Header>
        <Accordion.Body>
          <SimpleTable columns={childTableColumns} data={user?.children ?? []} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Orders</Accordion.Header>
        <Accordion.Body>
          <SimpleTable columns={ordersTableColumns} data={user?.orders ?? []} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Revisions</Accordion.Header>
        <Accordion.Body>
          {/* <Stack gap={2}>
            {user?.courses?.map((course) => course.venue.organizations.map((o) => (
              <LinkCell content={o.name} url={`/admin/places/organizations/${o.id}`} underline />
            )))}
          </Stack> */}
        </Accordion.Body>
      </Accordion.Item>
    </SFCAccordion>
  );

  const userDetails = (
    <>
      <DetailSection title="Details">
        <LabelText label="ID:">
          {user?.id}
        </LabelText>
        <LabelText label="Address:" direction="vertical">
           {user?.address}{user?.address2 ? `, ${user?.address2}` : ''}
           <br />
           {user?.city}
           {user?.state ? `, ${user?.state}` : ''}
           {user?.zip ? `, ${user?.zip}` : ''}
        </LabelText>
        <LabelText label="Email:">
          {user?.email}
        </LabelText>
        <LabelText label="Phone:">
          {user?.phone_mobile}
        </LabelText>

        {userType !== 'customer' && (
          <>
            <hr />
            <LabelText label="In-Person Rate:">
              <CurrencyCell value={user?.instructor_session_rate ?? ''} />
            </LabelText>
            <LabelText label="In-Person Reimbursement:">
              <CurrencyCell value={user?.instructor_session_reimbursement ?? ''} />
            </LabelText>
            <LabelText label="Online Rate:">
              <CurrencyCell value={user?.instructor_online_rate ?? ''} />
            </LabelText>
            <LabelText label="Online Reimbursement:">
              <CurrencyCell value={user?.instructor_online_reimbursement ?? ''} />
            </LabelText>
          </>
        )}
        {/* TODO: add refunds summary */}
        <LabelText label="Total Refunds:">
          N/A
        </LabelText>
      </DetailSection>
      {currentUser?.isAdmin && (
        <DetailSection title="Scholarships">
          <Link to={`/admin/discounts?user_id=${userID}`}>
            {discounts?.count} Discount{(discounts?.count ?? 0) != 1 ? 's' : ''}
          </Link>
          <ListGroup>
            {discounts?.results?.map(d => {
              let label = '';
              if (d.course) {
                label += `For enrollment in ${d.course.title}.`;
              }
              if (d.child) {
                label += `This discount was for ${d.child.full_name}`;
              }
              if (d.order) {
                label += `It was used on order ${d.order.id}.`;
              }
              return (
                <ListGroupItem key={d.id} href={`/admin/discounts/${d.id}`}>
                  {d.scholarship.scholarship_type} ({d.scholarship.percent_discount}% off)
                  <i className="tippy fa fa-info" title={label}></i>
                </ListGroupItem>
              )
            })}
          </ListGroup>
        </DetailSection>
      )}

      {/* TODO: add videos list  -- or not? this may be deprecated with 3.0 */}
      {/* <DetailSection title="Videos">
        No videos are available for this user.
      </DetailSection> */}
    </>
  );

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate(userLinksMap(userType, user?.id)?.back ?? '')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Dash Users
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{user?.first_name} {user?.last_name}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(userLinksMap(userType, user?.id)?.edit ?? '')}
          >
            Edit {userType}
          </SimpleButton>
          <SimpleButton
            variant="secondary"
            height={36}
            onClick={() => {
              if (user?.id) {
                mimic(user.id).unwrap().then((r) => {
                  window.location.reload();
                });
              } 
              else {
                console.error("user Id is undefined");
              }
            }}
          >
            Mimic
          </SimpleButton>
        </Stack>
      </div>
      <div className="d-lg-none">
        <Stack gap={4} className="p-4">
          {userDetails}
          {accordion}
        </Stack>
      </div>
      <div className="d-none d-lg-flex">
        <div className="mt-5 w-100 px-5 py-4" style={{ maxWidth: 'calc(100% - 400px)' }}>
          {accordion}
        </div>
        <Stack gap={4} className="p-4 vh-100" style={{ width: 400, background: '#F2F2F2' }}>
          {userDetails}
        </Stack>
      </div>
    </>
  );
};
