import React, { ReactNode, useEffect, useState } from 'react';
import { Course } from '../../../types/course.type';
import { Stack } from 'react-bootstrap';
import { FaLocationDot } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { LatLng } from '../../../services/helper';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useMyLocationContext } from '../../../context/LocationContext';

interface CourseLinkProps {
  content: ReactNode;
  url: string;
  underline?: boolean; 
  color: string;
}

export const CourseLink: React.FC<CourseLinkProps> = ({ content, url, underline, color }) => {
  return (
    <Link
      className={`fw-bolder course-card-link ${color}`}
      style={{ ...(!underline && { textDecoration: 'none' }) }}
      to={url}
    >
      {content}
    </Link>
  );
};

interface CourseVenueDistanceProps {
  course: Course;
  color: string;
  fillStyle: React.CSSProperties;
}

export const CourseVenueDistance: React.FC<CourseVenueDistanceProps> = ({ course, color, fillStyle }) => {
  let dest: LatLng|undefined;
  if (course.venue.latitude && course.venue.longitude) {
    dest = {lat: course.venue.latitude, lng: course.venue.longitude};
  }

  const RoutesLibrary = useMapsLibrary('routes');
  const [service, setService] = useState<google.maps.DistanceMatrixService>();

  const [ distance, setDistance ] = useState<number|null>();

  const { myLocation } = useMyLocationContext();

  const source = myLocation?.location;
  
  useEffect(() => {
    if (RoutesLibrary) {
      setService(new RoutesLibrary.DistanceMatrixService())
    }
  }, [RoutesLibrary]);
  
  useEffect(() => {
    if (service && source && dest) {
      const request = {
        origins: [source],
        destinations: [dest],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: 1,
        avoidHighways: false,
        avoidTolls: false,
      };
      service.getDistanceMatrix(request)
      .then((response) => {
        let distances = response.rows[0].elements.map((e) => (parseFloat(e.distance.text.split(' ')[0])));
        setDistance(distances[0]);
      })
      .catch((err: any) => {
        console.log(err);
      })
    } 
  }, [service, source]);

  return (
    <Stack direction='horizontal' gap={1} >
      <FaLocationDot size={16} style={fillStyle} className={`${color}`}/>
      <CourseLink color={color} content={course.venue.title} url={`/admin/places/venues/${course.venue.id}`} /> 
      <span className={`course-card-distance ${color}`}>|</span>
      {distance ? <div className={`course-card-distance ${color}`} >{distance} MILES</div> : <></>}
    </Stack>
  )
}
