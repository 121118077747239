import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { StepProps } from "../Register"
import { ErrorMessage, Field } from 'formik';
import { usaStates, usTimezones } from '../../../utils/consts';

export const LocationForm = ({ touched, errors }: StepProps) => {
  return (
    <>
      <Form.Group className="mb-1 mb-lg-3" controlId="address">
        <Form.Label>Address</Form.Label>
        <Field
          name="address"
          type="text"
          as={Form.Control}
          isInvalid={touched.address && !!errors.address}
        />
        <ErrorMessage name="address">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <Form.Group className="mb-1 mb-lg-3" controlId="address2">
        <Form.Label>Address 2</Form.Label>
        <Field
          name="address2"
          type="text"
          as={Form.Control}
          isInvalid={touched.address2 && !!errors.address2}
        />
        <ErrorMessage name="address2">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <Form.Group className="mb-1 mb-lg-3" controlId="city">
        <Form.Label>City</Form.Label>
        <Field
          name="city"
          type="text"
          as={Form.Control}
          isInvalid={touched.city && !!errors.city}
        />
        <ErrorMessage name="city">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <Row>
        <Col xs={9} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="state">
            <Form.Label>State</Form.Label>
            <Field
              name="state"
              as={Form.Select}
              placeholder="Select"
              isInvalid={touched.state && !!errors.state}
            >
              <option>Select</option>
              {usaStates.map((state) => (
                <option value={state} key={state}>{state}</option>
              ))}
            </Field>
            <ErrorMessage name="state">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
        <Col xs={3} className="ps-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="zip">
            <Form.Label>ZIP</Form.Label>
            <Field
              name="zip"
              type="text"
              as={Form.Control}
              isInvalid={touched.zip && !!errors.zip}
            />
            <ErrorMessage name="zip">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-1 mb-lg-3" controlId="timezone">
        <Form.Label>Preferred Time Zone</Form.Label>
        <Field
          name="timezone"
          as={Form.Select}
          options={['2', '3']}
          placeholder="Select"
          isInvalid={touched.timezone && !!errors.timezone}
        >
          <option>Select</option>
          {usTimezones.map((tz) => (
            <option value={tz} key={tz}>{tz}</option>
          ))}
        </Field>
        <ErrorMessage name="timezone">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>
    </>
  )
}
