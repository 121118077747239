import { DateField } from "../../../components/tables/filterForms/DateField";
import { FilterField } from "../../../components/tables/filterForms/FilterForm";

export const created_at_from: FilterField = {
  name: 'created_at_from',
  id: 'created_at',
  operator: 'greater_equal_than',
  element: (
    <DateField name="created_at_from" label="Created At" />
  )
};

export const created_at_to: FilterField = {
  name: 'created_at_to',
  id: 'created_at',
  operator: 'lesser_equal_than',
  element: (
    <DateField name="created_at_to" />
  )
};


export const updated_at_from: FilterField = {
  name: 'updated_at_from',
  id: 'updated_at',
  operator: 'greater_equal_than',
  element: (
    <DateField name="updated_at_from" label="Updated At" />
  )
};

export const updated_at_to: FilterField = {
  name: 'updated_at_to',
  id: 'updated_at',
  operator: 'lesser_equal_than',
  element: (
    <DateField name="updated_at_to" />
  )
};

export const created_at_from_to = [created_at_from, created_at_to];
export const updated_at_from_to = [updated_at_from, updated_at_to];
export const created_updated_at_from_to = [...created_at_from_to, ...updated_at_from_to];