import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { LessonPlan } from '../../../types/lessonPlan.type';
import { sfcApi } from '../../api';
import { CreateFormValues as CreateLessonPlanFormValues } from '../../../features/dash/views/content/LessonPlans/AddEditLessonPlan';

export const lessonPlanApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getLessonPlans: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `lesson-plans${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getLessonPlan: builder.query<{ lessonPlan: LessonPlan }, number|string>({
      query: (id) => `lesson-plans/${id}`,
    }),
    getLessonPlanPublic: builder.query<{ lessonPlan: LessonPlan }, number|string>({
      query: (id) => `lesson-plans/public/${id}`,
    }),

    createLessonPlan: builder.mutation<{ lessonPlan: LessonPlan }, CreateLessonPlanFormValues>({
      query: (data) => {
        return {
          url: 'lesson-plans/create',
          method: 'POST',
          body: {
            ...data
          },
        }
      },
    }),

    updateLessonPlan: builder.mutation<{ lessonPlan: LessonPlan }, CreateLessonPlanFormValues>({
      query: (data) => {
        const { id, ...requestObject } = data;
        return {
          url: `lesson-plans/update/${id}`,
          method: 'PUT',
          body: {
            ...requestObject,
          },
        }
      },
    }),

    deleteLessonPlan: builder.mutation<{ lessonPlan: LessonPlan }, number>({
      query: (id) => ({
        url: `lesson-plans/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetLessonPlansQuery,
  useGetLessonPlanQuery,
  useGetLessonPlanPublicQuery,
  useCreateLessonPlanMutation,
  useUpdateLessonPlanMutation,
  useDeleteLessonPlanMutation
} = lessonPlanApi;