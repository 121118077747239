import React from 'react';
import { Form } from 'react-bootstrap';

interface FileSelectProps {
  name: string,
  acceptType?: string,
  label?: string,
  onChange?: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
}

export const FileSelect: React.FC<FileSelectProps> = ({ name, acceptType, label, onChange }) => {

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    onChange?.(name, file);
  }

  return (
    <>
      {label ? <Form.Label>{label}</Form.Label> : <></>}
      <Form.Control
        name={name}
        type="file"
        size="sm"
        accept={acceptType ?? "*"}
        onChange={handleFileChange}
      />
    </>
  )
}
