import { DateField } from "../../../components/tables/filterForms/DateField";
import { FilterField } from "../../../components/tables/filterForms/FilterForm";

export const imageFilterFields: Array<FilterField> = [
  {
    name: 'image_file_name',
    label: 'Image File Name',
  },
  {
    name: 'image_content_type',
    label: 'Image Content Type',
  },
  {
    name: 'image_file_size',
    label: 'Image File Size',
    type: 'number',
  },
  {
    name: 'image_updated_at_from',
    id: 'image_updated_at',
    operator: 'greater_equal_than',
    element: (
      <DateField name="image_updated_at_from" label="Image Updated At" />
    )
  },
  {
    name: 'image_updated_at_to',
    id: 'image_updated_at',
    operator: 'lesser_equal_than',
    element: (
      <DateField name="image_updated_at_to" />
    )
  },
]