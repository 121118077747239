import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { sfcApi } from '../../api';
import { User } from './user';

export const parentApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllParents: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `users/parents${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Parent']
    }),
    getParent: builder.query<{ parent: User }, number>({
      query: (id) => `users/parent/${id}`,
      providesTags: (result, error, id) => [{ type: 'Parent', id }]
    }),
    // createChild: builder.mutation<{ child: Child }, ScholarshipValues>({
    //   query: (data) => ({
    //     url: `children/create`,
    //     method: 'POST',
    //     body: data
    //   }),
    //   invalidatesTags: ['Child']
    // }),
    // updateChild: builder.mutation<{ child: Child }, { id: number, data: Partial<ScholarshipValues> }>({
    //   query: ({ id, data }) => ({
    //     url: `children/update/${id}`,
    //     method: 'PUT',
    //     body: data
    //   }),
    //   invalidatesTags: (result, error, { id }) => [{ type: 'Child', id }, 'Child']
    // }),
    // deleteChild: builder.mutation<{ child: Child }, number>({
    //   query: (id) => ({
    //     url: `children/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: (result, error, id) => [{ type: 'Child', id }, 'Child']
    // }),
  }),
});

export const {
  useGetAllParentsQuery,
  useGetParentQuery,
} = parentApi;