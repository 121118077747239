import React, { useEffect, useRef, useState } from 'react';
import { Calendar, DateCellWrapperProps, dateFnsLocalizer, SlotInfo } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from './CustomComponents/Toolbar';
import { Event, eventStyleGetter } from './SFCCalendar';
import { enUS } from 'date-fns/locale/en-US';
import { endOfDay, format, getDay, parse, startOfDay, startOfWeek } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { dateInTimeZone, formatDate } from '../../../utils/dateUtils';

const locales = {
  'en-US': enUS,
};

// Create the localizer
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface CustomDayProp {
  date?: Date;
  events: Event[];
  admin?: boolean;
}

const CustomDayCell: React.FC<CustomDayProp> = ({ date, events, admin }) => {
  const filteredEvents = events.filter(
    (event) =>
      event.start.toDateString() === date?.toDateString() ||
      event.end.toDateString() === date?.toDateString()
  );

  return filteredEvents.length > 0 ? (
    <div
      className="d-flex justify-content-evenly align-items-end h-100"
      style={{ marginBottom: 0 }}
    >
      {admin
        ? (
          <div
            className="bg-primary"
            style={{ ...eventStyleGetter(filteredEvents[0]).style, width: 5, height: 5 }}
          />
        )
        : filteredEvents.slice(0, 5).map((ev: Event, idx: number) => ( // show up to 5 events indicators
          <div
            key={`${ev.title}-${idx}`}
            style={{ ...eventStyleGetter(ev).style, width: 5, height: 5 }}
          />
        ))
      }
    </div>
  ) : <></>;
};

interface CustomDayCellWrapperProps extends DateCellWrapperProps {
  admin: boolean;
  selected: boolean;
  events: Event[];
}

const CustomDayCellWrapper: React.FC<CustomDayCellWrapperProps> = ({ value, children, admin, selected, events }) => {
  return (
    <div
      className={`${children.props.className} ${selected ? 'selected-slot' : ''}`}
      style={{ zIndex: 10 }}
    >
      <CustomDayCell
        date={value}
        events={events}
        admin={admin}
      />
      {children}
    </div>
  );
};

interface SFCMobileCalendarProps {
  events: Event[];
  showFullEvents?: boolean;
  loading?: boolean;
  currentDates?: string[];
}

export const SFCMobileCalendar: React.FC<SFCMobileCalendarProps> = ({ events, showFullEvents, loading, currentDates }) => {
  const [dayEvents, setDayEvents] = useState<Event[]>([]);
  const [dayName, setDayName] = useState(`Today's`);
  const [selectedSlot, setSelectedSlot] = useState(dateInTimeZone(startOfDay(new Date())));
  const scheduleRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setDayEvents(events
      .filter((ev) => ev.start >= selectedSlot && ev.end <= dateInTimeZone(endOfDay(selectedSlot)))
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const onSelectSlot = (slotInfo: SlotInfo) => {
    if (!showFullEvents) {
      navigate(`/admin/schedule/classes?starts_on=${currentDates?.[0]}&ends_on=${currentDates?.[1]}`)
      return;
    }
    setSelectedSlot(slotInfo.start);
    setDayName(
      slotInfo.start.getDate() === new Date().getDate()
        ? `Today's`
        : `${formatDate(dateInTimeZone(slotInfo.start), 'EEEE dd')}`
    );
    setDayEvents(events
      .filter((ev) => ev.start >= slotInfo.start && ev.end <= slotInfo.end)
    );
    // Scroll to see loaded schedule for the selected slot
    scheduleRef.current!.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Calendar<Event>
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['month']} // Only show the month view
        defaultView="month" // Set the default view to month
        style={{ height: 400, background: '#FFFFFF', ...(loading && { filter: 'blur(2px)' }) }}
        components={{
          toolbar: Toolbar,
          dateCellWrapper: (props) => (
            <CustomDayCellWrapper
              {...props}
              events={events}
              admin={false}
              selected={selectedSlot.toDateString() === props.value.toDateString()}
            />
          ),
          month: {
            header: (props) => <>{localizer.format(props.date, 'EE')}</>,
          },
        }}
        selectable
        onSelectSlot={onSelectSlot}
        eventPropGetter={eventStyleGetter}
      />
      {showFullEvents && (
        <div className="mt-3 border rounded-3 p-2 pb-0" style={{ background: '#FFFFFF' }} ref={scheduleRef}>
          <span className="text-info text-uppercase fw-semibold">{dayName} Schedule</span>
          <div className="mt-1">
            {dayEvents.length > 0 ? dayEvents.map((ev: Event, index) => (
              <div key={index} className="mb-2" style={eventStyleGetter(ev).style}>
                <div className="custom-event-cell px-2 py-1" style={{ fontSize: 12 }}>
                  <span className="fw-bold">{ev.title}</span>
                  <br />
                  <span>
                    {`${formatDate(ev.start, 'hh:mm a')} - ${formatDate(ev.end, 'hh:mm')}`}
                  </span>
                </div>
              </div>
            )) : (
              <div className="mb-2">No classes</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
