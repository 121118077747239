import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../components/tables/cellComponents/LinkCell';
import CurrencyCell from '../../../components/tables/cellComponents/CurrencyCell';
import { formatDateString } from '../../utils';
import { CourseTypeCell } from '../../../components/tables/cellComponents/CourseTypeCell';
import { SimpleButton } from '../../../components/buttons/SimpleButton';
import { Form as FormikForm, Field } from 'formik';
import { Form } from 'react-bootstrap';
import { timesheetOverrideTypes } from '..';
import { OverrideStatusCell, overrideStatusType } from '../../../components/tables/cellComponents/OverrideStatusCell';
import { timesheet_entries, timesheets } from '../../../types/finance.types';
import OverrideCell from '../../../components/tables/cellComponents/OverrideCell';
import { downloadIndex } from '../../../services/api';

export const masterTimesheetColumns: ColumnDef<any>[] = [
  {
    header: 'Date',
    accessorKey: 'class_date',
    cell: ({ getValue }) => (formatDateString(getValue<string>())),
  },
  {
    header: 'Instructor',
    accessorKey: 'instructor_name',
  },
  {
    header: 'Role',
    accessorKey: 'role',
  },
  {
    header: 'Location',
    accessorKey: 'location_title',
  },
  {
    header: 'Session',
    accessorKey: 'course_title',
    cell: ({ getValue, row }) => (
      <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.course_id}`} />
    ),
  },
  {
    header: 'Qty',
    accessorKey: 'duration',
  },
  {
    header: 'Rate Type',
    accessorKey: 'rate_type',
  },
  {
    header: 'Rate',
    accessorKey: 'rate',
    cell: ({ getValue, row }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Reimbursement',
    accessorKey: 'reimbursement',
    cell: ({ getValue, row }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
  {
    header: 'Total',
    accessorKey: 'total',
    cell: ({ getValue, row }) => (
      <CurrencyCell value={getValue<string|number>()} />
    ),
  },
];

export const getCurrentTimesheetColumns: (adminView: boolean) => ColumnDef<timesheet_entries>[] = (adminView) => ([
  {
    header: 'Date',
    accessorKey: 'class_date',
    cell: ({ getValue }) => (formatDateString(getValue<string>())),
  },
  {
    header: 'Session',
    accessorKey: 'course_title',
    cell: ({ getValue, row }) => (
      <>
        <FormikForm id={`form_${row.id}`}>
        </FormikForm>
        <CourseTypeCell is_online={row.original.is_online} value={
          <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.course_id}`} />
        } />
      </>
    ),
  },
  {
    header: 'Standard\nRate',
    accessorKey: 'rate',
    cell: ({ row }) => {
      const {rate, final_rate} = row.original;
      return <OverrideCell base={rate} override={final_rate} />
    },
  },
  {
    header: 'Standard\nReimbursement',
    accessorKey: 'reimbursement',
    cell: ({ row }) => {
      const {reimbursement, final_reimbursement} = row.original;
      return <OverrideCell base={reimbursement} override={final_reimbursement} />;
    },
  },
  {
    header: 'Additional\nReimbursement Type',
    accessorKey: 'override.timesheet_override_type',
    cell: ({ getValue }) => {
      let value = Number(getValue());
      if (value) {
        return value <= timesheetOverrideTypes.length ? timesheetOverrideTypes[value-1] : "Unknown";
      }
      else if (adminView) {
        return "--";
      }
      else {
        return (
          <Form.Group controlId="timesheet_override_type">
            <Field
              name="timesheet_override_type"
              as={Form.Select}
            >
              <option value="0">Select</option>
              {timesheetOverrideTypes?.map((type: string, index: number) => (
                <option key={index} value={index+1}>{type === "Other" ? `${type} (please specify in notes)` : type}</option>
              ))}
            </Field>
          </Form.Group>
        )
      }
    },
  },
  {
    header: 'Notes',
    accessorKey: 'override.override_request',
    cell: ({ getValue }) => {
      let value = getValue();
      if (value) {
        return value;
      }
      else if (adminView) {
        return "--";
      }
      else {
        return (
          <Field
            name="override_request"
            as="textarea"
            rows={4}
            placeholder="Please include details and, for mileage, pre-approval from an SFC admin."
          >
          </Field>
        )
      }
    },
  },
  {
    header: 'Total',
    accessorKey: 'total',
    cell: ({ row }) => {
      const {rate, final_rate, reimbursement, final_reimbursement} = row.original;
      return <OverrideCell base={rate+reimbursement} override={final_rate+final_reimbursement} />;
    },
  },
  {
    header: 'Override',
    accessorKey: 'override',
    cell: ({ getValue, row }) => {
      let override = getValue<{id: Number, status: overrideStatusType, denial_notes: string}>();
      if (override && override.status) {
        return <OverrideStatusCell type={override.status} value={String(override.id)} notes={override.denial_notes} />;
      }
      else if (adminView) {
        return "--";
      }
      else {
        return (<SimpleButton height={45} type="submit" form={`form_${row.id}`} className="text-center btn-sm" variant="primary">
          Request Override
        </SimpleButton>);
      }
    }
  },
]);

export const getPastTimesheetColumns: (adminView: boolean) => ColumnDef<timesheets>[] = (adminView) => ([
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Start of Pay Period',
    accessorKey: 'starts_on',
    enableSorting: false,
    cell: ({ getValue }) => (formatDateString(getValue<string>())),
  },
  {
    header: 'End of Pay Period',
    accessorKey: 'date',
    enableSorting: false,
    cell: ({ getValue }) => (formatDateString(getValue<string>())),
  },
  {
    header: 'Timesheet Entries',
    accessorKey: 'none',
    enableSorting: false,
    cell: ({ row }) => (
      <>
        <LinkCell content={row.original.count ? `${row.original.count}` : 'View'} url={`/admin/timesheets/timesheet-entries/${row.original.id}`} />
      </>
    ),
  },
  {
    header: 'Export',
    accessorKey: 'blank',
    enableSorting: false,
    cell: ({ row }) => {
      if (adminView) {
        return <SimpleButton 
          onClick={() => downloadIndex({method: "GET", format: "xlsx", path: `timesheets/${row.original.id}`})} 
          height={30} 
          className="text-center btn-sm" 
          variant="primary">
          Export
        </SimpleButton>;
      }
      else {
        return <></>;
      }
    }
  },
]);

export const getTimesheetColumns: (adminView: boolean, globalView: boolean) => ColumnDef<timesheet_entries>[] = (adminView, globalView) => {
  
  let cols: ColumnDef<timesheet_entries>[] = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'Class Date',
      accessorKey: 'class_date',
      cell: ({ getValue }) => (formatDateString(getValue<string>())),
    },
    {
      header: 'Instructor',
      accessorKey: 'instructor.full_name',
    },
    {
      header: 'Role',
      accessorKey: 'role',
      enableSorting: false,
      cell: ({ getValue, row }) => (<>
        {getValue()}
        <br />
        <LinkCell content={row.original.course?.title ?? ''} url={`/admin/schedule/sessions/${row.original.course_id}`} />
      </>),
    },
    {
      header: 'Rate Type',
      accessorKey: 'rate_type',
      enableSorting: false,
    },
    {
      header: 'Rate',
      accessorKey: 'rate',
      enableSorting: false,
      cell: ({ row }) => {
        const { final_rate, rate } = row.original;
        if (final_rate > rate) {
          return <OverrideCell base={rate} override={row.original.override.override_rate} />
        }
        else {
          return <CurrencyCell value={rate} />
        }
      },
    },
    {
      header: 'Reimbursement',
      accessorKey: 'reimbursement',
      enableSorting: false,
      cell: ({ row }) => {
        const { final_reimbursement, reimbursement } = row.original;
        if (final_reimbursement > reimbursement) {
          return <OverrideCell base={reimbursement} override={row.original.override.override_reimbursement} />
        }
        else {
          return <CurrencyCell value={reimbursement} />
        }
      },
    },
    {
      header: 'Total',
      accessorKey: 'total',
      enableSorting: false,
      cell: ({ row }) => {
        const { final_total, total } = row.original;
        if (final_total && final_total > total) {
          return <OverrideCell base={total} override={final_total} />
        }
        else {
          return <CurrencyCell value={total} />
        }
      },
    },
    {
      header: 'Overridden',
      accessorKey: 'override',
      enableSorting: false,
      cell: ({ row }) => {
        const { override } = row.original;
        if (override) {
          let notes = override.status === "approved" ? override.override_request : (override.status === "denied" ?  override.denial_notes : undefined);
          return <OverrideStatusCell type={override.status} value={override.id} notes={notes} />;
        }
        else {
          return <></>;
        }
      },
    },
    {
      header: 'Program Type',
      accessorKey: 'course.program_type',
      enableSorting: false,
    },
  ];

  if (!adminView) {
    cols.splice(2, 1);
  }

  if (!globalView) {
    cols.splice((4 - (adminView ? 0 : 1)), 1);
  }
  return cols;
};