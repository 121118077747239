import { useEffect, useState } from 'react';
import { useToast } from '../../../../context/ToastContext';
import { SimpleTable } from '../../../../components/tables/SimpleTable';
import { masterTimesheetColumns } from '../../../../utils/consts/columnDefs/timesheetColumns';
import { useGetCurrentEntriesQuery } from '../../../../services/endpoints/finance/timesheets';
import { timesheet_entries } from '../../../../types/finance.types';
import { Spinner } from 'react-bootstrap';
import { getCurrentUser } from '../../../../services/helper';
import { isSuper } from '../../../../utils/consts';

export const MasterTimesheetView = () => {
  const { addToast } = useToast();
  const currentUser = getCurrentUser();
  const isSuperUser = isSuper(currentUser?.user_type_id!);
  const { data, error, isLoading } = useGetCurrentEntriesQuery(0, {skip: !isSuperUser});
  const [timesheetEntries, setTimesheetEntries] = useState<timesheet_entries[]>([]);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.payroll && data.payroll.timesheetEntries) {
      setTimesheetEntries(data.payroll.timesheetEntries);
    }
  }, [data]);

  return (
    <div className="py-2">
      {isLoading ? (<Spinner />) : (
        <SimpleTable
          columns={masterTimesheetColumns}
          data={timesheetEntries}
          maxHeight={"85vh"}
        />
      )}
    </div>
  );
}
