import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import SafeHTMLDisplay from '../../../../common/SafeHTMLDisplay';
import { trainingVideo } from '../../../../../types/trainingVideos.type';
import { useGetTraningVideoQuery } from '../../../../../services/endpoints/content/trainingVideo';

export const TrainingVideosDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetTraningVideoQuery(+params.id!);
  const [ trainingVideo, setTrainingVideos] = useState<trainingVideo>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
   
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setTrainingVideos(data.trainingVideo);
    }
  }, [data]);

  if (isLoading || !trainingVideo) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <div>Loading...</div>
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/training/videos')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Videos List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Video #${trainingVideo?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/training/videos/${trainingVideo?.id}/edit`)}
          >
            Edit Video
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete Video
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Id:" text={trainingVideo?.id} />
            <LabelText label="Name:" text={trainingVideo?.name} />
            <LabelText label="Category:" text={trainingVideo?.category} />
            <LabelText label="Code:" ><SafeHTMLDisplay htmlContent={trainingVideo?.code} /></LabelText>
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Video?"
        message="Are you sure you want to remove this video?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => navigate(`/admin/training-video/${trainingVideo?.id}/delete`)}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
