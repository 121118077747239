import { useEffect, useState } from "react";
import Calendar, { OnArgs } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./ClassDetailsCalendar.css";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";
import { getRangeDate } from "../../../utils/utils";
import { Lesson } from "../../../types/lesson.type";

const formatMonthYear = (locale: string | undefined, date: Date): string => {
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `<span class="month">${month}</span>\n<span class="year">${year}</span>`;
};

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface ClassDetailsCalendarProps {
  dateChange: ({ start, end }: { start: string; end: string }) => void;
  lessons: Lesson[] | undefined;
  selectedLesson: Lesson | null;
  selectedDate: (date: string) => void;
}
const ClassDetailsCalendar: React.FC<ClassDetailsCalendarProps> = ({
  dateChange,
  lessons,
  selectedLesson,
  selectedDate,
}) => {
  const [dateRange, setDateRange] = useState<{ start: string; end: string } | null>(null);
  const [noClassDates, setNoClassDates] = useState<string[]>([]);
  const handleDateChange = ({
    activeStartDate,
  }: {
    activeStartDate: Date | null;
  }) => {
    if (activeStartDate) {
      const { start, end } = getRangeDate(activeStartDate);
      dateChange({ start, end });
      setDateRange({ start, end });
    }
  };

  useEffect(() => {
    if (lessons?.length && dateRange) {
      let noClasses: string[] = [];
      lessons.forEach((lesson) => {
        if (lesson.course.no_class) {
          const classesDates = lesson.course.no_class.replace(/ /g, "").split(",").filter(d => dateRange.start <= d && d <= dateRange.end);
          noClasses = [...noClasses, ...classesDates];
        }
      });
      if (noClasses.length) {
        setNoClassDates(noClasses);
      }
    }
  }, [lessons]);
  return (
    <>
      <Calendar
        onActiveStartDateChange={handleDateChange}
        view="month"
        tileClassName={({ date, view }) => {
          const dateFormat = date.toISOString().slice(0, 10);
          if (lessons?.length) {
            const dateIncluded = lessons.some((l) => l.date === dateFormat);
            const noClassDateIncluded = noClassDates.some(
              (c) => c === dateFormat
            );
            if (selectedLesson?.date === dateFormat) {
              return "class-date-selected";
            } else if (dateIncluded) {
              return "class-date";
            } else if (noClassDateIncluded) {
              return "no-class-date";
            }
          }
          return null;
        }}
        prev2Label={null}
        next2Label={null}
        onClickDay={(value: Date) => {
          selectedDate(value.toISOString().slice(0, 10));
        }}
        navigationLabel={({ date, view }) => {
          const formatDate = formatMonthYear("default", date);
          return <SafeHTMLDisplay htmlContent={formatDate} />;
        }}
      />
    </>
  );
};

export default ClassDetailsCalendar;
