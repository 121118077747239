import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Joke } from '../../../types/content.type';
import { sfcApi } from '../../api';


export const jokeApi = sfcApi.injectEndpoints({
    endpoints: (builder) => ({
        getJokes: builder.query<GetQueryResponse, GetQueryParams | void>({
            query: (params) => ({
                url: `jokes${params?.scope ? `?scope=${params.scope}` : ''}`,
                method: 'POST',
                body: params?.data
            }),
            providesTags: ['Joke']
        }),
        getJoke: builder.query<{ joke: Joke }, number>({
            query: (id) => `jokes/${id}`,
            providesTags: (result, error, id) => [{ type: 'Joke', id }]
        }),
        createJoke: builder.mutation<{ joke: Joke }, Partial<Joke>>({
            query: (data) => ({
                url: `jokes/create`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Joke']
        }),
        updateJoke: builder.mutation<{ joke: Joke }, Partial<Joke>>({
            query: ((data) => {
                const { id } = data;
                delete data.id;
                return {
                    url: `jokes/update/${id}`,
                    method: 'PUT',
                    body: data,
                };
            }),
            invalidatesTags: ['Joke']
        }),
        deleteJoke: builder.mutation<{ joke: Joke }, number>({
            query: (id) => ({
                url: `jokes/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Joke']
        }),
    }),
});

export const {
    useGetJokesQuery,
    useGetJokeQuery,
    useCreateJokeMutation,
    useUpdateJokeMutation,
    useDeleteJokeMutation,
} = jokeApi;