import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { JokeCategory } from '../../../types/content.type';
import { sfcApi } from '../../api';


export const jokeCategoryApi = sfcApi.injectEndpoints({
    endpoints: (builder) => ({
        getJokeCategories: builder.query<GetQueryResponse, GetQueryParams | void>({
            query: (params) => ({
                url: `joke-categories`,
                method: 'POST',
                body: params?.data
            }),
            providesTags: ['JokeCategory']
        }),
        getJokeCategory: builder.query<{ jokeCategory: JokeCategory }, number>({
            query: (id) => `joke-categories/${id}`,
            providesTags: (result, error, id) => [{ type: 'JokeCategory', id }]
        }),
        createJokeCategory: builder.mutation<{ jokeCategory: JokeCategory }, Partial<JokeCategory>>({
            query: (data) => ({
                url: `joke-categories/create`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['JokeCategory']
        }),
        updateJokeCategory: builder.mutation<{ jokeCategory: JokeCategory }, Partial<JokeCategory>>({
            query: ((data) => {
                const { id } = data;
                delete data.id;
                return {
                    url: `joke-categories/update/${id}`,
                    method: 'PUT',
                    body: data,
                };
            }),
            invalidatesTags: ['JokeCategory']
        }),
        deleteJokeCategory: builder.mutation<{ jokeCategory: JokeCategory }, number>({
            query: (id) => ({
                url: `joke-categories/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['JokeCategory']
        }),
    }),
});

export const {
    useGetJokeCategoriesQuery,
    useGetJokeCategoryQuery,
    useCreateJokeCategoryMutation,
    useUpdateJokeCategoryMutation,
    useDeleteJokeCategoryMutation
} = jokeCategoryApi;