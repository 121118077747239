import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { StepProps } from './AddEditTheme';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import Editor from '../../../../../components/editor/Editor';

export const ThemeForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Details
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Field
            name="name"
            type="text"
            as={Form.Control}
            isInvalid={touched.name && !!errors.name}
          />
          <ErrorMessage name="name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="is_public">
          <Form.Label>Is public</Form.Label>
          <Form.Control
            as="select"
            name="user_type"
            value={values?.is_public}
            onChange={(e) => setFieldValue!('is_public', e.target.value)}
            isInvalid={touched.is_public && !!errors.is_public}
          >
            <option>Select</option>
            <option value="true">
              True
            </option>
            <option value="false">
              False
            </option>
          </Form.Control>
          <ErrorMessage name="is_public">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <Col xs={12} lg={12} className="pe-1">
        <Form.Group className="mb-10 mb-lg-3" controlId="details">
          <Form.Label>Details</Form.Label>
          <Field 
          name="details" 
          component={Editor} 
          className="form-control"
          isInvalid={touched.details && !!errors.details}/>
          <ErrorMessage name="details">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>

      <FieldArray 
        name="lesson_plans"
        render={(arrayHelpers) => (
          <Form.Group className="mb-1 mb-lg-3" controlId="lesson_plans">
            <Form.Label className="h4">Lesson Plans</Form.Label>
            <br />
            <div className="multi-content-scroll mt-2">
              <Row>
              {values?.lesson_plans && values?.lesson_plans.length > 0 ? (
                values.lesson_plans.map((lesson_plan, index) => {
                  let meta = {touched: Array.isArray(touched.lesson_plans) ? touched.lesson_plans[index] : {}, error: Array.isArray(errors.lesson_plans) ? (errors.lesson_plans[index] ?? {}) : {}};
                  return (
                    <Col  key={index} lg={3} className="mb-2">
                    <Card border="primary">
                    {typeof errors.lesson_plans === "string" ? <div>{errors.lesson_plans}</div> : null}
                    {/* TODO: Check how the lesson plans works on Theme section */}
                    <Card.Body>
                      <Col className="ms-1">
                        <Row>
                          <Col xs={12} className="pe-1">
                              <Form.Group className="mb-1 mb-lg-3" controlId={`lesson_plans.${index}.id`}>
                                <Field 
                                  as={FormSelect}
                                  name={`lesson_plans.${index}.id`}
                                  label="Name"
                                  options={values?.lesson_plans?.map((lp) => ({ value: lp.id, label: lp.name })) ?? []}
                                />
                              </Form.Group>

                              <Form.Group className="mb-1 mb-lg-3" controlId={`lesson_plans.${index}.featured_on`}>
                                <Form.Label>Featured On</Form.Label>
                                <Field
                                  name={`lesson_plans.${index}.featured_on`}
                                  type="date"
                                  as={Form.Control}
                                  isInvalid={meta.touched?.featured_on && !!(typeof meta.error === "string" ? meta.error : meta.error.featured_on)}
                                />
                                <ErrorMessage name={`lesson_plans.${index}.featured_on`}>
                                  {(msg) => (
                                    <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                  )}
                                </ErrorMessage>
                              </Form.Group>

                              <Form.Group className="mb-1 mb-lg-3" controlId={`lesson_plans.${index}.purpose`}>
                                <Form.Label>Purpose</Form.Label>
                                <Field
                                  name={`lesson_plans.${index}.purpose`}
                                  as={Form.Select}
                                  placeholder="Select"
                                  isInvalid={meta.touched?.purpose && !!(typeof meta.error === "string" ? meta.error : meta.error.purpose)}
                                >
                                  <option>Select</option>
                                  <option value={'any_time'}>Any Time</option>
                                  <option value={'first_class'}>First Class</option>
                                </Field>
                                <ErrorMessage name="payment_method">
                                  {(msg) => (
                                    <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                  )}
                                </ErrorMessage>
                              </Form.Group>
                            

                            <Button
                              variant="primary"
                              size="sm"
                              className="btn-box-shadow border-0 text-uppercase"
                              style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove  
                            </Button>                        
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col hidden={index === 0} xs={1}>
                        <CloseButton onClick={() => arrayHelpers.remove(index)} />
                      </Col> */}
                    </Card.Body>
                    {/* { index < values.lesson_plans.length - 1 ? <hr /> : <></>} */}
                  </Card>
              </Col>
                )})
              ) : (
                <></>
              )}
              </Row>
            </div>
            <button
              type='button'
              className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
              onClick={() => arrayHelpers.insert(values?.lesson_plans.length ?? 0, {
                id: "", 
              
              })} // insert an empty string at a position
            >
              + Add new Theme lesson plan
            </button>
          </Form.Group>
        )}
      />
    </Row>
  )
}
