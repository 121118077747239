import { formatUserName, getMimicUser, stopMimic } from '../../services/helper';

export const MimicHeader = () => {
  let currentUser = getMimicUser();
  return (
    currentUser !== null ? (
      <div className="text-center mimic-header text-light py-1" style={{ zIndex: 10, position: 'sticky', top: '0', backgroundColor: '#b81f69' }}>
        <div>You are logged in as <b>{formatUserName(currentUser)}</b>.
          <button className="btn btn-link text-light p-0 ms-1" onClick={stopMimic}>Click here to return to your own login.</button>
        </div>
      </div>
    )
    : <></>
  )
}