import { Col, Form, FormControl, Row, Stack } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditInvoice';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { formatDate } from '../../../../../utils/dateUtils';
import { addDays } from 'date-fns';

export const InvoiceForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="name">
            <Form.Label>Invoice Name</Form.Label>
            <Field
              name="name"
              type="text"
              as={Form.Control}
              isInvalid={touched.name && !!errors.name}
            />
            <ErrorMessage name="name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="po_number">
            <Form.Label>PO Number</Form.Label>
            <Field
              name="po_number"
              type="text"
              as={Form.Control}
              isInvalid={touched.po_number && !!errors.po_number}
            />
            <ErrorMessage name="po_number">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="issue_on">
            <Form.Label>Issue On</Form.Label>
            <Field
              name="issue_on"
              type="date"
              as={Form.Control}
              isInvalid={touched.issue_on && !!errors.issue_on}
            />
            <ErrorMessage name="issue_on">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="due_on">
            <Form.Label>Due On</Form.Label>
            <Field
              name="due_on"
              type="date"
              as={Form.Control}
              isInvalid={touched.due_on && !!errors.due_on}
            />
            <ErrorMessage name="due_on">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Stack direction="horizontal" gap={2}>
            <SimpleButton
              variant="primary"
              height={36}
              disabled={!values?.issue_on}
              onClick={() => values?.issue_on
                ? setFieldValue!('due_on', formatDate(addDays(new Date(values?.issue_on), 15), 'yyyy-MM-dd'))
                : {}
              }
            >
              Net 15
            </SimpleButton>
            <SimpleButton
              variant="primary"
              height={36}
              disabled={!values?.issue_on}
              onClick={() => values?.issue_on
                ? setFieldValue!('due_on', formatDate(addDays(new Date(values?.issue_on), 30), 'yyyy-MM-dd'))
                : {}
              }
            >
                Net 30
            </SimpleButton>
          </Stack>

        </Col>
      </Row>
    </>
  )
}
