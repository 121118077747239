import React from 'react';
import { TiArrowLeftThick } from 'react-icons/ti';
import { To, useNavigate } from 'react-router-dom';

interface BackButtonProps {
  text?: string;
  url?: string;
  color?: string;
  size?: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ text, url, color, size }) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(url ?? (-1 as To))}
      className="btn btn-link p-0 fw-bolder text-uppercase"
      style={{ color: color ?? 'rgba(94, 100, 105, 0.5)', fontSize: size ?? '.75rem', textDecoration: 'none' }}
    >
      <TiArrowLeftThick style={{ marginBottom: 4 }} />
      <span>{text}</span>
    </button>
  );
};
