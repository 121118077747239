import { useState, useEffect } from 'react';
import "../FranchiseLayout.css";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { useToast } from '../../../context/ToastContext';
import { GetQueryParams } from '../../../types/api.type';
import { Testimonial } from '../../../types/website.types';
import { useGetAllTestimonialsQuery } from '../../../services/endpoints/testimonial/testimonial';

// const testimonials = [
//   {
//     testimonial: `We have really enjoyed having Sticky Fingers Cooking here at our school, and appreciate your organization and professionalism. I think this is a great fit and I am so excited to hear about the kids trying new foods!`,
//     author: 'Gloria Roggow, After School Director',
//     img_link: 'https://uploads-ssl.webflow.com/5eb9d04b80931303f4166c06/5ed59771032ca86dc2b2cfd5_st-johns.png',
//     location: 'https://www.sjdenver.org/school',
//     location_link: 'St. Johns Lutheran School, Denver'
//   },
//   {
//     testimonial: "Five-star rating for Sticky Fingers Cooking! Gotta love our little sticky-fingered Saints!",
//     author: "Administrator",
//     img_link: "https://uploads-ssl.webflow.com/5eb9d04b80931303f4166c06/5ed5a3d005af1d41f9586133_st-joan.png",
//     location: "The Academy of St. Joan of Arc, Evanston",
//     location_link: "https://www.theacademysja.org/"
//   },
//   {
//       testimonial: "The Sticky Fingers Cooking staff are so good with the kids, so patient and the classes are well thought out and interactive with the students. I love having them come to our schools!",
//       author: "Patricia Dykstra, After School Program Manager",
//       img_link: "https://uploads-ssl.webflow.com/5eb9d04b80931303f4166c06/5ed59dfeba2aed8fe3a4028c_St%20Vrain%20chop_cmyk_rich%20blk.png",
//       location: "St. Vrain Valley School District, Colorado",
//       location_link: "https://www.svvsd.org/"
//   },
//   {
//       testimonial: "I can't believe this exists, the world needs this. My KIDS needs this!",
//       author: "Parent of Three Young Chefs",
//       img_link: "https://uploads-ssl.webflow.com/5eb9d04b80931303f4166c06/5ed5a3b1ba2aedcef5a41850_St-Ann-Logo-Refresh-01.png",
//       location: "St. Ann School, Chicago",
//       location_link: "https://www.stanncrusaders.org/"
//   },
//   {
//       testimonial: "Man, this really is a life skill I should have. I won't have my mom to cook for me forever!",
//       author: "Lilly, 8th Grade Student",
//       img_link: "https://uploads-ssl.webflow.com/5eb9d04b80931303f4166c06/5ed5a3b1ba2aedcef5a41850_St-Ann-Logo-Refresh-01.png",
//       location: "St. Ann School, Chicago",
//       location_link: "https://www.stanncrusaders.org/"
//   },
//   {
//       testimonial: "My son came home sooooooo thrilled with the cooking class yesterday. I asked him what he thought of it, and he said \"Mom, I love love LOVE it! It's so fun!\" So, I just wanted to say thank you for making my little guy the most jazzed I have seen him in a while about learning something new.",
//       author: "Rachel, Parent",
//       img_link: "https://uploads-ssl.webflow.com/5eb9d04b80931303f4166c06/5ed5a3c5f31d3f152c0514b0_round_edison_logo_smaller.png",
//       location: "Edison Elementary School, Denver",
//       location_link: "http://edison.dpsk12.org/"
//   },
//   {
//       testimonial: "I want to say another big THANK YOU for being a part of TEDxYouth@MileHigh! Thank you for your excitement about the event and for all the hard work you invested on your end to make it happen. It made me smile every time I looked across the room and saw such a big crowd of students that I couldn't even get close to your cooking exhibit! I love what Sticky Fingers Cooking is doing around our city, and I'm looking forward to more collaboration in the future!",
//       author: "Karmen Koehn, Exhibits Director",
//       img_link: "https://uploads-ssl.webflow.com/5eb9d04b80931303f4166c06/5ed92ad202f981242f413cdd_tedex-mile-high.png",
//       location: "TEDxMileHigh, Denver",
//       location_link: "https://www.tedxmilehigh.com/"
//   },
//   {
//       testimonial: "I've heard wonderful things about Sticky Fingers from our families. I've also seen you in action and it looks like a really fun experience for the children. We are very happy to have you at Kirby Hall and look forward to you returning for more sessions.",
//       author: "Helen Roberts, Director",
//       img_link: "https://uploads-ssl.webflow.com/5eb9d04b80931303f4166c06/5ed92a09917709f65dbf7cd4_kirby-hall-footer-logo.png",
//       location: "Kirby Hall, Austin",
//       location_link: "https://www.kirbyhallschool.org/"
//   }
// ];
const defaultScope = '';

export const TestimonialsSubpage = () => {

  const { addToast } = useToast();
  const [filters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading } = useGetAllTestimonialsQuery(filters);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setTestimonials(data.results);

    }
  }, [data]);

  return (
    <Container className="pb-5 text-blue">
    <div className="col-12 d-flex flex-nowrap justify-content-sm-center">
      <Button className="text-uppercase px-5 py-3 mb-5 bg-orange mt-3">
        Find Our Classes
        <FaArrowRight className="ms-2" size={22} />
      </Button>
    </div>

    <div className="div-masonry-3-column">
    {testimonials.map((testimonial, index) => (
        <>
          <div className="div-masonry-content testimonial-card">
            <div className="div-circle"><img alt="" className="image-testimonial" src={`${testimonial.s3_base_url}/${testimonial.image_file_name}`} /></div>
            <div className="div-quotation">
              <div className="text-quotation-mark-testimonial">&ldquo;</div>
              <div className="text-quotation">{testimonial.content}</div>
              <div className="text-quotation bold">{testimonial.name}</div>
              <a className="small_link green" href="https://www.sjdenver.org/school" target="_blank">{testimonial.origin}</a>
            </div>
          </div>
        </>
      ))}
    </div>
  </Container>
  )
}