import { Col, Form, Row, Stack } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from '../AddEditSession';

export const AdditionalInfoForm = ({ touched, errors, readOnly }: StepProps) => {

  return (
    <Row>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          <h6
            className="fw-bold mb-0"
            style={{ fontSize: '1.125rem', color: '#5E6469' }}
          >
            Additional Information
          </h6>
          <Form.Group controlId="admin_notes">
            <Form.Label>Admin Session Notes</Form.Label>
            <Field
              name="admin_notes"
              as="textarea"
              placeholder="Type here"
              rows={2}
              className="form-control"
              disabled={readOnly}
            />
            <Form.Text>
              Admin notes are only visible to admin on the SFC Dashboard.
              These notes are not visible on the roster to the school or to SFC instructors.
            </Form.Text>
            <ErrorMessage name="admin_notes" component="span" className="text-danger fs-small" />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Information for Browsing Parents</Form.Label>
            <Field
              name="description"
              as="textarea"
              placeholder="Type here"
              rows={2}
              className="form-control"
              disabled={readOnly}
            />
            <Form.Text>
              These details are visible on the Front End for unregistered parents
              and should include important information needed before signing up.
            </Form.Text>
            <ErrorMessage name="description" component="span" className="text-danger fs-small" />
          </Form.Group>

          <Form.Group controlId="registered_information">
            <Form.Label>Information for Registered Parents</Form.Label>
            <Field
              name="registered_information"
              as="textarea"
              placeholder="Type here"
              rows={2}
              className="form-control"
              disabled={readOnly}
            />
            <Form.Text>
              This text-box should include important information parents need to know after they have signed up.
              These details are displayed for each registered parent in their member account.
              In addition, these details automatically propogate onto the Welcome emails.
            </Form.Text>
            <ErrorMessage name="registered_information" component="span" className="text-danger fs-small" />
          </Form.Group>
        </Stack>
      </Col>
    </Row>
    
  )
}
