
import { PaymentIntent } from '@stripe/stripe-js/dist/api/payment-intents';
import { PaymentIntentPayload } from '../../../types/orders.type';
import { sfcApi } from '../../api';

export const stripeApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    createStripeIntent: builder.mutation<{payment_intent: PaymentIntent}, PaymentIntentPayload>({
      query: (params) => ({
        url: `stripe/create-payment-intent`,
        method: 'POST',
        body: params,
      }),
    }),
    getStripeIntent: builder.mutation<{payment_intent: PaymentIntent}, {payment_intent: string, location_id: number}>({
      query: (params) => ({
        url: `stripe/get-payment-intent`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useCreateStripeIntentMutation,
  useGetStripeIntentMutation,
} = stripeApi;