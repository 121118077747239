/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditTeamProfile';
import { quoteTypes } from '../../../../../utils/consts/website/quotes';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { getPartition } from '../../../../../utils/utils';
import ImageWithErrorHandling from '../../../../../components/image/ImageWithErrorHandling';
import { ImageSelect } from '../../../../../components/inputs/ImageSelect';
import Editor from '../../../../../components/editor/Editor';
import { useDataContext } from '../../../../../context';

export const TeamProfileForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
const { locationOptions } = useDataContext();

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Field
              name="name"
              as={Form.Control}
              isInvalid={touched.name && !!errors.name}
            />
            <ErrorMessage name="name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Field
              name="title"
              as={Form.Control}
              isInvalid={touched.title && !!errors.title}
            />
            <ErrorMessage name="title">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="location_id">
            <Field
              name="location_id"
              as={FormSelect}
              label="Location"
              options={locationOptions}
              isInvalid={touched.location_id && !!errors.location_id}
            />
            <ErrorMessage name="location_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="is_hq">
            <Form.Label>Is HQ</Form.Label>
            <Field
              name="is_hq"
              checked={values?.is_hq}
              as={Form.Check}
              isInvalid={touched.is_hq && !!errors.is_hq}
            />
            <ErrorMessage name="is_hq">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Field
              name="description"
              component={Editor}
              isInvalid={touched.description && !!errors.description}
            />
            <ErrorMessage name="description">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="quote_type">
            <Form.Label>Quote Type</Form.Label>
            <Field
              name="quote_type"
              as={Form.Select}
              isInvalid={touched.quote_type && !!errors.quote_type}
            >
               <option>Select</option>
              {Object.entries(quoteTypes).map((type, idx) => (
                <option key={idx} value={type[0]}>{type[1]}</option>
              ))}
            </Field>
            <ErrorMessage name="quote_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="quote">
            <Form.Label>Quote</Form.Label>
            <Field
              name="quote"
              as={Form.Control}
              isInvalid={touched.quote && !!errors.quote}
            />
            <ErrorMessage name="quote">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="image">
            <ImageSelect
              label={`Image${values?.image_file_name ? ` (${values?.image_file_name})` : ''}`}
              name="image"
              onChange={setFieldValue}
            />
            <ImageWithErrorHandling
              src={`${values?.s3_base_url}/${getPartition(values?.id)}/thumb/${values?.image_file_name}`} 
              alt="Error loading image"
            />
            <ErrorMessage name="image">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(JSON.stringify(msg, null, 2))}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
