import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { dateToUTC } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useGetDocumentQuery } from '../../../../../services/endpoints/content/document';
import { Document } from '../../../../../types/content.type';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';

export const DocumentDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetDocumentQuery(+params.id!);
  const [ document, setDocument] = useState<Document>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
   
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
     
      setDocument(data.document);
    }
  }, [data]);

  if (isLoading || !document) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/content/documents')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Documents List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Document #${document?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/content/documents/${document?.id}/edit`)}
          >
            Edit document
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete Document
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Title:" text={document?.title} />
            <LabelText label="Filename:" text={document?.file_file_name} />
            <LabelText label="Visibility:" text={document?.visibility} />
            <LabelText label="Description:" text={document?.description} />
            <LabelText label="EE:" text={document?.ee_id} />
            <LabelText label="UUID:" text={document?.uuid} />
            <LabelText label="Created at:" text={document?.created_at ? dateToUTC(new Date(document?.created_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="File file name:" text={document?.file_file_name} />
            <LabelText label="File content type:" text={document?.file_content_type} />
            <LabelText label="File file size:" text={document?.file_file_size} />
            <LabelText label="File uploaded at:" text={document?.file_updated_at} />
              
           
            {/* <LabelText label="Session:">
              <LinkCell
                content={document?.course?.title ?? 'No session title'}
                url={`/admin/schedule/sessions/${document?.course?.id}`}
                underline
              />
            </LabelText>
            <LabelText label="Store Name:" text={document?.store_name} />
            <LabelText label="Date of Purchase:" text={document?.purchased_at ? dateToUTC(new Date(document?.purchased_at), 'MM/dd/yyyy') : 'N/A'} />
            <LabelText label="Total:" text={`$${document?.total?.toFixed(2)}`} /> */}
            {/* TODO: add image icon/thumbnail */}
            {/* <LabelText label="Image:" text={document?.image_file_name ?? 'N/A'} />  */}
            {/* TODO: add image document download file */}
            {/* <LabelText label="Download:">
              <LinkCell
                content={document?.image_file_name ?? 'N/A'}
                url={`${document?.s3_original_url}`}
                underline
              />
            </LabelText> */}
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Document"
        message="Are you sure you want to remove this document?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => navigate(`/admin/content/documents/${document?.id}/delete`)}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
