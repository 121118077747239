import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';
import WelcomeBanner from '../../assets/images/welcome-banner.png';
import { SFCMemberRibbon } from './SFCMemberRibbon';
import { getCurrentUser } from '../../services/helper';
import { NavItem } from '../navbar/NavBarItems';

interface UserProfileLayoutProps {
  tabs: NavItem[];
  showTitle?: boolean;
}

export const UserProfileLayout: React.FC<UserProfileLayoutProps> = ({ tabs, showTitle = true }) => {
  const location = useLocation();
  const user = getCurrentUser();
  return (
    <Container fluid className="text-center">
      <Row className="vh-100">
        <Col
          className="d-none d-lg-block vh-100 p-5"
          lg={4}
          style={{
            background: `linear-gradient(180deg, rgba(96, 152, 184, 0) 10%, rgba(96, 152, 184, 0.5) 60%, rgba(43, 78, 100, 0.5) 100%), url(${WelcomeBanner})`,
            backgroundPositionY: 0,
            backgroundPositionX: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundBlendMode: "overlay",
          }}
        >
          <h2>Hello, {user ? user.first_name : "User"}</h2>
          <SFCMemberRibbon
            year={new Date(user?.createdAt ?? Date.now()).getFullYear()}
          />
        </Col>
        <Col className="text-start">
          <Container fluid className="m-lg-3">
            {showTitle && <h1 className="text-info">Account Details</h1>}
            {tabs.length > 0 && (
              <>
                <Nav
                  className="table-layout  my-2"
                  activeKey={location.pathname}
                  variant="underline"
                >
                  {tabs
                    .filter((t) => !t.hidden)
                    .map((tab, idx: number) => (
                      <Nav.Item key={idx}>
                        <Nav.Link
                          as={Link}
                          className="me-1 p-1"
                          to={tab.url ?? "#"}
                          href={tab.url ?? "#"}
                        >
                          {tab.title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                </Nav>
                <br />
              </>
            )}
            <Outlet />
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
