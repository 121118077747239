import { useEffect, useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import "./AddFavorite.css";
import { useGetFavoriteCourseQuery, useLazyToggleFavoriteCourseQuery } from "../../../../services/endpoints/schedule/course";
import { getCurrentUser } from "../../../../services/helper";

const currentUser = getCurrentUser();

interface AddFavoriteProps {
  course_id: number;
  show_label?: boolean;
  updateFavorites?: () => void;
};

const AddFavorite: React.FC<AddFavoriteProps> = ({course_id, show_label=true, updateFavorites}) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const { data, error, isLoading } = useGetFavoriteCourseQuery(course_id);
    const [ toggle ] = useLazyToggleFavoriteCourseQuery();

    useEffect(() => {
      if (data) {
        setIsFavorite(data.courseFavorited);
      }
    }, [data]);

    const toggleFavorite = async () => {
      let result = await toggle(course_id).unwrap();
      setIsFavorite(result.courseFavorited);
      if (updateFavorites) {
        updateFavorites();
      }
    };
    
    let classNames = `${show_label ? 'favorite-button' : 'favorite-button-no-label'} text-uppercase`;
    if (isFavorite) {
        classNames += ' favorite-button-active';        
    }
    if (!show_label) {
        classNames += ' favorite-button-no-label';
    }
    if (!currentUser?.isAuthenticated) {
      return <></>
    }

    return (
      <>
        <a className={classNames} onClick={toggleFavorite}>
          {isFavorite ? (
            <FaHeart className="favorite-button-icon" />
          ) : (
            <FaRegHeart className="favorite-button-icon" />
          )}{" "}
          {show_label && <span>{!isFavorite ? "Add to Favorites" : "Added to Favorites"}</span>}
        </a>
      </>
    );
};

export default AddFavorite;