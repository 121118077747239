import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getNestedTouchedTrue, getObjectDifference } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { phoneRegExp } from '../../../../../utils/consts';
import { SFCOrganization, OrganizationContact } from '../../../../../types/place.types';
import { useCreateOrganizationMutation, useGetOrganizationQuery, useUpdateOrganizationMutation } from '../../../../../services/endpoints/places/organization';
import { OrganizationForm } from './OrganizationForm';

const steps = [
  {name: 'Details', form: OrganizationForm},
];

export interface FormValues {
  id?: number;
  name: string;
  location_id: {value: number, label: string};
  venue_ids: {value: number, label: string}[];
  notes: string;
  contacts: OrganizationContact[];
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

export type CreateFormValues = Overwrite<FormValues, {
  venue_ids?: number[],
  location_id: number, 
}>

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

export const ContactSchema = Yup.object({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  title: Yup.string().optional(),
  phone_number: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number should have at least 10 digits')
    .optional(),
  email: Yup.string().email('Email is invalid').required('Email is required'),
  should_be_sent_roster: Yup.boolean().required().default(false),
  should_be_sent_invoices: Yup.boolean().required().default(false),
  should_be_sent_bulk_welcomes: Yup.boolean().required().default(false), 
  should_be_sent_bulk_thanks: Yup.boolean().required().default(false),
});

const validationSchemas = [
  Yup.object({
    name: Yup.string().required('Title is required'),
    location_id: Yup.object({value: Yup.number().min(1, "Location is required").required("Location is required"), label: Yup.string().optional()}).required('Location is required'),
    venue_ids: Yup.array().of(Yup.object({value: Yup.number().positive().required(), label: Yup.string().optional()})).optional(),
    notes: Yup.string().optional(),
    contacts: Yup.array().of(ContactSchema).optional(),
  }),
];

const formatFormValue = (formValue?: FormValues | SFCOrganization): CreateFormValues => {
  return JSON.parse(JSON.stringify(formValue)) as CreateFormValues;
}

interface AddEditOrganizationProps {
  editing?: boolean;
}

export const AddEditOrganization: React.FC<AddEditOrganizationProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createOrganization] = useCreateOrganizationMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { data, error, isLoading }  = useGetOrganizationQuery(Number(params.id!), { skip: !editing || !params.id });
  
  const [initialValues, setInitialValues] = useState<FormValues>({
    name: "",
    location_id: {value: 0, label: ''},
    venue_ids: [],
    notes: "",
    contacts: [],
  });

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading session', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.organization) {
      const { organization } = data;
      let values = {
        ...organization, 
        ...{venue_ids: organization.venues?.map(o => ({value: o.id, label: o.title})) ?? []}, 
        ...{location_id: {value: organization?.location?.id ?? 0, label: organization?.location?.title ?? ""}},
      };
      setInitialValues(values);
    }
  }, [data]);


  const handleAddOrganization = async (formValue: FormValues) => {
    try {
      let submission = formatFormValue(formValue);
      submission.venue_ids = formValue.venue_ids.map(o => o.value) ?? undefined;
      if (submission.venue_ids.length === 0) delete submission.venue_ids;
      submission.location_id = formValue.location_id.value;

      submission.contacts?.forEach(c => {
        delete c.full_name;
      });

      try {
        if (editing) {
          let formattedOrganization = formatFormValue(data?.organization);
          formattedOrganization.venue_ids = data?.organization.venues.map(o => o.id) ?? [];
          let update = getObjectDifference(formattedOrganization, submission);
          let submitUpdate = Object.keys(update).length > 0;
          if (submitUpdate) {
            update.id = data?.organization.id;
            const res = await updateOrganization(update as CreateFormValues).unwrap();
            if (res && res.organization) {
              addToast(`Organization updated succesfully`, 'success');
            }
          }
          else {
            addToast(`No changes to Organization`, 'info');
          }
          navigate(`/admin/places/organizations/${data?.organization.id}`);
        } else {
          const res = await createOrganization(submission).unwrap();
          if (res && res.organization) {
            navigate(`/admin/places/organizations/${res.organization.id}`);
            addToast('Organization created succesfully', 'success');
          } else throw new Error('A problem happened while creating Organization');
        }
      } catch (e) {
        addToast(getErrorMessage(e), 'error');
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
        location_id: {value: true},
        notes: true,
        contacts: Array.isArray(errors.contacts) ? errors.contacts?.map(() => getNestedTouchedTrue(ContactSchema)) : [],
        venue_ids: Array.isArray(errors.venue_ids) ? errors.venue_ids?.map(() => getNestedTouchedTrue(Yup.object({value: Yup.number().positive()}))) : [],
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to organization${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/places/organizations/${data?.organization.id}` : '/admin/places/organizations'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Organization
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddOrganization}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => {
                return (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
