import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditScholarship';
import { scholarshipTypes } from '../../../../../utils/consts/orders/scholarships';

export const ScholarshipForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="scholarship_type">
            <Form.Label>Scholarship Type</Form.Label>
            <Field
              name="scholarship_type"
              as={Form.Select}
              isInvalid={touched.scholarship_type && !!errors.scholarship_type}
            >
              <option>Select</option>
              {Object.entries(scholarshipTypes).map((type, idx) => (
                <option key={idx} value={type[0]}>{type[1]}</option>
              ))}
            </Field>
            <ErrorMessage name="scholarship_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="percent_discount">
            <Form.Label>Percent Discount</Form.Label>
            <Field
              name="percent_discount"
              type="number"
              min={1}
              as={Form.Control}
              isInvalid={touched.percent_discount && !!errors.percent_discount}
            />
            <ErrorMessage name="percent_discount">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
      </Row>
    </>
  )
}
