import { StepProps } from "../AddEditRecipe";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { Field } from "formik";
import { useEffect, useRef, useState } from "react";

export const RecipeImageElement = ({
  onChange,
  onRemove,
  index,
  file,
  metadata
}: {
  onChange: any;
  onRemove: any;
  index: number;
  file: File | null;
  metadata: { id?: number; source?: string; destroy: boolean } | undefined;
}) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    onChange({ file });
  };
  return (
    <div
      className="d-flex align-items-center justify-content-between px-2 px-lg-2 py-2"
      style={{
        background: "#F2F2F2",
        border: "1px solid rgba(94, 100, 105, .5)",
        borderRadius: 8,
      }}
    >
      <Stack direction="vertical">
        {!metadata?.id && (
          <Form.Group>
            <Form.Label>Image</Form.Label>
            <input
              name={`images[${index}]`}
              ref={fileRef}
              className="form-control"
              accept="image/*"
              type="file"
              onChange={handleFileChange}
            />
            {/* Display the name of the file if it exists */}
            {file && <div className="mt-2">Selected file: {file.name}</div>}
          </Form.Group>
        )}
        <Form.Group controlId={`image_metadata[${index}].source`}>
          <Form.Label>Source</Form.Label>
          <Field
            name={`image_metadata[${index}].source`}
            type="text"
            as={Form.Control}
          />
        </Form.Group>
        <Form.Group controlId={`image_metadata[${index}].destroy`}>
          <Form.Label>
            <Field type="checkbox" name={`image_metadata[${index}].destroy`} />
            Destroy
          </Form.Label>
        </Form.Group>
        {!metadata?.id && (
          <Button
            variant="primary"
            size="sm"
            className="btn-box-shadow border-0 text-uppercase"
            style={{ width: 200 }}
            onClick={() => {
              onRemove();
            }}
          >
            Remove
          </Button>
        )}
      </Stack>
    </div>
  );
};

export const RecipeImagesForm = ({
  touched,
  errors,
  values,
  setFieldValue,
  readOnly,
}: StepProps) => {
  const [chunks, setChunks] = useState<any[][]>([]);
  const handleChangeRegister = (data: any, index: any) => {
    const images = [];
    if (values!.images) {
      images.push(...values!.images);
    }
    setFieldValue!(
      "images",
      images!.map((image: any, i: any) => {
        if (i === index) {
          return data.file;
        }
        return image;
      })
    );
  };
  const handleRemove = (index: any) => {
    let images: any[] = [];
    let imageMetadata: any[] = [];
    if (values!.image_metadata) {
      imageMetadata = [...values!.image_metadata];
      imageMetadata.splice(index, 1);
    }
    if (values!.images) {
      images = [...values!.images];
      images.splice(index, 1);
    }

    setFieldValue!("images", images);
    setFieldValue!("image_metadata", imageMetadata);
  };
  const handleClick = () => {
    setFieldValue!("images", [...values!.images!, null]);
    const fileMetadata = {
      source: "",
      destroy: false,
    };
    setFieldValue!("image_metadata", [...values!.image_metadata!, fileMetadata]);
  };
  const chunkArray = (array: any[], chunkSize: number) => {
    const result = [];
    if (array.length) {
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
    }
    return result;
  };
  useEffect(() => {
    setChunks(chunkArray(values.image_metadata, 3));
  }, [values.image_metadata]);
  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Recipe Images
      </h6>
      <Col xs={12}>
        <Button
          variant="primary"
          size="sm"
          className="btn-box-shadow border-0 text-uppercase"
          style={{ width: 200 }}
          onClick={() => handleClick()}
        >
          Add New Recipe Image
        </Button>
        {chunks.map((chunk, index) => (
          <Row key={`file-row-${index}`}>
            {chunk.map((image, idx) => (
              <Col key={`file-col-${idx}`} xs={12} lg={4} className="mb-3">
                <RecipeImageElement
                  key={`file-${idx}`}
                  onChange={(obj: any) => handleChangeRegister(obj, idx)}
                  index={idx}
                  onRemove={() => handleRemove(idx)}
                  file={values.images[idx]}
                  metadata={values.image_metadata![idx]}
                />
              </Col>
            ))}
          </Row>
        ))}
      </Col>
    </Row>
  );
};
