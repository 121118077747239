import React, { ReactNode } from 'react';
import { Accordion } from 'react-bootstrap';
import './SFCAccordion.css';

interface SFCAccordionProps {
  children: ReactNode;
  defaultActiveKey?: string|string[];
}

export const SFCAccordion: React.FC<SFCAccordionProps> = ({ children, defaultActiveKey }) => {
  return (
    <Accordion defaultActiveKey={defaultActiveKey ?? "0"} alwaysOpen className="sfc-accordion">
      {children}
    </Accordion>
  )
}
