import React from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from './CustomComponents/Toolbar';
import { EventCell } from './CustomComponents/EventCell';
import { eventColors, eventTypes } from '../../../utils/consts/index';
import { EventWrapper } from './CustomComponents/EventWrapper';
import { CustomDayCellWrapper } from './CustomComponents/DayCellWrapper';
// Define your locales if necessary
import enUS from 'date-fns/locale/en-US';
import { format, getDay, parse, startOfWeek } from 'date-fns';
import { Lesson } from '../../../types/lesson.type';
import { dateInTimeZone } from '../../../utils/dateUtils';

const locales: { [key: string]: any } = {
  'en-US': enUS,
};


// Localizer configuration
const localizer = dateFnsLocalizer({
  format: (date: Date, formatString: string, culture?: string) => {
    return format(date, formatString, { locale: locales[culture || 'en-US'] });
  },
  parse: (dateString: string, formatString: string, culture?: string) =>
    parse(dateString, formatString, new Date(), { locale: locales[culture || 'en-US'] }),
  startOfWeek: (culture?: string) =>
    startOfWeek(new Date(), { locale: locales[culture || 'en-US'] }),
  getDay: (date: Date) => getDay(date),
  locales,
});

// Define the type for events
export interface Event {
  title: string;
  start: Date;
  end: Date;
  type: string;
  lesson?: Lesson;
}

export const eventStyleGetter = (event: Event) => {
  const style = {
    backgroundColor: eventColors[event.type],
    borderRadius: '4px',
    color: 'white',
    marginBottom: 2
  };

  return {
    style,
  };
};

interface SFCCalendarProps {
  events: Event[];
  showFullEvents?: boolean;
  loading?: boolean;
  currentDates?: string[];
}

export const SFCCalendar: React.FC<SFCCalendarProps> = ({
  events,
  showFullEvents = true,
  loading,
  currentDates
}) => {

  return (
    <Calendar<Event>
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      views={['month']} // Only show the month view
      defaultView="month" // Set the default view to month
      style={{ height: '100vh', background: '#FFFFFF', ...(loading && { filter: 'blur(2px)' }) }}
      components={{
        toolbar: Toolbar,
        dateCellWrapper: (props) => {

          let dateString = dateInTimeZone(props.value).toLocaleDateString();
          const eventList = events.filter(
            (event) =>
              event.start.toLocaleDateString() === dateString ||
              event.end.toLocaleDateString() === dateString
          );
          return (
          <CustomDayCellWrapper
            {...props}
            summarized={!showFullEvents}
            events={eventList}
            currentDates={currentDates}
          />
        )},
        month: {
          header: (props) => <>{localizer.format(props.date, 'EE')}</>,
        },
        eventWrapper: (props) => <EventWrapper {...props} summarized={!showFullEvents} />,
        event: EventCell,
      }}
      eventPropGetter={eventStyleGetter}
      showAllEvents
    />
  );
};
