import "../FranchiseLayout.css";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";

export const AvailableJobsSubpage = () => {
  return (
    <Container className="pb-5 text-blue">
      <div className="col-12 d-flex flex-nowrap justify-content-sm-center">
        <Button className="text-uppercase px-5 py-3 mb-5 bg-orange mt-3">
          Find Our Classes
          <FaArrowRight className="ms-2" size={22} />
        </Button>
      </div>



      <Container>
        <div className="column-content">
          <h2>Now hiring!</h2>
          <p>Join our team! <strong>Thyme to Turnip the Beet on What Kids Eat!®</strong><br/><br/>We are on the lookout for high-energy, creative chef instructors to lead children’s cooking classes (including after-school programs, summer camps, and weekend classes and parties) for children ages 4 to 18. All positions are part-time.</p>
          <div className="div-break"></div>
          <h3>Qualifications</h3>
          <p>Sticky Fingers Cooking is looking for experienced teachers and/or chefs who have a love for cooking and are committed to working with children in public, private, and charter schools.</p>
          <ul className="w-list-unstyled">
            <li className="list-item">Enthusiasm for teaching and cooking</li>
            <li className="list-item">Dedication to children who have diverse backgrounds and academic levels</li>
            <li className="list-item">Interest in cooking and healthy alternatives to traditional recipes</li>
            <li className="list-item">Experience working with large groups of children in a school, camp, etc.</li>
            <li className="list-item">Effective classroom management skills, with teaching experience preferred</li>
            <li className="list-item">Punctuality and reliability, with access to reliable transportation</li>
            <li className="list-item">Love for the messy, magical combination of cooking and children</li>
            <li className="list-item">Flexibility for a fast-paced and high-energy environment </li>
            <li className="list-item">Availability at least 2 days a week Monday-Friday between the hours of 2-5 pm for one-hour classes for 6-8 week sessions</li>
          </ul>
          <p>Serious applicants only please! If considered, Sticky Fingers Cooking will require a background check.</p>
          <Button className="  text-uppercase button-radius mb-5">
            apply now
          </Button>
          <div className="div-break"></div>
          <h3>Responsibilities</h3>
          <ul className="list w-list-unstyled">
            <li className="list-item">Lead Pre-K, Elementary and/or middle school students in exploring fun &amp; creative recipes</li>
            <li className="list-item">Facilitate mobile cooking classes. Teach cutting, stirring, measuring, and nutrition.</li>
            <li className="list-item">Actively communicate with administration staff</li>
            <li className="list-item">Work with large groups of children in a school, camp, etc.</li>
            <li className="list-item">Interact with parents and school staff</li>
            <li className="list-item">Shop independently for weekly recipes</li>
            <li className="list-item">Travel to classroom location</li>
          </ul>
          <p>Serious applicants only please! If considered, Sticky Fingers Cooking will require a background check.</p>
          <Button className="  text-uppercase button-radius mb-5">
            apply now
          </Button>
          <div className="div-break"></div>
          <h3>What Our Chefs Have to Say</h3>
          <div className="d-flex">
            <div className="child-50-rounded margin-right">
              <div className="text-quotation-mark-testimonial margin-top">“</div>
              <div className="text-quotation">The best part of the cooking class today was when I introduced the special ingredient as artichokes. One kid asked very seriously, &quot;What if I don&#x27;t eat vegetables?&quot; to which I replied, &quot;Let&#x27;s just wait and see if you eat them in the ravioli we make.&quot; After he had gobbled up all of his ravioli at the end of class, I asked him, &quot;So how were those vegetables?&quot; He smiled bashfully and said, &quot;They were pretty great!&quot;</div>
              <div className="text-quotation bold">Chef Ariel, Texas<br/></div>
            </div>
            <div className="child-50-rounded">
              <div className="text-quotation-mark-testimonial margin-top">“</div>
              <div className="text-quotation">I love sharing my passion for cooking (and eating) with my Sticky Fingers students! There is nothing better than the look of pride on a child&#x27;s face when they taste a dish they just made and discover how delicious it is. It is amazing what the pickiest eater will try if they had a hand in making it: Tofu Picatta, Watermelon Poke, Kale Ravioli. My heart melts when they ask if they can eat some raw kale!</div>
              <div className="text-quotation bold">Chef Dani, Colorado<br/></div>
            </div>
          </div>
        </div>
      
      </Container>
  </Container>
  )
}