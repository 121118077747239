export const sessionTypes: { [key: string]: string } = {
  after_school: 'After School',
  camp: 'Camp',
  event: 'Event',
};

export const sessionTypeNumbers: { [key: number]: string } = {
  1: 'After School',
  2: 'Camp',
  3: 'Event',
};

export const sessionStatus: { [key: string]: string } = {
  open: 'Open',
  offline: 'Offline',
  cancelled: 'Cancelled',
};

export const programTypes: { [key: string]: string } = {
  in_person: 'In-person',
  online: 'Online'
};

export const paymentTypes: { [key: string]: string } = {
  onsite: 'Onsite',
  website: 'Website',
};

export const payTypes: { [key: string]: string } = {
  per_hour: 'Per hour',
  flat_rate: 'Flat Rate',
};

export const payTypesMapping: { [key: string]: string } = {
  'per_hour': 'Hourly Rate',
  'flat_rate': 'Flat Rate',
};