import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useArchiveWaitlistMutation } from '../../../../../services/endpoints/schedule/waitlist';
import { useToast } from '../../../../../context/ToastContext';
import { Spinner } from 'react-bootstrap';

export const ArchiveWaitlist = () => {
  const params = useParams();
  const id = +params.id!;
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [archiveWaitlist] = useArchiveWaitlistMutation();
  const [loading, setLoading] = useState(true);

  const archiveFn = async () => {
    try {
      const res = await archiveWaitlist(id).unwrap();
      if (!res.waitlist || res.waitlist.status !== 'archived')
        throw new Error();
      addToast(`Waitlist #${id} archived correctly`, 'success');
    } catch (e) {
      navigate('/admin/schedule/waitlists');
      addToast('Error while archiving waitlist', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    archiveFn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
 
  return (
    <Navigate to={`/admin/schedule/waitlists/${id}`} />
  )
}
