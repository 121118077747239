import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { InvoiceClient } from '../../../types/finance.types';
import { sfcApi } from '../../api';

export interface InvoiceClientCreateUpdateValues {
  id?: number;
  name: string;
}

export interface PayInvoiceClientsValues {
  ids: number[];
  check_number?: number;
}

export interface PayInvoiceClientsResponse {
  count: number;
  rows: InvoiceClient[];
}

export const invoiceClientApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoiceClients: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `invoiceClients${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['InvoiceClient']
    }),
    getInvoiceClient: builder.query<{ invoiceClient: InvoiceClient }, number>({
      query: (id) => `invoiceClients/${id}`,
      providesTags: (result, error, id) => [{ type: 'InvoiceClient', id }]
    }),
    createInvoiceClient: builder.mutation<{ invoiceClient: InvoiceClient }, InvoiceClientCreateUpdateValues>({
      query: (data) => ({
        url: `invoiceClients/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['InvoiceClient']
    }),
    updateInvoiceClient: builder.mutation<{ invoiceClient: InvoiceClient }, InvoiceClientCreateUpdateValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `invoiceClients/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error) => [{ type: 'InvoiceClient', id: result?.invoiceClient?.id }, 'InvoiceClient']
    }),
    deleteInvoiceClients: builder.mutation<{ invoiceClient: InvoiceClient }, {ids: number[]}>({
      query: (params) => ({
        url: `invoiceClients`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['InvoiceClient'], //(result, error, params) => [...params.ids.map(id => ({ type: 'InvoiceClient', id })), 'InvoiceClient']
    }),
  }),
});

export const {
  useGetAllInvoiceClientsQuery,
  useGetInvoiceClientQuery,
  useCreateInvoiceClientMutation,
  useUpdateInvoiceClientMutation,
  useDeleteInvoiceClientsMutation,
} = invoiceClientApi;