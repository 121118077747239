import { CreateFormValues } from '../../../features/dash/views/content/Themes/AddEditTheme';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Theme } from '../../../types/theme.type';
import { LessonPlanThemesForm } from '../../../types/theme.type';
import { sfcApi } from '../../api';

export interface ThemesCreateUpdateValues {
  id?: number;
  name: string;
  is_public?: string;
  details:string;
  lesson_plans: LessonPlanThemesForm[];
}

export const themeApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getThemes: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `themes${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getTheme: builder.query<{ theme: Theme }, number>({
      query: (id) => `themes/${id}`,
    }),

    getThemesSimple: builder.query<Theme[], void>({
      query: () => ({
        url: `themes/simple`,
        method: 'POST',
      }),
    }),

    createTheme: builder.mutation<{ theme: Theme }, ThemesCreateUpdateValues>({
      query: (data) => {
        return {
          url: 'themes/create',
          method: 'POST',
          body: {
            ...data
          },
        }
      },
    }),
    updateTheme: builder.mutation<{ theme: Theme }, ThemesCreateUpdateValues>({
      query: (data) => {

        const formData = new FormData();
        let payload: any = {
          url: `themes/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
        delete data.id;
       
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
       
        if (data.lesson_plans?.length !== undefined) {
          formData.delete('lesson_plans');
        }
        
        payload.body = formData;

        return payload;
      },
      invalidatesTags: ['Themes']
    }),

    deleteTheme: builder.mutation<{ theme: Theme }, number>({
      query: (id) => ({
        url: `themes/${id}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetThemesQuery,
  useGetThemesSimpleQuery,
  useGetThemeQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation,
  useDeleteThemeMutation
} = themeApi;