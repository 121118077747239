import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useNavigate } from 'react-router-dom';
import { scholarshipTypes } from '../../../../../utils/consts/orders/scholarships';
import { useGetAllScholarshipsQuery } from '../../../../../services/endpoints/orders/scholarship';
import { Scholarship } from '../../../../../types/orders.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';

export const scholarshipsColumns: ColumnDef<Scholarship>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Scholarship Type',
    accessorKey: 'scholarship_type',
    cell: ({ getValue }) => scholarshipTypes[getValue<string>()],
  },
  {
    header: 'Percent Discount',
    accessorKey: 'percent_discount',
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/orders/scholarships/${row.original.id}`} underline />
        <LinkCell content="Edit" url={`/admin/orders/scholarships/${row.original.id}/edit`} underline />
      </Stack>
    )
  },
];

export const ScholarshipsView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: '' });
  const { data, error, isLoading, isFetching } = useGetAllScholarshipsQuery(filters);
  const [scholarships, setScholarships] = useState<Scholarship[]>([]);;
  const navigate = useNavigate();

  const filterFormFields: FilterField[] = [
    {
      name: 'scholarship_type',
      type: 'number',
      element: (
        <FormSelect
          name="scholarship_type"
          label="Scholarship Type"
          options={Object.entries(scholarshipTypes).map((type) => ({ value: type[0], label: type[1] }))}
        />
      ),
    },

    
  ];
  
  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading scholarships', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setScholarships(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name="Scholarships"
        columns={scholarshipsColumns}
        data={scholarships}
        count={data?.count}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters.data?.filterBy}
        indexDownloadPath='scholarships'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={350}
            onClick={() => navigate('/admin/orders/scholarships/new')}
          >
            New scholarship
          </SimpleButton>
        )}
      />
    </div>
  );
}
