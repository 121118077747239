import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { SavedLocationSelection, getPreferredLocation, setPreferredLocation } from '../services/helper';

interface MyLocationContextType {
  myLocation: SavedLocationSelection | null;
  saveMyLocation: (location: string, store: boolean) => SavedLocationSelection | null; 
}

const MyLocationContext = createContext<MyLocationContextType | undefined>(undefined);

export const useMyLocationContext = () => {
  const context = useContext(MyLocationContext);
  if (!context) {
    throw new Error('useMyLocationContext must be used within a MyLocationProvider');
  }
  return context;
};

const savedMyLocation = getPreferredLocation();

export const MyLocationProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [myLocation, setMyLocation] = useState<SavedLocationSelection | null>(savedMyLocation);

  const saveMyLocation = useCallback((location: string, store: boolean = false) => {
    if (store) {
      setPreferredLocation(location);
      let loc = getPreferredLocation(); 
      if (loc) {
        setMyLocation(loc);
      }
      return loc;
    }
    else {
      try {
        setMyLocation(JSON.parse(location) as SavedLocationSelection);
      } catch (error) {
        console.error('Failed to parse location:', error);
        // Optionally set location to null or handle the error
      }
      return getPreferredLocation();
    }
  }, []);

  return (
    <MyLocationContext.Provider value={{saveMyLocation, myLocation}} >{children}</MyLocationContext.Provider>
  );
};
