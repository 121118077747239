import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Feedback } from '../../../types/website.types';
import { sfcApi } from '../../api';

export const feedbackApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllFeedbacks: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `feedbacks${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Feedback']
    }),
    getFeedback: builder.query<{ feedback: Feedback }, number>({
      query: (id) => `feedbacks/${id}`,
      providesTags: (result, error, id) => [{ type: 'Feedback', id }]
    }),
    createFeedback: builder.mutation<{ feedback: Feedback }, Feedback>({
      query: (data) => ({
        url: `feedbacks/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Feedback']
    }),
    updateFeedback: builder.mutation<{ feedback: Feedback }, Feedback>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `feedbacks/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error) => [{ type: 'Feedback', id: result?.feedback?.id }, 'Feedback']
    }),
    deleteFeedback: builder.mutation<{ feedback: Feedback }, number>({
      query: (id) => ({
        url: `feedbacks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Feedback'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Feedback', id })), 'Feedback']
    }),
    deleteFeedbacks: builder.mutation<{ feedback: Feedback }, {ids: number[]}>({
      query: (params) => ({
        url: `feedbacks/bulk-delete`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['Feedback'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Feedback', id })), 'Feedback']
    }),
  }),
});

export const {
  useGetAllFeedbacksQuery,
  useGetFeedbackQuery,
  useCreateFeedbackMutation,
  useUpdateFeedbackMutation,
  useDeleteFeedbackMutation,
  useDeleteFeedbacksMutation,
} = feedbackApi;