import { FadeIn } from '../animations/FadeIn';
import { Form, FormControl, Image, Stack } from "react-bootstrap";
import { Formik, ErrorMessage, Form as FormikForm, FormikProps, Field } from "formik";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../components/buttons/SimpleButton';
import * as Yup from "yup";
import React, { useState } from "react";
import SFCLogoMD from '../../assets/images/sticky-fingers-logo-stacked-2.png';
import { useForgotPasswordMutation } from "../../services/endpoints/people/user";
import { useToast } from '../../context';
import { getErrorMessage } from '../../utils/utils';

type Props = {}

interface FormValues {
  email: string;
}

const ForgotPassword: React.FC<Props> = () => {
  const navigate: NavigateFunction = useNavigate();

  const [forgotPassword] = useForgotPasswordMutation();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const { addToast } = useToast();

  const initialValues: FormValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
  });

  const sendInstructions = (formValue: FormValues) => {
    const { email } = formValue;

    setLoading(true);
    setMessage("");
    forgotPassword(email).unwrap().then((response) => {
      setMessage(response.message);      
    })
    .catch((error) => {
      addToast(getErrorMessage(error), 'error');
    });

    setLoading(false);
  };

  return (
    <FadeIn className="d-flex flex-column flex-wrap align-content-center">
      <div className="pb-4">
        <Image fluid className="d-block d-sm-none" src={SFCLogoMD} alt="Sticky Fingers Logo" />
        <Image className="d-none d-sm-inline-flex" src={SFCLogoMD} style={{ maxWidth: 343 }} alt="Sticky Fingers Logo" />
      </div>

      <h2 className="text-dark fw-bold mt-4 mt-lg-5 text-start text-md-center" style={{ fontSize: 32 }}>
        Forgot your password?
      </h2>
      <Stack className="w-100 mt-3" style={{ maxWidth: 480 }} gap={1}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={sendInstructions}
        >
          {({ errors, touched }: FormikProps<FormValues>) => (
            <FormikForm className="text-start">

              <Form.Group className="mb-2" controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Field
                  name="email"
                  type="email"
                  as={Form.Control}
                  isInvalid={touched.email && !!errors.email}
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                  )}
                </ErrorMessage>
              </Form.Group>
              
              <SimpleButton type="submit" className="w-100 my-3" variant="primary" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Send instructions
              </SimpleButton>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </FormikForm>
          )}
        </Formik>

        <div className="my-3 d-flex justify-content-center align-items-center">
          <span className="ff-inter fs-small text-secondary">Don't have an account?</span>
          <button
            onClick={() => navigate('/users/register')}
            className="btn btn-link p-0 ms-2 fs-small fw-bolder text-uppercase link-primary link-underline-opacity-0"
          >
            Sign Up
          </button>
        </div>
      </Stack>
    </FadeIn>
  );
};

export default ForgotPassword;
